import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../../shared/constants.service';
import { AppointmentType } from '../../shared/appointment-type.model';
import { Patient } from '../../shared/patient.model';
import { CreateAppointmentRequest } from '../../shared/create-appointment-request.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ReadPatientsRequest } from '../../shared/read-patients-request.model';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-create-appointment-dialog',
  templateUrl: './create-appointment-dialog.component.html',
  styleUrls: ['./create-appointment-dialog.component.css'],
})
export class CreateAppointmentDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  maxPatientDateOfBirth = new Date();
  patientSearchInput$ = new Subject<string>();
  patientsCollection: ReadPatientsRequest[] = [];
  appointmentTypesCollection: AppointmentType[] = [];
  selectedAppointmentTypes: AppointmentType[] = [];
  appointmentTimesCollection: string[] = [];

  createAppointmentForm = new UntypedFormGroup({
    NewPatient: new UntypedFormControl(false, Validators.required),
    selectPatientFormSection: new UntypedFormGroup({
      Patient: new UntypedFormControl(null),
    }),
    createPatientFormSection: new UntypedFormGroup({
      PatientName: new UntypedFormControl(''),
      DateOfBirth: new UntypedFormControl({
        value: null,
        disabled: true,
      }),
      Address: new UntypedFormControl(''),
      PhoneNumber: new UntypedFormControl(''),
      Email: new UntypedFormControl(''),
      PassportNumber: new UntypedFormControl(''),
    }),
    Time: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(timeFormat1stRegex),
    ]),
    CenaPregleda: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
    ]),
    Anamnesis: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<CreateAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    this.patientSearchInput$
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => this.dashboardService.searchPatientByName(term))
    )
    .subscribe(
      patients => this.patientsCollection = patients,
      error => this.handleSearchError(error)
    );
    if (this.data && typeof this.data === 'object') {
      if (this.data.hasOwnProperty('appointment_types'))
        this.appointmentTypesCollection = this.data.appointment_types;

      if (this.data.hasOwnProperty('appointment_times')) {
        this.appointmentTimesCollection = this.data.appointment_times;

        if (
          this.data.hasOwnProperty('selected_appointment_time') &&
          typeof this.data.selected_appointment_time === 'string' &&
          this.data.selected_appointment_time.length > 0 &&
          RegExp(timeFormat1stRegex).test(this.data.selected_appointment_time)
        ) {
          // Selecting corresponding Appointment time
          if (
            this.appointmentTimesCollection.filter(
              (currentElement) =>
                typeof currentElement === 'string' &&
                currentElement.length > 0 &&
                currentElement === this.data.selected_appointment_time,
            ).length > 0
          )
            this.createAppointmentForm
              .get('Time')
              .setValue(this.data.selected_appointment_time);
        }
      }
         this.handleNewPatientState();
    }
  }

  selectChangeAppointmentTypes(event: any) {
    this.selectedAppointmentTypes = event.data.map((item: any) => item);
    let totalPrice = this.selectedAppointmentTypes.reduce(
      (accumulator, currentValue) => accumulator + currentValue.Price,
      0,
    );
    if (totalPrice > 0) {
      this.createAppointmentForm.get('CenaPregleda').setValue(totalPrice);
    }
  }

  onReadPhoneNumberErrorMessage(): string {
    return this.createAppointmentForm
      .get('createPatientFormSection')
      .get('PhoneNumber')
      .hasError('required')
      ? 'Unesite broj telefona'
      : this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PhoneNumber')
          .hasError('pattern')
      ? 'Broj telefona mora da sadrži samo cifre'
      : '';
  }

  //onReadEmailErrorMessage(): string {
  //    return this.createAppointmentForm.get('createPatientFormSection').get('Email').hasError('required') ? 'Unesite e-mail' : this.createAppointmentForm.get('createPatientFormSection').get('Email').hasError('email') ? 'Niste uneli validan e-mail' : '';
  //}

  onReadPriceErrorMessage(): string {
    return this.createAppointmentForm.get('CenaPregleda').hasError('required')
      ? 'Unesite cenu'
      : this.createAppointmentForm.get('CenaPregleda').hasError('min')
      ? 'Cena mora biti veća ili jednaka 0'
      : '';
  }

  onChangeNewPatientState() {
    if (
      typeof this.createAppointmentForm.get('NewPatient').value === 'boolean'
    ) {
      this.createAppointmentForm
        .get('NewPatient')
        .setValue(!this.createAppointmentForm.get('NewPatient').value);
      this.handleNewPatientState();
    }
  }

  onCreateAppointment() {
    if (
      typeof this.createAppointmentForm.get('NewPatient').value === 'boolean'
    ) {
      let requestParameters: CreateAppointmentRequest;

      if (this.createAppointmentForm.get('NewPatient').value === false)
        requestParameters = new CreateAppointmentRequest(
          null,
          this.data.date,
          this.createAppointmentForm.get('Time').value,
          null,
          this.createAppointmentForm
            .get('selectPatientFormSection')
            .get('Patient').value.Id,
          this.data.doctorId,
          this.createAppointmentForm
            .get('selectPatientFormSection')
            .get('Patient').value.Name,
          this.createAppointmentForm
            .get('selectPatientFormSection')
            .get('Patient').value.PhoneNumber,
          formatDate(
            this.createAppointmentForm
              .get('selectPatientFormSection')
              .get('Patient').value.DateOfBirth,
            'yyyy-MM-dd',
            'sr-Latn',
          ),
          false,
          this.createAppointmentForm.get('Anamnesis').value,
          this.createAppointmentForm.get('CenaPregleda').value,
          this.selectedAppointmentTypes,
          null,
          0,
          '',
          null,
          null,
          formatDate(
            this.createAppointmentForm
              .get('selectPatientFormSection')
              .get('Patient').value.DateOfBirth,
            'yyyy',
            'sr-Latn',
          ),
          new Patient(
            this.createAppointmentForm
              .get('selectPatientFormSection')
              .get('Patient').value.Id,
            this.createAppointmentForm
              .get('selectPatientFormSection')
              .get('Patient').value.Name,
            '',
            null,
            '',
            '',
            '',
            formatDate(
              this.createAppointmentForm
                .get('selectPatientFormSection')
                .get('Patient').value.DateOfBirth,
              'yyyy-MM-dd',
              'sr-Latn',
            ),
            '',
            '',
            this.createAppointmentForm
              .get('selectPatientFormSection')
              .get('Patient').value.PhoneNumber,
            '',
            null,
            false,
            '',
            false,
            '',
            false,
            '',
            false,
            null,
            false,
            false,
            '',
            false,
            '',
            false,
            '',
            false,
            '',
            null,
            '',
            '',
            '',
            '',
            false,
            '',
            '',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ),
        );
      else
        requestParameters = new CreateAppointmentRequest(
          null,
          this.data.date,
          this.createAppointmentForm.get('Time').value,
          null,
          null,
          this.data.doctorId,
          this.createAppointmentForm
            .get('createPatientFormSection')
            .get('PatientName').value,
          this.createAppointmentForm
            .get('createPatientFormSection')
            .get('PhoneNumber').value,
          formatDate(
            this.createAppointmentForm
              .get('createPatientFormSection')
              .get('DateOfBirth').value,
            'yyyy-MM-dd',
            'sr-Latn',
          ),
          true,
          this.createAppointmentForm.get('Anamnesis').value,
          this.createAppointmentForm.get('CenaPregleda').value,
          this.selectedAppointmentTypes,
          null,
          0,
          '',
          null,
          null,
          this.createAppointmentForm
            .get('createPatientFormSection')
            .get('PassportNumber').value,
          new Patient(
            null,
            this.createAppointmentForm
              .get('createPatientFormSection')
              .get('PatientName').value,
            '',
            null,
            '',
            '',
            '',
            null,
            '',
            this.createAppointmentForm
              .get('createPatientFormSection')
              .get('Address').value,
            this.createAppointmentForm
              .get('createPatientFormSection')
              .get('PhoneNumber').value,
            this.createAppointmentForm
              .get('createPatientFormSection')
              .get('Email').value,
            null,
            false,
            '',
            false,
            '',
            false,
            '',
            false,
            null,
            false,
            false,
            '',
            false,
            '',
            false,
            '',
            false,
            '',
            null,
            '',
            '',
            '',
            '',
            true,
            '',
            '',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ),
        );

      this.progressBarService.start();

      const createAppointmentSubscription = this.dashboardService
        .createAppointment(requestParameters)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();
            if (response === true) {
              this.snackbarService.showSuccess(
                'Termin pregleda je uspešno zakazan.',
              );
              this.dialogRef.close(response);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(createAppointmentSubscription);
    }
  }

  handleNewPatientState() {
    if (
      typeof this.createAppointmentForm.get('NewPatient').value === 'boolean'
    ) {
      if (this.createAppointmentForm.get('NewPatient').value === false) {
        this.createAppointmentForm
          .get('selectPatientFormSection')
          .get('Patient')
          .setValidators(Validators.required);
        this.createAppointmentForm
          .get('selectPatientFormSection')
          .get('Patient')
          .setValue(null);

        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PatientName')
          .clearValidators();
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Address')
          .clearValidators();
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PhoneNumber')
          .clearValidators();
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Email')
          .clearValidators();
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PassportNumber')
          .clearValidators();

        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PatientName')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Address')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('DateOfBirth')
          .setValue(null);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PhoneNumber')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Email')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Passportnumber')
          .setValue('');
      } else {
        this.createAppointmentForm
          .get('selectPatientFormSection')
          .get('Patient')
          .clearValidators();
        this.createAppointmentForm
          .get('selectPatientFormSection')
          .get('Patient')
          .setValue(null);
        this.createAppointmentForm
          .get('selectPatientFormSection')
          .get('DateOfBirth')
          .clearValidators();

        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PatientName')
          .setValidators([Validators.required, Validators.maxLength(64)]);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Address')
          .setValidators([Validators.required, Validators.maxLength(64)]);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PhoneNumber')
          .setValidators([
            Validators.required,
            Validators.maxLength(32),
            Validators.pattern('[0-9]+'),
          ]);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Email')
          .setValidators([Validators.required, Validators.email]);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PassportNumber')
          .setValidators([Validators.required, Validators.maxLength(64)]);

        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PatientName')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Address')
          .setValue('');
        //this.createAppointmentForm.get('createPatientFormSection').get('DateOfBirth').setValue(null);
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PhoneNumber')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('Email')
          .setValue('');
        this.createAppointmentForm
          .get('createPatientFormSection')
          .get('PassportNumber')
          .setValue('');
      }
    }
  }



  onSearchChanged(searchTerm: string): void {
    this.patientSearchInput$.next(searchTerm);
  }
  
  handleSearchError(error: any): void{
    this.errorHandlerService.handleError(error);
  }

  onReadTimeErrorMessage(): string {
    return this.createAppointmentForm.get('Time').hasError('required')
      ? 'Unesite vreme'
      : this.createAppointmentForm.get('Time').hasError('pattern')
      ? 'Mora biti od 00:00 do 23:59'
      : '';
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.patientSearchInput$.complete();
    this.subscriptions.unsubscribe();
  }
}
