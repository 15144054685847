import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../../shared/dashboard.service';
import { take } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-apartments-occupancy',
  templateUrl: './apartments-occupancy.component.html',
  styleUrls: ['./apartments-occupancy.component.scss'],
})
export class ApartmentsOccupancyComponent implements OnInit {
  @Input() set date(value: Date) {
    this.refreshDatasource(value);
  }

  displayedColumns: string[] = ['ApartmentName', 'Date', 'Text'];
  dataSource = new MatTableDataSource<any>([]);

  constructor(private readonly dashboardService: DashboardService) {}

  ngOnInit() {}

  refreshDatasource(value?: any) {
    const param = formatDate(
      value ?? new Date(),
      'yyyy-MM-ddThh:mm:ss',
      'sr-Latn',
    );
    this.dashboardService
      .getAllBookingForApartmentsForMonth(param)
      .pipe(take(1))
      .subscribe(
        (result) => (this.dataSource = new MatTableDataSource(result)),
      );
  }
}
