import { Component, OnInit, Inject } from '@angular/core';
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

// Import the Angular Material components
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.css'],
})
export class FilePreviewDialogComponent implements OnInit {
  isFileImage: boolean = false;
  imageSource: SafeUrl = '';

  isFilePdf: boolean = false;
  pdfSource: SafeResourceUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data && typeof this.data === 'object') {
      if (
        this.data.hasOwnProperty('file_type') &&
        typeof this.data.file_type === 'string' &&
        this.data.file_type.length > 0 &&
        (this.data.file_type.toLowerCase().includes('image/') === true ||
          this.data.file_type.toLowerCase() === 'application/pdf')
      ) {
        if (
          this.data.hasOwnProperty('file_content') &&
          typeof this.data.file_content === 'string' &&
          this.data.file_content.length > 0
        ) {
          if (this.data.file_type.toLowerCase().includes('image/') === true) {
            this.imageSource = this.sanitizer.bypassSecurityTrustUrl(
              'data:' +
                this.data.file_type +
                ';base64,' +
                this.data.file_content,
            );
            this.isFileImage = true;
          } else if (this.data.file_type.toLowerCase() === 'application/pdf') {
            this.pdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(
              'data:' +
                this.data.file_type +
                ';base64,' +
                this.data.file_content,
            );
            this.isFilePdf = true;
          }
        } else {
          this.snackbarService.showInfo(
            'Ne postoji dostupan sadržaj izabranog fajla.',
          );
        }
      } else {
        this.snackbarService.showInfo(
          'Izabrani fajl mora biti slika ili PDF dokument.',
        );
      }
    }
  }
}
