import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarMonthViewDay,
} from 'angular-calendar';
import * as moment from 'moment';
import { SurgeonWorkPlan } from '../../shared/surgeon-work-plan.model';
import { CalendarService } from '../services/calendar.service';
import { Subscription } from 'rxjs';

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('en', {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

@Component({
  selector: 'app-surgeon-calendar',
  templateUrl: './surgeon-calendar.component.html',
  styleUrls: ['./surgeon-calendar.component.scss'],
})
export class SurgeonCalendarComponent implements OnInit {
  @Input() year: number;
  @Input() month: string;
  @Input() monthIndex: number;
  @Input() events: Array<CalendarEvent> = [];
  @Output() readonly onSelectedDay: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  viewDate: Date;
  selectedDay;

  constructor(private readonly clandarService: CalendarService) {}

  ngOnInit() {
    const changedDateSubscription = this.clandarService.changedDate$.subscribe(
      (date) => {
        if (date !== this.monthIndex && this.selectedDay) {
          delete this.selectedDay.cssClass;
        }
      },
    );

    this.subscriptions.add(changedDateSubscription);
  }

  dayClicked(event): void {
    if (this.selectedDay) {
      delete this.selectedDay.cssClass;
    }
    event.cssClass = 'cal-day-selected';
    this.selectedDay = event;
    this.onSelectedDay.emit(this.selectedDay.date);
    this.clandarService.emitNewDate(this.monthIndex);
  }

  ngOnChanges() {
    this.selectMonth();
  }

  selectMonth() {
    this.viewDate = new Date(moment(this.year).year(), this.monthIndex);
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    const selectedDate = this.convertDateISO(
      moment(new Date()).set({ h: 0, m: 0, s: 0 }),
    );
    body.forEach((day) => {
      if (day.date.getTime() === Date.parse(selectedDate)) {
        day.cssClass = 'cal-day-selected';
        this.selectedDay = day;
        this.onSelectedDay.emit(this.selectedDay.date);
      }
    });
  }

  private convertDateISO(date) {
    const m = moment(date);
    return m.format();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
