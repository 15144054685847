import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from "rxjs";

// Import the Angular Material components
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from "../../../../core/error-handler.service";

import { CreateTemperatureNoteItemDialogComponent } from "./create-temperature-note-item-dialog/create-temperature-note-item-dialog.component";

import { TemperatureNoteType } from "../../../shared/temperature-note-type.model";
import { TemperatureNote } from "../../../shared/temperature-note.model";
import { AuthorizationService } from '../../../../core/authorization.service';

@Component({
    selector: 'app-update-temperature-note-dialog',
    templateUrl: './update-temperature-note-dialog.component.html',
    styleUrls: ['./update-temperature-note-dialog.component.css']
})
export class UpdateTemperatureNoteDialogComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    temperatureNoteTypesCollection: TemperatureNoteType[] = [];

    displayedColumns: string[] = ['Date', 'TemperatureListType', 'Description', 'Actions'];
    dataSource = new MatTableDataSource<TemperatureNote>([]);

    temperatureNoteEditRole: boolean;
    temperatureNoteCreateRole: boolean;

    constructor(
        private dialog: MatDialog, @Inject(MAT_DIALOG_DATA)
        private data: any,
        private dashboardService: DashboardService,
        private errorHandlerService: ErrorHandlerService,
        private authorizationService: AuthorizationService) { }

    ngOnInit() {
        const readTemperatureNoteTypesSubscription = this.dashboardService.readTemperatureNoteTypes()
            .subscribe(
                (response: TemperatureNoteType[]) => {
                    if (response && typeof response === "object" && response.length > 0) this.temperatureNoteTypesCollection = response;
                },
                error => this.errorHandlerService.handleError(error)
            );

        this.subscriptions.add(readTemperatureNoteTypesSubscription);

        this.authorizationService.readPermissions().subscribe(
            permissions => {
                this.temperatureNoteCreateRole = permissions.includes('TEMPLNOVASTAVKA');
                this.temperatureNoteEditRole = permissions.includes('TEMPLIZMENASTAVKI');
            },
            error => {
                console.error("Error fetching permissions:", error);
            }
        );

        if (this.data && typeof this.data === "object" && this.data.hasOwnProperty("has_temperature_note") && this.data.has_temperature_note === true) this.readTemperatureNotes();
    }

    onReadNameOfTemperatureNoteType(typeId: number): string {
        let name = "";

        if ((this.temperatureNoteTypesCollection && typeof this.temperatureNoteTypesCollection === "object" && this.temperatureNoteTypesCollection.length > 0) && typeof typeId === "number" && isNaN(typeId) === false) {
            let filteredTemperatureNoteTypes: TemperatureNoteType[] = this.temperatureNoteTypesCollection.filter(currentElement => typeof currentElement === "object" && currentElement.hasOwnProperty("Id") && currentElement.Id === typeId);
            if (filteredTemperatureNoteTypes && typeof filteredTemperatureNoteTypes === "object" && filteredTemperatureNoteTypes.length > 0 && filteredTemperatureNoteTypes[0] && typeof filteredTemperatureNoteTypes[0] === "object" && filteredTemperatureNoteTypes[0].hasOwnProperty("Name") && typeof filteredTemperatureNoteTypes[0].Name === "string" && filteredTemperatureNoteTypes[0].Name.length > 0) name = filteredTemperatureNoteTypes[0].Name;
        }

        return name;
    }

    onOpenCreateTemperatureNoteItemDialog() {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const dialogRef = this.dialog.open(CreateTemperatureNoteItemDialogComponent, {
                minWidth: "44vw",
                data: {
                    "source_type": this.data.source_type.toLowerCase(),
                    "source_id": this.data.source_id,
                    "update": false
                }
            });

            const createTemperatureNoteItemDialogSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result === true) this.readTemperatureNotes();
            });

            this.subscriptions.add(createTemperatureNoteItemDialogSubscription);
        }
    }

    readTemperatureNotes() {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const readTemperatureNotesByExcimerLaserSubscription = this.dashboardService.readTemperatureNotesByExcimerLaser(this.data.source_id)
                .subscribe(
                    (response: TemperatureNote[]) => {
                        if (response && typeof response === "object" && response.length > 0) this.dataSource = new MatTableDataSource<TemperatureNote>(response);
                    },
                    error => this.errorHandlerService.handleError(error)
                );

            this.subscriptions.add(readTemperatureNotesByExcimerLaserSubscription);
        }
    }

    onEditTemeratureNote(element) {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const dialogRef = this.dialog.open(CreateTemperatureNoteItemDialogComponent, {
                minWidth: "44vw",
                data: {
                    "source_type": this.data.source_type.toLowerCase(),
                    "source_id": this.data.source_id,
                    "update": true,
                    "temperatureNote": element
                }
            });

            const createTemperatureNoteItemDialogSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result === true) this.readTemperatureNotes();
            });

            this.subscriptions.add(createTemperatureNoteItemDialogSubscription);
        }               
    }

    ngOnDestroy() {
        // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
        this.subscriptions.unsubscribe();
    }
}
