import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from "rxjs";

// Import the Angular Material component
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { DashboardService } from '../../shared/dashboard.service';
import { ErrorHandlerService } from "../../../core/error-handler.service";
import { ProgressBarService } from "../../../core/progress-bar.service";

import { ReadRecipesResponse } from "../../shared/read-recipes-response.model";

@Component({
  selector: 'app-recipe-preview-dialog',
  templateUrl: './recipe-preview-dialog.component.html',
  styleUrls: ['./recipe-preview-dialog.component.css']
})
export class RecipePreviewDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ReadRecipesResponse, private dashboardService: DashboardService, private errorHandlerService: ErrorHandlerService, private progressBarService: ProgressBarService) { }

  ngOnInit() { }

  onReadRecipeFile() {
    if (this.data && typeof this.data === "object" && this.data.hasOwnProperty("Id") && typeof this.data.Id === "number" && isNaN(this.data.Id) === false) {
      this.progressBarService.start();

      const readRecipeFileSubscription = this.dashboardService.readRecipeFile(this.data.Id)
        .subscribe(
          (response: Blob) => {
            this.progressBarService.stop();

            if (response && response instanceof Blob && response.size > 0) this.dashboardService.printFile(response);
          },
          error => this.errorHandlerService.handleError(error)
        );

      this.subscriptions.add(readRecipeFileSubscription);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
