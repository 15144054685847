import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { StaffService } from '../core/services/staff.service';
import { AuthenticationService } from '../core/authentication.service';
import { AuthorizationService } from '../core/authorization.service';
import { SnackbarService } from '../core/services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  token: string;
  errorMessage: string = '';
  subscriptions: Subscription = new Subscription();

  permissions: string[] = [];
  isNewPasswordHidden = true;
  isConfirmPasswordHidden = true;

  changePasswordForm = new UntypedFormGroup({
    NewPassword: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    ConfirmPassword: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  constructor(
    public authorizationService: AuthorizationService,
    private router: Router,
    private staffService: StaffService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];

      // If the token is undefined, null, or empty, redirect the user.
      if (!this.token) {
        this.router.navigate(['/']);
        return;
      }
    });
  }

  onReadNewPasswordErrorMessage(): string {
    return this.changePasswordForm.get('NewPassword').hasError('required')
      ? 'Unesite lozinku'
      : this.changePasswordForm.get('NewPassword').hasError('minlength')
      ? 'Lozinka mora da sadrži najmanje 4 karaktera'
      : '';
  }

  onReadConfirmPasswordErrorMessage(): string {
    return this.changePasswordForm.get('ConfirmPassword').hasError('required')
      ? 'Unesite lozinku'
      : this.changePasswordForm.get('ConfirmPassword').hasError('minlength')
      ? 'Lozinka mora da sadrži najmanje 4 karaktera'
      : '';
  }

  onChangePassword() {
    if (!this.changePasswordForm.valid) {
      this.errorMessage = 'Molimo vas unesite pravilno šifru.';
      return;
    }

    if (
      this.changePasswordForm.get('NewPassword').value !==
      this.changePasswordForm.get('ConfirmPassword').value
    ) {
      this.errorMessage = 'Šifre se ne poklapaju';
      return;
    }

    this.staffService
      .changePassword(
        this.changePasswordForm.get('NewPassword').value,
        this.token,
      )
      .subscribe(
        () => {
          this.snackbarService.showSuccess('Šifra uspešno izmenjena!');
          this.authenticationService.signOut();
        },
        () => {
          this.snackbarService.showError(
            'Desila se neočekivana greška, kontaktirajte sistem admina.',
          );
        },
      );
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
