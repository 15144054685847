import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../../shared/dashboard.service';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { DiseaseHistoryType } from '../../../shared/disease-history-type.model';
import { DiseaseHistory } from '../../../shared/disease-history.model';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-medical-history-dialog',
  templateUrl: './update-medical-history-dialog.component.html',
  styleUrls: ['./update-medical-history-dialog.component.scss'],
})
export class UpdateMedicalHistoryDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  medicalHistoryDialogForm = new UntypedFormGroup({
    DiseaseHistoryTypeId: new UntypedFormControl(null, Validators.required),
  });

  diseaseHistoryTypesCollection: DiseaseHistoryType[];

  displayedColumns: string[] = ['SpecialistExamType', 'Date', 'Actions'];
  dataSource = new MatTableDataSource<DiseaseHistory>([]);
  medicalHistoryEditRole: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.readDiseaseHistoryTypes();
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.medicalHistoryEditRole = permissions.includes('ISTORIJAIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('has_disease_history') &&
      this.data.has_disease_history === true
    )
      this.readDiseaseHistoryForLaserExclaimer();
  }

  readDiseaseHistoryTypes() {
    const readDiseaseHistoryTypesSubscriptions = this.dashboardService
      .readDiseaseHistoryTypes()
      .subscribe(
        (response: DiseaseHistoryType[]) =>
          (this.diseaseHistoryTypesCollection = response),
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readDiseaseHistoryTypesSubscriptions);
  }

  onChangeDiseaseHistoryTypeFileSelection(filesCollection: FileList) {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0 &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false &&
        this.medicalHistoryDialogForm.get('DiseaseHistoryTypeId').value &&
        typeof this.medicalHistoryDialogForm.get('DiseaseHistoryTypeId')
          .value === 'number' &&
        isNaN(
          this.medicalHistoryDialogForm.get('DiseaseHistoryTypeId').value,
        ) === false
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf'
        ) {
          let requestData = new FormData();

          requestData.append(
            'LaserExclaimerId',
            this.data.source_id.toString(),
          );
          requestData.append(
            'DiseaseHistoryTypeId',
            this.medicalHistoryDialogForm
              .get('DiseaseHistoryTypeId')
              .value.toString(),
          );
          requestData.append(
            'Date',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );
          requestData.append('Description', '');
          requestData.append('_file', filesCollection[0]);
          requestData.append(
            'UploadedFileType',
            filesCollection[0].type.toLowerCase(),
          );

          this.progressBarService.start();

          const uploadDiseaseHistoryFileSubscription = this.dashboardService
            .uploadDiseaseHistoryFile(requestData)
            .subscribe(
              (response: boolean) => {
                this.progressBarService.stop();

                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Tip bolesti je uspešno dodat.',
                  );
                  this.readDiseaseHistoryForLaserExclaimer();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(uploadDiseaseHistoryFileSubscription);
        } else {
          this.snackbarService.showInfo(
            'Izabrani fajl mora biti slika ili PDF dokument.',
          );
        }
      }
    }
  }

  onOpenDiseaseHistoryPreviewDialog(diseaseHistory: DiseaseHistory) {
    if (
      diseaseHistory &&
      typeof diseaseHistory === 'object' &&
      diseaseHistory.hasOwnProperty('Id') &&
      typeof diseaseHistory.Id === 'number' &&
      isNaN(diseaseHistory.Id) === false
    ) {
      this.progressBarService.start();

      const readSpecialistFindingSubscription = this.dashboardService
        .readDiseaseHistory(diseaseHistory.Id)
        .subscribe(
          (response: DiseaseHistory) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('UploadedFileType') &&
                typeof response.UploadedFileType === 'string' &&
                response.UploadedFileType.length > 0 &&
                (response.UploadedFileType.toLowerCase().includes('image/') ===
                  true ||
                  response.UploadedFileType.toLowerCase() === 'application/pdf')
              ) {
                if (
                  response.hasOwnProperty('FileStream') &&
                  typeof response.FileStream === 'string' &&
                  response.FileStream.length > 0
                ) {
                  this.dialog.open(FilePreviewDialogComponent, {
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    data: {
                      file_type: response.UploadedFileType.toLowerCase(),
                      file_content: response.FileStream,
                    },
                  });
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoji dostupan sadržaj izabranog tipa bolesti.',
                  );
                }
              } else {
                this.snackbarService.showInfo(
                  'Izabrani tip bolesti mora biti slika ili PDF dokument.',
                );
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readSpecialistFindingSubscription);
    }
  }

  readDiseaseHistoryForLaserExclaimer() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      const readDiseaseHistoryForLaserExclaimerSubscription =
        this.dashboardService
          .readDiseaseHistoryForLaserExclaimer(this.data.source_id)
          .subscribe(
            (response) =>
              (this.dataSource = new MatTableDataSource<DiseaseHistory>(
                response,
              )),
            (error) => this.errorHandlerService.handleError(error),
          );

      this.subscriptions.add(readDiseaseHistoryForLaserExclaimerSubscription);
    }
  }

  onReadNameOfDiseaseHistoryType(typeId: number): string {
    let name = '';

    if (
      this.diseaseHistoryTypesCollection &&
      typeof this.diseaseHistoryTypesCollection === 'object' &&
      this.diseaseHistoryTypesCollection.length > 0 &&
      typeof typeId === 'number' &&
      isNaN(typeId) === false
    ) {
      let filteredSpecialistFindingTypes: DiseaseHistoryType[] =
        this.diseaseHistoryTypesCollection.filter(
          (currentElement) =>
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Id') &&
            currentElement.Id === typeId,
        );
      if (
        filteredSpecialistFindingTypes &&
        typeof filteredSpecialistFindingTypes === 'object' &&
        filteredSpecialistFindingTypes.length > 0 &&
        filteredSpecialistFindingTypes[0] &&
        typeof filteredSpecialistFindingTypes[0] === 'object' &&
        filteredSpecialistFindingTypes[0].hasOwnProperty('Name') &&
        typeof filteredSpecialistFindingTypes[0].Name === 'string' &&
        filteredSpecialistFindingTypes[0].Name.length > 0
      )
        name = filteredSpecialistFindingTypes[0].Name;
    }

    return name;
  }

  onDeleteDiseaseHistoryType(id: number) {
    const deleteDiseaseHistoryTypeSubscription = this.dashboardService
      .deleteDiseaseHistory(id)
      .subscribe(
        (response) => {
          if (response) {
            this.readDiseaseHistoryForLaserExclaimer();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(deleteDiseaseHistoryTypeSubscription);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
