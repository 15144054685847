import { Component, OnInit, Inject } from '@angular/core';
import { DashboardService } from '../../shared/dashboard.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressBarService } from '../../../core/progress-bar.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { Apartment } from '../../shared/apartment.model';

@Component({
  selector: 'app-apartment-dialog',
  templateUrl: './apartment-dialog.component.html',
  styleUrls: ['./apartment-dialog.component.scss'],
})
export class ApartmentDialogComponent implements OnInit {
  apartmentList: any; //Observable<Array<Apartment>>;

  apartmentDialogForm = new UntypedFormGroup({
    ApartmentId: new UntypedFormControl(null, Validators.required),
    Text: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    private readonly dashboardService: DashboardService,
    public dialogRef: MatDialogRef<ApartmentDialogComponent>,
    private readonly progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.apartmentList = this.data;
  }
}
