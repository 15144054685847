import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorizationService } from 'src/app/core/authorization.service';
import { CreateNewOperationComponent } from '../dialogs/create-new-operation/create-new-operation/create-new-operation.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EditOperationComponent } from '../dialogs/edit-operation/edit-operation.component';
import { InterventionTypeService } from 'src/app/core/services/intervention-type.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DeleteDialogComponent } from '../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-operation-administration',
  templateUrl: './operation-administration.component.html',
  styleUrls: ['./operation-administration.component.scss'],
})
export class OperationAdministrationComponent implements OnInit {
  nameInput: string;
  levelInput: number;
  parentIdInput: number;
  interventionTypes: InterventionType[] = [];
  helperList: InterventionType[] = [];
  permissions: string[] = [];

  displayedColumns: string[] = ['Level', 'Name', 'Id', 'ParentId', 'Delete'];
  dataSource = new MatTableDataSource<InterventionType>(this.interventionTypes);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public authorizationService: AuthorizationService,
    private progressBarService: ProgressBarService,
    private interventionTypeService: InterventionTypeService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllInterventions();
  }

  getAllInterventions() {
    this.progressBarService.start();
    this.interventionTypeService.getAllInterventions().subscribe(
      (data) => {
        this.progressBarService.stop();
        this.interventionTypes = data;
        this.helperList = [...this.interventionTypes];
        this.initDataSource();
      },
      (error) => {
        console.log(error);
      },
    );
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<InterventionType>(
      this.interventionTypes,
    );
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  addInterventionType() {
    const dialogRef = this.dialog.open(CreateNewOperationComponent, {
      width: '900px',
      data: this.helperList,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let message = 'Uspešno dodato ' + result + ' operacija ';
        this.snackBarService.showSuccess(message);
        this.cancelSearch();
        this.getAllInterventions();
      }
    });
  }

  editInterventionType(interventionType: InterventionType) {
    const dialogRef = this.dialog.open(EditOperationComponent, {
      width: '900px',
      data: {
        helperList: this.helperList,
        interventionType: interventionType,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBarService.showSuccess('Uspešno izmenjena operacija.');
        this.cancelSearch();
        this.getAllInterventions();
      }
    });
  }

  deleteType(id: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressBarService.start();
        this.interventionTypeService.deleteIntervention(id).subscribe(
          (data) => {
            if (data) {
              this.progressBarService.stop();
              this.snackBarService.showSuccess('Uspešno obrisana operacija.');
              this.cancelSearch();
              this.getAllInterventions();
            }
          },
          (error) => {
            this.progressBarService.stop();
            this.snackBarService.showError(
              'Nije moguće obrisati operaciju jer je vezana za postojeću operaciju',
            );
            console.log(error);
          },
        );
      }
    });
  }

  searchTypes() {
    this.interventionTypes = [...this.helperList];
    if (this.nameInput) {
      this.interventionTypes = this.interventionTypes.filter((x) =>
        x.Name.toLowerCase().includes(this.nameInput.toLowerCase()),
      );
      this.initDataSource();
    }
    if (this.levelInput) {
      this.interventionTypes = this.interventionTypes.filter(
        (x) => x.Level == this.levelInput,
      );
      this.initDataSource();
    }
    if (this.parentIdInput) {
      this.interventionTypes = this.interventionTypes.filter(
        (x) => x.ParentId == this.parentIdInput,
      );
      this.initDataSource();
    }
    if (!this.nameInput && !this.levelInput && !this.parentIdInput) {
      this.interventionTypes = this.helperList;
      this.initDataSource();
    }
  }

  cancelSearch() {
    this.nameInput = '';
    this.levelInput = null;
    this.parentIdInput = null;
    this.interventionTypes = this.helperList;
    this.initDataSource();
  }
}

export interface InterventionType {
  Id: number;
  Name: string;
  Level: number;
  ParentId: number;
}
