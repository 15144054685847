import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../../shared/dashboard.service';
import { Subscription } from 'rxjs';
import { Consent } from '../../../shared/consent.model';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { formatDate } from '@angular/common';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { GeneralConsent } from '../../../shared/general-consent.model';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-agreement-dialog',
  templateUrl: './update-agreement-dialog.component.html',
  styleUrls: ['./update-agreement-dialog.component.css'],
})
export class UpdateAgreementDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  displayedColumns: string[] = ['DateCreated', 'Actions'];
  dataSource = new MatTableDataSource<Consent | GeneralConsent>([]);
  agreementEditRole: boolean;

  constructor(
    private dialogRef: MatDialogRef<UpdateAgreementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    if(this.data.source_id === null)
      {
        this.snackbarService.showError("Nije moguće dodavanje saglasnosti za pacijente bez validne operacije ili pregleda");
        this.dialogRef.close();
      }
    this.readAllConsents();
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.agreementEditRole = permissions.includes('SAGIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  readAllConsents(): void {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      if (this.data.operation) {
        const readAllConsentsSubscription = this.dashboardService
          .getAllConsentsForLaserExclaimerId(this.data.source_id)
          .subscribe(
            (response: Consent[]) => {
              this.dataSource = new MatTableDataSource(response);
            },
            (error) => this.errorHandlerService.handleError(error),
          );
        this.subscriptions.add(readAllConsentsSubscription);
      } else {
        const readAllGeneralConsentsSubscription = this.dashboardService
          .getAllGeneralConsentsForExamChartId(this.data.source_id)
          .subscribe(
            (response: GeneralConsent[]) => {
              this.dataSource = new MatTableDataSource(response);
            },
            (error) => this.errorHandlerService.handleError(error),
          );
        this.subscriptions.add(readAllGeneralConsentsSubscription);
      }
    }
  }

  onConsentUpload(filesCollection: FileList): void {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0 &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf' ||
          filesCollection[0].type.toLowerCase().includes('document') === true
        ) {
          let requestData = new FormData();
          requestData.append(
            'DateCreated',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );
          requestData.append('_file', filesCollection[0]);
          requestData.append(
            'UploadedFileType',
            filesCollection[0].type.toLowerCase(),
          );

          if (this.data.operation) {
            requestData.append(
              'LaserExclaimerId',
              this.data.source_id.toString(),
            );

            this.progressBarService.start();

            const onConsentUploadSubscription = this.dashboardService
              .saveConsent(requestData)
              .subscribe(
                (response) => {
                  this.progressBarService.stop();
                  if (response === true) {
                    this.snackbarService.showSuccess(
                      'Saglasnost je uspešno dodata.',
                    );

                    this.readAllConsents();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(onConsentUploadSubscription);
          } else {
            requestData.append('ExamChartId ', this.data.source_id.toString());

            this.progressBarService.start();

            const onConsentUploadSubscription = this.dashboardService
              .saveGeneralConsent(requestData)
              .subscribe(
                (response) => {
                  this.progressBarService.stop();
                  if (response === true) {
                    this.snackbarService.showSuccess(
                      'Saglasnost je uspešno dodata.',
                    );
                    this.readAllConsents();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(onConsentUploadSubscription);
          }
        }
      } else {
        this.snackbarService.showInfo(
          'Izabrani fajl mora biti slika ili PDF dokument.',
        );
      }
    }
  }
  onOpenConsent(consent: Consent | GeneralConsent): void {
    if (
      consent &&
      consent.hasOwnProperty('Id') &&
      typeof consent.Id === 'number' &&
      isNaN(consent.Id) === false
    ) {
      this.progressBarService.start();

      if (this.data.operation) {
        const onOpenConsentSubscription = this.dashboardService
          .getConsent(consent.Id)
          .subscribe(
            (response: Consent) => {
              this.downloadHandler(response);
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(onOpenConsentSubscription);
      } else {
        const onOpenGeneralConsentSubscription = this.dashboardService
          .getGeneralConsent(consent.Id)
          .subscribe(
            (response: GeneralConsent) => {
              this.downloadHandler(response);
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(onOpenGeneralConsentSubscription);
      }
    }
  }

  onDeleteConsent(id: number) {
    this.progressBarService.start();

    if (this.data.operation) {
      const deleteConsents = this.dashboardService.deleteConsents(id).subscribe(
        (response) => {
          if (response) {
            this.progressBarService.stop();
            this.snackbarService.showSuccess('Saglasnost je uspešno obrisana.');
            this.readAllConsents();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

      this.subscriptions.add(deleteConsents);
    } else {
      const deleteGeneralConsents = this.dashboardService
        .deleteGeneralConsents(id)
        .subscribe(
          (response) => {
            if (response) {
              this.progressBarService.stop();

              this.snackbarService.showSuccess(
                'Saglasnost je uspešno obrisana.',
              );
              this.readAllConsents();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(deleteGeneralConsents);
    }
  }

  private downloadHandler(response: any) {
    this.progressBarService.stop();

    if (response && typeof response === 'object') {
      if (
        response.hasOwnProperty('UploadedFileType') &&
        typeof response.UploadedFileType === 'string' &&
        response.UploadedFileType.length > 0 &&
        (response.UploadedFileType.toLowerCase().includes('image/') === true ||
          response.UploadedFileType.toLowerCase() === 'application/pdf')
      ) {
        if (
          response.hasOwnProperty('FileStream') &&
          typeof response.FileStream === 'string' &&
          response.FileStream.length > 0
        ) {
          this.dialog.open(FilePreviewDialogComponent, {
            maxWidth: '90vw',
            maxHeight: '90vh',
            data: {
              file_type: response.UploadedFileType.toLowerCase(),
              file_content: response.FileStream,
            },
          });
        } else {
          this.snackbarService.showInfo(
            'Ne postoji dostupan sadržaj izabrane saglasnosti.',
          );
        }
      } else if (
        response.UploadedFileType.toLowerCase().includes('document') === true
      ) {
        function base64ToArrayBuffer(base64) {
          var binaryString = window.atob(base64);
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          return bytes;
        }
        const data = new Blob([base64ToArrayBuffer(response.FileStream)], {
          type: response.UploadedFileType,
        });
        let hyperlinkElement: HTMLElement = document.createElement('A');
        hyperlinkElement.setAttribute('href', URL.createObjectURL(data));
        hyperlinkElement.setAttribute('download', '');
        hyperlinkElement.click();
      } else {
        this.snackbarService.showInfo(
          'Izabrana saglasnost mora biti slika ili PDF dokument.',
        );
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
