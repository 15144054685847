import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';

import { Employee } from '../../../shared/employee.model';
import { OperationProtocol } from '../../../shared/operation-protocol.model';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-operation-protocol-dialog',
  templateUrl: './update-operation-protocol-dialog.component.html',
  styleUrls: ['./update-operation-protocol-dialog.component.css'],
})
export class UpdateOperationProtocolDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  operationProtocolId: number | null = null;
  employeesCollection: Employee[] = [];

  updateOperationProtocolForm = new UntypedFormGroup({
    SurnameMiddleNameAndName: new UntypedFormControl(''),
    HistoryIdentificationNumber: new UntypedFormControl(''),
    Date: new UntypedFormControl({
      value: new Date(),
      disabled: true,
    }),
    PersonalIdentificationNumber: new UntypedFormControl(''),
    ClinicalDiagnosis: new UntypedFormControl(''),
    Surgery: new UntypedFormControl(''),
    SurgeryFlow: new UntypedFormControl(''),
    PreOperationDiagnostics: new UntypedFormControl(''),
    PreMedication: new UntypedFormControl(''),
    SurgeryConclusion: new UntypedFormControl(''),
    Anestezia: new UntypedFormControl(''),
    Duration: new UntypedFormControl(''),
    Surgeon: new UntypedFormControl(''),
    Assistent: new UntypedFormControl(''),
    Anesthetist: new UntypedFormControl(''),
    Anesteziologist: new UntypedFormControl(''),
    Note: new UntypedFormControl(''),
    OrdinalNumber: new UntypedFormControl(''),
  });

  operationProtocolEditRole: boolean;

  constructor(
    private dialogRef: MatDialogRef<UpdateOperationProtocolDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    const readEmployeesSubscription = this.dashboardService
      .readEmployees()
      .subscribe(
        (response: Employee[]) => {
          if (response.length > 0) this.employeesCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readEmployeesSubscription);

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.operationProtocolEditRole = permissions.includes(
          'PROTOKOLIOPERIZMENA',
        );
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false &&
      this.data.hasOwnProperty('has_operation_protocol') &&
      this.data.has_operation_protocol === true
    ) {
      const readOperationProtocolByExcimerLaserSubscription =
        this.dashboardService
          .readOperationProtocolByExcimerLaser(this.data.source_id)
          .subscribe(
            (response: OperationProtocol) => {
              if (response && typeof response === 'object') {
                if (
                  response.hasOwnProperty('Id') &&
                  typeof response.Id === 'number' &&
                  isNaN(response.Id) === false
                )
                  this.operationProtocolId = response.Id;
                if (
                  response.hasOwnProperty('SurnameMiddleNameAndName') &&
                  typeof response.SurnameMiddleNameAndName === 'string' &&
                  response.SurnameMiddleNameAndName.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('SurnameMiddleNameAndName')
                    .setValue(response.SurnameMiddleNameAndName);
                if (
                  response.hasOwnProperty('HistoryIdentificationNumber') &&
                  typeof response.HistoryIdentificationNumber === 'string' &&
                  response.HistoryIdentificationNumber.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('HistoryIdentificationNumber')
                    .setValue(response.HistoryIdentificationNumber);
                if (
                  response.hasOwnProperty('Date') &&
                  typeof response.Date === 'string' &&
                  response.Date.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Date')
                    .setValue(new Date(response.Date));
                if (
                  response.hasOwnProperty('PersonalIdentificationNumber') &&
                  typeof response.PersonalIdentificationNumber === 'string' &&
                  response.PersonalIdentificationNumber.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('PersonalIdentificationNumber')
                    .setValue(response.PersonalIdentificationNumber);
                if (
                  response.hasOwnProperty('ClinicalDiagnosis') &&
                  typeof response.ClinicalDiagnosis === 'string' &&
                  response.ClinicalDiagnosis.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('ClinicalDiagnosis')
                    .setValue(response.ClinicalDiagnosis);
                if (
                  response.hasOwnProperty('Surgery') &&
                  typeof response.Surgery === 'string' &&
                  response.Surgery.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Surgery')
                    .setValue(response.Surgery);
                if (
                  response.hasOwnProperty('SurgeryFlow') &&
                  typeof response.SurgeryFlow === 'string' &&
                  response.SurgeryFlow.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('SurgeryFlow')
                    .setValue(response.SurgeryFlow);
                if (
                  response.hasOwnProperty('PreOperationDiagnostics') &&
                  typeof response.PreOperationDiagnostics === 'string' &&
                  response.PreOperationDiagnostics.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('PreOperationDiagnostics')
                    .setValue(response.PreOperationDiagnostics);
                if (
                  response.hasOwnProperty('PreMedication') &&
                  typeof response.PreMedication === 'string' &&
                  response.PreMedication.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('PreMedication')
                    .setValue(response.PreMedication);
                if (
                  response.hasOwnProperty('SurgeryConclusion') &&
                  typeof response.SurgeryConclusion === 'string' &&
                  response.SurgeryConclusion.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('SurgeryConclusion')
                    .setValue(response.SurgeryConclusion);
                if (
                  response.hasOwnProperty('Anestezia') &&
                  typeof response.Anestezia === 'string' &&
                  response.Anestezia.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Anestezia')
                    .setValue(response.Anestezia);
                if (
                  response.hasOwnProperty('Duration') &&
                  typeof response.Duration === 'string' &&
                  response.Duration.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Duration')
                    .setValue(response.Duration);
                if (
                  response.hasOwnProperty('Surgeon') &&
                  typeof response.Surgeon === 'string' &&
                  response.Surgeon.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Surgeon')
                    .setValue(response.Surgeon);
                if (
                  response.hasOwnProperty('Assistent') &&
                  typeof response.Assistent === 'string' &&
                  response.Assistent.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Assistent')
                    .setValue(response.Assistent);
                if (
                  response.hasOwnProperty('Anesthetist') &&
                  typeof response.Anesthetist === 'string' &&
                  response.Anesthetist.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Anesthetist')
                    .setValue(response.Anesthetist);
                if (
                  response.hasOwnProperty('Anesteziologist') &&
                  typeof response.Anesteziologist === 'string' &&
                  response.Anesteziologist.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Anesteziologist')
                    .setValue(response.Anesteziologist);
                if (
                  response.hasOwnProperty('Note') &&
                  typeof response.Note === 'string' &&
                  response.Note.length > 0
                )
                  this.updateOperationProtocolForm
                    .get('Note')
                    .setValue(response.Note);
                if (
                  response.hasOwnProperty('OrdinalNumber') &&
                  typeof response.OrdinalNumber === 'number' &&
                  isNaN(response.OrdinalNumber) === false
                )
                  this.updateOperationProtocolForm
                    .get('OrdinalNumber')
                    .setValue(response.OrdinalNumber);
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

      this.subscriptions.add(readOperationProtocolByExcimerLaserSubscription);
    }
  }

  onReadOperationProtocolExcel(excelType: string) {
    if (
      typeof this.operationProtocolId === 'number' &&
      isNaN(this.operationProtocolId) === false &&
      typeof excelType === 'string' &&
      excelType.length > 0 &&
      (excelType.toLowerCase() === 'operation_protocol' ||
        excelType.toLowerCase() === 'anesthesia_protocol')
    ) {
      switch (excelType.toLowerCase()) {
        case 'operation_protocol':
          {
            this.progressBarService.start();

            const readOperationProtocolExcelSubscription = this.dashboardService
              .readOperationProtocolExcel(this.operationProtocolId)
              .subscribe(
                (response: Blob) => {
                  this.progressBarService.stop();

                  if (
                    response &&
                    response instanceof Blob &&
                    response.size > 0
                  ) {
                    let hyperlinkElement: HTMLElement =
                      document.createElement('A');
                    hyperlinkElement.setAttribute(
                      'href',
                      URL.createObjectURL(response),
                    );
                    hyperlinkElement.setAttribute(
                      'download',
                      'Protokol operacija ' +
                        formatDate(
                          new Date(),
                          'dd-MM-yyyy HH-mm-ss',
                          'sr-Latn',
                        ),
                    );
                    hyperlinkElement.click();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(readOperationProtocolExcelSubscription);
          }
          break;
        case 'anesthesia_protocol': {
          this.progressBarService.start();

          const readAnesthesiaProtocolExcelSubscription = this.dashboardService
            .readAnesthesiaProtocolExcel(this.operationProtocolId)
            .subscribe(
              (response: Blob) => {
                this.progressBarService.stop();

                if (response && response instanceof Blob && response.size > 0) {
                  let hyperlinkElement: HTMLElement =
                    document.createElement('A');
                  hyperlinkElement.setAttribute(
                    'href',
                    URL.createObjectURL(response),
                  );
                  hyperlinkElement.setAttribute(
                    'download',
                    'Protokol anestezije ' +
                      formatDate(new Date(), 'dd-MM-yyyy HH-mm-ss', 'sr-Latn'),
                  );
                  hyperlinkElement.click();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(readAnesthesiaProtocolExcelSubscription);
        }
      }
    }
  }

  onUpdateOperationProtocol() {
    // Make sure that operation protocol ID is valid (null for new and number for existing operation protocol)
    if (
      this.operationProtocolId === null ||
      (typeof this.operationProtocolId === 'number' &&
        isNaN(this.operationProtocolId) === false)
    ) {
      // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
      if (
        this.data &&
        typeof this.data === 'object' &&
        this.data.hasOwnProperty('source_type') &&
        typeof this.data.source_type === 'string' &&
        this.data.source_type.length > 0 &&
        (this.data.source_type.toLowerCase() === 'excimer_laser' ||
          this.data.source_type.toLowerCase() === 'operations') &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false
      ) {
        this.progressBarService.start();

        const updateOperationProtocolByExcimerLaserSubscription =
          this.dashboardService
            .updateOperationProtocolByExcimerLaser(
              new OperationProtocol(
                this.operationProtocolId,
                this.data.source_id,
                this.updateOperationProtocolForm.get(
                  'SurnameMiddleNameAndName',
                ).value,
                this.updateOperationProtocolForm.get(
                  'HistoryIdentificationNumber',
                ).value,
                this.readDateParameter(),
                this.updateOperationProtocolForm.get(
                  'PersonalIdentificationNumber',
                ).value,
                this.updateOperationProtocolForm.get('ClinicalDiagnosis').value,
                this.updateOperationProtocolForm.get('Surgery').value,
                this.updateOperationProtocolForm.get('SurgeryFlow').value,
                this.updateOperationProtocolForm.get(
                  'PreOperationDiagnostics',
                ).value,
                this.updateOperationProtocolForm.get('PreMedication').value,
                this.updateOperationProtocolForm.get('SurgeryConclusion').value,
                this.updateOperationProtocolForm.get('Anestezia').value,
                this.updateOperationProtocolForm.get('Duration').value,
                this.updateOperationProtocolForm.get('Surgeon').value,
                this.updateOperationProtocolForm.get('Assistent').value,
                this.updateOperationProtocolForm.get('Anesteziologist').value,
                this.updateOperationProtocolForm.get('Note').value,
                this.updateOperationProtocolForm.get('Anesthetist').value,
                this.updateOperationProtocolForm.get('OrdinalNumber').value,
              ),
            )
            .subscribe(
              (response: boolean) => {
                this.progressBarService.stop();

                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Protokol operacije je uspešno izmenjen.',
                  );

                  this.dialogRef.close(response);
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

        this.subscriptions.add(
          updateOperationProtocolByExcimerLaserSubscription,
        );
      }
    }
  }

  readDateParameter(): string | null {
    return this.updateOperationProtocolForm.get('Date').value instanceof Date
      ? formatDate(
          this.updateOperationProtocolForm.get('Date').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
