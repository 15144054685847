import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { DashboardService } from '../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../../shared/constants.service';

import { AppointmentTemplate } from '../../shared/appointment-template.model';
import { Shift } from '../../shared/shift.model';
import { ShiftTime } from '../../shared/shift-time.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-appointment-template-dialog',
  templateUrl: './appointment-template-dialog.component.html',
  styleUrls: ['./appointment-template-dialog.component.css'],
})
export class AppointmentTemplateDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  doctor: string = '';
  shiftsCollection: Shift[] = [];
  appointmentTemplate: AppointmentTemplate = null;

  readAppointmentTemplateForm = new UntypedFormGroup({
    Shift: new UntypedFormControl(null, Validators.required),
  });

  createAppointmentTemplateTimeForm = new UntypedFormGroup({
    Time: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(timeFormat1stRegex),
    ]),
  });

  displayedColumns: string[] = ['Time', 'Actions'];
  dataSource = new MatTableDataSource<ShiftTime>([]);
  hasUserChangedTableData: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AppointmentTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data && typeof this.data === 'object') {
      if (
        this.data.hasOwnProperty('doctor_name') &&
        typeof this.data.doctor_name === 'string' &&
        this.data.doctor_name.length > 0
      )
        this.doctor = this.data.doctor_name;
      if (this.data.hasOwnProperty('doctor_shifts'))
        this.shiftsCollection = this.data.doctor_shifts;
    }
  }

  onReadTimeErrorMessage(): string {
    return this.createAppointmentTemplateTimeForm
      .get('Time')
      .hasError('required')
      ? 'Unesite vreme'
      : this.createAppointmentTemplateTimeForm.get('Time').hasError('pattern')
      ? 'Mora biti od 00:00 do 23:59'
      : '';
  }

  onChangeShift(shift: Shift) {
    if (shift && typeof shift === 'object') {
      this.dataSource = new MatTableDataSource<ShiftTime>([]);
      this.hasUserChangedTableData = false;

      this.progressBarService.start();

      const readAppointmentTemplateSubscription = this.dashboardService
        .readAppointmentTemplate(shift)
        .subscribe(
          (response: AppointmentTemplate) => {
            this.progressBarService.stop();

            if (
              response &&
              typeof response === 'object' &&
              response.hasOwnProperty('AppointmentSchedulesVM') &&
              typeof response.AppointmentSchedulesVM === 'object'
            ) {
              this.appointmentTemplate = response;

              if (response.AppointmentSchedulesVM.length === 0) {
                this.snackbarService.showInfo(
                  'Ne postoje dostupna vremena za izabranu Smenu.',
                );
              } else if (response.AppointmentSchedulesVM.length > 0) {
                this.dataSource = new MatTableDataSource<ShiftTime>(
                  response.AppointmentSchedulesVM,
                );
                this.hasUserChangedTableData = false;
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readAppointmentTemplateSubscription);
    }
  }

  onCreateAppointmentTemplateTime() {
    if (
      typeof this.createAppointmentTemplateTimeForm.get('Time').value ===
        'string' &&
      this.createAppointmentTemplateTimeForm.get('Time').value.length > 0 &&
      RegExp(timeFormat1stRegex).test(
        this.createAppointmentTemplateTimeForm.get('Time').value,
      )
    ) {
      // Make sure that entered Time is unique (there are no duplicates)
      if (
        this.dataSource &&
        typeof this.dataSource === 'object' &&
        this.dataSource.data &&
        typeof this.dataSource.data === 'object' &&
        this.dataSource.data.length > 0
      ) {
        let filteredTime = this.dataSource.data.filter(
          (currentElement) =>
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Time') &&
            typeof currentElement.Time === 'string' &&
            currentElement.Time.length > 0 &&
            currentElement.Time ===
              this.createAppointmentTemplateTimeForm.get('Time').value,
        );

        if (
          filteredTime &&
          typeof filteredTime === 'object' &&
          filteredTime.length > 0
        ) {
          this.snackbarService.showInfo('Vreme koje ste uneli već postoji.');
          return;
        }
      }

      // Make sure that entered Time is in selected Shift interval
      if (
        this.readAppointmentTemplateForm.get('Shift').value &&
        typeof this.readAppointmentTemplateForm.get('Shift').value ===
          'object' &&
        this.readAppointmentTemplateForm
          .get('Shift')
          .value.hasOwnProperty('ShiftFrom') &&
        typeof this.readAppointmentTemplateForm.get('Shift').value.ShiftFrom ===
          'string' &&
        this.readAppointmentTemplateForm.get('Shift').value.ShiftFrom.length >
          0 &&
        RegExp(timeFormat1stRegex).test(
          this.readAppointmentTemplateForm.get('Shift').value.ShiftFrom,
        ) &&
        this.readAppointmentTemplateForm
          .get('Shift')
          .value.hasOwnProperty('ShiftTo') &&
        typeof this.readAppointmentTemplateForm.get('Shift').value.ShiftTo ===
          'string' &&
        this.readAppointmentTemplateForm.get('Shift').value.ShiftTo.length >
          0 &&
        RegExp(timeFormat1stRegex).test(
          this.readAppointmentTemplateForm.get('Shift').value.ShiftTo,
        )
      ) {
        let timeHours = parseInt(
          this.createAppointmentTemplateTimeForm
            .get('Time')
            .value.split(':')[0],
        );
        let timeMinutes = parseInt(
          this.createAppointmentTemplateTimeForm
            .get('Time')
            .value.split(':')[1],
        );

        let shiftFromHours = parseInt(
          this.readAppointmentTemplateForm
            .get('Shift')
            .value.ShiftFrom.split(':')[0],
        );
        let shiftFromMinutes = parseInt(
          this.readAppointmentTemplateForm
            .get('Shift')
            .value.ShiftFrom.split(':')[1],
        );

        let shiftToHours = parseInt(
          this.readAppointmentTemplateForm
            .get('Shift')
            .value.ShiftTo.split(':')[0],
        );
        let shiftToMinutes = parseInt(
          this.readAppointmentTemplateForm
            .get('Shift')
            .value.ShiftTo.split(':')[1],
        );

        if (
          !(
            typeof timeHours === 'number' &&
            isNaN(timeHours) === false &&
            typeof timeMinutes === 'number' &&
            isNaN(timeMinutes) === false &&
            typeof shiftFromHours === 'number' &&
            isNaN(shiftFromHours) === false &&
            typeof shiftFromMinutes === 'number' &&
            isNaN(shiftFromMinutes) === false &&
            typeof shiftToHours === 'number' &&
            isNaN(shiftToHours) === false &&
            typeof shiftToMinutes === 'number' &&
            isNaN(shiftToMinutes) === false &&
            timeHours * 60 + timeMinutes >=
              shiftFromHours * 60 + shiftFromMinutes &&
            timeHours * 60 + timeMinutes < shiftToHours * 60 + shiftToMinutes
          )
        ) {
          this.snackbarService.showInfo(
            'Vreme mora biti u intervalu izabrane Smene.',
          );
        } else {
          if (
            this.dataSource &&
            typeof this.dataSource === 'object' &&
            this.dataSource.data &&
            typeof this.dataSource.data === 'object' &&
            this.dataSource.data.length >= 0
          ) {
            let shiftTimes: ShiftTime[] = this.dataSource.data;
            shiftTimes.push(
              new ShiftTime(
                null,
                this.createAppointmentTemplateTimeForm.get('Time').value,
              ),
            );
            this.dataSource = new MatTableDataSource<ShiftTime>(shiftTimes);
            this.hasUserChangedTableData = true;
            this.createAppointmentTemplateTimeForm.reset();
          }
        }
      }
    }
  }

  onDeleteAppointmentTemplateTime(shiftTime: ShiftTime) {
    if (
      shiftTime &&
      typeof shiftTime === 'object' &&
      shiftTime.hasOwnProperty('Time') &&
      this.dataSource &&
      typeof this.dataSource === 'object' &&
      this.dataSource.data &&
      typeof this.dataSource.data === 'object' &&
      this.dataSource.data.length > 0
    ) {
      let filteredShiftTimes: ShiftTime[] = this.dataSource.data.filter(
        (currentElement) =>
          typeof currentElement === 'object' &&
          currentElement.hasOwnProperty('Time') &&
          currentElement.Time !== shiftTime.Time,
      );

      if (
        filteredShiftTimes &&
        typeof filteredShiftTimes === 'object' &&
        filteredShiftTimes.length >= 0
      ) {
        this.dataSource = new MatTableDataSource<ShiftTime>(filteredShiftTimes);
        this.hasUserChangedTableData = true;
      }
    }
  }

  onUpdateAppointmentTemplate() {
    if (
      this.appointmentTemplate &&
      typeof this.appointmentTemplate === 'object' &&
      this.appointmentTemplate.hasOwnProperty('AppointmentSchedulesVM')
    ) {
      if (
        this.dataSource &&
        typeof this.dataSource === 'object' &&
        this.dataSource.data &&
        typeof this.dataSource.data === 'object' &&
        this.dataSource.data.length >= 0
      ) {
        this.appointmentTemplate.AppointmentSchedulesVM = this.dataSource.data;

        this.progressBarService.start();

        const updateAppointmentTemplateSubscription = this.dashboardService
          .updateAppointmentTemplate(this.appointmentTemplate)
          .subscribe(
            (response: boolean) => {
              this.progressBarService.stop();

              if (response === true) {
                this.snackbarService.showSuccess(
                  'Šablon termina pregleda je uspešno izmenjen.',
                );

                this.dialogRef.close();
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(updateAppointmentTemplateSubscription);
      }
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
