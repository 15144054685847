import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/core/authorization.service';
import { CreatePricelistComponent } from './dialogs/create-pricelist/create-pricelist.component';
import { UpdatePricelistComponent } from './dialogs/update-pricelist/update-pricelist.component';
import { PricelistService } from 'src/app/core/services/pricelist.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import { DeleteDialogComponent } from '../operation-administration/dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-pricelist-administration',
  templateUrl: './pricelist-administration.component.html',
  styleUrls: ['./pricelist-administration.component.scss'],
})
export class PricelistAdministrationComponent implements OnInit {
  nameInput: string;
  priceInput: number;
  pricelist: Pricelist[] = [];
  helperList: Pricelist[] = [];
  permissions: string[] = [];

  displayedColumns: string[] = [
    'Name',
    'Price',
    'Delete',
  ];
  dataSource = new MatTableDataSource<Pricelist>(this.pricelist);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public authorizationService: AuthorizationService,
    private priceListService: PricelistService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.helperList = [...this.pricelist];
    this.getAllPriceList();
    this.snackbarService.showError('Došlo je do greške prilikom učitavanja.');
    this.snackbarService.showSuccess('Uspešno učitana lista cena usluga.');
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<Pricelist>(this.pricelist);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getAllPriceList() {
    this.priceListService.getAllPricelists().subscribe((data) => {
      this.pricelist = data;
      this.helperList = [...this.pricelist];

      this.initDataSource();
    });
  }

  addServicePrice() {
    const dialogRef = this.dialog.open(CreatePricelistComponent, {
      width: '900px',
      data: this.helperList,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelSearch();
        this.getAllPriceList();
      }
    });
  }

  updateServicePrice(pricelist: Pricelist) {
    const dialogRef = this.dialog.open(UpdatePricelistComponent, {
      width: '900px',
      data: {
        pricelist: pricelist,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelSearch();
        this.getAllPriceList();
      }
    });
  }

  deletePriceList(pricelist: Pricelist) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.priceListService.deleteService(pricelist.Id).subscribe({
          next: () => {
            this.snackbarService.showSuccess('Uspešno obrisana cena usluge.');
            this.cancelSearch();
            this.getAllPriceList();
          },
          error: (error: any) => {
            this.snackbarService.showError(
              'Nije moguće obrisati uslugu jer je vezana za postojeći pregled ili operaciju.',
            );
            console.log(error);
          },
        });
      }
    });
  }

  searchPriceList() {
    this.pricelist = [...this.helperList];
    if (this.nameInput) {
      this.pricelist = this.pricelist.filter((x) =>
        x.Name.toLowerCase().includes(this.nameInput.toLowerCase()),
      );
      this.initDataSource();
    }
    if (this.priceInput) {
      this.pricelist = this.pricelist.filter((x) =>
        x.Price <= this.priceInput ? true : false,
      );
      this.initDataSource();
    }
    if (!this.nameInput && !this.priceInput) {
      this.pricelist = this.helperList;
      this.initDataSource();
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  cancelSearch() {
    this.nameInput = '';
    this.priceInput = null;
    this.pricelist = this.helperList;
    this.initDataSource();
  }
}

export interface Pricelist {
  Id: number;
  Name: string;
  Price: number;
}
