export class TemperatureNote {
    Id: number | null;
    LaserExclaimerId: number | null;
    TemperatureListTypeId: number | null;
    Date: string | null;
    Description: string;

    constructor(Id: number | null, LaserExclaimerId: number | null, TemperatureListTypeId: number | null, Date: string | null, Description: string) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.TemperatureListTypeId = TemperatureListTypeId;
        this.Date = Date;
        this.Description = Description;
    }
}