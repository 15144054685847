import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Subscription, Observable, forkJoin, timer } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

// Import the Angular Material components
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';

import { CompareDiagnosticImagesDialogComponent } from './general-examination/compare-diagnostic-images-dialog/compare-diagnostic-images-dialog.component';
import { UpdateAgreementDialogComponent } from './shared/update-agreement-dialog/update-agreement-dialog.component';
import { UpdateAnesthesiologicalExaminationDialogComponent } from './shared/update-anesthesiological-examination-dialog/update-anesthesiological-examination-dialog.component';
import { UpdateHospitalizationDialogComponent } from './shared/update-hospitalization-dialog/update-hospitalization-dialog.component';
import { UpdateOperationProtocolDialogComponent } from './shared/update-operation-protocol-dialog/update-operation-protocol-dialog.component';
import { UpdateInternalHistoryDialogComponent } from './shared/update-internal-history-dialog/update-internal-history-dialog.component';
import { UpdateSpecialistFindingsDialogComponent } from './shared/update-specialist-findings-dialog/update-specialist-findings-dialog.component';
import { UpdateLaboratoryDialogComponent } from './shared/update-laboratory-dialog/update-laboratory-dialog.component';
import { UpdateTemperatureNoteDialogComponent } from './shared/update-temperature-note-dialog/update-temperature-note-dialog.component';
import { UpdateDiabeticNoteDialogComponent } from './shared/update-diabetic-note-dialog/update-diabetic-note-dialog.component';
import { UpdateDischargeNoteDialogComponent } from './shared/update-discharge-note-dialog/update-discharge-note-dialog.component';

import { Patient } from '../shared/patient.model';
import { Gender } from '../shared/gender.model';
import { Employee } from '../shared/employee.model';
import { ExaminationChronology } from '../shared/examination-chronology.model';
import { ReadDiagnosticImagesRequest } from '../shared/read-diagnostic-images-request.model';
import { DiagnosticImage } from '../shared/diagnostic-image.model';
import { Diagnosis } from '../shared/diagnosis.model';
import { GeneralExamination } from '../shared/general-examination.model';
import { ReadExcimerLaserRequest } from '../shared/read-excimer-laser-request.model';
import { ExcimerLaser } from '../shared/excimer-laser.model';
import { Recipe } from '../shared/recipe.model';
import { PaymentItem } from '../shared/payment-item.model';
import { Payment } from '../shared/payment.model';
import { SurveyQuestion } from '../shared/survey-question.model';
import { DiagnosisItem } from '../shared/diagnosis-item.model';
import { UpdateMedicalHistoryDialogComponent } from './shared/update-medical-history-dialog/update-medical-history-dialog.component';
import { BluePaper } from '../shared/blue-paper.model';
import { DocumentListDialogComponent } from './shared/document-list-dialog/document-list-dialog.component';
import { UpdateDecursusMorbiDialogComponent } from './shared/update-decursus-morbi-dialog/update-decursus-morbi-dialog.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { AppointmentType } from '../shared/appointment-type.model';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
})
export class PatientComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  activeButton: number = 0;
  activeSubBotton: number = null;
  isNewPatient: boolean = false;
  isFormInvalid: boolean;
  isNewUser: boolean = false;
  showOperationTab: boolean = true;
  permissions: string[] = [];

  examinationChronologyCollection: ExaminationChronology[] = [];
  selectedExaminationChronologyId: number | null = null;

  maxPatientDateOfBirth = new Date();
  gendersCollection: Gender[] = [];
  doctorsCollection: Employee[] = [];

  // Used in General examination tab
  selectedGeneralExaminationId: number | null = null;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  diagnosisSearchFormControl = new UntypedFormControl();
  diagnosisSearchFormControlOS = new UntypedFormControl();
  diagnosisSearchFormControlOU = new UntypedFormControl();
  matchedDiagnosisCollection: Observable<Diagnosis[]>;
  matchedDiagnosisCollectionOS: Observable<Diagnosis[]>;
  matchedDiagnosisCollectionOU: Observable<Diagnosis[]>;
  selectedDiagnosisCollection: Diagnosis[] = [];
  selectedDiagnosisCollectionOS: Diagnosis[] = [];
  selectedDiagnosisCollectionOU: Diagnosis[] = [];
  diagnosisCollection: Diagnosis[] = [];

  // Used in Excimer laser tab
  excimerLaser: ExcimerLaser | null = null;

  // Used in Operations tab
  operations: ExcimerLaser | null = null;

  // Used in Recipe tab
  selectedRecipeId: number | null = null;

  // Used in Survey tab
  surveyQuestionsCollection: SurveyQuestion[] = [];

  // Used in Payment tab
  paymentItemsCollection: AppointmentType[] = [];
  displayedColumns: string[] = [
    'ServiceName',
    'Price',
    'Discount',
    'PriceWithDiscount',
    'Actions',
  ];
  dataSource = new MatTableDataSource<Payment>([]);
  hasUserChangedTableData: boolean = false;
  discountTotal: number = 0;
  priceWithDiscountTotal: number = 0;

  updatePatientForm = new UntypedFormGroup({
    Name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    MiddleName: new UntypedFormControl('', Validators.maxLength(64)),
    GenderId: new UntypedFormControl(null),
    IdentificationNumber: new UntypedFormControl('', Validators.required),
    PassportNumber: new UntypedFormControl(''),
    PersonalInsuranceNumber: new UntypedFormControl(
      '',
      Validators.pattern('[0-9]*'),
    ),
    DateOfBirth: new UntypedFormControl(
      {
        value: null,
        disabled: true,
      },
      Validators.required,
    ),
    Occupation: new UntypedFormControl('', Validators.maxLength(64)),
    Address: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    PhoneNumber: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    PhoneNumber1: new UntypedFormControl('', [
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    PhoneNumber2: new UntypedFormControl('', [
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    Email: new UntypedFormControl('', [Validators.required, Validators.email]),
    ChoosenDoctorId: new UntypedFormControl(null),
    Medicine: new UntypedFormControl(false),
    MedicineDescription: new UntypedFormControl(''),
    Allergies: new UntypedFormControl(false),
    AllergiesDescription: new UntypedFormControl(''),
    Hormones: new UntypedFormControl(false),
    HormonesDescription: new UntypedFormControl(''),
    Pregnant: new UntypedFormControl(false),
    PregnantMonth: new UntypedFormControl(null),
    Diabetes: new UntypedFormControl(false),
    PupilDialation: new UntypedFormControl(false),
    PupilDialationDescription: new UntypedFormControl(''),
    ChronicDisease: new UntypedFormControl(false),
    ChronicDiseaseDescription: new UntypedFormControl(''),
    EyeSurgery: new UntypedFormControl(false),
    EyeSurgeryDescription: new UntypedFormControl(''),
    OtherSurgery: new UntypedFormControl(false),
    OtherSurgeryDescription: new UntypedFormControl(''),
    Administrative: new UntypedFormControl(''),
    MedicalNote: new UntypedFormControl(''),
    ChartProtocol: new UntypedFormControl(''),
    NewUser: new UntypedFormControl(false),
  });

  // Used in General examination tab
  updateGeneralExaminationForm = new UntypedFormGroup({
    Anamnesis: new UntypedFormControl('', Validators.required),
    VODDistanceSC: new UntypedFormControl(''),
    VODDistanceCC: new UntypedFormControl(''),
    VODVisusDistance: new UntypedFormControl(''),
    VODNearSC: new UntypedFormControl(''),
    VODNearCC: new UntypedFormControl(''),
    VODVisusNear: new UntypedFormControl(''),
    VOSDistanceSC: new UntypedFormControl(''),
    VOSDistanceCC: new UntypedFormControl(''),
    VOSVisusDistance: new UntypedFormControl(''),
    VOSNearSC: new UntypedFormControl(''),
    VOSNearCC: new UntypedFormControl(''),
    VOSVisusNear: new UntypedFormControl(''),
    KeratometryOD: new UntypedFormControl(''),
    KeratometryOS: new UntypedFormControl(''),
    DominantEye: new UntypedFormControl('OS'),
    IOPAplanationTOD: new UntypedFormControl(''),
    IOPAplanationTOS: new UntypedFormControl(''),
    IOPNonContactTOD: new UntypedFormControl(''),
    IOPNonContactTOS: new UntypedFormControl(''),
    SpaltOd: new UntypedFormControl(''),
    SpaltOs: new UntypedFormControl(''),
    FundusOd: new UntypedFormControl(''),
    FundusDo: new UntypedFormControl(''),
    updateDiagnosticFormSection: new UntypedFormGroup({
      KKT: new UntypedFormControl(''),
      OctAnt: new UntypedFormControl(''),
      PahimetryDescription: new UntypedFormControl(''),
      OctPost: new UntypedFormControl(''),
      OctA: new UntypedFormControl(''),
      FotoFundus: new UntypedFormControl(''),
      FAF: new UntypedFormControl(''),
      CVP: new UntypedFormControl(''),
      EM: new UntypedFormControl(''),
      VEP: new UntypedFormControl(''),
      ERG: new UntypedFormControl(''),
      IOLMaster: new UntypedFormControl(''),
      UltrazvukOka: new UntypedFormControl(''),
      FA: new UntypedFormControl(''),
      FotoPrednjegSegmenta: new UntypedFormControl(''),
      Verion: new UntypedFormControl(''),
    }),
    SubDiagnosis: new UntypedFormControl('', Validators.required),
    Advice: new UntypedFormControl('', Validators.required),
    Intervention: new UntypedFormControl(''),
    CycloRODDistanceCC: new UntypedFormControl(''),
    CycloRODDistanceSC: new UntypedFormControl(''),
    CycloRODNearCC: new UntypedFormControl(''),
    CycloRODVisusDistance: new UntypedFormControl(''),
    CycloRODVisusNear: new UntypedFormControl(''),
    CycloROSDistanceCC: new UntypedFormControl(''),
    CycloROSDistanceSC: new UntypedFormControl(''),
    CycloROSNearCC: new UntypedFormControl(''),
    CycloROSVisusDistance: new UntypedFormControl(''),
    CycloROSVisusNear: new UntypedFormControl(''),
    FreeText: new UntypedFormControl(''),
    VOU: new UntypedFormControl(''),
    SN: new UntypedFormControl(''),
  });

  // Used in Recipe tab
  updateRecipeForm = new UntypedFormGroup({
    DistanceOD: new UntypedFormControl(''),
    DistanceOS: new UntypedFormControl(''),
    DistancePD: new UntypedFormControl(''),
    NearOD: new UntypedFormControl(''),
    NearOS: new UntypedFormControl(''),
    NearPD: new UntypedFormControl(''),
  });

  // Used in Payment tab
  updatePaymentsForm = new UntypedFormGroup({
    PaymentItem: new UntypedFormControl(null, Validators.required),
  });

  subscription: Subscription;

  // Used in Payment tab
  updateBluePaperForm = new UntypedFormGroup({
    BluePaper: new UntypedFormControl(null, Validators.required),
  });

  // Used in Payment tab
  showPaymentIfSurvayIsValid: boolean = true;

  // Used in General examination tab
  diagnosticExistState = {
    DiagnosticCVPExist: false,
    DiagnosticEMExist: false,
    DiagnosticERGExist: false,
    DiagnosticFAExist: false,
    DiagnosticFAFExist: false,
    DiagnosticFotoFundusExist: false,
    DiagnosticFotoPrednjegSegmentaExist: false,
    DiagnosticIOLMasterExist: false,
    DiagnosticKKTExist: false,
    DiagnosticOCTAExist: false,
    DiagnosticOCTAUTExist: false,
    DiagnosticOCTPOSTExist: false,
    DiagnosticUltrazvukOkaExist: false,
    DiagnosticVEPExist: false,
    DiagnosticVerionExist: false,
  };

  generalExaminationRole: boolean;
  generalExaminationEditRole: boolean;
  generalExaminationPrintRole: boolean;
  anesthesiologicalExaminationRole: boolean;
  specialistFindingsRole: boolean;
  agreementRole: boolean;
  medicalHistoryRole: boolean;
  operationProtocolRole: boolean;
  laboratoryRole: boolean;
  temperatureNoteRole: boolean;
  diabeticNoteRole: boolean;
  dischargeNoteRole: boolean;
  recipeRole: boolean;
  recipeEditRole: boolean;
  recipePrintRole: boolean;
  surveyRole: boolean;
  surveyEditRole: boolean;
  paymentsRole: boolean;
  paymentsEditRole: boolean;
  paymentsBluePaperRole: boolean;
  paymentsBluePaperEditRole: boolean;
  isDivVisible: boolean;

  @ViewChild('diagnosisSearchInput', { static: false })
  diagnosisSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('diagnosisAutocomplete', { static: false })
  matAutocomplete: MatAutocomplete;
  @ViewChild('diagnosisSearchInputOS', { static: false })
  diagnosisSearchInputOS: ElementRef<HTMLInputElement>;
  @ViewChild('diagnosisAutocompleteOS', { static: false })
  matAutocompleteOS: MatAutocomplete;
  @ViewChild('diagnosisAutocompleteOU', { static: false })
  matAutocompleteOU: MatAutocomplete;
  @ViewChild('diagnosisSearchInputOU', { static: false })
  diagnosisSearchInputOU: ElementRef<HTMLInputElement>;

  constructor(
    public authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) {
    this.matchedDiagnosisCollection =
      this.diagnosisSearchFormControl.valueChanges.pipe(
        startWith(null),
        map((searchTerm: string | null) =>
          typeof searchTerm === 'string' && searchTerm.length > 0
            ? this.filterDiagnosisByName(searchTerm)
            : this.diagnosisCollection.slice(),
        ),
      );
    this.matchedDiagnosisCollectionOS =
      this.diagnosisSearchFormControlOS.valueChanges.pipe(
        startWith(null),
        map((searchTerm: string | null) =>
          typeof searchTerm === 'string' && searchTerm.length > 0
            ? this.filterDiagnosisByName(searchTerm)
            : this.diagnosisCollection.slice(),
        ),
      );
    this.matchedDiagnosisCollectionOU =
      this.diagnosisSearchFormControlOU.valueChanges.pipe(
        startWith(null),
        map((searchTerm: string | null) =>
          typeof searchTerm === 'string' && searchTerm.length > 0
            ? this.filterDiagnosisByName(searchTerm)
            : this.diagnosisCollection.slice(),
        ),
      );
  }

  ngOnInit() {
    if (
      typeof this.dashboardService.getPatientId() === 'number' &&
      isNaN(this.dashboardService.getPatientId()) === false
    ) {
      // Used to highlight Address, PhoneNumber and Email input fields (from update patient form) in green color if patient is new
      if (typeof this.dashboardService.getIsNewPatientState() === 'boolean')
        this.isNewPatient = this.dashboardService.getIsNewPatientState();

      // Get Patient data
      this.progressBarService.start();
      this.isDivVisible = false;
      const readPatientSubscription = this.dashboardService
        .readPatient(this.dashboardService.getPatientId())
        .subscribe(
          (response: Patient) => {
            this.progressBarService.stop();

            // If Patient data exists, get examination chronology data for current Patient
            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('NewUser') &&
                typeof response.NewUser === 'boolean'
              )
                this.isNewUser = response.NewUser;
              if (
                response.hasOwnProperty('Name') &&
                typeof response.Name === 'string' &&
                response.Name.length > 0
              )
                this.updatePatientForm.get('Name').setValue(response.Name);
              if (
                response.hasOwnProperty('MiddleName') &&
                typeof response.MiddleName === 'string' &&
                response.MiddleName.length > 0
              )
                this.updatePatientForm
                  .get('MiddleName')
                  .setValue(response.MiddleName);
              if (
                response.hasOwnProperty('IdentificationNumber') &&
                typeof response.IdentificationNumber === 'string' &&
                response.IdentificationNumber.length > 0
              )
                this.updatePatientForm
                  .get('IdentificationNumber')
                  .setValue(response.IdentificationNumber);
              if (
                response.hasOwnProperty('PersonalInsuranceNumber') &&
                typeof response.PersonalInsuranceNumber === 'string' &&
                response.PersonalInsuranceNumber.length > 0
              )
                this.updatePatientForm
                  .get('PersonalInsuranceNumber')
                  .setValue(response.PersonalInsuranceNumber);
              if (
                response.hasOwnProperty('PassportNumber') &&
                typeof response.PassportNumber === 'string' &&
                response.PassportNumber.length > 0
              )
                this.updatePatientForm
                  .get('PassportNumber')
                  .setValue(response.PassportNumber);
              if (
                response.hasOwnProperty('DateOfBirth') &&
                typeof response.DateOfBirth === 'string' &&
                response.DateOfBirth.length > 0
              )
                this.updatePatientForm
                  .get('DateOfBirth')
                  .setValue(new Date(response.DateOfBirth));
              if (
                response.hasOwnProperty('Occupation') &&
                typeof response.Occupation === 'string' &&
                response.Occupation.length > 0
              )
                this.updatePatientForm
                  .get('Occupation')
                  .setValue(response.Occupation);
              if (
                response.hasOwnProperty('Address') &&
                typeof response.Address === 'string' &&
                response.Address.length > 0
              )
                this.updatePatientForm
                  .get('Address')
                  .setValue(response.Address);
              if (
                response.hasOwnProperty('GenderId') &&
                typeof response.GenderId === 'number' &&
                isNaN(response.GenderId) === false
              )
                this.updatePatientForm
                  .get('GenderId')
                  .setValue(response.GenderId);
              if (
                response.hasOwnProperty('PhoneNumber') &&
                typeof response.PhoneNumber === 'string' &&
                response.PhoneNumber.length > 0
              )
                this.updatePatientForm
                  .get('PhoneNumber')
                  .setValue(response.PhoneNumber);
              if (
                response.hasOwnProperty('PhoneNumber1') &&
                typeof response.PhoneNumber1 === 'string' &&
                response.PhoneNumber1.length > 0
              )
                this.updatePatientForm
                  .get('PhoneNumber1')
                  .setValue(response.PhoneNumber1);
              if (
                response.hasOwnProperty('PhoneNumber2') &&
                typeof response.PhoneNumber2 === 'string' &&
                response.PhoneNumber2.length > 0
              )
                this.updatePatientForm
                  .get('PhoneNumber2')
                  .setValue(response.PhoneNumber2);
              if (
                response.hasOwnProperty('Email') &&
                typeof response.Email === 'string' &&
                response.Email.length > 0
              )
                this.updatePatientForm.get('Email').setValue(response.Email);
              if (
                response.hasOwnProperty('ChoosenDoctorId') &&
                typeof response.ChoosenDoctorId === 'number' &&
                isNaN(response.ChoosenDoctorId) === false
              )
                this.updatePatientForm
                  .get('ChoosenDoctorId')
                  .setValue(response.ChoosenDoctorId);
              if (
                response.hasOwnProperty('Medicine') &&
                typeof response.Medicine === 'boolean'
              )
                this.updatePatientForm
                  .get('Medicine')
                  .setValue(response.Medicine);
              if (
                response.hasOwnProperty('MedicineDescription') &&
                typeof response.MedicineDescription === 'string' &&
                response.MedicineDescription.length > 0
              )
                this.updatePatientForm
                  .get('MedicineDescription')
                  .setValue(response.MedicineDescription);
              if (
                response.hasOwnProperty('Allergies') &&
                typeof response.Allergies === 'boolean'
              )
                this.updatePatientForm
                  .get('Allergies')
                  .setValue(response.Allergies);
              if (
                response.hasOwnProperty('AllergiesDescription') &&
                typeof response.AllergiesDescription === 'string' &&
                response.AllergiesDescription.length > 0
              )
                this.updatePatientForm
                  .get('AllergiesDescription')
                  .setValue(response.AllergiesDescription);
              if (
                response.hasOwnProperty('Hormones') &&
                typeof response.Hormones === 'boolean'
              )
                this.updatePatientForm
                  .get('Hormones')
                  .setValue(response.Hormones);
              if (
                response.hasOwnProperty('HormonesDescription') &&
                typeof response.HormonesDescription === 'string' &&
                response.HormonesDescription.length > 0
              )
                this.updatePatientForm
                  .get('HormonesDescription')
                  .setValue(response.HormonesDescription);
              if (
                response.hasOwnProperty('Pregnant') &&
                typeof response.Pregnant === 'boolean'
              )
                this.updatePatientForm
                  .get('Pregnant')
                  .setValue(response.Pregnant);
              if (
                response.hasOwnProperty('PregnantMonth') &&
                typeof response.PregnantMonth === 'number' &&
                isNaN(response.PregnantMonth) === false
              )
                this.updatePatientForm
                  .get('PregnantMonth')
                  .setValue(response.PregnantMonth);
              if (
                response.hasOwnProperty('Diabetes') &&
                typeof response.Diabetes === 'boolean'
              )
                this.updatePatientForm
                  .get('Diabetes')
                  .setValue(response.Diabetes);
              if (
                response.hasOwnProperty('PupilDialation') &&
                typeof response.PupilDialation === 'boolean'
              )
                this.updatePatientForm
                  .get('PupilDialation')
                  .setValue(response.PupilDialation);
              if (
                response.hasOwnProperty('PupilDialationDescription') &&
                typeof response.PupilDialationDescription === 'string' &&
                response.PupilDialationDescription.length > 0
              )
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .setValue(response.PupilDialationDescription);
              if (
                response.hasOwnProperty('ChronicDisease') &&
                typeof response.ChronicDisease === 'boolean'
              )
                this.updatePatientForm
                  .get('ChronicDisease')
                  .setValue(response.ChronicDisease);
              if (
                response.hasOwnProperty('ChronicDiseaseDescription') &&
                typeof response.ChronicDiseaseDescription === 'string' &&
                response.ChronicDiseaseDescription.length > 0
              )
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .setValue(response.ChronicDiseaseDescription);
              if (
                response.hasOwnProperty('EyeSurgery') &&
                typeof response.EyeSurgery === 'boolean'
              )
                this.updatePatientForm
                  .get('EyeSurgery')
                  .setValue(response.EyeSurgery);
              if (
                response.hasOwnProperty('EyeSurgeryDescription') &&
                typeof response.EyeSurgeryDescription === 'string' &&
                response.EyeSurgeryDescription.length > 0
              )
                this.updatePatientForm
                  .get('EyeSurgeryDescription')
                  .setValue(response.EyeSurgeryDescription);
              if (
                response.hasOwnProperty('OtherSurgery') &&
                typeof response.OtherSurgery === 'boolean'
              )
                this.updatePatientForm
                  .get('OtherSurgery')
                  .setValue(response.OtherSurgery);
              if (
                response.hasOwnProperty('OtherSurgeryDescription') &&
                typeof response.OtherSurgeryDescription === 'string' &&
                response.OtherSurgeryDescription.length > 0
              )
                this.updatePatientForm
                  .get('OtherSurgeryDescription')
                  .setValue(response.OtherSurgeryDescription);
              if (
                response.hasOwnProperty('Administrative') &&
                typeof response.Administrative === 'string' &&
                response.Administrative.length > 0
              )
                this.updatePatientForm
                  .get('Administrative')
                  .setValue(response.Administrative);
              if (
                response.hasOwnProperty('MedicalNote') &&
                typeof response.MedicalNote === 'string' &&
                response.MedicalNote.length > 0
              )
                this.updatePatientForm
                  .get('MedicalNote')
                  .setValue(response.MedicalNote);
              if (
                response.hasOwnProperty('ChartProtocol') &&
                typeof response.ChartProtocol === 'string' &&
                response.ChartProtocol.length > 0
              )
                this.updatePatientForm
                  .get('ChartProtocol')
                  .setValue(response.ChartProtocol);

              this.checkIfShowOperationTab();

              // Initialize questionnaire inputs
              this.onChangeQuestionState('Medicine');
              this.onChangeQuestionState('Allergies');
              this.onChangeQuestionState('Hormones');
              this.onChangeQuestionState('Pregnant');
              this.onChangeQuestionState('PupilDialation');
              this.onChangeQuestionState('ChronicDisease');
              this.onChangeQuestionState('EyeSurgery');
              this.onChangeQuestionState('OtherSurgery');

              // Check is form valid
              this.isFormInvalid = this.updatePatientForm.invalid;
              this.handleFormsState();

              this.progressBarService.start();

              const forkJoinSubscription = forkJoin([
                this.dashboardService.readGenders(),
                this.dashboardService.readDoctors(),
                this.dashboardService.readPatientExaminationChronology(
                  this.dashboardService.getPatientId(),
                ),
                this.dashboardService.readDiagnosis(),
                this.dashboardService.readPaymentItems(),
              ]).subscribe(
                ([
                  response1st,
                  response2nd,
                  response3rd,
                  response4th,
                  response5th,
                ]) => {
                  this.progressBarService.stop();

                  if (
                    response1st &&
                    typeof response1st === 'object' &&
                    response1st.length > 0
                  )
                    this.gendersCollection = response1st;
                  if (
                    response2nd &&
                    typeof response2nd === 'object' &&
                    response2nd.length > 0
                  )
                    this.doctorsCollection = response2nd;

                  if (response3rd.length === 0) {
                    this.snackbarService.showInfo(
                      'Ne postoji dostupna hronologija pregleda za izabranog pacijenta.',
                    );
                  } else if (response3rd.length > 0) {
                    this.examinationChronologyCollection = response3rd;
                    this.onChangeSelectedExaminationChronologyTab(0, true);
                  }

                  if (
                    response4th &&
                    typeof response4th === 'object' &&
                    response4th.length > 0
                  )
                    this.diagnosisCollection = response4th;

                  if (
                    response5th &&
                    typeof response5th === 'object' &&
                    response5th.length > 0
                  ) {
                    this.paymentItemsCollection = response5th.sort(
                      (operand1st, operand2nd) => {
                        if (
                          operand1st &&
                          typeof operand1st === 'object' &&
                          operand1st.hasOwnProperty('Name') &&
                          typeof operand1st.Name === 'string' &&
                          operand1st.Name.length > 0 &&
                          operand2nd &&
                          typeof operand2nd === 'object' &&
                          operand2nd.hasOwnProperty('Name') &&
                          typeof operand2nd.Name === 'string' &&
                          operand2nd.Name.length > 0
                        ) {
                          if (
                            operand1st.Name.toLowerCase() <
                            operand2nd.Name.toLowerCase()
                          )
                            return -1;
                          if (
                            operand1st.Name.toLowerCase() >
                            operand2nd.Name.toLowerCase()
                          )
                            return 1;
                        }

                        return 0;
                      },
                    );
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

              this.subscriptions.add(forkJoinSubscription);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readPatientSubscription);

      this.authorizationService.readPermissions().subscribe(
        (permissions) => {
          this.permissions = permissions;
          this.generalExaminationRole = permissions.includes('OPPREGLED');
          this.generalExaminationEditRole = permissions.includes('OPIZMENA');
          this.generalExaminationPrintRole = permissions.includes('OPSTAMPA');
          this.anesthesiologicalExaminationRole =
            permissions.includes('ANEPREGLED');
          this.specialistFindingsRole = permissions.includes('SPECNALPREGLED');
          this.agreementRole = permissions.includes('SAGPREGLED');
          this.medicalHistoryRole = permissions.includes('ISTORIJAPREGLED');
          this.operationProtocolRole = permissions.includes(
            'PROTOKOLIOPERPREGLED',
          );
          this.laboratoryRole = permissions.includes('LABPREGLED');
          this.temperatureNoteRole = permissions.includes('TEMPLPREGLED');
          this.diabeticNoteRole = permissions.includes('LISTADIAPREGLED');
          this.dischargeNoteRole = permissions.includes('OTPLPREGLED');
          this.recipeRole = permissions.includes('RECPREGLED');
          this.recipeEditRole = permissions.includes('RECIZMENA');
          this.recipePrintRole = permissions.includes('RECSTAMPA');
          this.surveyRole = permissions.includes('ANKPREGLED');
          this.surveyEditRole = permissions.includes('ANKIZMENA');
          this.paymentsRole = permissions.includes('NAPPREGLED');
          this.paymentsEditRole = permissions.includes('NAPIZMENA');
          this.paymentsBluePaperRole = permissions.includes('NAPPPPREGLED');
          this.paymentsBluePaperEditRole = permissions.includes('NAPPPIZMENA');
        },
        (error) => {
          console.error('Error fetching permissions:', error);
        },
      );
    }
    this.startTimer();
  }

  checkIfShowOperationTab() {
    this.authorizationService.getCurrentUserJobId().subscribe((userJobId) => {
      if (this.updatePatientForm.value.ChartProtocol == '' && userJobId === 4) {
        this.showOperationTab = false;
      } else {
        this.showOperationTab = true;
      }
    });
  }

  startTimer() {
    const source = timer(100000, 200000);
    //this.subscription = source.subscribe(val => this.onUpdateGeneralExamination());
  }

  stopTimer() {
    this.subscription.unsubscribe();
  }

  onReadPhoneNumberErrorMessage(): string {
    return this.updatePatientForm.get('PhoneNumber').hasError('required')
      ? 'Unesite broj telefona'
      : this.updatePatientForm.get('PhoneNumber').hasError('pattern')
      ? 'Broj telefona mora da sadrži samo cifre'
      : '';
  }

  changeDivVisibility() {
    if (this.isDivVisible === true) {
      this.isDivVisible = false;
    } else {
      this.isDivVisible = true;
    }
  }

  onReadEmailErrorMessage(): string {
    return this.updatePatientForm.get('Email').hasError('required')
      ? 'Unesite e-mail'
      : this.updatePatientForm.get('Email').hasError('email')
      ? 'Niste uneli validan e-mail'
      : '';
  }

  onReadPregnancyErrorMessage(): string {
    return this.updatePatientForm.get('PregnantMonth').hasError('required')
      ? 'Unesite mesec trudnoće'
      : this.updatePatientForm.get('PregnantMonth').hasError('min')
      ? 'Mesec trudnoće mora biti od 1 do 9'
      : this.updatePatientForm.get('PregnantMonth').hasError('max')
      ? 'Mesec trudnoće mora biti od 1 do 9'
      : '';
  }

  onChangeQuestionState(questionType: string) {
    if (typeof questionType === 'string' && questionType.length > 0) {
      switch (questionType) {
        case 'Medicine':
          {
            if (
              typeof this.updatePatientForm.get('Medicine').value === 'boolean'
            ) {
              if (this.updatePatientForm.get('Medicine').value === true) {
                this.updatePatientForm
                  .get('MedicineDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm.get('MedicineDescription').enable();
              } else {
                this.updatePatientForm
                  .get('MedicineDescription')
                  .clearValidators();
                this.updatePatientForm.get('MedicineDescription').setValue('');
                this.updatePatientForm.get('MedicineDescription').disable();
              }
            }
          }
          break;
        case 'Allergies':
          {
            if (
              typeof this.updatePatientForm.get('Allergies').value === 'boolean'
            ) {
              if (this.updatePatientForm.get('Allergies').value === true) {
                this.updatePatientForm
                  .get('AllergiesDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm.get('AllergiesDescription').enable();
              } else {
                this.updatePatientForm
                  .get('AllergiesDescription')
                  .clearValidators();
                this.updatePatientForm.get('AllergiesDescription').setValue('');
                this.updatePatientForm.get('AllergiesDescription').disable();
              }
            }
          }
          break;
        case 'Hormones':
          {
            if (
              typeof this.updatePatientForm.get('Hormones').value === 'boolean'
            ) {
              if (this.updatePatientForm.get('Hormones').value === true) {
                this.updatePatientForm
                  .get('HormonesDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm.get('HormonesDescription').enable();
              } else {
                this.updatePatientForm
                  .get('HormonesDescription')
                  .clearValidators();
                this.updatePatientForm.get('HormonesDescription').setValue('');
                this.updatePatientForm.get('HormonesDescription').disable();
              }
            }
          }
          break;
        case 'Pregnant':
          {
            if (
              typeof this.updatePatientForm.get('Pregnant').value === 'boolean'
            ) {
              if (this.updatePatientForm.get('Pregnant').value === true) {
                this.updatePatientForm
                  .get('PregnantMonth')
                  .setValidators([
                    Validators.required,
                    Validators.min(1),
                    Validators.max(9),
                  ]);
                this.updatePatientForm.get('PregnantMonth').enable();
              } else {
                this.updatePatientForm.get('PregnantMonth').clearValidators();
                this.updatePatientForm.get('PregnantMonth').setValue(null);
                this.updatePatientForm.get('PregnantMonth').disable();
              }
            }
          }
          break;
        case 'PupilDialation':
          {
            if (
              typeof this.updatePatientForm.get('PupilDialation').value ===
              'boolean'
            ) {
              if (this.updatePatientForm.get('PupilDialation').value === true) {
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .enable();
              } else {
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .clearValidators();
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .setValue('');
                this.updatePatientForm
                  .get('PupilDialationDescription')
                  .disable();
              }
            }
          }
          break;
        case 'ChronicDisease':
          {
            if (
              typeof this.updatePatientForm.get('ChronicDisease').value ===
              'boolean'
            ) {
              if (this.updatePatientForm.get('ChronicDisease').value === true) {
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .enable();
              } else {
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .clearValidators();
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .setValue('');
                this.updatePatientForm
                  .get('ChronicDiseaseDescription')
                  .disable();
              }
            }
          }
          break;
        case 'EyeSurgery':
          {
            if (
              typeof this.updatePatientForm.get('EyeSurgery').value ===
              'boolean'
            ) {
              if (this.updatePatientForm.get('EyeSurgery').value === true) {
                this.updatePatientForm
                  .get('EyeSurgeryDescription')
                  .setValidators(Validators.required);
                this.updatePatientForm.get('EyeSurgeryDescription').enable();
              } else {
                this.updatePatientForm
                  .get('EyeSurgeryDescription')
                  .clearValidators();
                this.updatePatientForm
                  .get('EyeSurgeryDescription')
                  .setValue('');
                this.updatePatientForm.get('EyeSurgeryDescription').disable();
              }
            }
          }
          break;
        case 'OtherSurgery': {
          if (
            typeof this.updatePatientForm.get('OtherSurgery').value ===
            'boolean'
          ) {
            if (this.updatePatientForm.get('OtherSurgery').value === true) {
              this.updatePatientForm
                .get('OtherSurgeryDescription')
                .setValidators(Validators.required);
              this.updatePatientForm.get('OtherSurgeryDescription').enable();
            } else {
              this.updatePatientForm
                .get('OtherSurgeryDescription')
                .clearValidators();
              this.updatePatientForm
                .get('OtherSurgeryDescription')
                .setValue('');
              this.updatePatientForm.get('OtherSurgeryDescription').disable();
            }
          }
        }
      }
    }
  }

  onUpdatePatient() {
    if (
      typeof this.dashboardService.getPatientId() === 'number' &&
      isNaN(this.dashboardService.getPatientId()) === false
    ) {
      this.progressBarService.start();

      const updatePatientSubscription = this.dashboardService
        .updatePatient(
          new Patient(
            this.dashboardService.getPatientId(),
            this.updatePatientForm.value.Name,
            this.updatePatientForm.value.MiddleName,
            this.updatePatientForm.value.GenderId,
            this.updatePatientForm.value.IdentificationNumber,
            this.updatePatientForm.value.PassportNumber,
            this.updatePatientForm.value.PersonalInsuranceNumber,
            formatDate(
              this.updatePatientForm.get('DateOfBirth').value,
              'yyyy-MM-dd',
              'sr-Latn',
            ),
            this.updatePatientForm.value.Occupation,
            this.updatePatientForm.value.Address,
            this.updatePatientForm.value.PhoneNumber,
            this.updatePatientForm.value.Email,
            this.updatePatientForm.value.ChoosenDoctorId,
            this.updatePatientForm.value.Medicine,
            this.updatePatientForm.value.MedicineDescription,
            this.updatePatientForm.value.Allergies,
            this.updatePatientForm.value.AllergiesDescription,
            this.updatePatientForm.value.Hormones,
            this.updatePatientForm.value.HormonesDescription,
            this.updatePatientForm.value.Pregnant,
            this.updatePatientForm.value.PregnantMonth,
            this.updatePatientForm.value.Diabetes,
            this.updatePatientForm.value.PupilDialation,
            this.updatePatientForm.value.PupilDialationDescription,
            this.updatePatientForm.value.ChronicDisease,
            this.updatePatientForm.value.ChronicDiseaseDescription,
            this.updatePatientForm.value.EyeSurgery,
            this.updatePatientForm.value.EyeSurgeryDescription,
            this.updatePatientForm.value.OtherSurgery,
            this.updatePatientForm.value.OtherSurgeryDescription,
            null,
            '',
            '',
            '',
            '',
            false,
            this.updatePatientForm.value.PhoneNumber1,
            this.updatePatientForm.value.PhoneNumber2,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.updatePatientForm.value.Administrative,
            this.updatePatientForm.value.MedicalNote,
            this.updatePatientForm.value.ChartProtocol,
            this.updatePatientForm.value.NewUser,
          ),
        )
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.checkIfShowOperationTab();
              this.snackbarService.showSuccess('Pacijent je uspešno izmenjen.');
              this.handleFormsState();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updatePatientSubscription);
    }
  }

  onChangeSelectedExaminationChronologyTab(
    index: number,
    buttonLevel: boolean,
    parentId?: number,
    childredId?: number,
  ) {
    if (buttonLevel) {
      this.activeButton = index;
      this.activeSubBotton = null;
      if (
        this.examinationChronologyCollection &&
        typeof this.examinationChronologyCollection === 'object' &&
        this.examinationChronologyCollection.length > 0 &&
        typeof index === 'number' &&
        isNaN(index) === false &&
        index >= 0 &&
        index <= this.examinationChronologyCollection.length - 1
      ) {
        if (
          this.examinationChronologyCollection[index] &&
          typeof this.examinationChronologyCollection[index] === 'object' &&
          this.examinationChronologyCollection[index].hasOwnProperty('Id') &&
          typeof this.examinationChronologyCollection[index].Id === 'number' &&
          isNaN(this.examinationChronologyCollection[index].Id) === false
        ) {
          this.selectedExaminationChronologyId =
            this.examinationChronologyCollection[index].Id;
          this.updateBluePaperForm
            .get('BluePaper')
            .setValue(
              this.examinationChronologyCollection[this.activeButton].BluePaper,
            );
        } else this.selectedExaminationChronologyId = null;
      }
    } else {
      this.activeSubBotton = childredId
        ? childredId
        : childredId === 0
        ? childredId
        : null;
      this.activeButton = null;
      if (
        this.examinationChronologyCollection &&
        typeof this.examinationChronologyCollection === 'object' &&
        this.examinationChronologyCollection.length > 0 &&
        typeof index === 'number' &&
        isNaN(index) === false &&
        index >= 0 &&
        typeof parentId === 'number' &&
        isNaN(parentId) === false
      ) {
        let selectedElement = this.examinationChronologyCollection.filter(
          (item) => item.Id === parentId,
        );
        this.selectedExaminationChronologyId =
          selectedElement[0].ChildrenList[index].Id;
        this.updateBluePaperForm
          .get('BluePaper')
          .setValue(selectedElement[0].ChildrenList[index].BluePaper);
      } else this.selectedExaminationChronologyId = null;
    }

    this.showPaymentIfSurvayIsValid = true;

    // WORK IN PROGRESS
    this.readGeneralExamination();
    //this.readExcimerLaser();
    this.readOperations();
    this.readRecipe();
    this.readSurvey();
    this.readPayments();
  }

  // Used in General examination tab
  onChangeDominantEyeState() {
    let currentState: string =
      this.updateGeneralExaminationForm.get('DominantEye').value;

    if (typeof currentState === 'string' && currentState.length > 0) {
      if (currentState.toLowerCase() === 'os')
        this.updateGeneralExaminationForm.get('DominantEye').setValue('OD');
      else this.updateGeneralExaminationForm.get('DominantEye').setValue('OS');
    }
  }

  // Used in General examination tab
  onChangeDiagnosticImageSelection(
    diagnosticType: string,
    imagesCollection: FileList,
  ) {
    if (typeof diagnosticType === 'string' && diagnosticType.length > 0) {
      let requestData = new FormData();

      switch (diagnosticType.toLowerCase()) {
        case 'kkt':
          {
            requestData.append('DiagnosticsTypeId', '1');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('KKT').value,
            );
          }
          break;
        case 'oct_aut':
          {
            requestData.append('DiagnosticsTypeId', '2');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('OctAnt').value,
            );
          }
          break;
        case 'oct_post':
          {
            requestData.append('DiagnosticsTypeId', '3');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('OctPost').value,
            );
          }
          break;
        case 'oct_a':
          {
            requestData.append('DiagnosticsTypeId', '4');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('OctA').value,
            );
          }
          break;
        case 'photo_fundus':
          {
            requestData.append('DiagnosticsTypeId', '5');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('FotoFundus').value,
            );
          }
          break;
        case 'faf':
          {
            requestData.append('DiagnosticsTypeId', '6');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('FAF').value,
            );
          }
          break;
        case 'cvp':
          {
            requestData.append('DiagnosticsTypeId', '7');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('CVP').value,
            );
          }
          break;
        case 'em':
          {
            requestData.append('DiagnosticsTypeId', '8');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('EM').value,
            );
          }
          break;
        case 'vep':
          {
            requestData.append('DiagnosticsTypeId', '9');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('VEP').value,
            );
          }
          break;
        case 'erg':
          {
            requestData.append('DiagnosticsTypeId', '10');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('ERG').value,
            );
          }
          break;
        case 'iol_master':
          {
            requestData.append('DiagnosticsTypeId', '1004');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('IOLMaster').value,
            );
          }
          break;
        case 'ultrasound_eye':
          {
            requestData.append('DiagnosticsTypeId', '1005');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('UltrazvukOka').value,
            );
          }
          break;
        case 'fa':
          {
            requestData.append('DiagnosticsTypeId', '1006');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('FA').value,
            );
          }
          break;
        case 'front_segment_photo':
          {
            requestData.append('DiagnosticsTypeId', '1007');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('FotoPrednjegSegmenta').value,
            );
          }
          break;
        case 'verion':
          {
            requestData.append('DiagnosticsTypeId', '1008');
            requestData.append(
              'Description',
              this.updateGeneralExaminationForm
                .get('updateDiagnosticFormSection')
                .get('Verion').value,
            );
          }
          break;
        default:
          return;
      }

      if (
        imagesCollection &&
        imagesCollection instanceof FileList &&
        imagesCollection.length > 0
      ) {
        if (
          imagesCollection[0] &&
          imagesCollection[0] instanceof File &&
          typeof imagesCollection[0].type === 'string' &&
          imagesCollection[0].type.length > 0 &&
          typeof this.selectedGeneralExaminationId === 'number' &&
          isNaN(this.selectedGeneralExaminationId) === false
        ) {
          if (
            imagesCollection[0].type.toLowerCase().includes('image/') ===
              true
          ) {
            requestData.append(
              'MedicalExaminationID',
              this.selectedGeneralExaminationId.toString(),
            );
            requestData.append('File', imagesCollection[0]);

            this.progressBarService.start();

            const uploadDiagnosticImageSubscription = this.dashboardService
              .uploadDiagnosticImage(requestData)
              .subscribe(
                (response) => {
                  this.progressBarService.stop();

                  if (response) {
                    this.snackbarService.showSuccess(
                      'Snimak je uspešno dodat.',
                    );
                    this.updateDiagnosticExistState();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(uploadDiagnosticImageSubscription);
          } else {
            this.snackbarService.showInfo(
              'Izabrani fajl mora biti slika.',
            );
          }
        }
      }
    }
  }

  // Used in General examination tab
  onOpenCompareDiagnosticImagesDialog(diagnosticTypeId: number) {
    if (
      typeof this.selectedGeneralExaminationId === 'number' &&
      isNaN(this.selectedGeneralExaminationId) === false &&
      typeof this.dashboardService.getPatientId() === 'number' &&
      isNaN(this.dashboardService.getPatientId()) === false &&
      typeof diagnosticTypeId === 'number' &&
      isNaN(diagnosticTypeId) === false
    ) {
      this.progressBarService.start();

      const readDiagnosticImagesSubscription = this.dashboardService
        .readDiagnosticImages(
          new ReadDiagnosticImagesRequest(
            this.selectedGeneralExaminationId,
            this.dashboardService.getPatientId(),
            diagnosticTypeId,
          ),
        )
        .subscribe(
          (response: DiagnosticImage[][]) => {
            this.progressBarService.stop();

            let diagnosticImagesData = {
              comparison_images: [],
              base_images: [],
            };

            if (
              response &&
              typeof response === 'object' &&
              response.length === 2
            ) {
              if (
                response[0] &&
                typeof response[0] === 'object' &&
                response[1] &&
                typeof response[1] === 'object'
              ) {
                if (response[0].length === 0 && response[1].length === 0) {
                  this.snackbarService.showInfo(
                    'Ne postoje dostupni snimci za pregled i poređenje.',
                  );
                } else {
                  if (response[0].length > 0)
                    diagnosticImagesData.comparison_images = response[0];
                  if (response[1].length > 0)
                    diagnosticImagesData.base_images = response[1];

                  this.dialog.open(CompareDiagnosticImagesDialogComponent, {
                    minWidth: '60vw',
                    data: diagnosticImagesData,
                  });
                }
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readDiagnosticImagesSubscription);
    }
  }

  // Used in General examination tab
  onUpdateGeneralExamination() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      let generalExaminationId: number | null = null;
      if (
        typeof this.selectedGeneralExaminationId === 'number' &&
        isNaN(this.selectedGeneralExaminationId) === false
      )
        generalExaminationId = this.selectedGeneralExaminationId;

      this.progressBarService.start();

      const updateGeneralExaminationSubscription = this.dashboardService
        .updateGeneralExamination(
          new GeneralExamination(
            generalExaminationId,
            this.selectedExaminationChronologyId,
            this.updateGeneralExaminationForm.value.SubDiagnosis,
            this.updateGeneralExaminationForm.value.Advice,
            this.updateGeneralExaminationForm.value.Anamnesis,
            this.updateGeneralExaminationForm.value.VODDistanceSC,
            this.updateGeneralExaminationForm.value.VODDistanceCC,
            this.updateGeneralExaminationForm.value.VODNearSC,
            this.updateGeneralExaminationForm.value.VODNearCC,
            this.updateGeneralExaminationForm.value.VOSDistanceSC,
            this.updateGeneralExaminationForm.value.VOSDistanceCC,
            this.updateGeneralExaminationForm.value.VOSNearSC,
            this.updateGeneralExaminationForm.value.VOSNearCC,
            this.updateGeneralExaminationForm.value.KeratometryOD,
            this.updateGeneralExaminationForm.value.KeratometryOS,
            this.updateGeneralExaminationForm.value.DominantEye,
            this.updateGeneralExaminationForm.value.IOPAplanationTOD,
            this.updateGeneralExaminationForm.value.IOPAplanationTOS,
            this.updateGeneralExaminationForm.value.IOPNonContactTOD,
            this.updateGeneralExaminationForm.value.IOPNonContactTOS,
            this.updateGeneralExaminationForm.value.SpaltOd,
            this.updateGeneralExaminationForm.value.SpaltOs,
            this.updateGeneralExaminationForm.value.VODVisusNear,
            this.updateGeneralExaminationForm.value.VODVisusDistance,
            this.updateGeneralExaminationForm.value.VOSVisusNear,
            this.updateGeneralExaminationForm.value.VOSVisusDistance,
            this.updateGeneralExaminationForm.value.FundusOd,
            this.updateGeneralExaminationForm.value.FundusDo,
            this.updateGeneralExaminationForm.value.Intervention,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('PahimetryDescription').value,
            this.readDiagnosesParameter(),
            [],
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('KKT').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctAnt').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctPost').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctA').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FotoFundus').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FAF').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('CVP').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('EM').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('VEP').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('ERG').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('IOLMaster').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('UltrazvukOka').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FA').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FotoPrednjegSegmenta').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('Verion').value,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.updateGeneralExaminationForm.value.CycloRODDistanceCC,
            this.updateGeneralExaminationForm.value.CycloRODDistanceSC,
            this.updateGeneralExaminationForm.value.CycloRODNearCC,
            this.updateGeneralExaminationForm.value.CycloRODVisusDistance,
            this.updateGeneralExaminationForm.value.CycloRODVisusNear,
            this.updateGeneralExaminationForm.value.CycloROSDistanceCC,
            this.updateGeneralExaminationForm.value.CycloROSDistanceSC,
            this.updateGeneralExaminationForm.value.CycloROSNearCC,
            this.updateGeneralExaminationForm.value.CycloROSVisusDistance,
            this.updateGeneralExaminationForm.value.CycloROSVisusNear,
            this.updateGeneralExaminationForm.value.FreeText,
            this.updateGeneralExaminationForm.value.VOU,
            this.updateGeneralExaminationForm.value.SN,
          ),
        )
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess(
                'Opšti pregled je uspešno izmenjen.',
              );
              this.readGeneralExamination();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updateGeneralExaminationSubscription);
    }
  }

  // Used in General examination tab
  readGeneralExamination() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected General examination tab (on Examination chronology tab change)
      this.selectedGeneralExaminationId = null;
      this.updateGeneralExaminationForm.reset();
      this.updateGeneralExaminationForm.get('DominantEye').setValue('OS');

      this.progressBarService.start();

      const readGeneralExaminationSubscription = this.dashboardService
        .readGeneralExamination(this.selectedExaminationChronologyId)
        .subscribe(
          (response: GeneralExamination) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('Id') &&
                typeof response.Id === 'number' &&
                isNaN(response.Id) === false
              )
                this.selectedGeneralExaminationId = response.Id;
              else this.selectedGeneralExaminationId = null;

              this.updateGeneralExaminationForm.reset();
              this.updateGeneralExaminationForm
                .get('DominantEye')
                .setValue('OS');

              if (
                response.hasOwnProperty('Anamnesis') &&
                typeof response.Anamnesis === 'string' &&
                response.Anamnesis.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('Anamnesis')
                  .setValue(response.Anamnesis);
              if (
                response.hasOwnProperty('VODDistanceSC') &&
                typeof response.VODDistanceSC === 'string' &&
                response.VODDistanceSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODDistanceSC')
                  .setValue(response.VODDistanceSC);
              if (
                response.hasOwnProperty('VODDistanceCC') &&
                typeof response.VODDistanceCC === 'string' &&
                response.VODDistanceCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODDistanceCC')
                  .setValue(response.VODDistanceCC);
              if (
                response.hasOwnProperty('VODVisusDistance') &&
                typeof response.VODVisusDistance === 'string' &&
                response.VODVisusDistance.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODVisusDistance')
                  .setValue(response.VODVisusDistance);
              if (
                response.hasOwnProperty('VODNearSC') &&
                typeof response.VODNearSC === 'string' &&
                response.VODNearSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODNearSC')
                  .setValue(response.VODNearSC);
              if (
                response.hasOwnProperty('VODNearCC') &&
                typeof response.VODNearCC === 'string' &&
                response.VODNearCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODNearCC')
                  .setValue(response.VODNearCC);
              if (
                response.hasOwnProperty('VODVisusNear') &&
                typeof response.VODVisusNear === 'string' &&
                response.VODVisusNear.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VODVisusNear')
                  .setValue(response.VODVisusNear);
              if (
                response.hasOwnProperty('VOSDistanceSC') &&
                typeof response.VOSDistanceSC === 'string' &&
                response.VOSDistanceSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSDistanceSC')
                  .setValue(response.VOSDistanceSC);
              if (
                response.hasOwnProperty('VOSDistanceCC') &&
                typeof response.VOSDistanceCC === 'string' &&
                response.VOSDistanceCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSDistanceCC')
                  .setValue(response.VOSDistanceCC);
              if (
                response.hasOwnProperty('VOSVisusDistance') &&
                typeof response.VOSVisusDistance === 'string' &&
                response.VOSVisusDistance.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSVisusDistance')
                  .setValue(response.VOSVisusDistance);
              if (
                response.hasOwnProperty('VOSNearSC') &&
                typeof response.VOSNearSC === 'string' &&
                response.VOSNearSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSNearSC')
                  .setValue(response.VOSNearSC);
              if (
                response.hasOwnProperty('VOSNearCC') &&
                typeof response.VOSNearCC === 'string' &&
                response.VOSNearCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSNearCC')
                  .setValue(response.VOSNearCC);
              if (
                response.hasOwnProperty('VOSVisusNear') &&
                typeof response.VOSVisusNear === 'string' &&
                response.VOSVisusNear.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOSVisusNear')
                  .setValue(response.VOSVisusNear);
              if (
                response.hasOwnProperty('KeratometryOD') &&
                typeof response.KeratometryOD === 'string' &&
                response.KeratometryOD.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('KeratometryOD')
                  .setValue(response.KeratometryOD);
              if (
                response.hasOwnProperty('KeratometryOS') &&
                typeof response.KeratometryOS === 'string' &&
                response.KeratometryOS.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('KeratometryOS')
                  .setValue(response.KeratometryOS);
              if (
                response.hasOwnProperty('DominantEye') &&
                typeof response.DominantEye === 'string' &&
                response.DominantEye.length > 0 &&
                (response.DominantEye.toLowerCase() === 'od' ||
                  response.DominantEye.toLowerCase() === 'os')
              )
                this.updateGeneralExaminationForm
                  .get('DominantEye')
                  .setValue(response.DominantEye.toUpperCase());
              if (
                response.hasOwnProperty('IOPAplanationTOD') &&
                typeof response.IOPAplanationTOD === 'string' &&
                response.IOPAplanationTOD.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('IOPAplanationTOD')
                  .setValue(response.IOPAplanationTOD);
              if (
                response.hasOwnProperty('IOPAplanationTOS') &&
                typeof response.IOPAplanationTOS === 'string' &&
                response.IOPAplanationTOS.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('IOPAplanationTOS')
                  .setValue(response.IOPAplanationTOS);
              if (
                response.hasOwnProperty('IOPNonContactTOD') &&
                typeof response.IOPNonContactTOD === 'string' &&
                response.IOPNonContactTOD.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('IOPNonContactTOD')
                  .setValue(response.IOPNonContactTOD);
              if (
                response.hasOwnProperty('IOPNonContactTOS') &&
                typeof response.IOPNonContactTOS === 'string' &&
                response.IOPNonContactTOS.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('IOPNonContactTOS')
                  .setValue(response.IOPNonContactTOS);
              if (
                response.hasOwnProperty('SpaltOd') &&
                typeof response.SpaltOd === 'string' &&
                response.SpaltOd.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('SpaltOd')
                  .setValue(response.SpaltOd);
              if (
                response.hasOwnProperty('SpaltOs') &&
                typeof response.SpaltOs === 'string' &&
                response.SpaltOs.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('SpaltOs')
                  .setValue(response.SpaltOs);
              if (
                response.hasOwnProperty('FundusOd') &&
                typeof response.FundusOd === 'string' &&
                response.FundusOd.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('FundusOd')
                  .setValue(response.FundusOd);
              if (
                response.hasOwnProperty('FundusDo') &&
                typeof response.FundusDo === 'string' &&
                response.FundusDo.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('FundusDo')
                  .setValue(response.FundusDo);
              if (
                response.hasOwnProperty('Pahimetry') &&
                typeof response.Pahimetry === 'string' &&
                response.Pahimetry.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('PahimetryDescription')
                  .setValue(response.Pahimetry);
              if (
                response.hasOwnProperty('Diagnoses') &&
                typeof response.Diagnoses === 'object' &&
                response.Diagnoses.length > 0
              )
                this.filterSelectedDiagnosis(response.Diagnoses);
              if (
                response.hasOwnProperty('SubDiagnosis') &&
                typeof response.SubDiagnosis === 'string' &&
                response.SubDiagnosis.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('SubDiagnosis')
                  .setValue(response.SubDiagnosis);
              if (
                response.hasOwnProperty('Advice') &&
                typeof response.Advice === 'string' &&
                response.Advice.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('Advice')
                  .setValue(response.Advice);
              if (
                response.hasOwnProperty('Intervention') &&
                typeof response.Intervention === 'string' &&
                response.Intervention.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('Intervention')
                  .setValue(response.Intervention);
              if (
                response.hasOwnProperty('KKT') &&
                typeof response.KKT === 'string' &&
                response.KKT.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('KKT')
                  .setValue(response.KKT);
              if (
                response.hasOwnProperty('OctAnt') &&
                typeof response.OctAnt === 'string' &&
                response.OctAnt.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('OctAnt')
                  .setValue(response.OctAnt);
              if (
                response.hasOwnProperty('OctPost') &&
                typeof response.OctPost === 'string' &&
                response.OctPost.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('OctPost')
                  .setValue(response.OctPost);
              if (
                response.hasOwnProperty('OctA') &&
                typeof response.OctA === 'string' &&
                response.OctA.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('OctA')
                  .setValue(response.OctA);
              if (
                response.hasOwnProperty('FotoFundus') &&
                typeof response.FotoFundus === 'string' &&
                response.FotoFundus.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('FotoFundus')
                  .setValue(response.FotoFundus);
              if (
                response.hasOwnProperty('FAF') &&
                typeof response.FAF === 'string' &&
                response.FAF.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('FAF')
                  .setValue(response.FAF);
              if (
                response.hasOwnProperty('CVP') &&
                typeof response.CVP === 'string' &&
                response.CVP.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('CVP')
                  .setValue(response.CVP);
              if (
                response.hasOwnProperty('EM') &&
                typeof response.EM === 'string' &&
                response.EM.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('EM')
                  .setValue(response.EM);
              if (
                response.hasOwnProperty('VEP') &&
                typeof response.VEP === 'string' &&
                response.VEP.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('VEP')
                  .setValue(response.VEP);
              if (
                response.hasOwnProperty('ERG') &&
                typeof response.ERG === 'string' &&
                response.ERG.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('ERG')
                  .setValue(response.ERG);
              if (
                response.hasOwnProperty('IOLMaster') &&
                typeof response.IOLMaster === 'string' &&
                response.IOLMaster.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('IOLMaster')
                  .setValue(response.IOLMaster);
              if (
                response.hasOwnProperty('UltrazvukOka') &&
                typeof response.UltrazvukOka === 'string' &&
                response.UltrazvukOka.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('UltrazvukOka')
                  .setValue(response.UltrazvukOka);
              if (
                response.hasOwnProperty('FA') &&
                typeof response.FA === 'string' &&
                response.FA.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('FA')
                  .setValue(response.FA);
              if (
                response.hasOwnProperty('FotoPrednjegSegmenta') &&
                typeof response.FotoPrednjegSegmenta === 'string' &&
                response.FotoPrednjegSegmenta.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('FotoPrednjegSegmenta')
                  .setValue(response.FotoPrednjegSegmenta);
              if (
                response.hasOwnProperty('Verion') &&
                typeof response.Verion === 'string' &&
                response.Verion.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('updateDiagnosticFormSection')
                  .get('Verion')
                  .setValue(response.Verion);
              if (
                response.hasOwnProperty('DiagnosticCVPExist') &&
                typeof response.DiagnosticCVPExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticCVPExist =
                  response.DiagnosticCVPExist;
              if (
                response.hasOwnProperty('DiagnosticEMExist') &&
                typeof response.DiagnosticEMExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticEMExist =
                  response.DiagnosticEMExist;
              if (
                response.hasOwnProperty('DiagnosticERGExist') &&
                typeof response.DiagnosticERGExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticERGExist =
                  response.DiagnosticERGExist;
              if (
                response.hasOwnProperty('DiagnosticFAExist') &&
                typeof response.DiagnosticFAExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticFAExist =
                  response.DiagnosticFAExist;
              if (
                response.hasOwnProperty('DiagnosticFAFExist') &&
                typeof response.DiagnosticFAFExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticFAFExist =
                  response.DiagnosticFAFExist;
              if (
                response.hasOwnProperty('DiagnosticFotoFundusExist') &&
                typeof response.DiagnosticFotoFundusExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticFotoFundusExist =
                  response.DiagnosticFotoFundusExist;
              if (
                response.hasOwnProperty(
                  'DiagnosticFotoPrednjegSegmentaExist',
                ) &&
                typeof response.DiagnosticFotoPrednjegSegmentaExist ===
                  'boolean'
              )
                this.diagnosticExistState.DiagnosticFotoPrednjegSegmentaExist =
                  response.DiagnosticFotoPrednjegSegmentaExist;
              if (
                response.hasOwnProperty('DiagnosticIOLMasterExist') &&
                typeof response.DiagnosticIOLMasterExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticIOLMasterExist =
                  response.DiagnosticIOLMasterExist;
              if (
                response.hasOwnProperty('DiagnosticOCTAExist') &&
                typeof response.DiagnosticOCTAExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticOCTAExist =
                  response.DiagnosticOCTAExist;
              if (
                response.hasOwnProperty('DiagnosticOCTAUTExist') &&
                typeof response.DiagnosticOCTAUTExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticOCTAUTExist =
                  response.DiagnosticOCTAUTExist;
              if (
                response.hasOwnProperty('DiagnosticOCTPOSTExist') &&
                typeof response.DiagnosticOCTPOSTExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticOCTPOSTExist =
                  response.DiagnosticOCTPOSTExist;
              if (
                response.hasOwnProperty('DiagnosticUltrazvukOkaExist') &&
                typeof response.DiagnosticUltrazvukOkaExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticUltrazvukOkaExist =
                  response.DiagnosticUltrazvukOkaExist;
              if (
                response.hasOwnProperty('DiagnosticVEPExist') &&
                typeof response.DiagnosticVEPExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticVEPExist =
                  response.DiagnosticVEPExist;
              if (
                response.hasOwnProperty('DiagnosticVerionExist') &&
                typeof response.DiagnosticVerionExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticVerionExist =
                  response.DiagnosticVerionExist;
              if (
                response.hasOwnProperty('DiagnosticKKTExist') &&
                typeof response.DiagnosticKKTExist === 'boolean'
              )
                this.diagnosticExistState.DiagnosticKKTExist =
                  response.DiagnosticKKTExist;
              if (
                response.hasOwnProperty('CycloRODDistanceCC') &&
                typeof response.CycloRODDistanceCC === 'string' &&
                response.CycloRODDistanceCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloRODDistanceCC')
                  .setValue(response.CycloRODDistanceCC);
              if (
                response.hasOwnProperty('CycloRODDistanceSC') &&
                typeof response.CycloRODDistanceSC === 'string' &&
                response.CycloRODDistanceSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloRODDistanceSC')
                  .setValue(response.CycloRODDistanceSC);
              if (
                response.hasOwnProperty('CycloRODNearCC') &&
                typeof response.CycloRODNearCC === 'string' &&
                response.CycloRODNearCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloRODNearCC')
                  .setValue(response.CycloRODNearCC);
              if (
                response.hasOwnProperty('CycloRODVisusDistance') &&
                typeof response.CycloRODVisusDistance === 'string' &&
                response.CycloRODVisusDistance.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloRODVisusDistance')
                  .setValue(response.CycloRODVisusDistance);
              if (
                response.hasOwnProperty('CycloRODVisusNear') &&
                typeof response.CycloRODVisusNear === 'string' &&
                response.CycloRODVisusNear.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloRODVisusNear')
                  .setValue(response.CycloRODVisusNear);
              if (
                response.hasOwnProperty('CycloROSDistanceCC') &&
                typeof response.CycloROSDistanceCC === 'string' &&
                response.CycloROSDistanceCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloROSDistanceCC')
                  .setValue(response.CycloROSDistanceCC);
              if (
                response.hasOwnProperty('CycloROSDistanceSC') &&
                typeof response.CycloROSDistanceSC === 'string' &&
                response.CycloROSDistanceSC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloROSDistanceSC')
                  .setValue(response.CycloROSDistanceSC);
              if (
                response.hasOwnProperty('CycloROSNearCC') &&
                typeof response.CycloROSNearCC === 'string' &&
                response.CycloROSNearCC.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloROSNearCC')
                  .setValue(response.CycloROSNearCC);
              if (
                response.hasOwnProperty('CycloROSVisusDistance') &&
                typeof response.CycloROSVisusDistance === 'string' &&
                response.CycloROSVisusDistance.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloROSVisusDistance')
                  .setValue(response.CycloROSVisusDistance);
              if (
                response.hasOwnProperty('CycloROSVisusNear') &&
                typeof response.CycloROSVisusNear === 'string' &&
                response.CycloROSVisusNear.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('CycloROSVisusNear')
                  .setValue(response.CycloROSVisusNear);
              if (
                response.hasOwnProperty('FreeText') &&
                typeof response.FreeText === 'string' &&
                response.FreeText.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('FreeText')
                  .setValue(response.FreeText);
              if (
                response.hasOwnProperty('VOU') &&
                typeof response.VOU === 'string' &&
                response.VOU.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('VOU')
                  .setValue(response.VOU);
              if (
                response.hasOwnProperty('SN') &&
                typeof response.SN === 'string' &&
                response.SN.length > 0
              )
                this.updateGeneralExaminationForm
                  .get('SN')
                  .setValue(response.SN);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readGeneralExaminationSubscription);
    }
  }

  // Used in General examination tab
  onReadGeneralExaminationReport() {
    if (
      typeof this.selectedGeneralExaminationId === 'number' &&
      isNaN(this.selectedGeneralExaminationId) === false
    ) {
      try {
      this.progressBarService.start();

      const updateSuccessful = this.updateGeneralExaminationPromise();

      if (updateSuccessful)
      {
        const readGeneralExaminationReportSubscription = this.dashboardService
        .readGeneralExaminationReport(this.selectedGeneralExaminationId)
        .subscribe(
          (response: Blob) => {
            this.progressBarService.stop();

            if (response && response instanceof Blob && response.size > 0)
              this.dashboardService.printFile(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );
        
        this.subscriptions.add(readGeneralExaminationReportSubscription);
      } else {
        this.progressBarService.stop();
        }
      } catch (error) {
        this.progressBarService.stop();
        this.errorHandlerService.handleError(error);
      }
    } 
  }

  updateGeneralExaminationPromise(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (
        typeof this.selectedExaminationChronologyId === 'number' &&
        isNaN(this.selectedExaminationChronologyId) === false
      ) {
        let generalExaminationId: number | null = null;
        if (
          typeof this.selectedGeneralExaminationId === 'number' &&
          isNaN(this.selectedGeneralExaminationId) === false
        ) {
          generalExaminationId = this.selectedGeneralExaminationId;
        }
  
        this.progressBarService.start();
  
        const updateGeneralExaminationSubscription = this.dashboardService
        .updateGeneralExamination(
          new GeneralExamination(
            generalExaminationId,
            this.selectedExaminationChronologyId,
            this.updateGeneralExaminationForm.value.SubDiagnosis,
            this.updateGeneralExaminationForm.value.Advice,
            this.updateGeneralExaminationForm.value.Anamnesis,
            this.updateGeneralExaminationForm.value.VODDistanceSC,
            this.updateGeneralExaminationForm.value.VODDistanceCC,
            this.updateGeneralExaminationForm.value.VODNearSC,
            this.updateGeneralExaminationForm.value.VODNearCC,
            this.updateGeneralExaminationForm.value.VOSDistanceSC,
            this.updateGeneralExaminationForm.value.VOSDistanceCC,
            this.updateGeneralExaminationForm.value.VOSNearSC,
            this.updateGeneralExaminationForm.value.VOSNearCC,
            this.updateGeneralExaminationForm.value.KeratometryOD,
            this.updateGeneralExaminationForm.value.KeratometryOS,
            this.updateGeneralExaminationForm.value.DominantEye,
            this.updateGeneralExaminationForm.value.IOPAplanationTOD,
            this.updateGeneralExaminationForm.value.IOPAplanationTOS,
            this.updateGeneralExaminationForm.value.IOPNonContactTOD,
            this.updateGeneralExaminationForm.value.IOPNonContactTOS,
            this.updateGeneralExaminationForm.value.SpaltOd,
            this.updateGeneralExaminationForm.value.SpaltOs,
            this.updateGeneralExaminationForm.value.VODVisusNear,
            this.updateGeneralExaminationForm.value.VODVisusDistance,
            this.updateGeneralExaminationForm.value.VOSVisusNear,
            this.updateGeneralExaminationForm.value.VOSVisusDistance,
            this.updateGeneralExaminationForm.value.FundusOd,
            this.updateGeneralExaminationForm.value.FundusDo,
            this.updateGeneralExaminationForm.value.Intervention,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('PahimetryDescription').value,
            this.readDiagnosesParameter(),
            [],
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('KKT').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctAnt').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctPost').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('OctA').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FotoFundus').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FAF').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('CVP').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('EM').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('VEP').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('ERG').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('IOLMaster').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('UltrazvukOka').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FA').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('FotoPrednjegSegmenta').value,
            this.updateGeneralExaminationForm
              .get('updateDiagnosticFormSection')
              .get('Verion').value,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.updateGeneralExaminationForm.value.CycloRODDistanceCC,
            this.updateGeneralExaminationForm.value.CycloRODDistanceSC,
            this.updateGeneralExaminationForm.value.CycloRODNearCC,
            this.updateGeneralExaminationForm.value.CycloRODVisusDistance,
            this.updateGeneralExaminationForm.value.CycloRODVisusNear,
            this.updateGeneralExaminationForm.value.CycloROSDistanceCC,
            this.updateGeneralExaminationForm.value.CycloROSDistanceSC,
            this.updateGeneralExaminationForm.value.CycloROSNearCC,
            this.updateGeneralExaminationForm.value.CycloROSVisusDistance,
            this.updateGeneralExaminationForm.value.CycloROSVisusNear,
            this.updateGeneralExaminationForm.value.FreeText,
            this.updateGeneralExaminationForm.value.VOU,
            this.updateGeneralExaminationForm.value.SN,
          ),
        )
          .subscribe(
            (response: boolean) => {
              this.progressBarService.stop();
  
              if (response === true) {
                this.snackbarService.showSuccess('Opšti pregled je uspešno izmenjen.');
                this.readGeneralExamination();
                resolve(true); // Resolve the promise on success
              } else {
                resolve(false); // Resolve as false or reject based on your error handling strategy
              }
            },
            (error) => {
              this.errorHandlerService.handleError(error);
              this.progressBarService.stop();
              reject(error); // Reject the promise on error
            },
          );
  
        this.subscriptions.add(updateGeneralExaminationSubscription);
      } else {
        reject(new Error('Invalid examination chronology ID'));
      }
    });
  }
  

  // Used in General examination tab
  // Used in General examination tab
  onDeleteDiagnosisChip(diagnosis: Diagnosis, eye: string) {
    if (
      diagnosis &&
      typeof diagnosis === 'object' &&
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0 &&
      eye &&
      typeof eye === 'string' &&
      eye.length > 0
    ) {
      if (eye === 'od') {
        if (this.selectedDiagnosisCollection.includes(diagnosis))
          this.selectedDiagnosisCollection.splice(
            this.selectedDiagnosisCollection.indexOf(diagnosis),
            1,
          );
      }
    }
    if (
      diagnosis &&
      typeof diagnosis === 'object' &&
      this.selectedDiagnosisCollectionOS &&
      typeof this.selectedDiagnosisCollectionOS === 'object' &&
      this.selectedDiagnosisCollectionOS.length > 0 &&
      eye &&
      typeof eye === 'string' &&
      eye.length > 0
    ) {
      if (eye === 'os') {
        if (this.selectedDiagnosisCollectionOS.includes(diagnosis))
          this.selectedDiagnosisCollectionOS.splice(
            this.selectedDiagnosisCollectionOS.indexOf(diagnosis),
            1,
          );
      }
    }
    if (
      diagnosis &&
      typeof diagnosis === 'object' &&
      this.selectedDiagnosisCollectionOU &&
      typeof this.selectedDiagnosisCollectionOU === 'object' &&
      this.selectedDiagnosisCollectionOU.length > 0 &&
      eye &&
      typeof eye === 'string' &&
      eye.length > 0
    ) {
      if (eye === 'ou') {
        if (this.selectedDiagnosisCollectionOU.includes(diagnosis))
          this.selectedDiagnosisCollectionOU.splice(
            this.selectedDiagnosisCollectionOU.indexOf(diagnosis),
            1,
          );
      }
    }
  }

  // Used in General examination tab
  onSelectDiagnosisOption(event: MatAutocompleteSelectedEvent, eye: string) {
    if (
      event &&
      typeof event === 'object' &&
      event.hasOwnProperty('option') &&
      event.option &&
      typeof event.option === 'object' &&
      event.option.hasOwnProperty('value') &&
      event.option.value &&
      typeof event.option.value === 'object' &&
      eye &&
      typeof eye === 'string' &&
      eye.length > 0
    ) {
      if (eye === 'od') {
        if (
          this.selectedDiagnosisCollection &&
          typeof this.selectedDiagnosisCollection === 'object'
        ) {
          if (this.selectedDiagnosisCollection.length > 0) {
            if (this.selectedDiagnosisCollection.includes(event.option.value)) {
              this.snackbarService.showInfo('Dijagnoza je već izabrana.');
            } else this.selectedDiagnosisCollection.push(event.option.value);
          } else this.selectedDiagnosisCollection.push(event.option.value);
        }

        this.diagnosisSearchInput.nativeElement.value = '';
        this.diagnosisSearchFormControl.setValue(null);
      } else if (eye === 'os') {
        if (
          this.selectedDiagnosisCollectionOS &&
          typeof this.selectedDiagnosisCollectionOS === 'object'
        ) {
          if (this.selectedDiagnosisCollectionOS.length > 0) {
            if (
              this.selectedDiagnosisCollectionOS.includes(event.option.value)
            ) {
              this.snackbarService.showInfo('Dijagnoza je već izabrana.');
            } else this.selectedDiagnosisCollectionOS.push(event.option.value);
          } else this.selectedDiagnosisCollectionOS.push(event.option.value);
        }

        this.diagnosisSearchInputOS.nativeElement.value = '';
        this.diagnosisSearchFormControlOS.setValue(null);
      } else if (eye === 'ou') {
        if (
          this.selectedDiagnosisCollectionOU &&
          typeof this.selectedDiagnosisCollectionOU === 'object'
        ) {
          if (this.selectedDiagnosisCollectionOU.length > 0) {
            if (
              this.selectedDiagnosisCollectionOU.includes(event.option.value)
            ) {
              this.snackbarService.showInfo('Dijagnoza je već izabrana.');
            } else this.selectedDiagnosisCollectionOU.push(event.option.value);
          } else this.selectedDiagnosisCollectionOU.push(event.option.value);
        }
        this.diagnosisSearchInputOU.nativeElement.value = '';
        this.diagnosisSearchFormControlOU.setValue(null);
      }
    }
  }

  // Used in General examination tab
  private filterDiagnosisByName(searchTerm: string): Diagnosis[] {
    return typeof searchTerm === 'string' && searchTerm.length > 0
      ? this.diagnosisCollection.filter(
          (currentElement) =>
            currentElement &&
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Name') &&
            typeof currentElement.Name === 'string' &&
            currentElement.Name.length > 0 &&
            currentElement.Name.toLowerCase().includes(
              searchTerm.toLowerCase(),
            ),
        )
      : [];
  }

  // Used in General examination tab
  filterSelectedDiagnosis(diagnosisIds: DiagnosisItem[]) {
    let collection: Diagnosis[] = [];
    let collectionOS: Diagnosis[] = [];
    let collectionOU: Diagnosis[] = [];

    if (
      diagnosisIds &&
      typeof diagnosisIds === 'object' &&
      diagnosisIds.length > 0 &&
      this.diagnosisCollection &&
      typeof this.diagnosisCollection === 'object' &&
      this.diagnosisCollection.length > 0
    ) {
      diagnosisIds.forEach((item: DiagnosisItem) => {
        for (let diagnosis of this.diagnosisCollection) {
          if (
            diagnosis &&
            typeof diagnosis === 'object' &&
            diagnosis.hasOwnProperty('Id') &&
            typeof diagnosis.Id === 'number' &&
            isNaN(diagnosis.Id) === false &&
            item.DiagnosisId === diagnosis.Id
          ) {
            if (
              item.hasOwnProperty('EyeId') &&
              isNaN(item.EyeId) === false &&
              item.EyeId === 1 &&
              collection.includes(diagnosis) === false
            ) {
              collection.push(diagnosis);
            } else if (
              item.hasOwnProperty('EyeId') &&
              isNaN(item.EyeId) === false &&
              item.EyeId === 2 &&
              collectionOS.includes(diagnosis) === false
            ) {
              collectionOS.push(diagnosis);
            } else if (
              item.hasOwnProperty('EyeId') &&
              isNaN(item.EyeId) === false &&
              item.EyeId === 3 &&
              collectionOU.includes(diagnosis) === false
            ) {
              collectionOU.push(diagnosis);
            }
          }
        }
      });
    }

    this.selectedDiagnosisCollection = collection;
    this.selectedDiagnosisCollectionOS = collectionOS;
    this.selectedDiagnosisCollectionOU = collectionOU;
  }

  // Used in General examination tab
  readDiagnosesParameter(): DiagnosisItem[] {
    let diagnosisCollection: DiagnosisItem[] = [];

    if (
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0
    ) {
      for (let diagnosis of this.selectedDiagnosisCollection) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false
        ) {
          diagnosisCollection.push(new DiagnosisItem(diagnosis.Id, 1));
        }
      }
    }

    if (
      this.selectedDiagnosisCollectionOS &&
      typeof this.selectedDiagnosisCollectionOS === 'object' &&
      this.selectedDiagnosisCollectionOS.length > 0
    ) {
      for (let diagnosis of this.selectedDiagnosisCollectionOS) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false
        ) {
          diagnosisCollection.push(new DiagnosisItem(diagnosis.Id, 2));
        }
      }
    }

    if (
      this.selectedDiagnosisCollectionOU &&
      typeof this.selectedDiagnosisCollectionOU === 'object' &&
      this.selectedDiagnosisCollectionOU.length > 0
    ) {
      for (let diagnosis of this.selectedDiagnosisCollectionOU) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false
        ) {
          diagnosisCollection.push(new DiagnosisItem(diagnosis.Id, 3));
        }
      }
    }

    return diagnosisCollection;
  }

  //Used in General examination tab
  //copyDiagnosisFromRightEyeToLeft(): void {
  //    this.selectedDiagnosisCollectionOS = [...this.selectedDiagnosisCollection];
  //}

  // Used in Excimer laser tab
  readExcimerLaser() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected Excimer laser tab (on Examination chronology tab change)
      this.excimerLaser = null;

      this.progressBarService.start();

      const readExcimerLaserSubscription = this.dashboardService
        .readExcimerLaser(
          new ReadExcimerLaserRequest(
            this.selectedExaminationChronologyId,
            false,
          ),
        )
        .subscribe(
          (response: ExcimerLaser) => {
            this.progressBarService.stop();

            this.excimerLaser = response;
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readExcimerLaserSubscription);
    }
  }

  // Used in Operations tab
  readOperations() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected Operations tab (on Examination chronology tab change)
      this.operations = null;

      this.progressBarService.start();

      const readExcimerLaserSubscription = this.dashboardService
        .readExcimerLaser(
          new ReadExcimerLaserRequest(
            this.selectedExaminationChronologyId,
            true,
          ),
        )
        .subscribe(
          (response: ExcimerLaser) => {
            this.progressBarService.stop();

            this.operations = response;
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readExcimerLaserSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateAgreementDialog(tabType: string) {
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let agreementData = {
        source_id: null,
        operation: true,
      };
      if (
        this.operations &&
        typeof this.operations === 'object' &&
        this.operations.hasOwnProperty('Id') &&
        typeof this.operations.Id === 'number' &&
        isNaN(this.operations.Id) === false
      ) {
        agreementData.source_id = this.operations.Id;
      }
      const dialogRef = this.dialog.open(UpdateAgreementDialogComponent, {
        minWidth: '44vw',
        data: agreementData,
      });

      const updateAgreementDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser') {
              if (
                this.excimerLaser &&
                typeof this.excimerLaser === 'object' &&
                this.excimerLaser.hasOwnProperty('Id') &&
                typeof this.excimerLaser.Id === 'number' &&
                isNaN(this.excimerLaser.Id) === false
              ) {
                this.progressBarService.start();

                const updateAgreementByExcimerLaserSubscription =
                  this.dashboardService
                    .updateAgreementByExcimerLaser(this.excimerLaser.Id)
                    .subscribe(
                      (response: boolean) => {
                        this.progressBarService.stop();

                        if (response === true) {
                          this.snackbarService.showSuccess(
                            'Saglasnost je uspešno potvrđena.',
                          );

                          this.readExcimerLaser();
                        }
                      },
                      (error) => this.errorHandlerService.handleError(error),
                    );

                this.subscriptions.add(
                  updateAgreementByExcimerLaserSubscription,
                );
              }
            } else if (tabType.toLowerCase() === 'operations') {
              if (
                this.operations &&
                typeof this.operations === 'object' &&
                this.operations.hasOwnProperty('Id') &&
                typeof this.operations.Id === 'number' &&
                isNaN(this.operations.Id) === false
              ) {
                this.progressBarService.start();

                const updateAgreementByExcimerLaserSubscription =
                  this.dashboardService
                    .updateAgreementByExcimerLaser(this.operations.Id)
                    .subscribe(
                      (response: boolean) => {
                        this.progressBarService.stop();

                        if (response === true) {
                          //this.snackBar.open("Saglasnost je uspešno potvrđena.", "", {
                          //    "duration": 3000,
                          //    "verticalPosition": "top"
                          //});

                          this.readOperations();
                        }
                      },
                      (error) => this.errorHandlerService.handleError(error),
                    );

                this.subscriptions.add(
                  updateAgreementByExcimerLaserSubscription,
                );
              }
            }
          }
        });

      this.subscriptions.add(updateAgreementDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateAnesthesiologicalExaminationDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let anesthesiologicalExaminationData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_anesthesiological_examination: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            anesthesiologicalExaminationData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('AnesthesiologyExam') &&
            typeof this.excimerLaser.AnesthesiologyExam === 'boolean'
          )
            anesthesiologicalExaminationData.has_anesthesiological_examination =
              this.excimerLaser.AnesthesiologyExam;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            anesthesiologicalExaminationData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('AnesthesiologyExam') &&
            typeof this.operations.AnesthesiologyExam === 'boolean'
          )
            anesthesiologicalExaminationData.has_anesthesiological_examination =
              this.operations.AnesthesiologyExam;
        }
      }

      const dialogRef = this.dialog.open(
        UpdateAnesthesiologicalExaminationDialogComponent,
        {
          minWidth: '44vw',
          data: anesthesiologicalExaminationData,
        },
      );

      const updateAnesthesiologicalExaminationDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser')
              this.readExcimerLaser();
            else if (tabType.toLowerCase() === 'operations')
              this.readOperations();
          }
        });

      this.subscriptions.add(
        updateAnesthesiologicalExaminationDialogSubscription,
      );
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateHospitalizationDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let hospitalizationData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_hospitalization: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            hospitalizationData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('Hospitalization') &&
            typeof this.excimerLaser.Hospitalization === 'boolean'
          )
            hospitalizationData.has_hospitalization =
              this.excimerLaser.Hospitalization;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            hospitalizationData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('Hospitalization') &&
            typeof this.operations.Hospitalization === 'boolean'
          )
            hospitalizationData.has_hospitalization =
              this.operations.Hospitalization;
        }
      }

      const dialogRef = this.dialog.open(UpdateHospitalizationDialogComponent, {
        minWidth: '44vw',
        data: hospitalizationData,
      });

      const updateHospitalizationDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser')
              this.readExcimerLaser();
            else if (tabType.toLowerCase() === 'operations')
              this.readOperations();
          }
        });

      this.subscriptions.add(updateHospitalizationDialogSubscription);
    }
  }

  //Used in Operations tab
  onOpenUpdateMedicalHistoryDialog() {
    let operationProtocolData = {
      source_id: null,
      has_disease_history: false,
    };

    if (this.operations && typeof this.operations === 'object') {
      if (
        this.operations.hasOwnProperty('Id') &&
        typeof this.operations.Id === 'number' &&
        isNaN(this.operations.Id) === false
      )
        operationProtocolData.source_id = this.operations.Id;
      if (
        this.operations.hasOwnProperty('DiseaseHistory') &&
        typeof this.operations.DiseaseHistory === 'boolean'
      )
        operationProtocolData.has_disease_history =
          this.operations.DiseaseHistory;
    }

    const dialogRef = this.dialog.open(UpdateMedicalHistoryDialogComponent, {
      minWidth: '44vw',
      data: operationProtocolData,
    });

    const updateMedicalHistoryDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {});

    this.subscriptions.add(updateMedicalHistoryDialogSubscription);
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateOperationProtocolDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let operationProtocolData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_operation_protocol: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            operationProtocolData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('SurgeryProtocol') &&
            typeof this.excimerLaser.SurgeryProtocol === 'boolean'
          )
            operationProtocolData.has_operation_protocol =
              this.excimerLaser.SurgeryProtocol;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            operationProtocolData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('SurgeryProtocol') &&
            typeof this.operations.SurgeryProtocol === 'boolean'
          )
            operationProtocolData.has_operation_protocol =
              this.operations.SurgeryProtocol;
        }
      }

      const dialogRef = this.dialog.open(
        UpdateOperationProtocolDialogComponent,
        {
          minWidth: '44vw',
          data: operationProtocolData,
        },
      );

      const updateOperationProtocolDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser')
              this.readExcimerLaser();
            else if (tabType.toLowerCase() === 'operations')
              this.readOperations();
          }
        });

      this.subscriptions.add(updateOperationProtocolDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateInternalHistoryDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let internalHistoryData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (
          this.excimerLaser &&
          typeof this.excimerLaser === 'object' &&
          this.excimerLaser.hasOwnProperty('Id') &&
          typeof this.excimerLaser.Id === 'number' &&
          isNaN(this.excimerLaser.Id) === false
        )
          internalHistoryData.source_id = this.excimerLaser.Id;
      } else if (tabType.toLowerCase() === 'operations') {
        if (
          this.operations &&
          typeof this.operations === 'object' &&
          this.operations.hasOwnProperty('Id') &&
          typeof this.operations.Id === 'number' &&
          isNaN(this.operations.Id) === false
        )
          internalHistoryData.source_id = this.operations.Id;
      }

      const dialogRef = this.dialog.open(UpdateInternalHistoryDialogComponent, {
        minWidth: '44vw',
        data: internalHistoryData,
      });

      const updateInternalHistoryDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser')
              this.readExcimerLaser();
            else if (tabType.toLowerCase() === 'operations')
              this.readOperations();
          }
        });

      this.subscriptions.add(updateInternalHistoryDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateSpecialistFindingsDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let specialistFindingsData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_specialist_findings: false,
        patient_id: this.dashboardService.getPatientId(),
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            specialistFindingsData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('SpecialistFindings') &&
            typeof this.excimerLaser.SpecialistFindings === 'boolean'
          )
            specialistFindingsData.has_specialist_findings =
              this.excimerLaser.SpecialistFindings;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            specialistFindingsData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('SpecialistFindings') &&
            typeof this.operations.SpecialistFindings === 'boolean'
          )
            specialistFindingsData.has_specialist_findings =
              this.operations.SpecialistFindings;
        }
      }

      const dialogRef = this.dialog.open(
        UpdateSpecialistFindingsDialogComponent,
        {
          minWidth: '44vw',
          data: specialistFindingsData,
        },
      );

      const updateSpecialistFindingsDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (tabType.toLowerCase() === 'excimer_laser')
            this.readExcimerLaser();
          else if (tabType.toLowerCase() === 'operations')
            this.readOperations();
        });

      this.subscriptions.add(updateSpecialistFindingsDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateLaboratoryDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let laboratoryData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_laboratory: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            laboratoryData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('Laboratory') &&
            typeof this.excimerLaser.Laboratory === 'boolean'
          )
            laboratoryData.has_laboratory = this.excimerLaser.Laboratory;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            laboratoryData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('Laboratory') &&
            typeof this.operations.Laboratory === 'boolean'
          )
            laboratoryData.has_laboratory = this.operations.Laboratory;
        }
      }

      const dialogRef = this.dialog.open(UpdateLaboratoryDialogComponent, {
        minWidth: '44vw',
        data: laboratoryData,
      });

      const updateLaboratoryDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (tabType.toLowerCase() === 'excimer_laser')
            this.readExcimerLaser();
          else if (tabType.toLowerCase() === 'operations')
            this.readOperations();
        });

      this.subscriptions.add(updateLaboratoryDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateTemperatureNoteDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let temperatureNoteData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_temperature_note: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            temperatureNoteData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('TemperatureList') &&
            typeof this.excimerLaser.TemperatureList === 'boolean'
          )
            temperatureNoteData.has_temperature_note =
              this.excimerLaser.TemperatureList;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            temperatureNoteData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('TemperatureList') &&
            typeof this.operations.TemperatureList === 'boolean'
          )
            temperatureNoteData.has_temperature_note =
              this.operations.TemperatureList;
        }
      }

      const dialogRef = this.dialog.open(UpdateTemperatureNoteDialogComponent, {
        minWidth: '60vw',
        data: temperatureNoteData,
      });

      const updateTemperatureNoteDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (tabType.toLowerCase() === 'excimer_laser')
            this.readExcimerLaser();
          else if (tabType.toLowerCase() === 'operations')
            this.readOperations();
        });

      this.subscriptions.add(updateTemperatureNoteDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateDiabeticNoteDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let diabeticNoteData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_diabetic_note: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            diabeticNoteData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('DiabeticList') &&
            typeof this.excimerLaser.DiabeticList === 'boolean'
          )
            diabeticNoteData.has_diabetic_note = this.excimerLaser.DiabeticList;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            diabeticNoteData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('DiabeticList') &&
            typeof this.operations.DiabeticList === 'boolean'
          )
            diabeticNoteData.has_diabetic_note = this.operations.DiabeticList;
        }
      }

      const dialogRef = this.dialog.open(UpdateDiabeticNoteDialogComponent, {
        minWidth: '60vw',
        data: diabeticNoteData,
      });

      const updateDiabeticNoteDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (tabType.toLowerCase() === 'excimer_laser')
            this.readExcimerLaser();
          else if (tabType.toLowerCase() === 'operations')
            this.readOperations();
        });

      this.subscriptions.add(updateDiabeticNoteDialogSubscription);
    }
  }

  // Used in Excimer laser and Operations tabs
  onOpenUpdateDischargeNoteDialog(tabType: string) {
    // Make sure that dialog will be opened from Excimer laser or Operations tab
    if (
      tabType &&
      typeof tabType === 'string' &&
      tabType.length > 0 &&
      (tabType.toLowerCase() === 'excimer_laser' ||
        tabType.toLowerCase() === 'operations')
    ) {
      let dischargeNoteData = {
        source_type: tabType.toLowerCase(),
        source_id: null,
        has_discharge_note: false,
      };

      if (tabType.toLowerCase() === 'excimer_laser') {
        if (this.excimerLaser && typeof this.excimerLaser === 'object') {
          if (
            this.excimerLaser.hasOwnProperty('Id') &&
            typeof this.excimerLaser.Id === 'number' &&
            isNaN(this.excimerLaser.Id) === false
          )
            dischargeNoteData.source_id = this.excimerLaser.Id;
          if (
            this.excimerLaser.hasOwnProperty('DischargeList') &&
            typeof this.excimerLaser.DischargeList === 'boolean'
          )
            dischargeNoteData.has_discharge_note =
              this.excimerLaser.DischargeList;
        }
      } else if (tabType.toLowerCase() === 'operations') {
        if (this.operations && typeof this.operations === 'object') {
          if (
            this.operations.hasOwnProperty('Id') &&
            typeof this.operations.Id === 'number' &&
            isNaN(this.operations.Id) === false
          )
            dischargeNoteData.source_id = this.operations.Id;
          if (
            this.operations.hasOwnProperty('DischargeList') &&
            typeof this.operations.DischargeList === 'boolean'
          )
            dischargeNoteData.has_discharge_note =
              this.operations.DischargeList;
        }
      }

      const dialogRef = this.dialog.open(UpdateDischargeNoteDialogComponent, {
        minWidth: '80vw',
        data: dischargeNoteData,
      });

      const updateDischargeNoteDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            if (tabType.toLowerCase() === 'excimer_laser')
              this.readExcimerLaser();
            else if (tabType.toLowerCase() === 'operations')
              this.readOperations();
          }
        });

      this.subscriptions.add(updateDischargeNoteDialogSubscription);
    }
  }

  // Used in Operations tab
  onOpenUpdateDecursusMorbiDialog() {
    let decursusMorbiData = { laserExclaimerId: null };

    if (this.operations && typeof this.operations === 'object') {
      if (
        this.operations.hasOwnProperty('Id') &&
        typeof this.operations.Id === 'number' &&
        isNaN(this.operations.Id) === false
      )
        decursusMorbiData.laserExclaimerId = this.operations.Id;
    }

    const dialogRef = this.dialog.open(UpdateDecursusMorbiDialogComponent, {
      minWidth: '44vw',
      data: decursusMorbiData,
    });

    const onOpenUpdateDecursusMorbiDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {});

    this.subscriptions.add(onOpenUpdateDecursusMorbiDialogSubscription);
  }

  // Used in Recipe tab
  onUpdateRecipe() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      let recipeId: number | null = null;
      if (
        typeof this.selectedRecipeId === 'number' &&
        isNaN(this.selectedRecipeId) === false
      )
        recipeId = this.selectedRecipeId;

      this.progressBarService.start();

      this.authorizationService
        .getUserId()
        .pipe(
          take(1),
          switchMap((userId: number) => {
            return this.dashboardService.updateRecipe(
              new Recipe(
                recipeId,
                this.selectedExaminationChronologyId,
                userId,
                this.updateRecipeForm.get('DistanceOD').value,
                this.updateRecipeForm.get('DistanceOS').value,
                this.updateRecipeForm.get('NearOD').value,
                this.updateRecipeForm.get('NearOS').value,
                this.updateRecipeForm.get('DistancePD').value,
                this.updateRecipeForm.get('NearPD').value,
                formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss', 'sr-Latn'),
              ),
            );
          }),
        )
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess('Recept je uspešno izmenjen.');
              this.readRecipe();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );
    }
  }

  // Used in Recipe tab
  onReadRecipeFile() {
    if (
      typeof this.selectedRecipeId === 'number' &&
      isNaN(this.selectedRecipeId) === false
    ) {
      this.progressBarService.start();

      const readRecipeFileSubscription = this.dashboardService
        .readRecipeFile(this.selectedRecipeId)
        .subscribe(
          (response: Blob) => {
            this.progressBarService.stop();

            if (response && response instanceof Blob && response.size > 0)
              this.dashboardService.printFile(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readRecipeFileSubscription);
    }
  }

  // Used in Recipe tab
  readRecipe() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected Recipe tab (on Examination chronology tab change)
      this.selectedRecipeId = null;
      this.updateRecipeForm.reset();

      this.progressBarService.start();

      const readRecipeSubscription = this.dashboardService
        .readRecipe(this.selectedExaminationChronologyId)
        .subscribe(
          (response: Recipe) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('Id') &&
                typeof response.Id === 'number' &&
                isNaN(response.Id) === false
              )
                this.selectedRecipeId = response.Id;
              else this.selectedRecipeId = null;

              if (
                response.hasOwnProperty('DistanceOD') &&
                typeof response.DistanceOD === 'string' &&
                response.DistanceOD.length > 0
              )
                this.updateRecipeForm
                  .get('DistanceOD')
                  .setValue(response.DistanceOD);
              if (
                response.hasOwnProperty('DistanceOS') &&
                typeof response.DistanceOS === 'string' &&
                response.DistanceOS.length > 0
              )
                this.updateRecipeForm
                  .get('DistanceOS')
                  .setValue(response.DistanceOS);
              if (
                response.hasOwnProperty('DistancePD') &&
                typeof response.DistancePD === 'string' &&
                response.DistancePD.length > 0
              )
                this.updateRecipeForm
                  .get('DistancePD')
                  .setValue(response.DistancePD);
              if (
                response.hasOwnProperty('NearOD') &&
                typeof response.NearOD === 'string' &&
                response.NearOD.length > 0
              )
                this.updateRecipeForm.get('NearOD').setValue(response.NearOD);
              if (
                response.hasOwnProperty('NearOS') &&
                typeof response.NearOS === 'string' &&
                response.NearOS.length > 0
              )
                this.updateRecipeForm.get('NearOS').setValue(response.NearOS);
              if (
                response.hasOwnProperty('NearPD') &&
                typeof response.NearPD === 'string' &&
                response.NearPD.length > 0
              )
                this.updateRecipeForm.get('NearPD').setValue(response.NearPD);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readRecipeSubscription);
    }
  }

  // Used in Survey tab
  onUpdateSurvey() {
    if (
      this.surveyQuestionsCollection &&
      typeof this.surveyQuestionsCollection === 'object' &&
      this.surveyQuestionsCollection.length > 0
    ) {
      this.progressBarService.start();

      const updateSurveySubscription = this.dashboardService
        .updateSurvey(this.surveyQuestionsCollection)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess('Anketa je uspešno izmenjena.');

              let status: boolean = true;
              this.surveyQuestionsCollection.forEach((item) => {
                if (
                  (item.QuickAnswer === false &&
                    item.QuestionId !== 3 &&
                    item.FullAnswer === null) ||
                  (item.QuickAnswer === true &&
                    item.QuestionId === 3 &&
                    item.FullAnswer === null)
                ) {
                  status = false;
                }
              });
              if (status === true) {
                this.showPaymentIfSurvayIsValid = true;
              } else {
                this.showPaymentIfSurvayIsValid = false;
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updateSurveySubscription);
    }
  }

  isSurveyFormValid() {
    if (
      this.surveyQuestionsCollection &&
      this.surveyQuestionsCollection.length > 0
    ) {
      for (let surveyQuestion of this.surveyQuestionsCollection) {
        if (
          surveyQuestion.QuickAnswer === null ||
          surveyQuestion.QuestionId == 1 &&
          !surveyQuestion.QuickAnswer &&
          !surveyQuestion.FullAnswer
        ) {
          return false;
        }

        else if (
          surveyQuestion.QuickAnswer === null ||
          surveyQuestion.QuestionId == 2 &&
          !surveyQuestion.QuickAnswer &&
          !surveyQuestion.FullAnswer
        ) {
          return false;
        }

        else if (
          surveyQuestion.QuickAnswer === null ||
          surveyQuestion.QuestionId == 3 &&
          surveyQuestion.QuickAnswer &&
          !surveyQuestion.FullAnswer
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  // Used in Survey tab
  readSurvey() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected Survey tab (on Examination chronology tab change)
      this.surveyQuestionsCollection = [];

      this.progressBarService.start();

      const readSurveySubscription = this.dashboardService
        .readSurvey(this.selectedExaminationChronologyId)
        .subscribe(
          (response: SurveyQuestion[]) => {
            this.progressBarService.stop();

            if (
              response &&
              typeof response === 'object' &&
              response.length > 0
            ) {
              this.surveyQuestionsCollection = response;
              this.surveyQuestionsCollection.forEach((item) => {
                if (
                  ((item.QuickAnswer === false || item.QuickAnswer === null) &&
                    item.QuestionId !== 3 &&
                    item.FullAnswer === null) ||
                  (item.QuickAnswer === true &&
                    item.QuestionId === 3 &&
                    item.FullAnswer === null)
                ) {
                  this.showPaymentIfSurvayIsValid = false;
                }
              });
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readSurveySubscription);
    }
  }

  // Used in Payment tab
  onCreatePayment() {
    if (
      this.updatePaymentsForm.get('PaymentItem').value &&
      typeof this.updatePaymentsForm.get('PaymentItem').value === 'object'
    ) {
      if (
        this.dataSource &&
        typeof this.dataSource === 'object' &&
        this.dataSource.data &&
        typeof this.dataSource.data === 'object' &&
        this.dataSource.data.length >= 0 &&
        typeof this.selectedExaminationChronologyId === 'number' &&
        isNaN(this.selectedExaminationChronologyId) === false
      ) {
        let payments: Payment[] = this.dataSource.data;
        payments.push(
          new Payment(
            -1,
            this.selectedExaminationChronologyId,
            this.updatePaymentsForm.get('PaymentItem').value.PriceListId,
            this.updatePaymentsForm.get('PaymentItem').value.Name,
            this.updatePaymentsForm.get('PaymentItem').value.Description,
            this.updatePaymentsForm.get('PaymentItem').value.Price,
            0,
            this.updatePaymentsForm.get('PaymentItem').value.Price,
          ),
        );
        this.dataSource = new MatTableDataSource<Payment>(payments);
        this.hasUserChangedTableData = true;

        this.updatePaymentTotal();
      }
    }
  }

  // Used in Payment tab
  onCreateBluePaper() {
    if (
      this.updateBluePaperForm.get('BluePaper').value &&
      typeof this.updateBluePaperForm.get('BluePaper').value === 'string' &&
      this.updateBluePaperForm.get('BluePaper').value.length > 0 &&
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      this.progressBarService.start();
      const saveBluePaperOnExamChartSubscription = this.dashboardService
        .saveBluePaperOnExamChart(
          new BluePaper(
            this.selectedExaminationChronologyId,
            this.updateBluePaperForm.get('BluePaper').value,
          ),
        )
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();
            if (response === true) {
              this.snackbarService.showSuccess('Plavi papir je uspešno dodat.');
            }

            if (
              this.examinationChronologyCollection &&
              typeof this.examinationChronologyCollection === 'object' &&
              this.examinationChronologyCollection.length > 0 &&
              this.examinationChronologyCollection[this.activeButton] &&
              this.examinationChronologyCollection[
                this.activeButton
              ].hasOwnProperty('BluePaper')
            )
              this.examinationChronologyCollection[
                this.activeButton
              ].BluePaper = this.updateBluePaperForm.get('BluePaper').value;
          },
          (error) => this.errorHandlerService.handleError(error),
        );
      this.subscriptions.add(saveBluePaperOnExamChartSubscription);
    }
  }

  // Used in Payment tab
  onDeletePayment(index: number) {
    if (
      this.dataSource &&
      typeof this.dataSource === 'object' &&
      this.dataSource.data &&
      typeof this.dataSource.data === 'object' &&
      this.dataSource.data.length > 0 &&
      typeof index === 'number' &&
      isNaN(index) === false &&
      index >= 0 &&
      index <= this.dataSource.data.length - 1
    ) {
      let payments: Payment[] = this.dataSource.data;
      payments.splice(index, 1);
      this.dataSource = new MatTableDataSource<Payment>(payments);
      this.hasUserChangedTableData = true;

      this.updatePaymentTotal();
    }
  }

  // Used in Payment tab
  onUpdatePayments() {
    if (
      this.dataSource &&
      typeof this.dataSource === 'object' &&
      this.dataSource.data &&
      typeof this.dataSource.data === 'object' &&
      this.dataSource.data.length > 0
    ) {
      this.progressBarService.start();

      const updatePaymentsSubscription = this.dashboardService
        .updatePayments(this.dataSource.data)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess('Naplata je uspešno izmenjena.');
              this.readPayments();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updatePaymentsSubscription);
    }
  }

  // Used in Payment tab
  onChangeDiscount(payment: Payment) {
    if (
      payment &&
      typeof payment === 'object' &&
      payment.hasOwnProperty('Discount')
    ) {
      if (
        typeof payment.Discount === 'number' &&
        isNaN(payment.Discount) === false &&
        payment.Discount >= 0 &&
        payment.Discount <= 100
      ) {
        if (
          payment.hasOwnProperty('Price') &&
          typeof payment.Price === 'number' &&
          isNaN(payment.Price) === false &&
          payment.Price > 0
        ) {
          payment.PriceWithDiscount =
            payment.Price - payment.Price * (payment.Discount / 100);

          this.hasUserChangedTableData = true;
          this.updatePaymentTotal();
        }
      } else {
        payment.Discount = 0;
        payment.PriceWithDiscount = payment.Price;

        this.hasUserChangedTableData = true;
        this.updatePaymentTotal();
      }
    }
  }

  // Used in Payment tab
  updatePaymentTotal() {
    if (
      this.dataSource &&
      typeof this.dataSource === 'object' &&
      this.dataSource.data &&
      typeof this.dataSource.data === 'object' &&
      this.dataSource.data.length > 0
    ) {
      let discountSummation: number = 0;
      let priceWithDiscountSummation: number = 0;

      for (let payment of this.dataSource.data) {
        if (typeof payment === 'object') {
          if (
            payment.hasOwnProperty('Price') &&
            typeof payment.Price === 'number' &&
            isNaN(payment.Price) === false &&
            payment.Price > 0 &&
            typeof payment.Discount === 'number' &&
            isNaN(payment.Discount) === false &&
            payment.Discount > 0 &&
            payment.Discount <= 100
          )
            discountSummation = payment.Price * (payment.Discount / 100);
          if (
            payment.hasOwnProperty('PriceWithDiscount') &&
            typeof payment.PriceWithDiscount === 'number' &&
            isNaN(payment.PriceWithDiscount) === false &&
            payment.PriceWithDiscount > 0
          )
            priceWithDiscountSummation += payment.PriceWithDiscount;
        }
      }

      this.discountTotal = discountSummation;
      this.priceWithDiscountTotal = priceWithDiscountSummation;
    } else {
      this.discountTotal = 0;
      this.priceWithDiscountTotal = 0;
    }
  }

  // Used in Payment tab
  readPayments() {
    if (
      typeof this.selectedExaminationChronologyId === 'number' &&
      isNaN(this.selectedExaminationChronologyId) === false
    ) {
      // Make sure to reset the content in selected Payment tab (on Examination chronology tab change)
      this.dataSource = new MatTableDataSource<Payment>([]);
      this.hasUserChangedTableData = false;
      this.updatePaymentTotal();

      this.progressBarService.start();

      const readPaymentsSubscription = this.dashboardService
        .readPayments(this.selectedExaminationChronologyId)
        .subscribe(
          (response: Payment[]) => {
            this.progressBarService.stop();

            if (
              response &&
              typeof response === 'object' &&
              response.length > 0
            ) {
              this.dataSource = new MatTableDataSource<Payment>(response);
              this.hasUserChangedTableData = false;

              this.updatePaymentTotal();
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readPaymentsSubscription);
    }
  }

  openDocumentList(): void {
    const dialogRef = this.dialog.open(DocumentListDialogComponent, {
      minWidth: '44vw',
      data: { patient_id: this.dashboardService.getPatientId() },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openAgreementList(): void {
    let agreementData = {
      source_id: new ReadExcimerLaserRequest(
        this.selectedExaminationChronologyId,
        false,
      ).ExamChartId,
      operation: false,
    };

    const dialogRef = this.dialog.open(UpdateAgreementDialogComponent, {
      minWidth: '44vw',
      data: agreementData,
    });

    const updateAgreementDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.progressBarService.start();
          if (!this.operations) {
            this.progressBarService.stop();
            return;
          }
          const updateAgreementByExcimerLaserSubscription =
            this.dashboardService
              .updateAgreementByExcimerLaser(this.operations.Id)
              .subscribe(
                (response: boolean) => {
                  this.progressBarService.stop();

                  if (response === true) {
                    this.readOperations();
                  }
                },
                (error) => {
                  this.errorHandlerService.handleError(error);
                  this.progressBarService.stop();
                },
              );

          this.subscriptions.add(updateAgreementByExcimerLaserSubscription);
        }
      });

    this.subscriptions.add(updateAgreementDialogSubscription);
  }

  private handleFormsState() {
    if (this.isFormInvalid) {
      this.updateGeneralExaminationForm.get('Anamnesis').disable();
      this.updateGeneralExaminationForm.get('VODDistanceSC').disable();
      this.updateGeneralExaminationForm.get('VODDistanceCC').disable();
      this.updateGeneralExaminationForm.get('VODVisusDistance').disable();
      this.updateGeneralExaminationForm.get('VODNearSC').disable();
      this.updateGeneralExaminationForm.get('VODNearCC').disable();
      this.updateGeneralExaminationForm.get('VODVisusNear').disable();
      this.updateGeneralExaminationForm.get('VOSDistanceSC').disable();
      this.updateGeneralExaminationForm.get('VOSDistanceCC').disable();
      this.updateGeneralExaminationForm.get('VOSVisusDistance').disable();
      this.updateGeneralExaminationForm.get('VOSNearSC').disable();
      this.updateGeneralExaminationForm.get('VOSNearCC').disable();
      this.updateGeneralExaminationForm.get('VOSVisusNear').disable();
      this.updateGeneralExaminationForm.get('KeratometryOD').disable();
      this.updateGeneralExaminationForm.get('KeratometryOS').disable();
      this.updateGeneralExaminationForm.get('DominantEye').disable();
      this.updateGeneralExaminationForm.get('IOPAplanationTOD').disable();
      this.updateGeneralExaminationForm.get('IOPAplanationTOS').disable();
      this.updateGeneralExaminationForm.get('IOPNonContactTOD').disable();
      this.updateGeneralExaminationForm.get('IOPNonContactTOS').disable();
      this.updateGeneralExaminationForm.get('SpaltOd').disable();
      this.updateGeneralExaminationForm.get('SpaltOs').disable();
      this.updateGeneralExaminationForm.get('FundusOd').disable();
      this.updateGeneralExaminationForm.get('FundusDo').disable();
      this.updateGeneralExaminationForm
        .get('updateDiagnosticFormSection')
        .disable();
      this.updateGeneralExaminationForm.get('SubDiagnosis').disable();
      this.updateGeneralExaminationForm.get('Advice').disable();
      this.updateGeneralExaminationForm.get('Intervention').disable();

      this.updateRecipeForm.get('DistanceOD').disable();
      this.updateRecipeForm.get('DistanceOS').disable();
      this.updateRecipeForm.get('DistancePD').disable();
      this.updateRecipeForm.get('NearOD').disable();
      this.updateRecipeForm.get('NearOS').disable();
      this.updateRecipeForm.get('NearPD').disable();

      this.updateBluePaperForm.get('BluePaper').disable();
    } else {
      this.updateGeneralExaminationForm.get('Anamnesis').enable();
      this.updateGeneralExaminationForm.get('VODDistanceSC').enable();
      this.updateGeneralExaminationForm.get('VODDistanceCC').enable();
      this.updateGeneralExaminationForm.get('VODVisusDistance').enable();
      this.updateGeneralExaminationForm.get('VODNearSC').enable();
      this.updateGeneralExaminationForm.get('VODNearCC').enable();
      this.updateGeneralExaminationForm.get('VODVisusNear').enable();
      this.updateGeneralExaminationForm.get('VOSDistanceSC').enable();
      this.updateGeneralExaminationForm.get('VOSDistanceCC').enable();
      this.updateGeneralExaminationForm.get('VOSVisusDistance').enable();
      this.updateGeneralExaminationForm.get('VOSNearSC').enable();
      this.updateGeneralExaminationForm.get('VOSNearCC').enable();
      this.updateGeneralExaminationForm.get('VOSVisusNear').enable();
      this.updateGeneralExaminationForm.get('KeratometryOD').enable();
      this.updateGeneralExaminationForm.get('KeratometryOS').enable();
      this.updateGeneralExaminationForm.get('DominantEye').enable();
      this.updateGeneralExaminationForm.get('IOPAplanationTOD').enable();
      this.updateGeneralExaminationForm.get('IOPAplanationTOS').enable();
      this.updateGeneralExaminationForm.get('IOPNonContactTOD').enable();
      this.updateGeneralExaminationForm.get('IOPNonContactTOS').enable();
      this.updateGeneralExaminationForm.get('SpaltOd').enable();
      this.updateGeneralExaminationForm.get('SpaltOs').enable();
      this.updateGeneralExaminationForm.get('FundusOd').enable();
      this.updateGeneralExaminationForm.get('FundusDo').enable();
      this.updateGeneralExaminationForm
        .get('updateDiagnosticFormSection')
        .enable();
      this.updateGeneralExaminationForm.get('SubDiagnosis').enable();
      this.updateGeneralExaminationForm.get('Advice').enable();
      this.updateGeneralExaminationForm.get('Intervention').enable();

      this.updateRecipeForm.get('DistanceOD').enable();
      this.updateRecipeForm.get('DistanceOS').enable();
      this.updateRecipeForm.get('DistancePD').enable();
      this.updateRecipeForm.get('NearOD').enable();
      this.updateRecipeForm.get('NearOS').enable();
      this.updateRecipeForm.get('NearPD').enable();

      this.updateBluePaperForm.get('BluePaper').enable();
    }
  }

  private updateDiagnosticExistState() {
    const readGeneralExaminationSubscription = this.dashboardService
      .readGeneralExamination(this.selectedExaminationChronologyId)
      .subscribe(
        (response: GeneralExamination) => {
          if (response && typeof response === 'object') {
            if (
              response.hasOwnProperty('DiagnosticCVPExist') &&
              typeof response.DiagnosticCVPExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticCVPExist =
                response.DiagnosticCVPExist;
            if (
              response.hasOwnProperty('DiagnosticEMExist') &&
              typeof response.DiagnosticEMExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticEMExist =
                response.DiagnosticEMExist;
            if (
              response.hasOwnProperty('DiagnosticERGExist') &&
              typeof response.DiagnosticERGExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticERGExist =
                response.DiagnosticERGExist;
            if (
              response.hasOwnProperty('DiagnosticFAExist') &&
              typeof response.DiagnosticFAExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticFAExist =
                response.DiagnosticFAExist;
            if (
              response.hasOwnProperty('DiagnosticFAFExist') &&
              typeof response.DiagnosticFAFExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticFAFExist =
                response.DiagnosticFAFExist;
            if (
              response.hasOwnProperty('DiagnosticFotoFundusExist') &&
              typeof response.DiagnosticFotoFundusExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticFotoFundusExist =
                response.DiagnosticFotoFundusExist;
            if (
              response.hasOwnProperty('DiagnosticFotoPrednjegSegmentaExist') &&
              typeof response.DiagnosticFotoPrednjegSegmentaExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticFotoPrednjegSegmentaExist =
                response.DiagnosticFotoPrednjegSegmentaExist;
            if (
              response.hasOwnProperty('DiagnosticIOLMasterExist') &&
              typeof response.DiagnosticIOLMasterExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticIOLMasterExist =
                response.DiagnosticIOLMasterExist;
            if (
              response.hasOwnProperty('DiagnosticOCTAExist') &&
              typeof response.DiagnosticOCTAExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticOCTAExist =
                response.DiagnosticOCTAExist;
            if (
              response.hasOwnProperty('DiagnosticOCTAUTExist') &&
              typeof response.DiagnosticOCTAUTExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticOCTAUTExist =
                response.DiagnosticOCTAUTExist;
            if (
              response.hasOwnProperty('DiagnosticOCTPOSTExist') &&
              typeof response.DiagnosticOCTPOSTExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticOCTPOSTExist =
                response.DiagnosticOCTPOSTExist;
            if (
              response.hasOwnProperty('DiagnosticUltrazvukOkaExist') &&
              typeof response.DiagnosticUltrazvukOkaExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticUltrazvukOkaExist =
                response.DiagnosticUltrazvukOkaExist;
            if (
              response.hasOwnProperty('DiagnosticVEPExist') &&
              typeof response.DiagnosticVEPExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticVEPExist =
                response.DiagnosticVEPExist;
            if (
              response.hasOwnProperty('DiagnosticVerionExist') &&
              typeof response.DiagnosticVerionExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticVerionExist =
                response.DiagnosticVerionExist;
            if (
              response.hasOwnProperty('DiagnosticKKTExist') &&
              typeof response.DiagnosticKKTExist === 'boolean'
            )
              this.diagnosticExistState.DiagnosticKKTExist =
                response.DiagnosticKKTExist;
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );
    this.subscriptions.add(readGeneralExaminationSubscription);
  }

  ngOnDestroy() {
    this.dashboardService.removePatientId();
    this.dashboardService.removeIsNewPatientState();

    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
