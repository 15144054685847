import { Injectable } from '@angular/core';

import { formatDate } from '@angular/common';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, ObservedValueOf } from 'rxjs';

import { apiUrl } from '../../shared/constants.service';
import { AuthenticationService } from '../../core/authentication.service';

import { Gender } from './gender.model';
import { Patient } from './patient.model';
import { ExaminationChronology } from './examination-chronology.model';
import { ReadAppointmentsRequest } from './read-appointments-request.model';
import { ReadAppointmentsResponse } from './read-appointments-response.model';
import { AppointmentType } from './appointment-type.model';
import { CreateAppointmentRequest } from './create-appointment-request.model';
import { Appointment } from './appointment.model';
import { Diagnosis } from './diagnosis.model';
import { GeneralExamination } from './general-examination.model';
import { ReadDiagnosticImagesRequest } from './read-diagnostic-images-request.model';
import { DiagnosticImage } from './diagnostic-image.model';
import { ReadExcimerLaserRequest } from './read-excimer-laser-request.model';
import { ExcimerLaser } from './excimer-laser.model';
import { AnesthesiologicalExamination } from './anesthesiological-examination.model';
import { OperationProtocol } from './operation-protocol.model';
import { Hospitalization } from './hospitalization.model';
import { Reception } from './reception.model';
import { AnesthesiaFlow } from './anesthesia-flow.model';
import { OperationFlow } from './operation-flow.model';
import { SpecialistFindingType } from './specialist-finding-type.model';
import { SpecialistFinding } from './specialist-finding.model';
import { LaboratoryType } from './laboratory-type.model';
import { Laboratory } from './laboratory.model';
import { TemperatureNoteType } from './temperature-note-type.model';
import { TemperatureNote } from './temperature-note.model';
import { DiabeticNote } from './diabetic-note.model';
import { DischargeNote } from './discharge-note.model';
import { Recipe } from './recipe.model';
import { PaymentItem } from './payment-item.model';
import { Payment } from './payment.model';
import { SurveyQuestion } from './survey-question.model';
import { ReadPatientsRequest } from './read-patients-request.model';
import { Employee } from './employee.model';
import { ReadRecipesResponse } from './read-recipes-response.model';
import { Job } from './job.model';
import { Shift } from './shift.model';
import { AppointmentTemplate } from './appointment-template.model';
import { BusinessHour } from './business-hour.model';

import AES from 'crypto-js/aes';
import EncUtf8 from 'crypto-js/enc-utf8';
import { StatisticsFilter } from './statistics-filter.model';
import { DiseaseHistoryType } from './disease-history-type.model';
import { DiseaseHistory } from './disease-history.model';
import { BluePaper } from './blue-paper.model';
import { InterventionType } from './intervention-type.model';
import { Consent } from './consent.model';
import { IssueDocument } from './issue-document-model';
import { DecursusMorbi } from './decursus-morbi.model';
import { SurgeonWorkPlan } from './surgeon-work-plan.model';
import { Surgeon } from './surgeon.model';
import { SurgeonSchedule } from './surgeon-schedule-model';
import { Message } from './message.model';
import { Protocol } from './protocol.model';
import { ApartmentBooking } from './apartment-booking.model';
import { Apartment } from './apartment.model';
import { GeneralConsent } from './general-consent.model';
import { ApartmentBookingDto } from './apartment-bookingDto.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {}

  // Used in New patient
  readGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(apiUrl + '/patient/getgenderlist', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used in New patient
  createPatient(patient: Patient): Observable<number> {
    return this.http.post<number>(
      apiUrl + '/patient/createnewpatient',
      patient,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  deletePatient(patientId: number): any {
    return this.http.delete<boolean>(
      apiUrl + `/patient/deletepatient/${patientId}`,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Search
  searchPatients(patient: Patient): Observable<Patient[]> {
    return this.http.post<Patient[]>(
      apiUrl + '/patient/searchpatient',
      patient,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Search
  getInterventionTypesByLevel(level: number): Observable<InterventionType[]> {
    return this.http.get<InterventionType[]>(
      apiUrl + '/Exclaimer/GetInterventionTypesByLevel',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('level', level.toString()),
      },
    );
  }

  // Used for Patient page
  // sv_pi is the abbreviation of sveti_vid_patient_id
  getPatientId(): number | null {
    let currentId: number | null = null;

    if (
      typeof Storage !== 'undefined' &&
      sessionStorage.getItem('sv_pi') &&
      typeof sessionStorage.getItem('sv_pi') === 'string' &&
      sessionStorage.getItem('sv_pi').length > 0
    ) {
      let storedId = AES.decrypt(
        sessionStorage.getItem('sv_pi'),
        '4f43a1597436dd0c1f0ef14dbdd6a567749a4ea66c772f6970a5a702bc357aba8a9e8ece8883429484b84d9eeaf55559e7a6900eca3a865c10423cbb2f5e5e5a',
      ).toString(EncUtf8);
      if (
        typeof parseInt(storedId) === 'number' &&
        isNaN(parseInt(storedId)) === false
      )
        currentId = parseInt(storedId);
    }

    return currentId;
  }

  // Used for Patient page
  setPatientId(patientId: number) {
    if (
      typeof Storage !== 'undefined' &&
      typeof patientId === 'number' &&
      isNaN(patientId) === false
    )
      sessionStorage.setItem(
        'sv_pi',
        AES.encrypt(
          patientId.toString(),
          '4f43a1597436dd0c1f0ef14dbdd6a567749a4ea66c772f6970a5a702bc357aba8a9e8ece8883429484b84d9eeaf55559e7a6900eca3a865c10423cbb2f5e5e5a',
        ).toString(),
      );
  }

  // Used for Patient page
  removePatientId() {
    if (typeof Storage !== 'undefined' && sessionStorage.getItem('sv_pi'))
      sessionStorage.removeItem('sv_pi');
  }

  // Used for Patient page
  // sv_inp is the abbreviation of sveti_vid_is_new_patient
  getIsNewPatientState(): boolean | null {
    let currentState: boolean | null = null;

    if (
      typeof Storage !== 'undefined' &&
      sessionStorage.getItem('sv_inp') &&
      typeof sessionStorage.getItem('sv_inp') === 'string' &&
      sessionStorage.getItem('sv_inp').length > 0
    ) {
      let storedState = AES.decrypt(
        sessionStorage.getItem('sv_inp'),
        '4f43a1597436dd0c1f0ef14dbdd6a567749a4ea66c772f6970a5a702bc357aba8a9e8ece8883429484b84d9eeaf55559e7a6900eca3a865c10423cbb2f5e5e5a',
      ).toString(EncUtf8);
      if (
        storedState &&
        typeof storedState === 'string' &&
        storedState.length > 0
      ) {
        if (storedState.toLowerCase() === 'true') currentState = true;
        else if (storedState.toLowerCase() === 'false') currentState = false;
      }
    }

    return currentState;
  }

  // Used for Patient page
  setIsNewPatientState(state: boolean) {
    if (typeof Storage !== 'undefined' && typeof state === 'boolean')
      sessionStorage.setItem(
        'sv_inp',
        AES.encrypt(
          state.toString(),
          '4f43a1597436dd0c1f0ef14dbdd6a567749a4ea66c772f6970a5a702bc357aba8a9e8ece8883429484b84d9eeaf55559e7a6900eca3a865c10423cbb2f5e5e5a',
        ).toString(),
      );
  }

  // Used for Patient page
  removeIsNewPatientState() {
    if (typeof Storage !== 'undefined' && sessionStorage.getItem('sv_inp'))
      sessionStorage.removeItem('sv_inp');
  }

  // Used for Patient page
  readPatientExaminationChronology(
    patientId: number,
  ): Observable<ExaminationChronology[]> {
    return this.http.get<ExaminationChronology[]>(
      apiUrl + '/patient/getexamchartsforpatient',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('PatientId', patientId.toString()),
      },
    );
  }

  // Used for Patient page
  readPatient(patientId: number): Observable<Patient> {
    return this.http.get<Patient>(apiUrl + '/patient/getpatientbyid', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('patientId', patientId.toString()),
    });
  }

  // Used for Patient page
  updatePatient(patient: Patient): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/patient/updatepatient', patient, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Patient page
  readDiagnosis(): Observable<Diagnosis[]> {
    return this.http.get<Diagnosis[]>(
      apiUrl + '/MedicalExamination/getalldiagnosis',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readGeneralExamination(
    examinationChronologyId: number,
  ): Observable<GeneralExamination> {
    return this.http.get<GeneralExamination>(
      apiUrl + '/MedicalExamination/GetMedicalExaminationByExamChart',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'examChartId',
          examinationChronologyId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateGeneralExamination(
    examination: GeneralExamination,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveMedicalExam',
      examination,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  uploadDiagnosticImage(requestData: FormData): Observable<boolean> {
    const headers = new HttpHeaders({
      token: this.authenticationService.getToken(),
    });

    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/DiagnosticsFileUpload',
      requestData,
      {
        headers: headers,
        reportProgress: true,
      },
    );
  }

  readDiagnosticImages(
    readDiagnosticImagesRequest: ReadDiagnosticImagesRequest,
  ): Observable<DiagnosticImage[][]> {
    return this.http.get<DiagnosticImage[][]>(
      apiUrl + '/MedicalExamination/GetAllDiagnosticsForCompare',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams()
          .set(
            'MedicalExaminationId',
            readDiagnosticImagesRequest.MedicalExaminationId.toString(),
          )
          .set('PatientId', readDiagnosticImagesRequest.PatientId.toString())
          .set(
            'DiagnosticsTypeId',
            readDiagnosticImagesRequest.DiagnosticsTypeId.toString(),
          ),
      },
    );
  }

  // Used for Patient page
  readGeneralExaminationReport(generalExaminationId: number) {
    return this.http.get(apiUrl + '/Report/GetExamReport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'medicalExamId',
        generalExaminationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used on Patient and Recipes pages
  printFile(file: Blob) {
    if (file && file instanceof Blob && file.size > 0) {
      // Make sure to remove previously appended file <iframe> tag
      if (document.getElementById('fileIframe'))
        document.body.removeChild(document.getElementById('fileIframe'));

      // Then create <iframe> for new file
      const iframeElement = document.createElement('iframe');
      iframeElement.setAttribute('id', 'fileIframe');
      iframeElement.setAttribute('src', URL.createObjectURL(file));
      iframeElement.style.display = 'none';

      document.body.appendChild(iframeElement);

      iframeElement.contentWindow.print();
    }
  }

  // Used for Patient page
  readExcimerLaser(
    readExcimerLaserRequest: ReadExcimerLaserRequest,
  ): Observable<ExcimerLaser> {
    return this.http.get<ExcimerLaser>(
      apiUrl + '/Exclaimer/GetLaserExclaimerByExamChart',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams()
          .set('ExamChartId', readExcimerLaserRequest.ExamChartId.toString())
          .set('IsSurgery', readExcimerLaserRequest.IsSurgery.toString()),
      },
    );
  }

  // Used for Patient page
  updateAgreementByExcimerLaser(excimerLaserId: number): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/ConfirmExlclaimerApproval',
      { ExclaimerId: excimerLaserId },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  getAllConsentsForLaserExclaimerId(
    laserExclaimerId: number,
  ): Observable<Consent[]> {
    return this.http.get<Consent[]>(
      apiUrl + '/MedicalExamination/GetAllConsentsForLaserExclaimerId',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          laserExclaimerId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  getAllAnesthesiologyExamForLaserExclaimerId(
    laserExclaimerId: number,
  ): Observable<Consent[]> {
    return this.http.get<Consent[]>(
      apiUrl +
        '/MedicalExamination/GetAllAnesthesiologyExamForLaserExclaimerId',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          laserExclaimerId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  getConsent(id: number): Observable<Consent> {
    return this.http.get<Consent>(apiUrl + '/MedicalExamination/GetConsent', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('consentId', id.toString()),
    });
  }

  // Used for Patient page
  saveConsent(consent) {
    return this.http.post(apiUrl + '/MedicalExamination/SaveConsent', consent, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Patient page
  getAllGeneralConsentsForExamChartId(
    examChartId: number,
  ): Observable<GeneralConsent[]> {
    return this.http.get<GeneralConsent[]>(
      apiUrl + '/MedicalExamination/GetAllGeneralConsentsForExamChartId',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('examChartId', examChartId.toString()),
      },
    );
  }

  // Used for Patient page
  saveGeneralConsent(consent) {
    return this.http.post(
      apiUrl + '/MedicalExamination/SaveGeneralConsent',
      consent,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  getGeneralConsent(id: number): Observable<GeneralConsent> {
    return this.http.get<GeneralConsent>(
      apiUrl + '/MedicalExamination/GetGeneralConsent',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('consentId', id.toString()),
      },
    );
  }

  // Used for Patient page
  deleteGeneralConsents(Id: number) {
    return this.http.post(
      apiUrl + '/MedicalExamination/DeleteGeneralConsents ',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  //readAnesthesiologicalExaminationByExcimerLaser(excimerLaserId: number): Observable<AnesthesiologicalExamination> {
  //    return this.http.get<AnesthesiologicalExamination>(apiUrl + "/MedicalExamination/GetAnesthesiologyExam", {
  //        headers: new HttpHeaders({
  //            'token': this.authenticationService.getToken()
  //        }),
  //        params: new HttpParams().set('laserExclaimerId', excimerLaserId.toString())
  //    });
  //}

  readAnesthesiologicalExaminationByExcimerLaser(
    anesthesiologyExamId: number,
  ): Observable<Consent> {
    return this.http.get<Consent>(
      apiUrl + '/MedicalExamination/GetAnesthesiologyExam',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'anesthesiologyExamId',
          anesthesiologyExamId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  //updateAnesthesiologicalExaminationByExcimerLaser(examination: AnesthesiologicalExamination): Observable<boolean> {
  //    return this.http.post<boolean>(apiUrl + "/MedicalExamination/SaveAnesthesiologyExam", examination, {
  //        headers: new HttpHeaders({
  //            'token': this.authenticationService.getToken()
  //        })
  //    });
  //}

  updateAnesthesiologicalExaminationByExcimerLaser(consent) {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveAnesthesiologyExam',
      consent,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readOperationProtocolByExcimerLaser(
    excimerLaserId: number,
  ): Observable<OperationProtocol> {
    return this.http.get<OperationProtocol>(
      apiUrl + '/Exclaimer/GetSurgeryProtocol',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateOperationProtocolByExcimerLaser(
    protocol: OperationProtocol,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/SaveSurgeryProtocol',
      protocol,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readOperationProtocolExcel(operationProtocolId: number) {
    return this.http.get(apiUrl + '/Excel/GetOperationProtocol', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'surgeryProdtocolId',
        operationProtocolId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readAnesthesiaProtocolExcel(operationProtocolId: number) {
    return this.http.get(apiUrl + '/Excel/GetAnesthesiaProtocol', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'surgeryProdtocolId',
        operationProtocolId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readHospitalizationByExcimerLaser(
    excimerLaserId: number,
  ): Observable<Hospitalization> {
    return this.http.get<Hospitalization>(
      apiUrl + '/Exclaimer/GetHospitalization',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateHospitalizationByExcimerLaser(
    hospitalization: Hospitalization,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/SaveHospitalization',
      hospitalization,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readDiseaseHistoryExcel(hospitalizationId: number) {
    return this.http.get(apiUrl + '/Excel/GetHistoryExcel', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'hospitalizationId',
        hospitalizationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  deleteDiseaseHistory(id: number) {
    return this.http.post(
      apiUrl + '/MedicalExamination/DeleteDiseaseHistoryForId',
      id,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readHospitalizationReportExcel(hospitalizationId: number) {
    return this.http.get(apiUrl + '/Excel/GetHospitalizationExcel', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'hospitalizationId',
        hospitalizationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readTreatmentInvoiceExcel(hospitalizationId: number) {
    return this.http.get(apiUrl + '/Excel/GetBillingExcel', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'hospitalizationId',
        hospitalizationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readMasterFileExcel(hospitalizationId: number) {
    return this.http.get(apiUrl + '/Excel/GetPersonalListExcel', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'hospitalizationId',
        hospitalizationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readReceptionByExcimerLaser(excimerLaserId: number): Observable<Reception> {
    return this.http.get<Reception>(
      apiUrl + '/MedicalExamination/GetSurgeryPreparationExam',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateReceptionByExcimerLaser(reception: Reception): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveSurgeryPreparationExam',
      reception,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readAnesthesiaFlowByExcimerLaser(
    excimerLaserId: number,
  ): Observable<AnesthesiaFlow> {
    return this.http.get<AnesthesiaFlow>(
      apiUrl + '/MedicalExamination/GetSurgeryAnesthesia',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateAnesthesiaFlowByExcimerLaser(
    anesthesiaFlow: AnesthesiaFlow,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveSurgeryAnesthesia',
      anesthesiaFlow,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readOperationFlowByExcimerLaser(
    excimerLaserId: number,
  ): Observable<OperationFlow> {
    return this.http.get<OperationFlow>(
      apiUrl + '/MedicalExamination/GetSurgeryDescription',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateOperationFlowByExcimerLaser(
    operationFlow: OperationFlow,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveSurgeryDescription',
      operationFlow,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readInternalHistoryReport(generalExaminationId: number) {
    return this.http.get(apiUrl + '/Report/GetInternalHistoryReport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'medicalExamId',
        generalExaminationId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readSpecialistFindingTypes(): Observable<SpecialistFindingType[]> {
    return this.http.get<SpecialistFindingType[]>(
      apiUrl + '/MedicalExamination/GetAllSpecialistExamTypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readDiseaseHistoryTypes(): Observable<DiseaseHistoryType[]> {
    return this.http.get<DiseaseHistoryType[]>(
      apiUrl + '/MedicalExamination/GetAllDiseaseHistoryTypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  uploadSpecialistFindingFile(requestData: FormData): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveSpecialistFinding',
      requestData,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  uploadDiseaseHistoryFile(requestData: FormData): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveDiseaseHistory',
      requestData,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readSpecialistFindingsByExcimerLaser(
    excimerLaserId: number,
  ): Observable<SpecialistFinding[]> {
    return this.http.get<SpecialistFinding[]>(
      apiUrl + '/MedicalExamination/GetAllSpecialistExamsForLaserExclaimerId',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  getSpecialistFindingsForPatientAndExamType(
    specialistExamTypeId: number,
    patientId: number,
  ): Observable<SpecialistFinding[]> {
    return this.http.get<SpecialistFinding[]>(
      apiUrl + '/MedicalExamination/GetSpecialistFindingsForPatientAndExamType',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams()
          .set('specialistExamTypeId', specialistExamTypeId.toString())
          .set('patientId', patientId.toString()),
      },
    );
  }

  // Used for Patient page
  readDiseaseHistoryForLaserExclaimer(
    excimerLaserId: number,
  ): Observable<DiseaseHistory[]> {
    return this.http.get<DiseaseHistory[]>(
      apiUrl + '/MedicalExamination/GetAllDiseaseHistoryForLaserExclaimerId',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  readSpecialistFinding(
    specialistFindingId: number,
  ): Observable<SpecialistFinding> {
    return this.http.get<SpecialistFinding>(
      apiUrl + '/MedicalExamination/GetSpecialistFinding',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'specialistFindingId',
          specialistFindingId.toString(),
        ),
      },
    );
  }

  //Used for Patient page
  readDiseaseHistory(diseaseHistoryId: number): Observable<DiseaseHistory> {
    return this.http.get<DiseaseHistory>(
      apiUrl + '/MedicalExamination/GetDiseaseHistory',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'diseaseHistoryId',
          diseaseHistoryId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  readLaboratoryTypes(): Observable<LaboratoryType[]> {
    return this.http.get<LaboratoryType[]>(
      apiUrl + '/MedicalExamination/GetLaboratoryTypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  uploadLaboratoryFile(requestData: FormData): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/UploadLaboratory',
      requestData,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readLaboratoryByExcimerLaser(
    excimerLaserId: number,
  ): Observable<Laboratory[]> {
    return this.http.get<Laboratory[]>(
      apiUrl + '/MedicalExamination/GetAllLaboratory',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  readLaboratory(laboratoryId: number): Observable<Laboratory> {
    return this.http.get<Laboratory>(
      apiUrl + '/MedicalExamination/GetLaboratory',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('laboratoryId', laboratoryId.toString()),
      },
    );
  }

  // Used for Patient page
  readTemperatureNoteTypes(): Observable<TemperatureNoteType[]> {
    return this.http.get<TemperatureNoteType[]>(
      apiUrl + '/Exclaimer/GetAllTemperatureListTypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readTemperatureNotesByExcimerLaser(
    excimerLaserId: number,
  ): Observable<TemperatureNote[]> {
    return this.http.get<TemperatureNote[]>(
      apiUrl + '/Exclaimer/GetTemperatureList',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  createTemperatureNoteByExcimerLaser(
    temperatureNote: TemperatureNote,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/AddEntryForTemperatureList',
      temperatureNote,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readDiabeticNotesByExcimerLaser(
    excimerLaserId: number,
  ): Observable<DiabeticNote[]> {
    return this.http.get<DiabeticNote[]>(
      apiUrl + '/Exclaimer/GetDiabeticList',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  createDiabeticNoteByExcimerLaser(
    diabeticNote: DiabeticNote,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/SaveDiabeticList',
      diabeticNote,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readDischargeNoteByExcimerLaser(
    excimerLaserId: number,
  ): Observable<DischargeNote> {
    return this.http.get<DischargeNote>(
      apiUrl + '/Exclaimer/GetDischargeList',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          excimerLaserId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateDischargeNoteByExcimerLaser(
    dischargeNote: DischargeNote,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Exclaimer/SaveDischargeList',
      dischargeNote,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readDischargeNoteReport(dischargeNoteId: number) {
    return this.http.get(apiUrl + '/Report/GetDischargeListReport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'dischargeListId',
        dischargeNoteId.toString(),
      ),
      responseType: 'blob',
    });
  }

  // Used for Patient page
  readInterventionTypesForFirstLevel(): Observable<InterventionType[]> {
    return this.http.get<InterventionType[]>(
      apiUrl + '/Exclaimer/GetInterventionTypesForFirstLevel',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patint page
  readInterventionTypesByLevelAndParent(
    level: number,
    parentId: number,
  ): Observable<InterventionType[]> {
    return this.http.get<InterventionType[]>(
      apiUrl + '/Exclaimer/GetInterventionTypesByLevelAndParent',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams()
          .set('level', level.toString())
          .set('parentId', parentId.toString()),
      },
    );
  }

  // Used for Patient page
  readRecipe(examinationChronologyId: number): Observable<Recipe> {
    return this.http.get<Recipe>(apiUrl + '/Recipe/getrecipe', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'examChartId',
        examinationChronologyId.toString(),
      ),
    });
  }

  // Used for Patient page
  updateRecipe(recipe: Recipe): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Recipe/createorupdaterecipe',
      recipe,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readPaymentItems(): Observable<AppointmentType[]> {
    return this.http.get<AppointmentType[]>(
      apiUrl + '/appointment/getallappointmenttypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readPayments(examinationChronologyId: number): Observable<Payment[]> {
    return this.http.get<Payment[]>(apiUrl + '/Payment/getpayments', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'examChartId',
        examinationChronologyId.toString(),
      ),
    });
  }

  // Used for Patient page
  updatePayments(payments: Payment[]): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/Payment/savepayments', payments, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Patient page
  saveBluePaperOnExamChart(bluePaper: BluePaper): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/MedicalExamination/SaveBluePaperOnExamChart',
      bluePaper,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  readSurvey(examinationChronologyId: number): Observable<SurveyQuestion[]> {
    return this.http.get<SurveyQuestion[]>(
      apiUrl + '/Survey/getexamchartsurvay',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'examChartId',
          examinationChronologyId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  updateSurvey(surveyQuestions: SurveyQuestion[]): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Survey/updateanswers',
      surveyQuestions,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  getIssuedDocumentsForPatient(id: number): Observable<IssueDocument[]> {
    return this.http.get<IssueDocument[]>(
      apiUrl + '/Patient/GetIssuedDocumentsForPatient',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('patientId', id.toString()),
      },
    );
  }

  // Used for Patient page
  getIssuedDocumentById(issuedDocumentId: number) {
    return this.http.get(apiUrl + '/Patient/GetIssuedDocumentById', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set(
        'issuedDocumentId',
        issuedDocumentId.toString(),
      ),
      responseType: 'blob',
    });
  }

  deleteIssuedDocument(id: number) {
    return this.http.delete(apiUrl + `/Patient/DeleteIssuedDocument/${id}`);
  }

  // Used for Patient page
  getDecursusMorbi(laserExclaimerId: number): Observable<DecursusMorbi> {
    return this.http.get<DecursusMorbi>(
      apiUrl + '/MedicalExamination/GetDecursusMorbi',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'laserExclaimerId',
          laserExclaimerId.toString(),
        ),
      },
    );
  }

  // Used for Patient page
  saveDecursusMorbi(data: DecursusMorbi) {
    return this.http.post(
      apiUrl + '/MedicalExamination/SaveDecursusMorbi',
      data,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  deleteAnesthesiologyExam(Id: number) {
    return this.http.post(
      apiUrl + '/Exclaimer/DeleteAnesthesiologyExam',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  deleteSpecialistFindings(Id: number) {
    return this.http.post(
      apiUrl + '/Exclaimer/DeleteSpecialistFindings',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  deleteConsents(Id: number) {
    return this.http.post(
      apiUrl + '/Exclaimer/DeleteConsents',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  deleteLaboratory(Id: number) {
    return this.http.post(
      apiUrl + '/Exclaimer/DeleteLaboratory',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Patient page
  deleteRecipe(Id: number) {
    return this.http.post(
      apiUrl + '/Recipe/DeleteRecipe',
      { Id },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  readPatients(): Observable<ReadPatientsRequest[]> {
    return this.http.post<ReadPatientsRequest[]>(
      apiUrl + '/patient/searchpatientlight',
      null,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  searchPatientByName(name: any): Observable<ReadPatientsRequest[]> {
    const params = new HttpParams().set('name', name.term);
    return this.http.get<ReadPatientsRequest[]>(
      `${apiUrl}/patient/searchpatientbyname`,
      {
        params: params,
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  //readAppointments(readAppointmentsRequest: ReadAppointmentsRequest): Observable<ReadAppointmentsResponse[]> {
  //    return this.http.get<ReadAppointmentsResponse[]>(apiUrl + "/appointment/getdailyappointments", {
  //        headers: new HttpHeaders({
  //            'token': this.authenticationService.getToken()
  //        }),
  //        params: new HttpParams().set('UserName', readAppointmentsRequest.UserName).set('UserId', readAppointmentsRequest.UserId.toString()).set('ForDate', readAppointmentsRequest.ForDate)
  //    });
  //}

  // Used in Scheduling
  readAppointments(readAppointmentsRequest: ReadAppointmentsRequest) {
    return this.http.get(apiUrl + '/appointment/getAllUsersDailyAppointments', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('UserName', readAppointmentsRequest.UserName)
        .set('UserId', readAppointmentsRequest.UserId.toString())
        .set('ForDate', readAppointmentsRequest.ForDate),
    });
  }

  // Used in Scheduling
  readAppointmentTypes(): Observable<AppointmentType[]> {
    return this.http.get<AppointmentType[]>(
      apiUrl + '/appointment/getallappointmenttypes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  createAppointment(
    appointment: CreateAppointmentRequest,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/appointment/saveappointment',
      appointment,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  updateAppointment(appointment: Appointment): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/appointment/changeappointment',
      appointment,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  deleteAppointment(appointmentId: number): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/appointment/deleteappointment',
      { appointmentId: appointmentId },
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling
  createPatientExaminationChronology(
    examinationChronology: ExaminationChronology,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/patient/createexamchart',
      examinationChronology,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Scheduling and Business hours
  readDoctors(): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      apiUrl + '/appointment/getallbookableusers',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  getAppointmentsForDoctor(
    username: string,
    id: number,
    date: Date,
  ): Observable<Employee> {
    return this.http.get<Employee>(
      apiUrl + '/appointment/getdailyappointments',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Recipes
  readRecipes(date: Date): Observable<ReadRecipesResponse[]> {
    return this.http.get<ReadRecipesResponse[]>(
      apiUrl + '/Recipe/getlastrecipes',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set(
          'date',
          formatDate(date, 'yyyy-MM-dd', 'sr-Latn'),
        ),
      },
    );
  }

  // Used in Recipes
  readRecipeFile(recipeId: number) {
    return this.http.get(apiUrl + '/Report/GetRecipeReport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('RecipeId', recipeId.toString()),
      responseType: 'blob',
    });
  }

  // Used in Staff administration
  readJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(apiUrl + '/AdminPanel/GetAllJobs', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used in Staff administration
  readEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(apiUrl + '/adminPanel/getAllStaff', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used in Staff administration
  createEmployee(employee: Employee): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/access/admin', employee, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used in Staff administration
  updateEmployee(employee: Employee): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/stuff/updateuserdata', employee, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used in Staff administration
  resetPassword(employeeId: number): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/stuff/resetpassword', null, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('id', employeeId.toString()),
    });
  }

  // Used in Business hours
  readBusinessHours(date: Date, username: string): Observable<BusinessHour[]> {
    return this.http.get<BusinessHour[]>(apiUrl + '/stuff/workinghours', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('date', formatDate(date, 'yyyy-MM-dd', 'sr-Latn'))
        .set('username', username),
    });
  }

  // Used in Business hours
  readDoctorShifts(doctorId: number, date: Date): Observable<Shift[]> {
    return this.http.get<Shift[]>(apiUrl + '/appointment/getdoctorshifts', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('DoctorId', doctorId.toString())
        .set('Date', formatDate(date, 'yyyy-MM-dd', 'sr-Latn')),
    });
  }

  // Used in Business hours
  readAppointmentTemplate(shift: Shift): Observable<AppointmentTemplate> {
    return this.http.post<AppointmentTemplate>(
      apiUrl + '/appointment/getappointmenttemplate',
      shift,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Business hours
  updateAppointmentTemplate(
    appointmentTemplate: AppointmentTemplate,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/appointment/saveappointmenttemplate',
      appointmentTemplate,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used in Business hours
  updateDayBusinessHours(dayBusinessHours: BusinessHour): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/stuff/updatesingleworkinghours',
      dayBusinessHours,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Statistics page
  getOftamologyStatistics(statistics: StatisticsFilter): Observable<Blob> {
    return this.http.post(
      apiUrl + '/Report/GetOftamologyStatistics',
      statistics,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        responseType: 'blob',
      },
    );
  }

  getAppointmentsReport(datum: string, doctorId: number): Observable<Blob> {
    return this.http.get(apiUrl + '/Report/getappointmentsreport/', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('DoctorId', doctorId.toString())
        .set('Datum', datum.toString()),
      responseType: 'blob',
    });
  }

  // Used for Statistics page
  getStatisticsAgency(statistics: StatisticsFilter): Observable<Blob> {
    return this.http.post(apiUrl + '/Report/GetStatisticsAgency', statistics, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      responseType: 'blob',
    });
  }

  // Used for Surgeon work plan page
  getAllSurgeonWorkingDays(year: number): Observable<SurgeonWorkPlan[]> {
    return this.http.get<SurgeonWorkPlan[]>(
      apiUrl + '/Appointment/GetAllSurgeonWorkingDays',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('year', year.toString()),
      },
    );
  }

  // Used for Surgeon work plan page
  getAllSurgeonWorkingDaysForDate(date) {
    return this.http.get(
      apiUrl + '/Appointment/GetAllSurgeonWorkingDaysForDate',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('date', date.toString()),
      },
    );
  }

  getallsurgeonsfordate(date) {
    return this.http.get(apiUrl + '/appointment/getallsurgeonsfordate', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('date', date.toString()),
    });
  }

  // Used for Surgeon work plan page
  getAllSurgeons(): Observable<Surgeon[]> {
    return this.http.get<Surgeon[]>(apiUrl + '/Appointment/GetAllSurgeons', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Discharge list
  getAllSurgeonsWithDoctors(): Observable<Surgeon[]> {
    return this.http.get<Surgeon[]>(
      apiUrl + '/Appointment/GetAllSurgeonsWithDoctors',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Surgeon work plan page
  saveSurgeonWorkingDay(data: SurgeonSchedule): Observable<boolean> {
    return this.http.post<boolean>(
      apiUrl + '/Appointment/SaveSurgeonWorkingDay',
      data,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Surgeon work plan page
  deleteSurgeonWorkingDay(data: SurgeonSchedule) {
    return this.http.post(
      apiUrl + '/Appointment/DeleteSurgeonWorkingDay',
      data,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Surgeon work plan page
  getsurgeonworkingdayreport(data: SurgeonSchedule) {
    return this.http.get(apiUrl + '/Report/getsurgeonworkingdayreport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('Id', data.SurgeonId.toString())
        .set('From', data.Start)
        .set('To', data.End),
      responseType: 'blob',
    });
  }

  // Used for Surgeon work plan page
  getdecursusmorbireport(Id: number) {
    return this.http.get(apiUrl + '/Report/getdecursusmorbireport', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('Id', Id.toString()),
      responseType: 'blob',
    });
  }

  // Used for Apartments page
  getallapartments(): Observable<Array<Apartment>> {
    return this.http.get<Array<Apartment>>(
      apiUrl + '/Appointment/getallapartments',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Apartments page
  getallbookingforapartmentsfordate(date): Observable<ApartmentBooking> {
    return this.http.get<ApartmentBooking>(
      apiUrl + '/Appointment/getallbookingforapartmentsfordate',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('Date', date),
      },
    );
  }

  // Used for Apartments page
  savebookingforapartment(data: ApartmentBookingDto) {
    return this.http.post(
      apiUrl + '/Appointment/savebookingforapartment',
      data,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Apartments page
  updateBookingForApartment(data: ApartmentBookingDto) {
    return this.http.put(
      apiUrl + '/Appointment/updatebookingforapartment',
      data,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
      },
    );
  }

  // Used for Apartments page
  deleteapartmentbookingforid(id: number) {
    return this.http.post(
      apiUrl + '/Appointment/deleteapartmentbookingforid',
      null,
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('Id', id.toString()),
      },
    );
  }

  // Used for Apartments page
  getAllBookingForApartmentsForMonth(date: string): Observable<any> {
    return this.http.get(
      apiUrl + '/Appointment/GetAllBookingForApartmentsForMonth',
      {
        headers: new HttpHeaders({
          token: this.authenticationService.getToken(),
        }),
        params: new HttpParams().set('Date', date),
      },
    );
  }

  // Used for Protocols page
  getAllProtocols(): Observable<Array<Protocol>> {
    return this.http.get<Array<Protocol>>(apiUrl + '/Stuff/GetAllProtocols', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Protocols page
  saveProtocolFile(file): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/Stuff/SaveProtocolFile', file, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Protocols page
  getProtocolFileById(Id: number): Observable<Protocol> {
    return this.http.get<Protocol>(apiUrl + '/Stuff/GetProtocolFileById', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('protocolFileId', Id.toString()),
    });
  }

  // Used for Protocols page
  deleteProtocolFile(Id: number): Observable<boolean> {
    return this.http.post<boolean>(apiUrl + '/Stuff/DeleteProtocolFile', null, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('protocolFileId', Id.toString()),
    });
  }

  // Used for Messages page
  saveMessage(message) {
    return this.http.post(apiUrl + '/Stuff/saveusermessage', message, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  // Used for Messages page
  getAllUserMessages(Id: number): Observable<Message[]> {
    return this.http.get<Message[]>(apiUrl + '/Stuff/getallusermessages', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('UserID', Id.toString()),
    });
  }

  // Used for Messages page
  getNumberOfUnreadMessagesForUser(Id: number) {
    return this.http.get(apiUrl + '/Stuff/getnumberofunreadmessagesforuser', {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams().set('UserID', Id.toString()),
    });
  }

  // Used for Messages page
  markMessageAsReadedForUser(Id: number, messageId: number) {
    return this.http.post(apiUrl + '/Stuff/markmessageasreadedforuser', null, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
      params: new HttpParams()
        .set('UserID', Id.toString())
        .set('MessageId', messageId.toString()),
    });
  }
}
