import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from "rxjs";

// Import the Angular Material components
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from "../../../../core/error-handler.service";

import { CreateDiabeticNoteItemDialogComponent } from "./create-diabetic-note-item-dialog/create-diabetic-note-item-dialog.component";

import { DiabeticNote } from "../../../shared/diabetic-note.model";
import { AuthorizationService } from '../../../../core/authorization.service';

@Component({
    selector: 'app-update-diabetic-note-dialog',
    templateUrl: './update-diabetic-note-dialog.component.html',
    styleUrls: ['./update-diabetic-note-dialog.component.css']
})
export class UpdateDiabeticNoteDialogComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    displayedColumns: string[] = ['Date', 'Glycemic', 'Laboratory', 'Therapy', 'Meal', 'Note', 'Nurse', 'Actions'];
    dataSource = new MatTableDataSource<DiabeticNote>([]);

    diabeticNoteEditRole: boolean;
    diabeticNoteCreateRole: boolean;

    constructor(
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dashboardService: DashboardService,
        private errorHandlerService: ErrorHandlerService,
        private authorizationService: AuthorizationService) { }

    ngOnInit() {
        if (this.data && typeof this.data === "object" && this.data.hasOwnProperty("has_diabetic_note") && this.data.has_diabetic_note === true) this.readDiabeticNotes();

        this.authorizationService.readPermissions().subscribe(
            permissions => {
                this.diabeticNoteEditRole = permissions.includes('LISTADIAIZMENA');
                this.diabeticNoteCreateRole = permissions.includes('LISTADIANOVESTAVKE');
            },
            error => {
                console.error("Error fetching permissions:", error);
            }
        );
    }

    onOpenCreateDiabeticNoteItemDialog() {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const dialogRef = this.dialog.open(CreateDiabeticNoteItemDialogComponent, {
                minWidth: "44vw",
                data: {
                    "source_type": this.data.source_type.toLowerCase(),
                    "source_id": this.data.source_id,
                    "update": false
                }
            });

            const createDiabeticNoteItemDialogSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result === true) this.readDiabeticNotes();
            });

            this.subscriptions.add(createDiabeticNoteItemDialogSubscription);
        }
    }

    readDiabeticNotes() {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const readDiabeticNotesByExcimerLaserSubscription = this.dashboardService.readDiabeticNotesByExcimerLaser(this.data.source_id)
                .subscribe(
                    (response: DiabeticNote[]) => {
                        if (response && typeof response === "object" && response.length > 0) this.dataSource = new MatTableDataSource<DiabeticNote>(response);
                    },
                    error => this.errorHandlerService.handleError(error)
                );

            this.subscriptions.add(readDiabeticNotesByExcimerLaserSubscription);
        }
    }

    onEditDiabeticNote(element) {
        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {
            const dialogRef = this.dialog.open(CreateDiabeticNoteItemDialogComponent, {
                minWidth: "44vw",
                data: {
                    "source_type": this.data.source_type.toLowerCase(),
                    "source_id": this.data.source_id,
                    "update": true,
                    "diabeticNote": element
                }
            });

            const createDiabeticNoteItemDialogSubscription = dialogRef.afterClosed().subscribe(result => {
                if (result === true) this.readDiabeticNotes();
            });

            this.subscriptions.add(createDiabeticNoteItemDialogSubscription);
        }
    }

    ngOnDestroy() {
        // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
        this.subscriptions.unsubscribe();
    }
}
