import { AppointmentType } from "./appointment-type.model";

export class Appointment {
    Id: number | null;
    Date: string;
    Time: string;
    LengthMinutes: number | null;
    PatientId: number | null;
    DoctorId: number | null;
    PatientName: string;
    PhoneNumber: string;
    PatientDateOfBirth: string | null;
    NewPatient: boolean | null;
    Anamnesis: string;
    CenaPregleda: number | null;
    AppointmentTypes: AppointmentType[];
    Arrival: string | null;
    AmountPayed: number;
    Note: string;
    DatePayed: string | null;
    TimePayed: string | null;
    PassportNumber: string | null;
    
  constructor(Id: number | null, Date: string, Time: string, LengthMinutes: number | null, PatientId: number | null, DoctorId: number | null, PatientName: string, PhoneNumber: string, PatientDateOfBirth: string | null, NewPatient: boolean | null, Anamnesis: string, CenaPregleda: number | null, AppointmentTypes: AppointmentType[], Arrival: string | null, AmountPayed: number, Note: string, DatePayed: string | null, TimePayed: string | null, PassportNumber: string | null) {
        this.Id = Id;
        this.Date = Date;
        this.Time = Time;
        this.LengthMinutes = LengthMinutes;
        this.PatientId = PatientId;
        this.DoctorId = DoctorId;
        this.PatientName = PatientName;
        this.PhoneNumber = PhoneNumber;
        this.PatientDateOfBirth = PatientDateOfBirth;
        this.NewPatient = NewPatient;
        this.Anamnesis = Anamnesis;
        this.CenaPregleda = CenaPregleda;
        this.AppointmentTypes = AppointmentTypes;
        this.Arrival = Arrival;
        this.AmountPayed = AmountPayed;
        this.Note = Note;
        this.DatePayed = DatePayed;
        this.TimePayed = TimePayed;
        this.PassportNumber = PassportNumber
    }
}
