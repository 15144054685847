import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
    @Input() name: string;
    @Input() message: string;
    @Input() isReceiver: boolean;
    @Input() Id: number;
    @Input() readed: boolean;
    @Input() date: Date;
    @Output() onMessageClick = new EventEmitter<number>();

    constructor() { }

    ngOnInit() {
    }

    onClick() {
        if (!this.readed) {
            this.onMessageClick.emit(this.Id);
        }        
    }

}
