import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DecursusMorbi } from '../../../shared/decursus-morbi.model';

@Component({
  selector: 'app-update-decursus-morbi-dialog',
  templateUrl: './update-decursus-morbi-dialog.component.html',
  styleUrls: ['./update-decursus-morbi-dialog.component.scss']
})
export class UpdateDecursusMorbiDialogComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    decursusMorbiForm: UntypedFormGroup = this.fb.group({
        Id: [null],
        Text: [null, Validators.required],
        LaserExclaimerId: [null]
    });

    constructor(
        private dialogRef: MatDialogRef<UpdateDecursusMorbiDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dashboardService: DashboardService,
        private readonly progressBarService: ProgressBarService,
        private errorHandlerService: ErrorHandlerService,
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit() {
        if (this.data && typeof this.data === 'object' && this.data.hasOwnProperty('laserExclaimerId') && typeof this.data.laserExclaimerId === 'number' && isNaN(this.data.laserExclaimerId) === false) {
            const getDecursusMorbiSubscription = this.dashboardService.getDecursusMorbi(this.data.laserExclaimerId)
                .subscribe(
                    (response: DecursusMorbi) => {
                        if (response) {
                            this.decursusMorbiForm.get('Id').setValue(response.Id);
                            this.decursusMorbiForm.get('Text').setValue(response.Text);
                            this.decursusMorbiForm.get('LaserExclaimerId').setValue(response.LaserExclaimerId);
                        }
                    },
                    error => this.errorHandlerService.handleError(error)
                );
            this.subscriptions.add(getDecursusMorbiSubscription);
        }
    }

    onSave() {
        const onSaveSubscription = this.dashboardService.saveDecursusMorbi(
            new DecursusMorbi(this.decursusMorbiForm.value.Id, this.decursusMorbiForm.value.Text, this.decursusMorbiForm.value.LaserExclaimerId || this.data.laserExclaimerId)
            )
            .subscribe(
                response => this.dialogRef.close(),
                error => this.errorHandlerService.handleError(error)
            );
        this.subscriptions.add(onSaveSubscription);
    }
  onPrint() {
    this.progressBarService.start();
    const getDecursusMorbiSubscription = this.dashboardService.getdecursusmorbireport(this.decursusMorbiForm.value.Id).subscribe(
      (response: Blob) => {
        this.progressBarService.stop();
        if (response && response instanceof Blob && response.size > 0) this.dashboardService.printFile(response);
      }
    );

    this.subscriptions.add(getDecursusMorbiSubscription);
  }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
