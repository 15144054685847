import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DayPilot } from '@daypilot/daypilot-lite-angular';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../dashboard.service';
import { formatDate } from '@angular/common';

@Injectable()
export class DataService {
  static colors = {
    green: '#6aa84f',
    yellow: '#f1c232',
    red: '#cc4125',
    gray: '#808080',
    blue: '#2e78d6',
  };

  events = [
    {
      id: 1,
      text: 'Event 1',
      start: DayPilot.Date.today(),
      end: DayPilot.Date.today().addDays(3),
    },
  ];

  constructor(
    private http: HttpClient,
    private readonly dashboardService: DashboardService,
  ) {}

  getApartmentBookings(from: DayPilot.Date): any {
    const param = formatDate(from.toDate(), 'yyyy-MM-ddThh:mm:ss', 'sr-Latn');
    return this.dashboardService.getAllBookingForApartmentsForMonth(param);
  }

  getEvents(from: DayPilot.Date, to: DayPilot.Date): Observable<any[]> {
    // simulating an HTTP request
    return new Observable((observer) => {
      setTimeout(() => {
        observer.next(this.events);
      }, 200);
    });

    // return this.http.get("/api/events?from=" + from.toString() + "&to=" + to.toString());
  }

  getColors(): any[] {
    const colors = [
      { name: 'Zelena', id: DataService.colors.green },
      { name: 'Žuta', id: DataService.colors.yellow },
      { name: 'Crvena', id: DataService.colors.red },
      { name: 'Siva', id: DataService.colors.gray },
      { name: 'Plava', id: DataService.colors.blue },
    ];
    return colors;
  }
}
