import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DashboardService } from '../../shared/dashboard.service';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { ApartmentBooking } from '../../shared/apartment-booking.model';
import { take } from 'rxjs/operators';
import { AuthorizationService } from '../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-apartment-list',
  templateUrl: './apartment-list.component.html',
  styleUrls: ['./apartment-list.component.scss'],
})
export class ApartmentListComponent implements OnChanges, OnInit {
  @Input() apartment: ApartmentBooking;

  apartmentForm = new UntypedFormGroup({
    ApartmentName: new UntypedFormControl(null),
    Text: new UntypedFormControl(null, Validators.required),
  });

  @Output() readonly onDeleteBookingApartment: EventEmitter<any> =
    new EventEmitter();

  editRole: boolean;
  deleteRole: boolean;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.editRole = permissions.includes('APARTIZMENA');
        this.deleteRole = permissions.includes('APARTBRISANJE');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  ngOnChanges() {
    if (this.apartment) {
      if (this.apartment.ApartmentName)
        this.apartmentForm
          .get('ApartmentName')
          .setValue(this.apartment.ApartmentName);
      if (this.apartment.Text)
        this.apartmentForm.get('Text').setValue(this.apartment.Text);
    }
  }

  onSave() {
    // this.progressBarService.start();
    // const params = new ApartmentBooking(
    //   this.apartment.Id,
    //   this.apartment.ApartmentId,
    //   this.apartment.Date,
    //   this.apartmentForm.get('Text').value,
    //   this.apartmentForm.get('ApartmentName').value,
    // );
    // this.dashboardService
    //   .savebookingforapartment(params)
    //   .pipe(take(1))
    //   .subscribe((response) => {
    //     if (response) {
    //       this.snackbarService.showSuccess('Apartman je uspešno ažuriran.');
    //       this.progressBarService.stop();
    //     }
    //   });
  }

  onDelete() {
    this.progressBarService.start();
    this.dashboardService
      .deleteapartmentbookingforid(this.apartment.Id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.snackbarService.showSuccess('Apartman je uspešno obrisan.');
          this.progressBarService.stop();
          this.onDeleteBookingApartment.emit();
        }
      });
  }
}
