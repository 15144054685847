import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ProgressBarService } from '../../../core/progress-bar.service';
import {
  timeFormat1stRegex,
  timeFormat2ndRegex,
} from '../../../shared/constants.service';

import { BusinessHour } from '../../shared/business-hour.model';
import { WorkingTime } from 'src/app/shared/enum/time-enum';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-day-business-hours-dialog',
  templateUrl: './update-day-business-hours-dialog.component.html',
  styleUrls: ['./update-day-business-hours-dialog.component.css'],
})
export class UpdateDayBusinessHoursDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  workingTimes = Object.keys(WorkingTime).map((key) => ({
    value: WorkingTime[key],
    display: key,
  }));
  workingTimeFrom: WorkingTime;
  workingTimeTo: WorkingTime;

  dayBusinessHours: BusinessHour = null;

  updateDayBusinessHoursForm = new UntypedFormGroup({
    From: new UntypedFormControl(''),
    To: new UntypedFormControl(''),
    DayOff: new UntypedFormControl(false),
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateDayBusinessHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: BusinessHour,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data && typeof this.data === 'object') {
      if (
        this.data.hasOwnProperty('DayOff') &&
        typeof this.data.DayOff === 'boolean'
      ) {
        this.updateDayBusinessHoursForm
          .get('DayOff')
          .setValue(this.data.DayOff);
        this.handleDayOffState();
      }

      this.dayBusinessHours = this.data;
    }
  }

  onReadStartOfBusinessHoursErrorMessage(): string {
    return this.updateDayBusinessHoursForm.get('From').hasError('required')
      ? 'Unesite početak radnog vremena'
      : this.updateDayBusinessHoursForm.get('From').hasError('pattern')
      ? 'Mora biti od 00:00 do 23:59'
      : '';
  }

  onReadEndOfBusinessHoursErrorMessage(): string {
    return this.updateDayBusinessHoursForm.get('To').hasError('required')
      ? 'Unesite kraj radnog vremena'
      : this.updateDayBusinessHoursForm.get('To').hasError('pattern')
      ? 'Mora biti od 00:01 do 23:59'
      : '';
  }

  onChangeDayOffState() {
    if (
      typeof this.updateDayBusinessHoursForm.get('DayOff').value === 'boolean'
    ) {
      this.updateDayBusinessHoursForm
        .get('DayOff')
        .setValue(!this.updateDayBusinessHoursForm.get('DayOff').value);
      this.handleDayOffState();
    }
  }

  onUpdateDayBusinessHours() {
    if (
      this.dayBusinessHours &&
      typeof this.dayBusinessHours === 'object' &&
      this.dayBusinessHours.hasOwnProperty('From') &&
      this.dayBusinessHours.hasOwnProperty('To') &&
      this.dayBusinessHours.hasOwnProperty('DayOff')
    ) {
      if (
        typeof this.updateDayBusinessHoursForm.get('DayOff').value === 'boolean'
      ) {
        if (this.updateDayBusinessHoursForm.get('DayOff').value === true) {
          this.dayBusinessHours.From = null;
          this.dayBusinessHours.To = null;
          this.dayBusinessHours.DayOff = true;
        } else {
          let timeFromHours = parseInt(
            this.updateDayBusinessHoursForm.get('From').value.split(':')[0],
          );
          let timeFromMinutes = parseInt(
            this.updateDayBusinessHoursForm.get('From').value.split(':')[1],
          );

          let timeToHours = parseInt(
            this.updateDayBusinessHoursForm.get('To').value.split(':')[0],
          );
          let timeToMinutes = parseInt(
            this.updateDayBusinessHoursForm.get('To').value.split(':')[1],
          );

          if (
            !(
              typeof timeFromHours === 'number' &&
              isNaN(timeFromHours) === false &&
              typeof timeFromMinutes === 'number' &&
              isNaN(timeFromMinutes) === false &&
              typeof timeToHours === 'number' &&
              isNaN(timeToHours) === false &&
              typeof timeToMinutes === 'number' &&
              isNaN(timeToMinutes) === false &&
              timeFromHours * 60 + timeFromMinutes <
                timeToHours * 60 + timeToMinutes
            )
          ) {
            this.snackbarService.showInfo(
              'Početak radnog vremena mora biti pre kraja radnog vremena.',
            );
            return;
          }

          this.dayBusinessHours.From =
            this.updateDayBusinessHoursForm.get('From').value;
          this.dayBusinessHours.To =
            this.updateDayBusinessHoursForm.get('To').value;
          this.dayBusinessHours.DayOff = false;
        }

        this.progressBarService.start();

        const updateDayBusinessHoursSubscription = this.dashboardService
          .updateDayBusinessHours(this.dayBusinessHours)
          .subscribe(
            (response: boolean) => {
              this.progressBarService.stop();

              if (response === true) {
                this.snackbarService.showSuccess(
                  'Radno vreme je uspešno izmenjeno.',
                );
                this.dialogRef.close(response);
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(updateDayBusinessHoursSubscription);
      }
    }
  }

  handleDayOffState() {
    if (
      typeof this.updateDayBusinessHoursForm.get('DayOff').value === 'boolean'
    ) {
      if (this.updateDayBusinessHoursForm.get('DayOff').value === true) {
        this.updateDayBusinessHoursForm.get('From').clearValidators();
        this.updateDayBusinessHoursForm.get('To').clearValidators();

        this.updateDayBusinessHoursForm.get('From').setValue('');
        this.updateDayBusinessHoursForm.get('To').setValue('');

        this.updateDayBusinessHoursForm.get('From').disable();
        this.updateDayBusinessHoursForm.get('To').disable();
      } else {
        this.updateDayBusinessHoursForm
          .get('From')
          .setValidators([
            Validators.required,
            Validators.pattern(timeFormat1stRegex),
          ]);
        this.updateDayBusinessHoursForm
          .get('To')
          .setValidators([
            Validators.required,
            Validators.pattern(timeFormat2ndRegex),
          ]);

        if (
          this.data.hasOwnProperty('From') &&
          typeof this.data.From === 'string' &&
          this.data.From.length > 0 &&
          RegExp(timeFormat1stRegex).test(this.data.From)
        )
          this.updateDayBusinessHoursForm.get('From').setValue(this.data.From);
        if (
          this.data.hasOwnProperty('To') &&
          typeof this.data.To === 'string' &&
          this.data.To.length > 0 &&
          RegExp(timeFormat2ndRegex).test(this.data.To)
        )
          this.updateDayBusinessHoursForm.get('To').setValue(this.data.To);

        this.updateDayBusinessHoursForm.get('From').enable();
        this.updateDayBusinessHoursForm.get('To').enable();
      }
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
