import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-full-size-comparison',
  templateUrl: './full-size-comparison.component.html',
  styleUrls: ['./full-size-comparison.component.scss']
})
export class FullSizeComparisonComponent implements OnInit {
    baseImageSource: string = "";
    comparisonImageSource: string = "";

    readComparisonImageForm = new UntypedFormGroup({
        SideBySideComparison: new UntypedFormControl(false)
    });

    constructor(@Inject(MAT_DIALOG_DATA) private data: any) { }

    ngOnInit() {
        if (this.data && typeof this.data === "object") {
            if (this.data.hasOwnProperty("base_image") && typeof this.data.base_image === "string" && this.data.base_image.length > 0) {
                this.baseImageSource = this.data.base_image;                
            }

            if (this.data.hasOwnProperty("comparison_image") && typeof this.data.comparison_image === "string" && this.data.comparison_image.length > 0) {
                this.comparisonImageSource = this.data.comparison_image;                
            }
        }
    }

}
