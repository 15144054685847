import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DashboardService } from '../shared/dashboard.service';
import { Observable, of, Subscription } from 'rxjs';
import { Employee } from '../shared/employee.model';
import { startWith, map, take } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Message } from '../shared/message.model';
import { MessagesService } from '../shared/messages.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  messagesForm = new UntypedFormGroup({
    message: new UntypedFormControl(),
  });

  permissions: string[] = [];
  contactFormControl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  contactList: Employee[] = [];
  selectedContacts: Employee[] = [];
  matchedContacts$: Observable<Employee[]>;
  userId: number;
  userMessages: Array<Message> = [];
  editRole: boolean;

  @ViewChild('contactsSearchInput', { static: false })
  contactsSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('contactsAutocomplete', { static: false })
  matAutocomplete: MatAutocomplete;

  constructor(
    public authorizationService: AuthorizationService,
    private readonly dashboardService: DashboardService,
    private readonly snackbarService: SnackbarService,
    private readonly messagesService: MessagesService,
  ) {
    this.matchedContacts$ = this.contactFormControl.valueChanges.pipe(
      startWith(null),
      map((searchTerm: string | null) =>
        typeof searchTerm === 'string' && searchTerm.length > 0
          ? this.filterContactsByName(searchTerm)
          : this.contactList.slice(),
      ),
    );
  }

  ngOnInit() {
    this.readEmployees();
    this.getUserId();
    this.readPermissions();
  }

  getUserId() {
    this.authorizationService.getUserId().subscribe(
      (user) => {
        this.userId = user;
        this.getAllUserMessages();
      },
      (error) => {
        console.error('Error fetching user id:', error);
      },
    );
  }

  readPermissions() {
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('PORUKESLANJE');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  readEmployees() {
    const readEmployeesSubscription = this.dashboardService
      .readEmployees()
      .subscribe((response) => {
        this.contactList = response;
        this.contactFormControl.setValue('');
      });
    this.subscriptions.add(readEmployeesSubscription);
  }

  onDeleteContact(contact) {
    if (this.selectedContacts.includes(contact))
      this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
  }

  onSelectContact(event: MatAutocompleteSelectedEvent) {
    if (this.selectedContacts && typeof this.selectedContacts === 'object') {
      if (this.selectedContacts.length > 0) {
        if (this.selectedContacts.includes(event.option.value)) {
          this.snackbarService.showInfo('Kontakt je već izabrana.');
        } else this.selectedContacts.push(event.option.value);
      } else this.selectedContacts.push(event.option.value);
    }
  }

  sendMessage() {
    const message = this.messagesForm.get('message').value;
    const recepinets = this.selectedContacts.map((item) => item.Id);

    const data = {
      Sender: this.userId,
      Text: message,
      MessageRecepients: recepinets,
    };

    this.messagesForm.reset();

    this.dashboardService.saveMessage(data).subscribe((response) => {
      if (response) {
        this.snackbarService.showSuccess('Poruka je uspešno poslata.');
        this.selectedContacts = [];
        this.getAllUserMessages();
      }
    });
  }

  onMessageClick(Id) {
    this.dashboardService
      .markMessageAsReadedForUser(this.userId, Id)
      .subscribe((response) => {
        if (response) {
          this.userMessages.forEach((item: Message) => {
            if (item.Id === Id) {
              item.Readed = true;

              this.dashboardService
                .getNumberOfUnreadMessagesForUser(this.userId)
                .pipe(take(1))
                .subscribe((response: number) =>
                  this.messagesService.emitNewnumberOfMessagesValue(response),
                );
            }
          });
        }
      });
  }

  private filterContactsByName(searchTerm: string): Employee[] {
    return typeof searchTerm === 'string' && searchTerm.length > 0
      ? this.contactList.filter(
          (currentElement) =>
            currentElement &&
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Name') &&
            typeof currentElement.Name === 'string' &&
            currentElement.Name.length > 0 &&
            currentElement.Name.toLowerCase().includes(
              searchTerm.toLowerCase(),
            ),
        )
      : [];
  }

  private getAllUserMessages() {
    this.dashboardService
      .getAllUserMessages(this.userId)
      .pipe(take(1))
      .subscribe((response) => (this.userMessages = response));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
