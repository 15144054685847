export class ApartmentBookingDto {
  constructor(
    public Id: number,
    public ApartmentId: number,
    public Start: string,
    public End: string,
    public Text: string,
    public ApartmentName: string,
    public Color: string,
  ) {}
}
