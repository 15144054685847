export class Reception {
    Id: number | null;
    LaserExclaimerId: number | null;
    UserId: number | null;
    Description: string;
    VODDistanceSC: string;
    VODDistanceCC: string;
    VODNearSC: string;
    VODNearCC: string;
    VOSDistanceSC: string;
    VOSDistanceCC: string;
    VOSNearSC: string;
    VOSNearCC: string;
    IOPAplanationTOD: string;
    IOPAplanationTOS: string;
    IOPNonContactTOD: string;
    IOPNonContactTOS: string;
    VODVisusDistance: string;
    VODVisusNear: string;
    VOSVisusDistance: string;
    VOSVisusNear: string;
    Advice: string;

    constructor(Id: number | null, LaserExclaimerId: number | null, UserId: number | null, Description: string, VODDistanceSC: string, VODDistanceCC: string, VODNearSC: string, VODNearCC: string, VOSDistanceSC: string, VOSDistanceCC: string, VOSNearSC: string, VOSNearCC: string, IOPAplanationTOD: string, IOPAplanationTOS: string, IOPNonContactTOD: string, IOPNonContactTOS: string, VODVisusDistance: string, VODVisusNear: string, VOSVisusDistance: string, VOSVisusNear: string, Advice: string) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.UserId = UserId;
        this.Description = Description;
        this.VODDistanceSC = VODDistanceSC;
        this.VODDistanceCC = VODDistanceCC;
        this.VODNearSC = VODNearSC;
        this.VODNearCC = VODNearCC;
        this.VOSDistanceSC = VOSDistanceSC;
        this.VOSDistanceCC = VOSDistanceCC;
        this.VOSNearSC = VOSNearSC;
        this.VOSNearCC = VOSNearCC;
        this.IOPAplanationTOD = IOPAplanationTOD;
        this.IOPAplanationTOS = IOPAplanationTOS;
        this.IOPNonContactTOD = IOPNonContactTOD;
        this.IOPNonContactTOS = IOPNonContactTOS;
        this.VODVisusDistance = VODVisusDistance;
        this.VODVisusNear = VODVisusNear;
        this.VOSVisusDistance = VOSVisusDistance;
        this.VOSVisusNear = VOSVisusNear;
        this.Advice = Advice;
    }
}