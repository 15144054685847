import { DiagnosisItem } from './diagnosis-item.model';

export class GeneralExamination {
    Id: number | null;
    ExamChartId: number | null;
    SubDiagnosis: string;
    Advice: string;
    Anamnesis: string;
    VODDistanceSC: string;
    VODDistanceCC: string;
    VODNearSC: string;
    VODNearCC: string;
    VOSDistanceSC: string;
    VOSDistanceCC: string;
    VOSNearSC: string;
    VOSNearCC: string;
    KeratometryOD: string;
    KeratometryOS: string;
    DominantEye: string;
    IOPAplanationTOD: string;
    IOPAplanationTOS: string;
    IOPNonContactTOD: string;
    IOPNonContactTOS: string;
    SpaltOd: string;
    SpaltOs: string;
    VODVisusNear: string;
    VODVisusDistance: string;
    VOSVisusNear: string;
    VOSVisusDistance: string;
    FundusOd: string;
    FundusDo: string;
    Intervention: string;
    Pahimetry: string;
    MedicalDiagnosisId: DiagnosisItem[];
    Diagnoses: DiagnosisItem[];
    KKT: string;
    OctAnt: string;
    OctPost: string;
    OctA: string;
    FA: string;
    FotoFundus: string;
    FotoPrednjegSegmenta: string;
    FAF: string;
    CVP: string;
    EM: string;
    VEP: string;
    ERG: string;
    IOLMaster: string;
    UltrazvukOka: string;
    Verion: string;
    DiagnosticCVPExist: boolean | null;
    DiagnosticEMExist: boolean | null;
    DiagnosticERGExist: boolean | null;
    DiagnosticFAExist: boolean | null;
    DiagnosticFAFExist: boolean | null;
    DiagnosticFotoFundusExist: boolean | null;
    DiagnosticFotoPrednjegSegmentaExist: boolean | null;
    DiagnosticIOLMasterExist: boolean | null;
    DiagnosticKKTExist: boolean | null;
    DiagnosticOCTAExist: boolean | null;
    DiagnosticOCTAUTExist: boolean | null;
    DiagnosticOCTPOSTExist: boolean | null;
    DiagnosticUltrazvukOkaExist: boolean | null;
    DiagnosticVEPExist: boolean | null;
    DiagnosticVerionExist: boolean | null;
    CycloRODDistanceCC: string;
    CycloRODDistanceSC: string;
    CycloRODNearCC: string;
    CycloRODVisusDistance: string;
    CycloRODVisusNear: string;
    CycloROSDistanceCC: string;
    CycloROSDistanceSC: string;
    CycloROSNearCC: string;
    CycloROSVisusDistance: string;
    CycloROSVisusNear: string;
  FreeText: string;
  VOU: string;
  SN: string;

    constructor(
        Id: number | null,
        ExamChartId: number | null,
        SubDiagnosis: string,
        Advice: string,
        Anamnesis: string,
        VODDistanceSC: string,
        VODDistanceCC: string,
        VODNearSC: string,
        VODNearCC: string,
        VOSDistanceSC: string,
        VOSDistanceCC: string,
        VOSNearSC: string,
        VOSNearCC: string,
        KeratometryOD: string,
        KeratometryOS: string,
        DominantEye: string,
        IOPAplanationTOD: string,
        IOPAplanationTOS: string,
        IOPNonContactTOD: string,
        IOPNonContactTOS: string,
        SpaltOd: string,
        SpaltOs: string,
        VODVisusNear: string,
        VODVisusDistance: string,
        VOSVisusNear: string,
        VOSVisusDistance: string,
        FundusOd: string,
        FundusDo: string,
        Intervention: string,
        Pahimetry: string,
        MedicalDiagnosisId: DiagnosisItem[],
        Diagnoses: DiagnosisItem[],
        KKT: string,
        OctAnt: string,
        OctPost: string,
        OctA: string,
        FotoFundus: string,
        FAF: string,
        CVP: string,
        EM: string,
        VEP: string,
        ERG: string,
        IOLMaster: string,
        UltrazvukOka: string,
        FA: string,       
        FotoPrednjegSegmenta: string,       
        Verion: string,
        DiagnosticCVPExist: boolean | null,
        DiagnosticEMExist: boolean | null,
        DiagnosticERGExist: boolean | null,
        DiagnosticFAExist: boolean | null,
        DiagnosticFAFExist: boolean | null,
        DiagnosticFotoFundusExist: boolean | null,
        DiagnosticFotoPrednjegSegmentaExist: boolean | null,
        DiagnosticIOLMasterExist: boolean | null,
        DiagnosticKKTExist: boolean | null,
        DiagnosticOCTAExist: boolean | null,
        DiagnosticOCTAUTExist: boolean | null,
        DiagnosticOCTPOSTExist: boolean | null,
        DiagnosticUltrazvukOkaExist: boolean | null,
        DiagnosticVEPExist: boolean | null,
        DiagnosticVerionExist: boolean | null,
        CycloRODDistanceCC: string,
        CycloRODDistanceSC: string,
        CycloRODNearCC: string,
        CycloRODVisusDistance: string,
        CycloRODVisusNear: string,
        CycloROSDistanceCC: string,
        CycloROSDistanceSC: string,
        CycloROSNearCC: string,
        CycloROSVisusDistance: string,
        CycloROSVisusNear: string,
      FreeText: string,
      VOU: string,
      SN: string
    ) {
            this.Id = Id;
            this.ExamChartId = ExamChartId;
            this.SubDiagnosis = SubDiagnosis;
            this.Advice = Advice;
            this.Anamnesis = Anamnesis;
            this.VODDistanceSC = VODDistanceSC;
            this.VODDistanceCC = VODDistanceCC;
            this.VODNearSC = VODNearSC;
            this.VODNearCC = VODNearCC;
            this.VOSDistanceSC = VOSDistanceSC;
            this.VOSDistanceCC = VOSDistanceCC;
            this.VOSNearSC = VOSNearSC;
            this.VOSNearCC = VOSNearCC;
            this.KeratometryOD = KeratometryOD;
            this.KeratometryOS = KeratometryOS;
            this.DominantEye = DominantEye;
            this.IOPAplanationTOD = IOPAplanationTOD;
            this.IOPAplanationTOS = IOPAplanationTOS;
            this.IOPNonContactTOD = IOPNonContactTOD;
            this.IOPNonContactTOS = IOPNonContactTOS;
            this.SpaltOd = SpaltOd;
            this.SpaltOs = SpaltOs;
            this.VODVisusNear = VODVisusNear;
            this.VODVisusDistance = VODVisusDistance;
            this.VOSVisusNear = VOSVisusNear;
            this.VOSVisusDistance = VOSVisusDistance;
            this.FundusOd = FundusOd;
            this.FundusDo = FundusDo;
            this.Intervention = Intervention;
            this.Pahimetry = Pahimetry;
            this.MedicalDiagnosisId = MedicalDiagnosisId;
            this.Diagnoses = Diagnoses;
            this.KKT = KKT;
            this.OctAnt = OctAnt;
            this.OctPost = OctPost;
            this.OctA = OctA;
            this.FotoFundus = FotoFundus;
            this.FAF = FAF;
            this.CVP = CVP;
            this.EM = EM;
            this.VEP = VEP;
            this.ERG = ERG;
            this.IOLMaster = IOLMaster;
            this.UltrazvukOka = UltrazvukOka;
            this.FA = FA;            
            this.FotoPrednjegSegmenta = FotoPrednjegSegmenta;
            this.Verion = Verion;
            this.DiagnosticCVPExist = DiagnosticCVPExist;
            this.DiagnosticEMExist = DiagnosticEMExist;
            this.DiagnosticERGExist = DiagnosticERGExist;
            this.DiagnosticFAExist = DiagnosticFAExist;
            this.DiagnosticFAFExist = DiagnosticFAFExist;
            this.DiagnosticFotoFundusExist = DiagnosticFotoFundusExist;
            this.DiagnosticFotoPrednjegSegmentaExist = DiagnosticFotoPrednjegSegmentaExist;
            this.DiagnosticIOLMasterExist = DiagnosticIOLMasterExist;
            this.DiagnosticKKTExist = DiagnosticKKTExist;
            this.DiagnosticOCTAExist = DiagnosticOCTAExist;
            this.DiagnosticOCTAUTExist = DiagnosticOCTAUTExist;
            this.DiagnosticOCTPOSTExist = DiagnosticOCTPOSTExist;
            this.DiagnosticUltrazvukOkaExist = DiagnosticUltrazvukOkaExist;
            this.DiagnosticVEPExist = DiagnosticVEPExist;
            this.DiagnosticVerionExist = DiagnosticVerionExist;
            this.CycloRODDistanceCC = CycloRODDistanceCC;
            this.CycloRODDistanceSC = CycloRODDistanceSC;
            this.CycloRODNearCC = CycloRODNearCC;
            this.CycloRODVisusDistance = CycloRODVisusDistance;
            this.CycloRODVisusNear = CycloRODVisusNear;
            this.CycloROSDistanceCC = CycloROSDistanceCC;
            this.CycloROSDistanceSC = CycloROSDistanceSC,
            this.CycloROSNearCC = CycloROSNearCC;
            this.CycloROSVisusDistance = CycloROSVisusDistance;
            this.CycloROSVisusNear = CycloROSVisusNear;
      this.FreeText = FreeText;
      this.VOU = VOU;
      this.SN = SN;
    }
}                                        
