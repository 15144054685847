import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

// Import the Angular Material components
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';

import { RecipePreviewDialogComponent } from './recipe-preview-dialog/recipe-preview-dialog.component';

import { ReadRecipesResponse } from '../shared/read-recipes-response.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css'],
})
export class RecipesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  readRecipesForm = new UntypedFormGroup({
    Date: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
  });

  permissions: string[] = [];
  displayedColumns: string[] = ['Id', 'PatientName', 'Date', 'Actions'];
  dataSource = new MatTableDataSource<ReadRecipesResponse>([]);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
  }

  onChangeDate(date: Date) {
    if (date && date instanceof Date) {
      this.readRecipes(date);
    }
  }

  onOpenRecipePreviewDialog(recipe: ReadRecipesResponse) {
    if (
      recipe &&
      typeof recipe === 'object' &&
      recipe.hasOwnProperty('Id') &&
      typeof recipe.Id === 'number' &&
      isNaN(recipe.Id) === false
    ) {
      this.dialog.open(RecipePreviewDialogComponent, {
        minWidth: '60vw',
        data: recipe,
      });
    }
  }

  onDeleteRecipe(id: number) {
    this.progressBarService.start();

    const deleteRecipe = this.dashboardService.deleteRecipe(id).subscribe(
      (response) => {
        if (response) {
          this.progressBarService.stop();

          this.snackbarService.showSuccess('Recept je uspešno obrisan.');

          this.readRecipes(this.readRecipesForm.get('Date').value);
        }
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(deleteRecipe);
  }

  readRecipes(date) {
    this.dataSource = new MatTableDataSource<ReadRecipesResponse>([]);

    this.progressBarService.start();

    const readRecipesSubscription = this.dashboardService
      .readRecipes(date)
      .subscribe(
        (response: ReadRecipesResponse[]) => {
          this.progressBarService.stop();

          if (response && typeof response === 'object' && response.length > 0) {
            this.dataSource = new MatTableDataSource(response);
            this.dataSource.paginator = this.paginator;
          } else {
            this.snackbarService.showInfo(
              'Ne postoje dostupni recepti za izabrani Datum.',
            );
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readRecipesSubscription);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
