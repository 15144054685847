import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '../../../shared/dashboard.service';
import { Subscription } from 'rxjs';
import { SpecialistFinding } from '../../../shared/specialist-finding.model';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { SpecialistFindingType } from '../../../shared/specialist-finding-type.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { formatDate } from '@angular/common';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-specialist-findings-from-arhive-dialog',
  templateUrl: './specialist-findings-from-arhive-dialog.component.html',
  styleUrls: ['./specialist-findings-from-arhive-dialog.component.scss'],
})
export class SpecialistFindingsFromArhiveDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  specialistFindingTypesCollection: SpecialistFindingType[] = [];

  createSpecialistFindingForm = new UntypedFormGroup({
    SpecialistExamTypeId: new UntypedFormControl(null),
  });

  displayedColumns: string[] = ['SpecialistExamType', 'Date', 'Actions'];
  dataSource = new MatTableDataSource<SpecialistFinding>([]);

  constructor(
    private dialogRef: MatDialogRef<SpecialistFindingsFromArhiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    const readSpecialistFindingTypesSubscription = this.dashboardService
      .readSpecialistFindingTypes()
      .subscribe(
        (response: SpecialistFindingType[]) => {
          if (response.length > 0)
            this.specialistFindingTypesCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readSpecialistFindingTypesSubscription);

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('specialistExamTypeId') &&
      typeof this.data.specialistExamTypeId === 'number' &&
      isNaN(this.data.specialistExamTypeId) === false &&
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('patientId') &&
      typeof this.data.patientId === 'number' &&
      isNaN(this.data.patientId) === false
    ) {
      this.readSpecialistFindingsFromArchive(
        this.data.specialistExamTypeId,
        this.data.patientId,
      );
    }
  }

  readSpecialistFindingsFromArchive(
    specialistExamTypeId: number,
    patientId: number,
  ): void {
    const readSpecialistFindingsFromArchiveSubscription = this.dashboardService
      .getSpecialistFindingsForPatientAndExamType(
        specialistExamTypeId,
        patientId,
      )
      .subscribe(
        (response: SpecialistFinding[]) => {
          if (response && typeof response === 'object' && response.length > 0)
            this.dataSource = new MatTableDataSource<SpecialistFinding>(
              response,
            );
        },
        (error) => this.errorHandlerService.handleError(error),
      );
    this.subscriptions.add(readSpecialistFindingsFromArchiveSubscription);
  }

  onReadNameOfSpecialistFindingType(typeId: number): string {
    let name = '';

    if (
      this.specialistFindingTypesCollection &&
      typeof this.specialistFindingTypesCollection === 'object' &&
      this.specialistFindingTypesCollection.length > 0 &&
      typeof typeId === 'number' &&
      isNaN(typeId) === false
    ) {
      let filteredSpecialistFindingTypes: SpecialistFindingType[] =
        this.specialistFindingTypesCollection.filter(
          (currentElement) =>
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Id') &&
            currentElement.Id === typeId,
        );
      if (
        filteredSpecialistFindingTypes &&
        typeof filteredSpecialistFindingTypes === 'object' &&
        filteredSpecialistFindingTypes.length > 0 &&
        filteredSpecialistFindingTypes[0] &&
        typeof filteredSpecialistFindingTypes[0] === 'object' &&
        filteredSpecialistFindingTypes[0].hasOwnProperty('Name') &&
        typeof filteredSpecialistFindingTypes[0].Name === 'string' &&
        filteredSpecialistFindingTypes[0].Name.length > 0
      )
        name = filteredSpecialistFindingTypes[0].Name;
    }

    return name;
  }

  onSelectionChange() {
    if (
      this.createSpecialistFindingForm.get('SpecialistExamTypeId').value &&
      typeof this.createSpecialistFindingForm.get('SpecialistExamTypeId')
        .value === 'number' &&
      isNaN(
        this.createSpecialistFindingForm.get('SpecialistExamTypeId').value,
      ) === false &&
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('patientId') &&
      typeof this.data.patientId === 'number' &&
      isNaN(this.data.patientId) === false
    ) {
      this.readSpecialistFindingsFromArchive(
        this.createSpecialistFindingForm.get('SpecialistExamTypeId').value,
        this.data.patientId,
      );
    }
  }

  onFileUpdate(specialistFinding: SpecialistFinding) {
    if (
      specialistFinding &&
      typeof specialistFinding === 'object' &&
      specialistFinding.hasOwnProperty('Id') &&
      typeof specialistFinding.Id === 'number' &&
      isNaN(specialistFinding.Id) === false
    ) {
      this.progressBarService.start();

      const readSpecialistFindingSubscription = this.dashboardService
        .readSpecialistFinding(specialistFinding.Id)
        .subscribe(
          (response: SpecialistFinding) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              // Convert base64 to File
              const byteString = window.atob(response.FileStream);
              const arrayBuffer = new ArrayBuffer(byteString.length);
              const int8Array = new Uint8Array(arrayBuffer);
              for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
              }

              const blob = new Blob([int8Array], {
                type: response.UploadedFileType,
              });
              //

              let requestData = new FormData();

              requestData.append(
                'LaserExclaimerId',
                response.LaserExclaimerId.toString(),
              );
              requestData.append(
                'SpecialistExamTypeId',
                response.SpecialistExamTypeId.toString(),
              );
              requestData.append(
                'Date',
                formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
              );
              requestData.append('Description', '');
              requestData.append('_file', blob);
              requestData.append(
                'UploadedFileType',
                response.UploadedFileType.toLowerCase(),
              );
              requestData.append('OriginallyUploaded', 'false');

              const uploadSpecialistFindingFileSubscription =
                this.dashboardService
                  .uploadSpecialistFindingFile(requestData)
                  .subscribe(
                    (response: boolean) => {
                      this.progressBarService.stop();

                      if (response === true) {
                        this.snackbarService.showSuccess(
                          'Specijalistički nalaz je uspešno kopiran.',
                        );
                        this.dialogRef.close();
                      }
                    },
                    (error) => this.errorHandlerService.handleError(error),
                  );

              this.subscriptions.add(uploadSpecialistFindingFileSubscription);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readSpecialistFindingSubscription);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
