import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Import the Angular Material components
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../../../core/authorization.service';
import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';

import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';

import { LaboratoryType } from '../../../shared/laboratory-type.model';
import { Laboratory } from '../../../shared/laboratory.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-laboratory-dialog',
  templateUrl: './update-laboratory-dialog.component.html',
  styleUrls: ['./update-laboratory-dialog.component.css'],
})
export class UpdateLaboratoryDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  laboratoryTypesCollection: LaboratoryType[] = [];

  createLaboratoryForm = new UntypedFormGroup({
    LaboratoryTypeId: new UntypedFormControl(null, Validators.required),
  });

  displayedColumns: string[] = ['LaboratoryType', 'Date', 'Actions'];
  dataSource = new MatTableDataSource<Laboratory>([]);

  laboratoryEditRole: boolean;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    const readLaboratoryTypesSubscription = this.dashboardService
      .readLaboratoryTypes()
      .subscribe(
        (response: LaboratoryType[]) => {
          if (response.length > 0) this.laboratoryTypesCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readLaboratoryTypesSubscription);

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('has_laboratory') &&
      this.data.has_laboratory === true
    )
      this.readLaboratory();

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.laboratoryEditRole = permissions.includes('LABIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  onReadNameOfLaboratoryType(typeId: number): string {
    let name = '';

    if (
      this.laboratoryTypesCollection &&
      typeof this.laboratoryTypesCollection === 'object' &&
      this.laboratoryTypesCollection.length > 0 &&
      typeof typeId === 'number' &&
      isNaN(typeId) === false
    ) {
      let filteredLaboratoryTypes: LaboratoryType[] =
        this.laboratoryTypesCollection.filter(
          (currentElement) =>
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Id') &&
            currentElement.Id === typeId,
        );
      if (
        filteredLaboratoryTypes &&
        typeof filteredLaboratoryTypes === 'object' &&
        filteredLaboratoryTypes.length > 0 &&
        filteredLaboratoryTypes[0] &&
        typeof filteredLaboratoryTypes[0] === 'object' &&
        filteredLaboratoryTypes[0].hasOwnProperty('Name') &&
        typeof filteredLaboratoryTypes[0].Name === 'string' &&
        filteredLaboratoryTypes[0].Name.length > 0
      )
        name = filteredLaboratoryTypes[0].Name;
    }

    return name;
  }

  onChangeLaboratoryFileSelection(filesCollection: FileList) {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0 &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false &&
        this.createLaboratoryForm.get('LaboratoryTypeId').value &&
        typeof this.createLaboratoryForm.get('LaboratoryTypeId').value ===
          'number' &&
        isNaN(this.createLaboratoryForm.get('LaboratoryTypeId').value) === false
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf' ||
          filesCollection[0].type.toLowerCase().includes('document') === true
        ) {
          let requestData = new FormData();

          requestData.append(
            'LaserExclaimerId',
            this.data.source_id.toString(),
          );
          requestData.append(
            'LaboratoryTypeId',
            this.createLaboratoryForm.get('LaboratoryTypeId').value.toString(),
          );
          requestData.append(
            'Date',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );

          this.authorizationService
            .getUserId()
            .pipe(take(1))
            .subscribe((userId) => {
              requestData.append('UserId', userId.toString());
              requestData.append('_file', filesCollection[0]);
              requestData.append(
                'UploadedFileType',
                filesCollection[0].type.toLowerCase(),
              );

              this.progressBarService.start();

              const uploadLaboratoryFileSubscription = this.dashboardService
                .uploadLaboratoryFile(requestData)
                .subscribe(
                  (response: boolean) => {
                    this.progressBarService.stop();

                    if (response === true) {
                      this.snackbarService.showSuccess(
                        'Laboratorijski nalaz je uspešno dodat.',
                      );

                      this.readLaboratory();
                    }
                  },
                  (error) => this.errorHandlerService.handleError(error),
                );

              this.subscriptions.add(uploadLaboratoryFileSubscription);
            });
        } else {
          this.snackbarService.showInfo(
            'Izabrani fajl mora biti slika ili PDF dokument.',
          );
        }
      }
    }
  }

  onOpenLaboratoryPreviewDialog(laboratory: Laboratory) {
    if (
      laboratory &&
      typeof laboratory === 'object' &&
      laboratory.hasOwnProperty('Id') &&
      typeof laboratory.Id === 'number' &&
      isNaN(laboratory.Id) === false
    ) {
      this.progressBarService.start();

      const readLaboratorySubscription = this.dashboardService
        .readLaboratory(laboratory.Id)
        .subscribe(
          (response: Laboratory) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('UploadedFileType') &&
                typeof response.UploadedFileType === 'string' &&
                response.UploadedFileType.length > 0 &&
                (response.UploadedFileType.toLowerCase().includes('image/') ===
                  true ||
                  response.UploadedFileType.toLowerCase() === 'application/pdf')
              ) {
                if (
                  response.hasOwnProperty('LaboratoryFinding') &&
                  typeof response.LaboratoryFinding === 'string' &&
                  response.LaboratoryFinding.length > 0
                ) {
                  this.dialog.open(FilePreviewDialogComponent, {
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    data: {
                      file_type: response.UploadedFileType.toLowerCase(),
                      file_content: response.LaboratoryFinding,
                    },
                  });
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoji dostupan sadržaj izabranog laboratorijskog nalaza.',
                  );
                }
              } else if (
                response.UploadedFileType.toLowerCase().includes('document') ===
                true
              ) {
                function base64ToArrayBuffer(base64) {
                  var binaryString = window.atob(base64);
                  var binaryLen = binaryString.length;
                  var bytes = new Uint8Array(binaryLen);
                  for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                  }
                  return bytes;
                }
                const data = new Blob(
                  [base64ToArrayBuffer(response.LaboratoryFinding)],
                  { type: response.UploadedFileType },
                );
                let hyperlinkElement: HTMLElement = document.createElement('A');
                hyperlinkElement.setAttribute(
                  'href',
                  URL.createObjectURL(data),
                );
                hyperlinkElement.setAttribute('download', '');
                hyperlinkElement.click();
              } else {
                this.snackbarService.showInfo(
                  'Izabrani laboratorijski nalaz mora biti slika ili PDF dokument.',
                );
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readLaboratorySubscription);
    }
  }

  readLaboratory() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      const readLaboratoryByExcimerLaserSubscription = this.dashboardService
        .readLaboratoryByExcimerLaser(this.data.source_id)
        .subscribe(
          (response: Laboratory[]) => {
            if (response && typeof response === 'object' && response.length > 0)
              this.dataSource = new MatTableDataSource<Laboratory>(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readLaboratoryByExcimerLaserSubscription);
    }
  }

  onDeleteLaboratory(id: number) {
    this.progressBarService.start();

    const deleteLaboratory = this.dashboardService
      .deleteLaboratory(id)
      .subscribe(
        (response) => {
          if (response) {
            this.progressBarService.stop();
            this.snackbarService.showSuccess(
              'Laboratorijski nalaz je uspešno obrisan.',
            );
            this.readLaboratory();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(deleteLaboratory);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
