import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../../../shared/constants.service';

import { Gender } from '../../../shared/gender.model';
import { Employee } from '../../../shared/employee.model';
import { Hospitalization } from '../../../shared/hospitalization.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-hospitalization-dialog',
  templateUrl: './update-hospitalization-dialog.component.html',
  styleUrls: ['./update-hospitalization-dialog.component.css'],
})
export class UpdateHospitalizationDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  hospitalizationId: number | null = null;

  maxBirthDate = new Date();
  gendersCollection: Gender[] = [];
  doctorsCollection: Employee[] = [];

  // Disabled form input field that does not belong to the model and it is always calculated as number of days between AcceptanceDate and DateOfDischarge. Default (minimum) value is 1.
  numberOfHospitalizationDays: number = 1;

  updateHospitalizationForm = new UntypedFormGroup({
    IdentificationNumber: new UntypedFormControl(''),
    AcceptanceDate: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    GenderId: new UntypedFormControl(null),
    DateOfBirth: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    Address: new UntypedFormControl(''),
    CompanyAndJobAddress: new UntypedFormControl(''),
    HealthInsuranceCategory: new UntypedFormControl(
      '',
      Validators.pattern('^(0[1-9]|1[0-7])$'),
    ),
    Occupation: new UntypedFormControl(''),
    ExternalCauseOfInjury: new UntypedFormControl(''),
    CauseOfDeath: new UntypedFormControl(''),
    OtherDiseaseOrComplications: new UntypedFormControl(''),
    DateOfDischarge: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    TypeOfSurgery: new UntypedFormControl(''),
    DateOfSurgery: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    SurgeryResult: new UntypedFormControl(''),
    DoctorId: new UntypedFormControl(null),
    InjuryAtWork: new UntypedFormControl(false),
    DateOfInjury: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    TimeOfInjury: new UntypedFormControl(
      {
        value: '',
        disabled: true,
      },
      Validators.pattern(timeFormat1stRegex),
    ),
    Rehabilitation: new UntypedFormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateHospitalizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    const forkJoinSubscription = forkJoin([
      this.dashboardService.readGenders(),
      this.dashboardService.readDoctors(),
    ]).subscribe(
      ([response1st, response2nd]) => {
        if (
          response1st &&
          typeof response1st === 'object' &&
          response1st.length > 0
        )
          this.gendersCollection = response1st;
        if (
          response2nd &&
          typeof response2nd === 'object' &&
          response2nd.length > 0
        )
          this.doctorsCollection = response2nd;
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(forkJoinSubscription);

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false &&
      this.data.hasOwnProperty('has_hospitalization') &&
      this.data.has_hospitalization === true
    ) {
      const readHospitalizationByExcimerLaserSubscription =
        this.dashboardService
          .readHospitalizationByExcimerLaser(this.data.source_id)
          .subscribe(
            (response: Hospitalization) => {
              if (response && typeof response === 'object') {
                if (
                  response.hasOwnProperty('Id') &&
                  typeof response.Id === 'number' &&
                  isNaN(response.Id) === false
                )
                  this.hospitalizationId = response.Id;
                if (
                  response.hasOwnProperty('IdentificationNumber') &&
                  typeof response.IdentificationNumber === 'string' &&
                  response.IdentificationNumber.length > 0
                )
                  this.updateHospitalizationForm
                    .get('IdentificationNumber')
                    .setValue(response.IdentificationNumber);
                if (
                  response.hasOwnProperty('AcceptanceDate') &&
                  typeof response.AcceptanceDate === 'string' &&
                  response.AcceptanceDate.length > 0
                )
                  this.updateHospitalizationForm
                    .get('AcceptanceDate')
                    .setValue(new Date(response.AcceptanceDate));
                if (
                  response.hasOwnProperty('GenderId') &&
                  typeof response.GenderId === 'number' &&
                  isNaN(response.GenderId) === false
                )
                  this.updateHospitalizationForm
                    .get('GenderId')
                    .setValue(response.GenderId);
                if (
                  response.hasOwnProperty('DateOfBirth') &&
                  typeof response.DateOfBirth === 'string' &&
                  response.DateOfBirth.length > 0
                )
                  this.updateHospitalizationForm
                    .get('DateOfBirth')
                    .setValue(new Date(response.DateOfBirth));
                if (
                  response.hasOwnProperty('Address') &&
                  typeof response.Address === 'string' &&
                  response.Address.length > 0
                )
                  this.updateHospitalizationForm
                    .get('Address')
                    .setValue(response.Address);
                if (
                  response.hasOwnProperty('CompanyAndJobAddress') &&
                  typeof response.CompanyAndJobAddress === 'string' &&
                  response.CompanyAndJobAddress.length > 0
                )
                  this.updateHospitalizationForm
                    .get('CompanyAndJobAddress')
                    .setValue(response.CompanyAndJobAddress);
                if (
                  response.hasOwnProperty('HealthInsuranceCategory') &&
                  typeof response.HealthInsuranceCategory === 'string' &&
                  response.HealthInsuranceCategory.length > 0
                )
                  this.updateHospitalizationForm
                    .get('HealthInsuranceCategory')
                    .setValue(response.HealthInsuranceCategory);
                if (
                  response.hasOwnProperty('Occupation') &&
                  typeof response.Occupation === 'string' &&
                  response.Occupation.length > 0
                )
                  this.updateHospitalizationForm
                    .get('Occupation')
                    .setValue(response.Occupation);
                if (
                  response.hasOwnProperty('ExternalCauseOfInjury') &&
                  typeof response.ExternalCauseOfInjury === 'string' &&
                  response.ExternalCauseOfInjury.length > 0
                )
                  this.updateHospitalizationForm
                    .get('ExternalCauseOfInjury')
                    .setValue(response.ExternalCauseOfInjury);
                if (
                  response.hasOwnProperty('CauseOfDeath') &&
                  typeof response.CauseOfDeath === 'string' &&
                  response.CauseOfDeath.length > 0
                )
                  this.updateHospitalizationForm
                    .get('CauseOfDeath')
                    .setValue(response.CauseOfDeath);
                if (
                  response.hasOwnProperty('OtherDiseaseOrComplications') &&
                  typeof response.OtherDiseaseOrComplications === 'string' &&
                  response.OtherDiseaseOrComplications.length > 0
                )
                  this.updateHospitalizationForm
                    .get('OtherDiseaseOrComplications')
                    .setValue(response.OtherDiseaseOrComplications);
                if (
                  response.hasOwnProperty('DateOfDischarge') &&
                  typeof response.DateOfDischarge === 'string' &&
                  response.DateOfDischarge.length > 0
                )
                  this.updateHospitalizationForm
                    .get('DateOfDischarge')
                    .setValue(new Date(response.DateOfDischarge));
                if (
                  response.hasOwnProperty('TypeOfSurgery') &&
                  typeof response.TypeOfSurgery === 'string' &&
                  response.TypeOfSurgery.length > 0
                )
                  this.updateHospitalizationForm
                    .get('TypeOfSurgery')
                    .setValue(response.TypeOfSurgery);
                if (
                  response.hasOwnProperty('DateOfSurgery') &&
                  typeof response.DateOfSurgery === 'string' &&
                  response.DateOfSurgery.length > 0
                )
                  this.updateHospitalizationForm
                    .get('DateOfSurgery')
                    .setValue(new Date(response.DateOfSurgery));
                if (
                  response.hasOwnProperty('SurgeryResult') &&
                  typeof response.SurgeryResult === 'string' &&
                  response.SurgeryResult.length > 0
                )
                  this.updateHospitalizationForm
                    .get('SurgeryResult')
                    .setValue(response.SurgeryResult);
                if (
                  response.hasOwnProperty('DoctorId') &&
                  typeof response.DoctorId === 'number' &&
                  isNaN(response.DoctorId) === false
                )
                  this.updateHospitalizationForm
                    .get('DoctorId')
                    .setValue(response.DoctorId);
                if (
                  response.hasOwnProperty('InjuryAtWork') &&
                  typeof response.InjuryAtWork === 'boolean'
                )
                  this.updateHospitalizationForm
                    .get('InjuryAtWork')
                    .setValue(response.InjuryAtWork);
                if (
                  response.hasOwnProperty('DateAndTimeOfInjury') &&
                  typeof response.DateAndTimeOfInjury === 'string' &&
                  response.DateAndTimeOfInjury.length > 0
                ) {
                  this.updateHospitalizationForm
                    .get('DateOfInjury')
                    .setValue(new Date(response.DateAndTimeOfInjury));
                  this.updateHospitalizationForm
                    .get('TimeOfInjury')
                    .setValue(
                      formatDate(
                        new Date(response.DateAndTimeOfInjury),
                        'HH:mm',
                        'sr-Latn',
                      ),
                    );
                  this.onChangeInjuryDate();
                }
                if (
                  response.hasOwnProperty('Rehabilitation') &&
                  typeof response.Rehabilitation === 'string' &&
                  response.Rehabilitation.length > 0
                )
                  this.updateHospitalizationForm
                    .get('Rehabilitation')
                    .setValue(response.Rehabilitation);

                if (
                  this.updateHospitalizationForm.get('AcceptanceDate').value &&
                  this.updateHospitalizationForm.get('AcceptanceDate')
                    .value instanceof Date &&
                  this.updateHospitalizationForm.get('DateOfDischarge').value &&
                  this.updateHospitalizationForm.get('DateOfDischarge')
                    .value instanceof Date
                )
                  this.onChangeReceptionOrCheckoutDate(
                    this.updateHospitalizationForm.get('AcceptanceDate').value,
                    this.updateHospitalizationForm.get('DateOfDischarge').value,
                  );
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

      this.subscriptions.add(readHospitalizationByExcimerLaserSubscription);
    }
  }

  onChangeReceptionOrCheckoutDate(receptionDate: Date, checkoutDate: Date) {
    if (
      receptionDate &&
      receptionDate instanceof Date &&
      checkoutDate &&
      checkoutDate instanceof Date
    ) {
      if (
        typeof moment(checkoutDate).diff(moment(receptionDate), 'days') ===
          'number' &&
        isNaN(moment(checkoutDate).diff(moment(receptionDate), 'days')) ===
          false &&
        moment(checkoutDate).diff(moment(receptionDate), 'days') >= 1
      )
        this.numberOfHospitalizationDays = moment(checkoutDate).diff(
          moment(receptionDate),
          'days',
        );
      else this.numberOfHospitalizationDays = 1;
    }
  }

  onChangeInjuryAtWorkState() {
    if (
      typeof this.updateHospitalizationForm.get('InjuryAtWork').value ===
      'boolean'
    )
      this.updateHospitalizationForm
        .get('InjuryAtWork')
        .setValue(!this.updateHospitalizationForm.get('InjuryAtWork').value);
  }

  onChangeInjuryDate() {
    // Make sure that TimeOfInjury is cleared and disabled if DateOfInjury is not selected
    if (
      !(
        this.updateHospitalizationForm.get('DateOfInjury').value &&
        this.updateHospitalizationForm.get('DateOfInjury').value instanceof Date
      )
    ) {
      this.updateHospitalizationForm.get('TimeOfInjury').setValue('');
      this.updateHospitalizationForm.get('TimeOfInjury').disable();
    } else this.updateHospitalizationForm.get('TimeOfInjury').enable();
  }

  onReadHospitalizationExcel(excelType: string) {
    if (
      typeof this.hospitalizationId === 'number' &&
      isNaN(this.hospitalizationId) === false &&
      typeof excelType === 'string' &&
      excelType.length > 0 &&
      (excelType.toLowerCase() === 'disease_history' ||
        excelType.toLowerCase() === 'hospitalization_report' ||
        excelType.toLowerCase() === 'treatment_invoice' ||
        excelType.toLowerCase() === 'master_file')
    ) {
      switch (excelType.toLowerCase()) {
        case 'disease_history':
          {
            this.progressBarService.start();

            const readDiseaseHistoryExcelSubscription = this.dashboardService
              .readDiseaseHistoryExcel(this.hospitalizationId)
              .subscribe(
                (response: Blob) => {
                  this.progressBarService.stop();

                  if (
                    response &&
                    response instanceof Blob &&
                    response.size > 0
                  ) {
                    let hyperlinkElement: HTMLElement =
                      document.createElement('A');
                    hyperlinkElement.setAttribute(
                      'href',
                      URL.createObjectURL(response),
                    );
                    hyperlinkElement.setAttribute(
                      'download',
                      'Istorija bolesti ' +
                        formatDate(
                          new Date(),
                          'dd-MM-yyyy HH-mm-ss',
                          'sr-Latn',
                        ),
                    );
                    hyperlinkElement.click();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(readDiseaseHistoryExcelSubscription);
          }
          break;
        case 'hospitalization_report':
          {
            this.progressBarService.start();

            const readHospitalizationReportExcelSubscription =
              this.dashboardService
                .readHospitalizationReportExcel(this.hospitalizationId)
                .subscribe(
                  (response: Blob) => {
                    this.progressBarService.stop();

                    if (
                      response &&
                      response instanceof Blob &&
                      response.size > 0
                    ) {
                      let hyperlinkElement: HTMLElement =
                        document.createElement('A');
                      hyperlinkElement.setAttribute(
                        'href',
                        URL.createObjectURL(response),
                      );
                      hyperlinkElement.setAttribute(
                        'download',
                        'Izvestaj o hospitalizaciji ' +
                          formatDate(
                            new Date(),
                            'dd-MM-yyyy HH-mm-ss',
                            'sr-Latn',
                          ),
                      );
                      hyperlinkElement.click();
                    }
                  },
                  (error) => this.errorHandlerService.handleError(error),
                );

            this.subscriptions.add(readHospitalizationReportExcelSubscription);
          }
          break;
        case 'treatment_invoice':
          {
            this.progressBarService.start();

            const readTreatmentInvoiceExcelSubscription = this.dashboardService
              .readTreatmentInvoiceExcel(this.hospitalizationId)
              .subscribe(
                (response: Blob) => {
                  this.progressBarService.stop();

                  if (
                    response &&
                    response instanceof Blob &&
                    response.size > 0
                  ) {
                    let hyperlinkElement: HTMLElement =
                      document.createElement('A');
                    hyperlinkElement.setAttribute(
                      'href',
                      URL.createObjectURL(response),
                    );
                    hyperlinkElement.setAttribute(
                      'download',
                      'Racun za lecenje ' +
                        formatDate(
                          new Date(),
                          'dd-MM-yyyy HH-mm-ss',
                          'sr-Latn',
                        ),
                    );
                    hyperlinkElement.click();
                  }
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(readTreatmentInvoiceExcelSubscription);
          }
          break;
        case 'master_file': {
          this.progressBarService.start();

          const readMasterFileExcelSubscription = this.dashboardService
            .readMasterFileExcel(this.hospitalizationId)
            .subscribe(
              (response: Blob) => {
                this.progressBarService.stop();

                if (response && response instanceof Blob && response.size > 0) {
                  let hyperlinkElement: HTMLElement =
                    document.createElement('A');
                  hyperlinkElement.setAttribute(
                    'href',
                    URL.createObjectURL(response),
                  );
                  hyperlinkElement.setAttribute(
                    'download',
                    'Maticni list ' +
                      formatDate(new Date(), 'dd-MM-yyyy HH-mm-ss', 'sr-Latn'),
                  );
                  hyperlinkElement.click();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(readMasterFileExcelSubscription);
        }
      }
    }
  }

  onUpdateHospitalization() {
    // Make sure that hospitalization ID is valid (null for new and number for existing hospitalization)
    if (
      this.hospitalizationId === null ||
      (typeof this.hospitalizationId === 'number' &&
        isNaN(this.hospitalizationId) === false)
    ) {
      // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
      if (
        this.data &&
        typeof this.data === 'object' &&
        this.data.hasOwnProperty('source_type') &&
        typeof this.data.source_type === 'string' &&
        this.data.source_type.length > 0 &&
        (this.data.source_type.toLowerCase() === 'excimer_laser' ||
          this.data.source_type.toLowerCase() === 'operations') &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false
      ) {
        this.progressBarService.start();

        const updateHospitalizationByExcimerLaserSubscription =
          this.dashboardService
            .updateHospitalizationByExcimerLaser(
              new Hospitalization(
                this.hospitalizationId,
                this.data.source_id,
                this.updateHospitalizationForm.get(
                  'IdentificationNumber',
                ).value,
                this.readAcceptanceDateParameter(),
                this.updateHospitalizationForm.get('GenderId').value,
                this.readDateOfBirthParameter(),
                this.updateHospitalizationForm.get('Address').value,
                this.updateHospitalizationForm.get(
                  'CompanyAndJobAddress',
                ).value,
                this.updateHospitalizationForm.get(
                  'HealthInsuranceCategory',
                ).value,
                this.updateHospitalizationForm.get('Occupation').value,
                this.updateHospitalizationForm.get(
                  'ExternalCauseOfInjury',
                ).value,
                this.updateHospitalizationForm.get('CauseOfDeath').value,
                this.updateHospitalizationForm.get(
                  'OtherDiseaseOrComplications',
                ).value,
                this.readDateOfDischargeParameter(),
                this.updateHospitalizationForm.get('TypeOfSurgery').value,
                this.readDateOfSurgeryParameter(),
                this.updateHospitalizationForm.get('SurgeryResult').value,
                this.updateHospitalizationForm.get('DoctorId').value,
                this.updateHospitalizationForm.get('InjuryAtWork').value,
                this.readDateAndTimeOfInjuryParameter(),
                this.updateHospitalizationForm.get('Rehabilitation').value,
              ),
            )
            .subscribe(
              (response: boolean) => {
                this.progressBarService.stop();

                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Istorija bolesti / Izveštaj o hospitalizaciji / Račun za lečenje / Matični list su uspešno izmenjeni.',
                  );

                  this.dialogRef.close(response);
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

        this.subscriptions.add(updateHospitalizationByExcimerLaserSubscription);
      }
    }
  }

  readAcceptanceDateParameter(): string | null {
    return this.updateHospitalizationForm.get('AcceptanceDate').value instanceof
      Date
      ? formatDate(
          this.updateHospitalizationForm.get('AcceptanceDate').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readDateOfBirthParameter(): string | null {
    return this.updateHospitalizationForm.get('DateOfBirth').value instanceof
      Date
      ? formatDate(
          this.updateHospitalizationForm.get('DateOfBirth').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readDateOfDischargeParameter(): string | null {
    return this.updateHospitalizationForm.get('DateOfDischarge')
      .value instanceof Date
      ? formatDate(
          this.updateHospitalizationForm.get('DateOfDischarge').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readDateOfSurgeryParameter(): string | null {
    return this.updateHospitalizationForm.get('DateOfSurgery').value instanceof
      Date
      ? formatDate(
          this.updateHospitalizationForm.get('DateOfSurgery').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readDateAndTimeOfInjuryParameter(): string | null {
    let injuryDateAndTime: string | null = null;

    if (
      this.updateHospitalizationForm.get('DateOfInjury').value &&
      this.updateHospitalizationForm.get('DateOfInjury').value instanceof Date
    ) {
      let injuryDate: Date =
        this.updateHospitalizationForm.get('DateOfInjury').value;

      if (
        typeof this.updateHospitalizationForm.get('TimeOfInjury').value ===
          'string' &&
        this.updateHospitalizationForm.get('TimeOfInjury').value.length > 0 &&
        RegExp(timeFormat1stRegex).test(
          this.updateHospitalizationForm.get('TimeOfInjury').value,
        )
      ) {
        let injuryTimeHours = parseInt(
          this.updateHospitalizationForm
            .get('TimeOfInjury')
            .value.split(':')[0],
        );
        let injuryTimeMinutes = parseInt(
          this.updateHospitalizationForm
            .get('TimeOfInjury')
            .value.split(':')[1],
        );

        if (
          typeof injuryTimeHours === 'number' &&
          isNaN(injuryTimeHours) === false &&
          typeof injuryTimeMinutes === 'number' &&
          isNaN(injuryTimeMinutes) === false
        ) {
          injuryDate.setHours(injuryTimeHours);
          injuryDate.setMinutes(injuryTimeMinutes);
        }
      }

      injuryDateAndTime = formatDate(
        injuryDate,
        'yyyy-MM-ddTHH:mm:ss',
        'sr-Latn',
      );
    }

    return injuryDateAndTime;
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
