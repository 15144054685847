export class BusinessHour {
    Id: number;
    UserId: number;
    Date: string;
    From: string | null;
    To: string | null;
    DayOff: boolean;
    SurgeryFrom: string | null;
    SurgeryTo: string | null;
    WeekNo: number;

    constructor(Id: number, UserId: number, Date: string, From: string | null, To: string | null, DayOff: boolean, SurgeryFrom: string | null, SurgeryTo: string | null, WeekNo: number) {
        this.Id = Id;
        this.UserId = UserId;
        this.Date = Date;
        this.From = From;
        this.To = To;
        this.DayOff = DayOff;
        this.SurgeryFrom = SurgeryFrom;
        this.SurgeryTo = SurgeryTo;
        this.WeekNo = WeekNo;
    }
}