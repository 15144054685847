export class Employee {
  Id: number | null;
  UserName: string;
  Name: string;
  Active: boolean;
  Title: string;
  JobId: number;
  JobName: string;
  Email: string;
  GeneratedPassword: boolean;
  Color?: string;

  constructor(
    Id: number | null,
    UserName: string,
    Name: string,
    Active: boolean,
    Title: string,
    JobId: number,
    JobName: string,
    Email: string,
    GeneratedPassword: boolean,
    Color?: string,
  ) {
    this.Id = Id;
    this.UserName = UserName;
    this.Name = Name;
    this.Active = Active;
    this.Title = Title;
    this.JobId = JobId;
    this.JobName = JobName;
    this.Email = Email;
    this.GeneratedPassword = GeneratedPassword;
    this.Color = Color;
  }
}
