export class DiabeticNote {
    Id: number | null;
    LaserExclaimerId: number | null;
    Glycemic: string;
    Laboratory: string;
    Meal: string;
    Note: string;
    Nurse: string;
    Therapy: string;
    Date: string | null;

    constructor(Id: number | null, LaserExclaimerId: number | null, Glycemic: string, Laboratory: string, Meal: string, Note: string, Nurse: string, Therapy: string, Date: string | null) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.Glycemic = Glycemic;
        this.Laboratory = Laboratory;
        this.Meal = Meal;
        this.Note = Note;
        this.Nurse = Nurse;
        this.Therapy = Therapy;
        this.Date = Date;
    }
}