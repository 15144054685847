import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  isInvisible: boolean = true;

  constructor() {}

  isHidden() {
      return this.isInvisible;
  }

  start() {
      this.isInvisible = false;
  }

  stop() {
      this.isInvisible = true;
  }
}