import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SurgeonSchedule } from '../../shared/surgeon-schedule-model';
import { DashboardService } from '../../shared/dashboard.service';
import { Subscription } from 'rxjs';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { AuthorizationService } from '../../../core/authorization.service';
import { LockService } from '../services/lock.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DeleteDialogComponent } from '../../operation-administration/dialogs/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { jwtDecode } from 'jwt-decode';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-surgeon-list',
  templateUrl: './surgeon-list.component.html',
  styleUrls: ['./surgeon-list.component.scss'],
})
export class SurgeonListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() surgeon: any;
  scheduleText: string = '';
  surgeonForm = new UntypedFormGroup({
    Name: new UntypedFormControl(null),
    Schedule: new UntypedFormControl(null, Validators.required),
  });
  subscriptions: Subscription = new Subscription();
  @Output() readonly onDeleteSurgeonWorkingDay: EventEmitter<any> =
    new EventEmitter();
  @Output() readonly deletedWorkingPlan: EventEmitter<any> = new EventEmitter();
  @Output() panelOpened: EventEmitter<any> = new EventEmitter<any>();

  editRole: boolean;

  isLocked: boolean = false;
  lockedByUser: string = '';
  lock: any = null;
  refreshTimeoutIds: any = [];
  @Input() refreshIds: any = [];

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private authorizationService: AuthorizationService,
    private errorHandlerService: ErrorHandlerService,
    private lockService: LockService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.scheduleText = this.surgeon.Schedule;
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.editRole = permissions.includes('HIRPLRADIZMENASADRZAJABOXA');
      },
      (error) => {
        console.error('Error fetching permissions', error);
      },
    );
  }

  onPanelOpened() {
    this.panelOpened.emit(this.surgeon.Id);
  }

  getAllLocks() {
    this.cancelRefresh();
    this.lockService.getLock(this.surgeon.SurgeonId).subscribe({
      next: (result) => {
        this.lock = result;
        let userId = this.getUserIdFromLocalStorage();
        let workPlanId = this.surgeon.Id;
        let currentTime = new Date();
        let scheduledTime = new Date(this.lock.ReleasedAt);

        if (scheduledTime > currentTime) {
          const timeUntilExecution =
            scheduledTime.getTime() - currentTime.getTime();

          let refreshTimeoutId = setTimeout(() => {
            if (this.lock.UserId == userId) {
              this.onSave();
            } else {
              window.location.reload();
            }
          }, timeUntilExecution);
          this.refreshIds.push(refreshTimeoutId);
        } else {
          console.error('Zakazano vreme mora biti u buducnosti.');
        }
      },
      error: (error) => {
        //this.errorHandlerService.handleError(error);
      },
    });
  }

  cancelRefresh() {
    if (this.refreshIds.length > 0) {
      for (let i = 0; i < this.refreshIds.length; i++) {
        clearTimeout(this.refreshIds[i]);
      }
      console.log('Refresh timeout canceled. Id:', this.refreshIds[0]);
    } else {
      console.warn('No refresh timeout to cancel.');
    }
  }

  refreshText() {
    const param = formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss', 'sr-Latn');
    if (param) {
      this.dashboardService.getAllSurgeonWorkingDaysForDate(param).subscribe(
        (response: any) => {
          if (response) {
            this.scheduleText = response.find(
              (x) => x.SurgeonId == this.surgeon.SurgeonId,
            ).Schedule;
            this.surgeonForm.get('Schedule').setValue(this.scheduleText);
            this.snackbarService.showSuccess(
              'Nova verzija termina je preuzeta.',
            );
          }
        },
        (error) => {
          this.errorHandlerService.handleError(error);
        },
      );
    }
  }

  getUserIdFromLocalStorage() {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken) {
        return decodedToken.nameid;
      }
    }
    return null;
  }

  ngOnChanges() {
    if (this.surgeon) {
      if (this.surgeon.Name)
        this.surgeonForm.get('Name').setValue(this.surgeon.Name);
      if (this.surgeon.Schedule)
        this.surgeonForm.get('Schedule').setValue(this.surgeon.Schedule);
    }
  }

  onSave() {
    this.progressBarService.start();

    const params = new SurgeonSchedule(
      this.surgeon.Id,
      this.surgeon.SurgeonId,
      this.surgeon.Name,
      this.surgeon.Start,
      this.surgeon.End,
      this.surgeonForm.get('Schedule').value,
    );

    const saveSurgeonWorkingDaySubscription = this.dashboardService
      .saveSurgeonWorkingDay(params)
      .subscribe(
        () => {
          this.progressBarService.stop();
          this.scheduleText = this.surgeonForm.get('Schedule').value;
          this.snackbarService.showSuccess('Termin je uspešno ažuriran.');
          this.onEditEnd(this.surgeon.SurgeonId);
          this.subscriptions.add(saveSurgeonWorkingDaySubscription);
        },
        (error) => {
          this.progressBarService.stop();
          this.errorHandlerService.handleError(error);
        },
      );
  }

  onDelete() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressBarService.start();
        const params = new SurgeonSchedule(
          this.surgeon.Id,
          this.surgeon.SurgeonId,
          this.surgeon.Name,
          this.surgeon.Start,
          this.surgeon.End,
          this.surgeonForm.get('Schedule').value,
        );

        const deleteSurgeonWorkingDaySubscription = this.dashboardService
          .deleteSurgeonWorkingDay(params)
          .subscribe((response) => {
            this.progressBarService.stop();

            if (response) {
              this.snackbarService.showSuccess('Termin je uspešno obrisan.');

              this.onDeleteSurgeonWorkingDay.emit();
              this.deletedWorkingPlan.emit();
            }
          });
        this.subscriptions.add(deleteSurgeonWorkingDaySubscription);
      }
    });
  }

  onPrint() {
    this.progressBarService.start();

    const params = new SurgeonSchedule(
      this.surgeon.Id,
      this.surgeon.SurgeonId,
      this.surgeon.Name,
      this.surgeon.Start,
      this.surgeon.End,
      this.surgeonForm.get('Schedule').value,
    );

    const getSurgeonWorkingDaysSubscription = this.dashboardService
      .getsurgeonworkingdayreport(params)
      .subscribe((response: Blob) => {
        this.progressBarService.stop();

        if (response && response instanceof Blob && response.size > 0)
          this.dashboardService.printFile(response);
      });

    this.subscriptions.add(getSurgeonWorkingDaysSubscription);
  }

  onEditStart() {
    this.onPanelOpened();
    this.lockService.acquireLock(this.surgeon.SurgeonId).subscribe({
      next: () => {
        // Lock acquired
        this.refreshText();
        this.isLocked = false;
      },
      error: (error) => {
        // Lock acquisition failed
        if (error.status === 423) {
          this.isLocked = true;
          this.displayLockMessage(error.error);
        }
      },
    });
  }

  onEditEnd(resourceId: number) {
    this.lockService.releaseLock(resourceId).subscribe({
      next: (response) => {
        window.location.reload();
      },
    });
  }

  displayLockMessage(message: string) {
    this.snackbarService.showError(message);
  }

  ngOnDestroy(): void {
    if (this.surgeon && !this.isLocked) {
      this.onEditEnd(56);
    }
    this.subscriptions.unsubscribe();
  }
}
