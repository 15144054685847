import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {
  CalendarEvent,
  CalendarEventTitleFormatter,
  CalendarDateFormatter,
} from 'angular-calendar';
import * as moment from 'moment';

@Component({
  selector: 'app-apartment-calendar',
  templateUrl: './apartment-calendar.component.html',
  styleUrls: ['./apartment-calendar.component.scss'],
})
export class ApartmentCalendarComponent implements OnInit {
  viewDate: Date = new Date();
  today: Date = new Date();
  listOfMonths: Array<string>;
  monthSliderIndex: number;
  listOfMonthsMatrix: Array<Array<number>> = [];
  selectedDay;

  @Output() readonly onSelectedDay: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.setMonthSlider();
  }

  dayClicked(event): void {
    if (this.selectedDay) {
      delete this.selectedDay.cssClass;
    }
    event.cssClass = 'cal-day-selected';
    this.selectedDay = event;
    this.onSelectedDay.emit(this.selectedDay.date);
  }

  beforeMonthViewRender({ body }: { body }): void {
    const selectedDate = this.convertDateISO(
      moment(new Date()).set({ h: 0, m: 0, s: 0 }),
    );
    body.forEach((day) => {
      if (selectedDate && day.date.getTime() === Date.parse(selectedDate)) {
        day.cssClass = 'cal-day-selected';
        this.selectedDay = day;
        this.onSelectedDay.emit(this.selectedDay.date);
      }
    });
  }

  addYear() {
    this.viewDate = moment(this.viewDate).add(1, 'years').toDate();
  }

  substractYear() {
    this.viewDate = moment(this.viewDate).subtract(1, 'years').toDate();
  }

  selectMonth(month: string, index: number) {
    this.viewDate = moment(this.viewDate).month(month).toDate();
    if (this.isSameYear(index)) {
    } else if (index > this.monthSliderIndex) {
      this.addYear();
    } else if (index < this.monthSliderIndex) {
      this.substractYear();
    }
    this.monthSliderIndex = index;
  }

  addMonth() {
    this.viewDate = moment(this.viewDate).add(1, 'months').toDate();
    this.monthSliderIndex++;
    if (this.monthSliderIndex === this.listOfMonths.length - 1) {
      this.listOfMonths = this.listOfMonths.concat(this.listOfMonths);
      this.createMonthListMatrix();
    }
    this.scroll(this.monthSliderIndex);
  }

  substractMonth() {
    this.viewDate = moment(this.viewDate).subtract(1, 'months').toDate();
    this.monthSliderIndex--;
    this.scroll(this.monthSliderIndex);
  }

  scroll(index: number) {
    const elements = document.querySelectorAll('.month');
    const el = elements[index] as HTMLElement;
    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  private convertDateISO(date) {
    const m = moment(date);
    return m.format();
  }

  private createMonthListMatrix(): void {
    const monthsInYear = 12;
    let indexes = [];
    this.listOfMonths.forEach((_, i) => {
      indexes.push(i);
      if ((i + 1) % monthsInYear === 0 && i !== 0) {
        this.listOfMonthsMatrix.push(indexes);
        indexes = [];
      }
    });
  }
  private isSameYear(index: number): boolean {
    const currentYear = this.findMonthInMatrix(this.monthSliderIndex);
    const yearOfSelectedMonth = this.findMonthInMatrix(index);
    return currentYear === yearOfSelectedMonth;
  }

  private findMonthInMatrix(index: number): number {
    return this.listOfMonthsMatrix.findIndex((indexes: Array<number>) =>
      indexes.includes(index),
    );
  }

  private setMonthSlider() {
    // have this and next year in month slider
    this.listOfMonths = [...moment.monthsShort(), ...moment.monthsShort()];
    this.monthSliderIndex = moment(this.viewDate).month();
    // viewDate is next year (or even later), so move index and add more years to the month slider
    const yearDifference =
      moment(this.viewDate).year() - moment(this.today).year();
    this.monthSliderIndex += yearDifference * 12;
    for (let i = 0; i < yearDifference; i++) {
      this.listOfMonths = this.listOfMonths.concat(this.listOfMonths);
    }
    this.createMonthListMatrix();
  }
}
