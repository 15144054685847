import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import ValidateForm from '../helpers/validationform';
import { AuthenticationService } from '../core/authentication.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { ProgressBarService } from '../core/progress-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  isPasswordHidden = true;

  loginForm = new FormGroup({
    UserName: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    Password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
  ) {}

  ngOnInit() {
    // If user is already signed in (has token, ID and permission(s)), do the redirection to Dashboard
    // TODO
  }

  onReadUserNameErrorMessage(): string {
    return this.loginForm.get('UserName').hasError('required')
      ? 'Unesite korisničko ime'
      : this.loginForm.get('UserName').hasError('minlength')
      ? 'Korisničko ime mora da sadrži najmanje 4 karaktera'
      : '';
  }

  onReadPasswordErrorMessage(): string {
    return this.loginForm.get('Password').hasError('required')
      ? 'Unesite lozinku'
      : this.loginForm.get('Password').hasError('minlength')
      ? 'Lozinka mora da sadrži najmanje 4 karaktera'
      : '';
  }

  onSignIn() {
    if (this.loginForm.valid) {
      this.progressBarService.start();
      this.authenticationService.signIn(this.loginForm.value).subscribe({
        next: (res) => {
          this.progressBarService.stop();
          this.loginForm.reset();
          this.authenticationService.storeToken(res);
          const tokenPayload = this.authenticationService.decodedToken();
          this.router.navigate(['/dashboard/home']);
        },
        error: (err) => {
          this.progressBarService.stop();
          this.errorHandlerService.handleError(err);
        },
      });
    } else {
      ValidateForm.ValidateAllFormFields(this.loginForm);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription)
    this.subscriptions.unsubscribe();
  }
}
