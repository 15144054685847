import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Pricelist } from '../../pricelist-administration.component';
import { PricelistService } from 'src/app/core/services/pricelist.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';

@Component({
  selector: 'app-update-pricelist',
  templateUrl: './update-pricelist.component.html',
  styleUrls: ['./update-pricelist.component.scss'],
})
export class UpdatePricelistComponent implements OnInit {
  nameInput: string = '';
  descriptionInput: string = '';
  isActiveInput: boolean = false;
  priceInput: number = 0;
  selectedPriceList: Pricelist;

  constructor(
    public dialogRef: MatDialogRef<UpdatePricelistComponent>,
    private pricelistService: PricelistService,
    private snackbarService: SnackbarService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.selectedPriceList = this.data.pricelist;
    this.nameInput = this.selectedPriceList.Name;
    this.priceInput = this.selectedPriceList.Price;
  }

  onSubmit() {
    this.progressBarService.start();
    let dto = {
      id: this.selectedPriceList.Id,
      name: this.nameInput,
      price: this.priceInput,
    };

    this.pricelistService.updateService(dto).subscribe({
      next: () => {
        this.progressBarService.stop();
        this.snackbarService.showSuccess('Uspešno izmenjena cena usluge.');
        this.dialogRef.close(true);
      },
      error: (error: any) => {
        this.progressBarService.stop();
        console.log(error);
      },
    });
  }

  isFormValid() {
    if (!this.nameInput) return true;
    if (!this.priceInput || this.priceInput <= 0) return true;
    return false;
  }
}
