import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from 'src/app/shared/constants.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: HttpClient) {}

  getAllPermissionsWithJobs() {
    return this.http.get<any>(`${apiUrl}/adminPanel/getAllPermissionsWithJobs`);
  }

  getPermissionByIdWithJobs(id: any) {
    return this.http.get<any>(
      `${apiUrl}/adminPanel/getPermissionByIdWithJobs/${id}`,
    );
  }

  createNewPermission(dto: Permissions) {
    return this.http.post<any>(`${apiUrl}/adminPanel/addNewPermission`, dto);
  }

  updatePermission(dto: Permissions) {
    return this.http.put<any>(`${apiUrl}/adminPanel/updatePermission`, dto);
  }

  deletePermission(id: any) {
    return this.http.delete<boolean>(
      `${apiUrl}/adminPanel/deletePermission/${id}`,
    );
  }

  assignPermission(dto: AssignPermissionDTO) {
    return this.http.post<any>(`${apiUrl}/adminPanel/assignPermission`, dto);
  }

  removePermission(dto: AssignPermissionDTO) {
    return this.http.post<any>(`${apiUrl}/adminPanel/removePermission`, dto);
  }
}

export interface Permissions {
  id: number;
  key: string;
  description: string;
  roles: any;
}

export interface AssignPermissionDTO {
  permissionId: number;
  jobId: number;
}
