import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

// Import the Angular Material components
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';

import { AppointmentTemplateDialogComponent } from './appointment-template-dialog/appointment-template-dialog.component';
import { UpdateDayBusinessHoursDialogComponent } from './update-day-business-hours-dialog/update-day-business-hours-dialog.component';

import { Employee } from '../shared/employee.model';
import { Shift } from '../shared/shift.model';
import { BusinessHour } from '../shared/business-hour.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.css'],
})
export class BusinessHoursComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  doctorsCollection: Employee[] = [];
  permissions: string[] = [];

  readBusinessHoursForm = new UntypedFormGroup({
    WorkWeekDate: new UntypedFormControl({
      value: new Date(),
      disabled: true,
    }),
    Doctor: new UntypedFormControl(null, Validators.required),
  });

  displayedColumns: string[] = [
    'Day',
    'Date',
    'From',
    'To',
    'DayOff',
    'Actions',
  ];
  dataSource = new MatTableDataSource<BusinessHour>([]);

  editRole: boolean;
  templateRole: boolean;

  constructor(
    public authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.progressBarService.start();

    const readDoctorsSubscription = this.dashboardService
      .readDoctors()
      .subscribe(
        (response: Employee[]) => {
          this.progressBarService.stop();

          if (response.length > 0) this.doctorsCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readDoctorsSubscription);

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions
        this.editRole = permissions.includes('RVIZMENA');
        this.templateRole = permissions.includes('RVSABLONI');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  onChangeWorkWeekDateOrDoctor(workWeekDate: Date, doctor: Employee) {
    if (
      workWeekDate &&
      workWeekDate instanceof Date &&
      doctor &&
      typeof doctor === 'object' &&
      doctor.hasOwnProperty('UserName') &&
      typeof doctor.UserName === 'string' &&
      doctor.UserName.length > 0
    )
      this.readBusinessHours(workWeekDate, doctor.UserName);
  }

  onOpenAppointmentTemplateDialog() {
    // Make sure that Doctor is selected before opening dialog
    if (
      typeof this.readBusinessHoursForm.get('Doctor').value.Id === 'number' &&
      isNaN(this.readBusinessHoursForm.get('Doctor').value.Id) === false
    ) {
      // Make sure that Shifts exists before opening dialog
      this.progressBarService.start();

      const readDoctorShiftsSubscription = this.dashboardService
        .readDoctorShifts(
          this.readBusinessHoursForm.get('Doctor').value.Id,
          this.readBusinessHoursForm.get('WorkWeekDate').value,
        )
        .subscribe(
          (response: Shift[]) => {
            this.progressBarService.stop();

            if (response.length === 0) {
              this.snackbarService.showInfo(
                'Ne postoje dostupne smene za izabranog Doktora.',
              );
            } else if (response.length > 0) {
              this.dialog.open(AppointmentTemplateDialogComponent, {
                data: {
                  doctor_name:
                    this.readBusinessHoursForm.get('Doctor').value.Name,
                  doctor_shifts: response,
                },
              });
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readDoctorShiftsSubscription);
    } else {
      this.snackbarService.showInfo('Izaberite Doktora.');
    }
  }

  onOpenUpdateDayBusinessHoursDialog(dayBusinessHours: BusinessHour) {
    const dialogRef = this.dialog.open(UpdateDayBusinessHoursDialogComponent, {
      data: dayBusinessHours,
    });

    const updateDayBusinessHoursDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result === true)
          this.onChangeWorkWeekDateOrDoctor(
            this.readBusinessHoursForm.get('WorkWeekDate').value,
            this.readBusinessHoursForm.get('Doctor').value,
          );
      });

    this.subscriptions.add(updateDayBusinessHoursDialogSubscription);
  }

  readBusinessHours(date: Date, username: string) {
    this.dataSource = new MatTableDataSource<BusinessHour>([]);

    this.progressBarService.start();

    const readBusinessHoursSubscription = this.dashboardService
      .readBusinessHours(date, username)
      .subscribe(
        (response: BusinessHour[]) => {
          this.progressBarService.stop();

          if (response.length === 0) {
            this.snackbarService.showInfo(
              'Ne postoji dostupno radno vreme za izabranu Radnu nedelju i Doktora.',
            );
          } else if (response.length > 0)
            this.dataSource = new MatTableDataSource(response);
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readBusinessHoursSubscription);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
