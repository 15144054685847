import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthorizationService } from '../../../../core/authorization.service';
import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';

import { Reception } from '../../../shared/reception.model';
import { AnesthesiaFlow } from '../../../shared/anesthesia-flow.model';
import { OperationFlow } from '../../../shared/operation-flow.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-internal-history-dialog',
  templateUrl: './update-internal-history-dialog.component.html',
  styleUrls: ['./update-internal-history-dialog.component.css'],
})
export class UpdateInternalHistoryDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  generalExaminationId: number | null = null;

  selectedTabIndex: number | null = 0;

  receptionId: number | null = null;
  anesthesiaFlowId: number | null = null;
  operationFlowId: number | null = null;

  updateReceptionForm = new UntypedFormGroup({
    Description: new UntypedFormControl(''),
    VODDistanceSC: new UntypedFormControl(''),
    VODDistanceCC: new UntypedFormControl(''),
    VODVisusDistance: new UntypedFormControl(''),
    VODNearSC: new UntypedFormControl(''),
    VODNearCC: new UntypedFormControl(''),
    VODVisusNear: new UntypedFormControl(''),
    VOSDistanceSC: new UntypedFormControl(''),
    VOSDistanceCC: new UntypedFormControl(''),
    VOSVisusDistance: new UntypedFormControl(''),
    VOSNearSC: new UntypedFormControl(''),
    VOSNearCC: new UntypedFormControl(''),
    VOSVisusNear: new UntypedFormControl(''),
    IOPAplanationTOD: new UntypedFormControl(''),
    IOPAplanationTOS: new UntypedFormControl(''),
    IOPNonContactTOD: new UntypedFormControl(''),
    IOPNonContactTOS: new UntypedFormControl(''),
    Advice: new UntypedFormControl(''),
  });

  updateAnesthesiaFlowForm = new UntypedFormGroup({
    Description: new UntypedFormControl(''),
  });

  updateOperationFlowForm = new UntypedFormGroup({
    Description: new UntypedFormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateInternalHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    this.readReception();

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    )
      this.generalExaminationId = this.data.source_id;
  }

  onChangeSelectedTab(index: number) {
    if (
      typeof index === 'number' &&
      isNaN(index) === false &&
      index >= 0 &&
      index <= 2
    ) {
      this.selectedTabIndex = index;

      switch (index) {
        case 0:
          this.readReception();
          break;
        case 1:
          this.readAnesthesiaFlow();
          break;
        case 2:
          this.readOperationFlow();
      }
    } else this.selectedTabIndex = null;
  }

  onUpdateInternalHistory() {
    // Make sure that appropriate tab is selected (currently Reception, Anesthesia or Operation flow)
    if (
      typeof this.selectedTabIndex === 'number' &&
      isNaN(this.selectedTabIndex) === false &&
      this.selectedTabIndex >= 0 &&
      this.selectedTabIndex <= 2
    ) {
      // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
      if (
        this.data &&
        typeof this.data === 'object' &&
        this.data.hasOwnProperty('source_type') &&
        typeof this.data.source_type === 'string' &&
        this.data.source_type.length > 0 &&
        (this.data.source_type.toLowerCase() === 'excimer_laser' ||
          this.data.source_type.toLowerCase() === 'operations') &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false
      ) {
        // Retrieve the user ID
        this.authorizationService
          .getUserId()
          .pipe(take(1))
          .subscribe((userId) => {
            switch (this.selectedTabIndex) {
              case 0: {
                this.progressBarService.start();
                const reception = new Reception(
                  this.receptionId,
                  this.data.source_id,
                  userId,
                  this.updateReceptionForm.get('Description').value,
                  this.updateReceptionForm.get('VODDistanceSC').value,
                  this.updateReceptionForm.get('VODDistanceCC').value,
                  this.updateReceptionForm.get('VODNearSC').value,
                  this.updateReceptionForm.get('VODNearCC').value,
                  this.updateReceptionForm.get('VOSDistanceSC').value,
                  this.updateReceptionForm.get('VOSDistanceCC').value,
                  this.updateReceptionForm.get('VOSNearSC').value,
                  this.updateReceptionForm.get('VOSNearCC').value,
                  this.updateReceptionForm.get('IOPAplanationTOD').value,
                  this.updateReceptionForm.get('IOPAplanationTOS').value,
                  this.updateReceptionForm.get('IOPNonContactTOD').value,
                  this.updateReceptionForm.get('IOPNonContactTOS').value,
                  this.updateReceptionForm.get('VODVisusDistance').value,
                  this.updateReceptionForm.get('VODVisusNear').value,
                  this.updateReceptionForm.get('VOSVisusDistance').value,
                  this.updateReceptionForm.get('VOSVisusNear').value,
                  this.updateReceptionForm.get('Advice').value,
                );
                const sub = this.dashboardService
                  .updateReceptionByExcimerLaser(reception)
                  .subscribe(
                    (response: boolean) => {
                      this.progressBarService.stop();
                      if (response === true) {
                        this.snackbarService.showSuccess(
                          'Prijem je uspešno izmenjen.',
                        );
                        this.dialogRef.close(response);
                      }
                    },
                    (error) => this.errorHandlerService.handleError(error),
                  );
                this.subscriptions.add(sub);
                break;
              }
              case 1: {
                this.progressBarService.start();
                const anesthesiaFlow = new AnesthesiaFlow(
                  this.anesthesiaFlowId,
                  this.data.source_id,
                  userId,
                  this.updateAnesthesiaFlowForm.get('Description').value,
                );
                const sub = this.dashboardService
                  .updateAnesthesiaFlowByExcimerLaser(anesthesiaFlow)
                  .subscribe(
                    (response: boolean) => {
                      this.progressBarService.stop();
                      if (response === true) {
                        this.snackbarService.showSuccess(
                          'Anestezija je uspešno izmenjena.',
                        );
                        this.dialogRef.close(response);
                      }
                    },
                    (error) => this.errorHandlerService.handleError(error),
                  );
                this.subscriptions.add(sub);
                break;
              }
              case 2: {
                this.progressBarService.start();
                const operationFlow = new OperationFlow(
                  this.operationFlowId,
                  this.data.source_id,
                  userId,
                  this.updateOperationFlowForm.get('Description').value,
                );
                const sub = this.dashboardService
                  .updateOperationFlowByExcimerLaser(operationFlow)
                  .subscribe(
                    (response: boolean) => {
                      this.progressBarService.stop();
                      if (response === true) {
                        this.snackbarService.showSuccess(
                          'Operativni tok je uspešno izmenjen.',
                        );
                        this.dialogRef.close(response);
                      }
                    },
                    (error) => this.errorHandlerService.handleError(error),
                  );
                this.subscriptions.add(sub);
                break;
              }
            }
          });
      }
    }
  }

  onReadInternalHistoryReport() {
    if (
      typeof this.generalExaminationId === 'number' &&
      isNaN(this.generalExaminationId) === false
    ) {
      this.progressBarService.start();

      const readInternalHistoryReportSubscription = this.dashboardService
        .readInternalHistoryReport(this.generalExaminationId)
        .subscribe(
          (response: Blob) => {
            this.progressBarService.stop();

            if (response && response instanceof Blob && response.size > 0)
              this.dashboardService.printFile(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readInternalHistoryReportSubscription);
    }
  }

  // Used in Reception tab
  readReception() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      const readReceptionByExcimerLaserSubscription = this.dashboardService
        .readReceptionByExcimerLaser(this.data.source_id)
        .subscribe(
          (response: Reception) => {
            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('Id') &&
                typeof response.Id === 'number' &&
                isNaN(response.Id) === false
              )
                this.receptionId = response.Id;
              if (
                response.hasOwnProperty('Description') &&
                typeof response.Description === 'string' &&
                response.Description.length > 0
              )
                this.updateReceptionForm
                  .get('Description')
                  .setValue(response.Description);
              if (
                response.hasOwnProperty('VODDistanceSC') &&
                typeof response.VODDistanceSC === 'string' &&
                response.VODDistanceSC.length > 0
              )
                this.updateReceptionForm
                  .get('VODDistanceSC')
                  .setValue(response.VODDistanceSC);
              if (
                response.hasOwnProperty('VODDistanceCC') &&
                typeof response.VODDistanceCC === 'string' &&
                response.VODDistanceCC.length > 0
              )
                this.updateReceptionForm
                  .get('VODDistanceCC')
                  .setValue(response.VODDistanceCC);
              if (
                response.hasOwnProperty('VODVisusDistance') &&
                typeof response.VODVisusDistance === 'string' &&
                response.VODVisusDistance.length > 0
              )
                this.updateReceptionForm
                  .get('VODVisusDistance')
                  .setValue(response.VODVisusDistance);
              if (
                response.hasOwnProperty('VODNearSC') &&
                typeof response.VODNearSC === 'string' &&
                response.VODNearSC.length > 0
              )
                this.updateReceptionForm
                  .get('VODNearSC')
                  .setValue(response.VODNearSC);
              if (
                response.hasOwnProperty('VODNearCC') &&
                typeof response.VODNearCC === 'string' &&
                response.VODNearCC.length > 0
              )
                this.updateReceptionForm
                  .get('VODNearCC')
                  .setValue(response.VODNearCC);
              if (
                response.hasOwnProperty('VODVisusNear') &&
                typeof response.VODVisusNear === 'string' &&
                response.VODVisusNear.length > 0
              )
                this.updateReceptionForm
                  .get('VODVisusNear')
                  .setValue(response.VODVisusNear);
              if (
                response.hasOwnProperty('VOSDistanceSC') &&
                typeof response.VOSDistanceSC === 'string' &&
                response.VOSDistanceSC.length > 0
              )
                this.updateReceptionForm
                  .get('VOSDistanceSC')
                  .setValue(response.VOSDistanceSC);
              if (
                response.hasOwnProperty('VOSDistanceCC') &&
                typeof response.VOSDistanceCC === 'string' &&
                response.VOSDistanceCC.length > 0
              )
                this.updateReceptionForm
                  .get('VOSDistanceCC')
                  .setValue(response.VOSDistanceCC);
              if (
                response.hasOwnProperty('VOSVisusDistance') &&
                typeof response.VOSVisusDistance === 'string' &&
                response.VOSVisusDistance.length > 0
              )
                this.updateReceptionForm
                  .get('VOSVisusDistance')
                  .setValue(response.VOSVisusDistance);
              if (
                response.hasOwnProperty('VOSNearSC') &&
                typeof response.VOSNearSC === 'string' &&
                response.VOSNearSC.length > 0
              )
                this.updateReceptionForm
                  .get('VOSNearSC')
                  .setValue(response.VOSNearSC);
              if (
                response.hasOwnProperty('VOSNearCC') &&
                typeof response.VOSNearCC === 'string' &&
                response.VOSNearCC.length > 0
              )
                this.updateReceptionForm
                  .get('VOSNearCC')
                  .setValue(response.VOSNearCC);
              if (
                response.hasOwnProperty('VOSVisusNear') &&
                typeof response.VOSVisusNear === 'string' &&
                response.VOSVisusNear.length > 0
              )
                this.updateReceptionForm
                  .get('VOSVisusNear')
                  .setValue(response.VOSVisusNear);
              if (
                response.hasOwnProperty('IOPAplanationTOD') &&
                typeof response.IOPAplanationTOD === 'string' &&
                response.IOPAplanationTOD.length > 0
              )
                this.updateReceptionForm
                  .get('IOPAplanationTOD')
                  .setValue(response.IOPAplanationTOD);
              if (
                response.hasOwnProperty('IOPAplanationTOS') &&
                typeof response.IOPAplanationTOS === 'string' &&
                response.IOPAplanationTOS.length > 0
              )
                this.updateReceptionForm
                  .get('IOPAplanationTOS')
                  .setValue(response.IOPAplanationTOS);
              if (
                response.hasOwnProperty('IOPNonContactTOD') &&
                typeof response.IOPNonContactTOD === 'string' &&
                response.IOPNonContactTOD.length > 0
              )
                this.updateReceptionForm
                  .get('IOPNonContactTOD')
                  .setValue(response.IOPNonContactTOD);
              if (
                response.hasOwnProperty('IOPNonContactTOS') &&
                typeof response.IOPNonContactTOS === 'string' &&
                response.IOPNonContactTOS.length > 0
              )
                this.updateReceptionForm
                  .get('IOPNonContactTOS')
                  .setValue(response.IOPNonContactTOS);
              if (
                response.hasOwnProperty('Advice') &&
                typeof response.Advice === 'string' &&
                response.Advice.length > 0
              )
                this.updateReceptionForm
                  .get('Advice')
                  .setValue(response.Advice);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readReceptionByExcimerLaserSubscription);
    }
  }

  // Used in Anesthesia tab
  readAnesthesiaFlow() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      this.progressBarService.start();

      const readAnesthesiaFlowByExcimerLaserSubscription = this.dashboardService
        .readAnesthesiaFlowByExcimerLaser(this.data.source_id)
        .subscribe(
          (response: AnesthesiaFlow) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('Id') &&
                typeof response.Id === 'number' &&
                isNaN(response.Id) === false
              )
                this.anesthesiaFlowId = response.Id;
              if (
                response.hasOwnProperty('Description') &&
                typeof response.Description === 'string' &&
                response.Description.length > 0
              )
                this.updateAnesthesiaFlowForm
                  .get('Description')
                  .setValue(response.Description);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readAnesthesiaFlowByExcimerLaserSubscription);
    }
  }

  // Used in Operation flow tab
  readOperationFlow() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      this.progressBarService.start();

      const readOperationFlowByExcimerLaserSubscription = this.dashboardService
        .readOperationFlowByExcimerLaser(this.data.source_id)
        .subscribe(
          (response: OperationFlow) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('Id') &&
                typeof response.Id === 'number' &&
                isNaN(response.Id) === false
              )
                this.operationFlowId = response.Id;
              if (
                response.hasOwnProperty('Description') &&
                typeof response.Description === 'string' &&
                response.Description.length > 0
              )
                this.updateOperationFlowForm
                  .get('Description')
                  .setValue(response.Description);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readOperationFlowByExcimerLaserSubscription);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
