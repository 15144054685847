import { Pipe, PipeTransform } from '@angular/core';
import { InterventionType } from 'src/app/dashboard/shared/intervention-type.model';

@Pipe({
  name: 'findInterventrionName',
})
export class FindInterventrionNamePipe implements PipeTransform {
  transform(id: number, objects: InterventionType[]): string {
    const object = objects.find((obj) => obj.Id === id);
    return object ? object.Name : 'Nema nadredjenog';
  }
}
