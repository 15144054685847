import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { HomeComponent } from './home/home.component';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { SearchComponent } from './search/search.component';
import { PatientComponent } from './patient/patient.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { RecipesComponent } from './recipes/recipes.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StaffAdministrationComponent } from './staff-administration/staff-administration.component';
import { BusinessHoursComponent } from './business-hours/business-hours.component';

import { RouterGuard } from '../core/router.guard';
import { SurgeonWorkPlanComponent } from './surgeon-work-plan/surgeon-work-plan.component';
import { MessagesComponent } from './messages/messages.component';
import { ProtocolsComponent } from './protocols/protocols.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { StaffAdministrationGuard } from '../core/staff-administration.guard';
import { BusinessHoursGuard } from '../core/business-hours.guard';
import { NewPatientGuard } from '../core/new-patient.guard';
import { SearchPatientGuard } from '../core/search-patient.guard';
import { SchedulingGuard } from '../core/scheduling.guard';
import { StatisticsGuard } from '../core/statistics.guard';
import { ProtocolsGuard } from '../core/protocols.guard';
import { SurgeonWorkPlanGuard } from '../core/surgeon-work-plan.guard';
import { MessagesGuard } from '../core/messages.guard';
import { ApartmentsGuard } from '../core/apartments.guard';
import { OperationAdministrationComponent } from './operation-administration/operation-administration/operation-administration.component';
import { PricelistAdministrationComponent } from './pricelist-administration/pricelist-administration.component';
import { PermissionAdministrationComponent } from './permission-administration/permission-administration/permission-administration.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [RouterGuard],
    children: [
      {
        path: '',
        canActivateChild: [RouterGuard],
        children: [
          { path: 'home', component: HomeComponent },
          {
            path: 'new-patient',
            component: NewPatientComponent,
            canActivate: [NewPatientGuard],
          },
          {
            path: 'search',
            component: SearchComponent,
            canActivate: [SearchPatientGuard],
          },
          { path: 'patient', component: PatientComponent },
          {
            path: 'scheduling',
            component: SchedulingComponent,
            canActivate: [SchedulingGuard],
          },
          { path: 'recipes', component: RecipesComponent },
          {
            path: 'statistics',
            component: StatisticsComponent,
            canActivate: [StatisticsGuard],
          },
          {
            path: 'staff-administration',
            component: StaffAdministrationComponent,
            canActivate: [StaffAdministrationGuard],
          },
          {
            path: 'permissions-administration',
            component: PermissionAdministrationComponent,
          },
          {
            path: 'operation-administration',
            component: OperationAdministrationComponent,
          },
          {
            path: 'pricelist-administration',
            component: PricelistAdministrationComponent,
          },
          {
            path: 'business-hours',
            component: BusinessHoursComponent,
            canActivate: [BusinessHoursGuard],
          },
          {
            path: 'surgeon-work-plan',
            component: SurgeonWorkPlanComponent,
            canActivate: [SurgeonWorkPlanGuard],
          },
          {
            path: 'apartments',
            component: ApartmentsComponent,
            canActivate: [ApartmentsGuard],
          },
          {
            path: 'protocols',
            component: ProtocolsComponent,
            canActivate: [ProtocolsGuard],
          },
          {
            path: 'messages',
            component: MessagesComponent,
            canActivate: [MessagesGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
