import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../../../../shared/constants.service';

import { DiabeticNote } from '../../../../shared/diabetic-note.model';
import * as moment from 'moment';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-create-diabetic-note-item-dialog',
  templateUrl: './create-diabetic-note-item-dialog.component.html',
  styleUrls: ['./create-diabetic-note-item-dialog.component.css'],
})
export class CreateDiabeticNoteItemDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  createDiabeticNoteItemForm = new UntypedFormGroup({
    Date: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    Time: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(timeFormat1stRegex),
    ]),
    Glycemic: new UntypedFormControl(''),
    Laboratory: new UntypedFormControl(''),
    Therapy: new UntypedFormControl(''),
    Meal: new UntypedFormControl(''),
    Note: new UntypedFormControl(''),
    Nurse: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<CreateDiabeticNoteItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data.update) {
      const time = this.data.diabeticNote.Date.split('T').pop().substring(0, 5);
      this.createDiabeticNoteItemForm
        .get('Date')
        .setValue(moment(this.data.diabeticNote.Date).toDate());
      this.createDiabeticNoteItemForm.get('Time').setValue(time);
      this.createDiabeticNoteItemForm
        .get('Glycemic')
        .setValue(this.data.diabeticNote.Glycemic);
      this.createDiabeticNoteItemForm
        .get('Laboratory')
        .setValue(this.data.diabeticNote.Laboratory);
      this.createDiabeticNoteItemForm
        .get('Therapy')
        .setValue(this.data.diabeticNote.Therapy);
      this.createDiabeticNoteItemForm
        .get('Meal')
        .setValue(this.data.diabeticNote.Meal);
      this.createDiabeticNoteItemForm
        .get('Note')
        .setValue(this.data.diabeticNote.Note);
      this.createDiabeticNoteItemForm
        .get('Nurse')
        .setValue(this.data.diabeticNote.Nurse);
    }
  }

  onReadTimeErrorMessage(): string {
    return this.createDiabeticNoteItemForm.get('Time').hasError('required')
      ? 'Unesite vreme'
      : this.createDiabeticNoteItemForm.get('Time').hasError('pattern')
      ? 'Mora biti od 00:00 do 23:59'
      : '';
  }

  onCreateDiabeticNoteItem() {
    // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      this.progressBarService.start();

      const Id = this.data.update ? this.data.diabeticNote.Id : null;

      const createDiabeticNoteByExcimerLaserSubscription = this.dashboardService
        .createDiabeticNoteByExcimerLaser(
          new DiabeticNote(
            Id,
            this.data.source_id,
            this.createDiabeticNoteItemForm.get('Glycemic').value,
            this.createDiabeticNoteItemForm.get('Laboratory').value,
            this.createDiabeticNoteItemForm.get('Meal').value,
            this.createDiabeticNoteItemForm.get('Note').value,
            this.createDiabeticNoteItemForm.get('Nurse').value,
            this.createDiabeticNoteItemForm.get('Therapy').value,
            this.readDateAndTimeParameter(),
          ),
        )
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess(
                'Stavka Liste dijabetičara je uspešno dodata.',
              );
              this.dialogRef.close(response);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(createDiabeticNoteByExcimerLaserSubscription);
    }
  }

  readDateAndTimeParameter(): string {
    let dateAndTime: string = '';

    if (
      this.createDiabeticNoteItemForm.get('Date').value &&
      this.createDiabeticNoteItemForm.get('Date').value instanceof Date
    ) {
      let date: Date = this.createDiabeticNoteItemForm.get('Date').value;

      if (
        typeof this.createDiabeticNoteItemForm.get('Time').value === 'string' &&
        this.createDiabeticNoteItemForm.get('Time').value.length > 0 &&
        RegExp(timeFormat1stRegex).test(
          this.createDiabeticNoteItemForm.get('Time').value,
        )
      ) {
        let timeHours = parseInt(
          this.createDiabeticNoteItemForm.get('Time').value.split(':')[0],
        );
        let timeMinutes = parseInt(
          this.createDiabeticNoteItemForm.get('Time').value.split(':')[1],
        );

        if (
          typeof timeHours === 'number' &&
          isNaN(timeHours) === false &&
          typeof timeMinutes === 'number' &&
          isNaN(timeMinutes) === false
        ) {
          date.setHours(timeHours);
          date.setMinutes(timeMinutes);
        }
      }

      dateAndTime = formatDate(date, 'yyyy-MM-ddTHH:mm:ss', 'sr-Latn');
    }

    return dateAndTime;
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
