import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { apiUrl } from 'src/app/shared/constants.service';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PricelistService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  getAllPricelists() {
    return this.http.get<any>(`${apiUrl}/appointment/getallappointmenttypes`);
  }

  addNewService(data: any) {
    return this.http.post<any>(`${apiUrl}/appointment/AddNewAppointmentType`, data);
  }

  updateService(data: any) {
    return this.http.put<any>(`${apiUrl}/appointment/UpdateAppointmentType`, data);
  }

  deleteService(id: number) {
    return this.http.delete<boolean>(
      `${apiUrl}/appointment/DeleteAppointmentType/${id}`,
    );
  }
}
