import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
    
    private numberOfMessagesSource: BehaviorSubject<number> = new BehaviorSubject(null);   
    numberOfMessages = this.numberOfMessagesSource.asObservable();   

    constructor() { }

    emitNewnumberOfMessagesValue(value: number){
        this.numberOfMessagesSource.next(value);
    }   
  
}
