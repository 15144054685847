import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Protocol } from '../shared/protocol.model';
import { formatDate } from '@angular/common';
import { ProgressBarService } from '../../core/progress-bar.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { Subscription, Observable } from 'rxjs';
import { FilePreviewDialogComponent } from '../patient/shared/file-preview-dialog/file-preview-dialog.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.component.html',
  styleUrls: ['./protocols.component.scss'],
})
export class ProtocolsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['ProtocolName', 'Actions'];
  dataSource = new MatTableDataSource<Protocol>([]);
  subscriptions: Subscription = new Subscription();
  editRole: boolean;

  permissions: string[] = [];

  constructor(
    public authorizationService: AuthorizationService,
    private readonly dashboardService: DashboardService,
    private readonly progressBarService: ProgressBarService,
    private readonly snackbarService: SnackbarService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getAllProtocols();
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('PROTIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  getAllProtocols() {
    this.dashboardService
      .getAllProtocols()
      .subscribe(
        (result) => (this.dataSource = new MatTableDataSource(result)),
      );
  }

  onContentUpload(filesCollection: FileList): void {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf' ||
          filesCollection[0].type.toLowerCase().includes('document') === true
        ) {
          let requestData = new FormData();
          requestData.append(
            'DateCreated',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );
          requestData.append('_file', filesCollection[0]);
          requestData.append(
            'UploadedFileType',
            filesCollection[0].type.toLowerCase(),
          );
          requestData.append(
            'Name',
            filesCollection[0].name.split('.').slice(0, -1).join('.'),
          );
          requestData.append('Id', null);

          this.progressBarService.start();

          const onContentUploadSubscription = this.dashboardService
            .saveProtocolFile(requestData)
            .subscribe(
              (response) => {
                this.progressBarService.stop();
                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Protokol je uspešno dodat.',
                  );
                  this.getAllProtocols();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(onContentUploadSubscription);
        }
      } else {
        this.snackbarService.showInfo(
          'Izabrani fajl mora biti slika ili PDF dokument.',
        );
      }
    }
  }

  onDeleteProtocol(id: number): void {
    this.progressBarService.start();

    const deleteProtocolSubscription = this.dashboardService
      .deleteProtocolFile(id)
      .subscribe(
        (response) => {
          if (response) {
            this.progressBarService.stop();
            this.snackbarService.showSuccess('Protokol je uspešno obrisan.');
            this.getAllProtocols();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(deleteProtocolSubscription);
  }

  onOpenProtocolPreviewDialog(content: Protocol): void {
    if (
      content &&
      content.hasOwnProperty('Id') &&
      typeof content.Id === 'number' &&
      isNaN(content.Id) === false
    ) {
      this.progressBarService.start();

      const onOpenProtocolPreviewDialogSubscription = this.dashboardService
        .getProtocolFileById(content.Id)
        .subscribe(
          (response: any) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('UploadedFileType') &&
                typeof response.UploadedFileType === 'string' &&
                response.UploadedFileType.length > 0 &&
                (response.UploadedFileType.toLowerCase().includes('image/') ===
                  true ||
                  response.UploadedFileType.toLowerCase() === 'application/pdf')
              ) {
                if (
                  response.hasOwnProperty('FileStream') &&
                  typeof response.FileStream === 'string' &&
                  response.FileStream.length > 0
                ) {
                  this.dialog.open(FilePreviewDialogComponent, {
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    data: {
                      file_type: response.UploadedFileType.toLowerCase(),
                      file_content: response.FileStream,
                    },
                  });
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoji dostupan sadržaj izabranog protokola.',
                  );
                }
              } else if (
                response.UploadedFileType.toLowerCase().includes('document') ===
                true
              ) {
                function base64ToArrayBuffer(base64) {
                  var binaryString = window.atob(base64);
                  var binaryLen = binaryString.length;
                  var bytes = new Uint8Array(binaryLen);
                  for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                  }
                  return bytes;
                }
                const data = new Blob(
                  [base64ToArrayBuffer(response.FileStream)],
                  { type: response.UploadedFileType },
                );
                let hyperlinkElement: HTMLElement = document.createElement('A');
                hyperlinkElement.setAttribute(
                  'href',
                  URL.createObjectURL(data),
                );
                hyperlinkElement.setAttribute('download', response.Name);
                hyperlinkElement.click();
              } else {
                this.snackbarService.showInfo(
                  'Izabrani protokol mora biti slika ili PDF dokument.',
                );
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(onOpenProtocolPreviewDialogSubscription);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
