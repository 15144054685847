import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../../core/progress-bar.service';

import { TemperatureNoteType } from '../../../../shared/temperature-note-type.model';
import { TemperatureNote } from '../../../../shared/temperature-note.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-create-temperature-note-item-dialog',
  templateUrl: './create-temperature-note-item-dialog.component.html',
  styleUrls: ['./create-temperature-note-item-dialog.component.css'],
})
export class CreateTemperatureNoteItemDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  temperatureNoteTypesCollection: TemperatureNoteType[] = [];

  createTemperatureNoteItemForm = new UntypedFormGroup({
    TemperatureListTypeId: new UntypedFormControl(null),
    Description: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<CreateTemperatureNoteItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    const readTemperatureNoteTypesSubscription = this.dashboardService
      .readTemperatureNoteTypes()
      .subscribe(
        (response: TemperatureNoteType[]) => {
          if (response && typeof response === 'object' && response.length > 0)
            this.temperatureNoteTypesCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readTemperatureNoteTypesSubscription);

    if (this.data.update) {
      this.createTemperatureNoteItemForm
        .get('TemperatureListTypeId')
        .setValue(this.data.temperatureNote.TemperatureListTypeId);
      this.createTemperatureNoteItemForm
        .get('Description')
        .setValue(this.data.temperatureNote.Description);
    }
  }

  onCreateTemperatureNoteItem() {
    // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      this.progressBarService.start();

      const Id = this.data.update ? this.data.temperatureNote.Id : null;

      const createTemperatureNoteByExcimerLaserSubscription =
        this.dashboardService
          .createTemperatureNoteByExcimerLaser(
            new TemperatureNote(
              Id,
              this.data.source_id,
              this.createTemperatureNoteItemForm.get(
                'TemperatureListTypeId',
              ).value,
              formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss', 'sr-Latn'),
              this.createTemperatureNoteItemForm.get('Description').value,
            ),
          )
          .subscribe(
            (response: boolean) => {
              this.progressBarService.stop();
              if (response === true) {
                this.snackbarService.showSuccess(
                  'Stavka Temperaturne liste je uspešno dodata.',
                );
                this.dialogRef.close(response);
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

      this.subscriptions.add(createTemperatureNoteByExcimerLaserSubscription);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
