import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';

import { AuthorizationService } from "../../core/authorization.service";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { DashboardService } from '../shared/dashboard.service';
import { Observable, Subscription } from 'rxjs';
import { Employee } from '../shared/employee.model';
import { StatisticsFilter } from '../shared/statistics-filter.model';
import { ErrorHandlerService } from "../../core/error-handler.service";
import { ProgressBarService } from "../../core/progress-bar.service";
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit, OnDestroy {
    ophthalmologyForm = new UntypedFormGroup({
        From: new UntypedFormControl({
            value: new Date(),
            disabled: true
        }, Validators.required),
        To: new UntypedFormControl({
            value: new Date(),
            disabled: true
        }, Validators.required),
        Doctor: new UntypedFormControl(null, Validators.required)
    });

    instituteForm = new UntypedFormGroup({
        From: new UntypedFormControl({
            value: new Date(),
            disabled: true
        }, Validators.required),
        To: new UntypedFormControl({
            value: new Date(),
            disabled: true
        }, Validators.required),
        Doctor: new UntypedFormControl(null, Validators.required)
    });

    doctors$: Observable<Employee[]>;

    permissions: string[] = [];

    subscriptions: Subscription = new Subscription();

    editRole: boolean;

    constructor(
        public authorizationService: AuthorizationService,
        private dashboardService: DashboardService,
        private errorHandlerService: ErrorHandlerService,
        private progressBarService: ProgressBarService) { }

    ngOnInit() {
        this.doctors$ = this.dashboardService.readDoctors().pipe(
            catchError(error => this.errorHandlerService.handleError(error))
        );

        this.authorizationService.readPermissions().subscribe(
            permissions => {
                this.permissions = permissions;
                this.editRole = permissions.includes('STATIZMENA');
            },
            error => {
                console.error("Error fetching permissions:", error);
            }
        );
    }

    showOftamologyStatistics(from: Date, to: Date, doctors: number[]): void {
        this.progressBarService.start();

        const showOftamologyStatisticsSubscription = this.dashboardService.getOftamologyStatistics(new StatisticsFilter(formatDate(from, "yyyy-MM-dd", "sr-Latn"), formatDate(to, "yyyy-MM-dd", "sr-Latn"), doctors)).subscribe(
            res => {
                this.progressBarService.stop();

                let parentElement = document.getElementById("ophthalmologyIframePlaceholder");
                if (document.getElementById("fileIframeOphthalmology")) parentElement.removeChild(document.getElementById("fileIframeOphthalmology"));
                const iframeElement = document.createElement("iframe");
                iframeElement.setAttribute("id", "fileIframeOphthalmology");
                iframeElement.setAttribute("src", URL.createObjectURL(res));
                iframeElement.setAttribute("width", "900px");
                iframeElement.setAttribute("height", "500px");
                parentElement.appendChild(iframeElement);
            },
            error => this.errorHandlerService.handleError(error)
        );

        this.subscriptions.add(showOftamologyStatisticsSubscription);
    }

    showInstituteStatistics(from: Date, to: Date, doctors: number[]): void {
        this.progressBarService.start();

        const showInstituteStatisticsSubscription = this.dashboardService.getStatisticsAgency(new StatisticsFilter(formatDate(from, "yyyy-MM-dd", "sr-Latn"), formatDate(to, "yyyy-MM-dd", "sr-Latn"), doctors)).subscribe(
            res => {
                this.progressBarService.stop();

                let parentElement = document.getElementById("instituteIframePlaceholder");
                if (document.getElementById("fileIframeInstitute")) parentElement.removeChild(document.getElementById("fileIframeInstitute"));
                const iframeElement = document.createElement("iframe");
                iframeElement.setAttribute("id", "fileIframeInstitute");
                iframeElement.setAttribute("src", URL.createObjectURL(res));
                iframeElement.setAttribute("width", "900px");
                iframeElement.setAttribute("height", "500px");
                parentElement.appendChild(iframeElement);
            },
            error => this.errorHandlerService.handleError(error)
        );

        this.subscriptions.add(showInstituteStatisticsSubscription);
    }

    ngOnDestroy() {
        // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
        this.subscriptions.unsubscribe();
    }
}
