import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

// Import the Angular Material components
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../shared/constants.service';

import { CreateAppointmentDialogComponent } from './create-appointment-dialog/create-appointment-dialog.component';
import { UpdateAppointmentDialogComponent } from './update-appointment-dialog/update-appointment-dialog.component';
import { DeleteAppointmentDialogComponent } from './delete-appointment-dialog/delete-appointment-dialog.component';

import { Employee } from '../shared/employee.model';
import { ReadAppointmentsRequest } from '../shared/read-appointments-request.model';
import { ReadAppointmentsResponse } from '../shared/read-appointments-response.model';
import { AppointmentType } from '../shared/appointment-type.model';
import { ExaminationChronology } from '../shared/examination-chronology.model';
import { ReadAppointments } from '../shared/read-appointments.model';
import { Surgeon } from '../shared/surgeon.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css'],
})
export class SchedulingComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  doctorsCollection: Employee[] = [];
  appoitmentCollections: any;
  permissions: string[] = [];

  forDate: Date = new Date();
  doctor: any = null;

  displayedColumns: string[] = [
    'Time',
    'PatientName',
    'AppointmentTypes',
    'PatientDateOfBirth',
    'Price',
    'AmountPayed',
    'Anamnesis',
    'Note',
    'Actions',
  ];
  dataSource = new MatTableDataSource<ReadAppointmentsResponse>([]);

  editRole: boolean;
  createRole: boolean;
  linkRole: boolean;
  allSurgeons: Surgeon;

  selectedDoctorControl = new UntypedFormControl(null);

  constructor(
    public authorizationService: AuthorizationService,
    private router: Router,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.progressBarService.start();

    this.loadDoctors();

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('ZAKIZMENA');
        this.createRole = permissions.includes('ZAKNOVI');
        this.linkRole = permissions.includes('ZAKLINK');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  changeForDate(date: any) {
    this.forDate = date;
    this.onChangeDateOrDoctor(this.forDate, this.doctor);
  }

  changeDoctor(doctor: any) {
    this.doctor = doctor;
    this.onChangeDateOrDoctor(this.forDate, this.doctor);
  }

  loadDoctors() {
    this.progressBarService.start();
    this.dashboardService.readDoctors().subscribe(
      (doctors: Employee[]) => {
        this.progressBarService.stop();
        this.doctorsCollection = doctors;
        if (this.doctorsCollection.length > 0) {
          this.onChangeDateOrDoctor(this.forDate, this.doctorsCollection[0]);
        }
      },
      (error) => {
        this.progressBarService.stop();
        this.errorHandlerService.handleError(error);
      },
    );
  }

  showAppointmentsReport(Datum: Date): void {
    this.progressBarService.start();

    const showAppointmentsReportSubscription = this.dashboardService
      .getAppointmentsReport(
        formatDate(Datum, 'yyyy-MM-dd', 'sr-Latn'),
        this.doctor.Id,
      )
      .subscribe(
        (res) => {
          this.progressBarService.stop();

          let parentElement = document.getElementById(
            'appointmentIframePlaceholder',
          );
          if (document.getElementById('fileIframeAppointment'))
            parentElement.removeChild(
              document.getElementById('fileIframeAppointment'),
            );
          const iframeElement = document.createElement('iframe');
          iframeElement.setAttribute('id', 'fileIframeAppointment');
          iframeElement.setAttribute('src', URL.createObjectURL(res));
          iframeElement.setAttribute('width', '900px');
          iframeElement.setAttribute('height', '500px');
          parentElement.appendChild(iframeElement);
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(showAppointmentsReportSubscription);
  }

  onReadAppointmentReport(Datum: Date) {
    this.progressBarService.start();

    if (this.doctor == null) {
      this.progressBarService.stop();
      return;
    }

    const ReadAppointmentReportSubscription = this.dashboardService
      .getAppointmentsReport(
        formatDate(Datum, 'yyyy-MM-dd', 'sr-Latn'),
        this.doctor.Id,
      )
      .subscribe(
        (response: Blob) => {
          this.progressBarService.stop();

          if (response && response instanceof Blob && response.size > 0)
            this.dashboardService.printFile(response);
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(ReadAppointmentReportSubscription);
  }

  onChangeDateOrDoctor(date: Date, doctor: any) {
    // Check if doctor exists and has an Id property
    if (doctor && doctor.hasOwnProperty('Id')) {
      // Create ReadAppointmentsRequest with name and id
      this.readAppointments(
        new ReadAppointmentsRequest(
          doctor.UserName,
          doctor.Id,
          formatDate(date, 'yyyy-MM-dd', 'sr-Latn'),
        ),
      );
    } else {
      // Create ReadAppointmentsRequest with just the date
      this.readAppointments(
        new ReadAppointmentsRequest(
          '',
          0,
          formatDate(date, 'yyyy-MM-dd', 'sr-Latn'),
        ),
      );
    }
    this.readSurgeons(date);
  }

  onOpenCreateAppointmentDialog(
    appointmentTime: string,
    dataSource: any,
    doctorId: number,
    doctorName: string,
  ) {
    // Make sure that Date is selected before opening dialog
    if (
      this.readAppointmentDateParameter() &&
      typeof this.readAppointmentDateParameter() === 'string' &&
      this.readAppointmentDateParameter().length > 0
    ) {
      if (typeof doctorId === 'number' && isNaN(doctorId) === false) {
        this.progressBarService.start();

        const readAppointmentTypesSubscription = this.dashboardService
          .readAppointmentTypes()
          .subscribe(
            (response: AppointmentType[]) => {
              this.progressBarService.stop();

              if (response.length === 0) {
                this.snackbarService.showInfo(
                  'Ne postoje dostupne vrste pregleda za zakazivanje termina.',
                );
              } else if (response.length > 0) {
                // Make sure that Appointment times exists before opening dialog
                if (
                  dataSource &&
                  typeof dataSource === 'object' &&
                  dataSource.length > 0
                ) {
                  let timesCollection: string[] = [];

                  for (let appointment of dataSource) {
                    if (
                      typeof appointment === 'object' &&
                      appointment.hasOwnProperty('Time') &&
                      typeof appointment.Time === 'string' &&
                      appointment.Time.length > 0 &&
                      RegExp(timeFormat1stRegex).test(appointment.Time) &&
                      timesCollection.indexOf(appointment.Time) === -1
                    )
                      timesCollection.push(appointment.Time);
                  }

                  if (
                    typeof timesCollection === 'object' &&
                    timesCollection.length > 0
                  ) {
                    const dialogRef = this.dialog.open(
                      CreateAppointmentDialogComponent,
                      {
                        minWidth: '44vw',
                        data: {
                          date: this.readAppointmentDateParameter(),
                          doctorId: doctorId,
                          appointment_types: response,
                          appointment_times: timesCollection,
                          selected_appointment_time: appointmentTime,
                        },
                      },
                    );

                    const createAppointmentDialogSubscription = dialogRef
                      .afterClosed()
                      .subscribe((result) => {
                        if (result === true) {
                          const param = {
                            Id: doctorId,
                            UserName: doctorName,
                          };
                          this.onChangeDateOrDoctor(this.forDate, param);
                        }
                      });

                    this.subscriptions.add(createAppointmentDialogSubscription);
                  } else {
                    this.snackbarService.showInfo(
                      'Ne postoje slobodni termini za zakazivanje pregleda.',
                    );
                  }
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoje dostupna vremena za zakazivanje termina pregleda.',
                  );
                }
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(readAppointmentTypesSubscription);
      } else {
        this.snackbarService.showInfo('Izaberite Doktora.');
      }
    } else {
      this.snackbarService.showInfo('Izaberite Datum.');
    }
  }

  onOpenUpdateAppointmentDialog(
    appointmentData: ReadAppointmentsResponse,
    dataSource,
  ) {
    // Make sure that Appointment exists before opening dialog
    if (
      appointmentData &&
      typeof appointmentData === 'object' &&
      appointmentData.hasOwnProperty('Id') &&
      typeof appointmentData.Id === 'number' &&
      isNaN(appointmentData.Id) === false
    ) {
      // Make sure that Appointment has existing Patient before opening dialog
      if (
        appointmentData.hasOwnProperty('PatientId') &&
        typeof appointmentData.PatientId === 'number' &&
        isNaN(appointmentData.PatientId) === false
      ) {
        // Make sure that Appointment types exists before opening dialog
        this.progressBarService.start();

        const readAppointmentTypesSubscription = this.dashboardService
          .readAppointmentTypes()
          .subscribe(
            (response: AppointmentType[]) => {
              this.progressBarService.stop();

              if (response.length === 0) {
                this.snackbarService.showInfo(
                  'Ne postoje dostupne vrste pregleda za zakazivanje termina.',
                );
              } else if (response.length > 0) {
                // Make sure that Appointment times exists before opening dialog
                if (
                  dataSource &&
                  typeof dataSource === 'object' &&
                  dataSource.length > 0
                ) {
                  let timesCollection: string[] = [];

                  for (let appointment of dataSource) {
                    if (
                      typeof appointment === 'object' &&
                      appointment.hasOwnProperty('Time') &&
                      typeof appointment.Time === 'string' &&
                      appointment.Time.length > 0 &&
                      RegExp(timeFormat1stRegex).test(appointment.Time) &&
                      timesCollection.indexOf(appointment.Time) === -1
                    )
                      timesCollection.push(appointment.Time);
                  }

                  if (
                    typeof timesCollection === 'object' &&
                    timesCollection.length > 0
                  ) {
                    if (appointmentData.hasOwnProperty('TimeSlotFrom'))
                      delete appointmentData.TimeSlotFrom;
                    if (appointmentData.hasOwnProperty('TimeSlotTo'))
                      delete appointmentData.TimeSlotTo;

                    const dialogRef = this.dialog.open(
                      UpdateAppointmentDialogComponent,
                      {
                        minWidth: '44vw',
                        data: {
                          appointment: appointmentData,
                          appointment_types: response,
                          appointment_times: timesCollection,
                        },
                      },
                    );

                    const updateAppointmentDialogSubscription = dialogRef
                      .afterClosed()
                      .subscribe((result) => {
                        if (result === true)
                          this.onChangeDateOrDoctor(this.forDate, this.doctor);
                      });

                    this.subscriptions.add(updateAppointmentDialogSubscription);
                  } else {
                    this.snackbarService.showInfo(
                      'Ne postoje slobodni termini za zakazivanje pregleda.',
                    );
                  }
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoje dostupna vremena za zakazivanje termina pregleda.',
                  );
                }
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(readAppointmentTypesSubscription);
      } else {
        this.snackbarService.showInfo(
          'Ne postoji dostupan pacijent za zakazani termin pregleda.',
        );
      }
    } else {
      this.snackbarService.showInfo(
        'Ne postoji dostupan zakazan termin pregleda.',
      );
    }
  }

  onOpenDeleteAppointmentDialog(appointmentId: number) {
    if (typeof appointmentId === 'number' && isNaN(appointmentId) === false) {
      const dialogRef = this.dialog.open(DeleteAppointmentDialogComponent);

      const deleteAppointmentDialogSubscription = dialogRef
        .afterClosed()
        .subscribe((result) => {
          if (result === true) {
            this.progressBarService.start();

            const deleteAppointmentSubscription = this.dashboardService
              .deleteAppointment(appointmentId)
              .subscribe(
                (response: boolean) => {
                  this.progressBarService.stop();

                  if (response === true)
                    this.onChangeDateOrDoctor(this.forDate, this.doctor);
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(deleteAppointmentSubscription);
          }
        });

      this.subscriptions.add(deleteAppointmentDialogSubscription);
    }
  }

  onCreatePatientExaminationChronology(
    appointmentData: ReadAppointmentsResponse,
  ) {
    // Make sure that Appointment has existing Patient
    if (
      appointmentData &&
      typeof appointmentData === 'object' &&
      appointmentData.hasOwnProperty('PatientId') &&
      typeof appointmentData.PatientId === 'number' &&
      isNaN(appointmentData.PatientId) === false
    ) {
      // Make sure that Appointment has existing Date
      if (
        appointmentData.hasOwnProperty('Date') &&
        typeof appointmentData.Date === 'string' &&
        appointmentData.Date.length > 0
      ) {
        let appointmentId = null;
        if (
          appointmentData.hasOwnProperty('Id') &&
          typeof appointmentData.Id === 'number' &&
          isNaN(appointmentData.Id) === false
        )
          appointmentId = appointmentData.Id;

        this.progressBarService.start();

        const createPatientExaminationChronologySubscription =
          this.dashboardService
            .createPatientExaminationChronology(
              new ExaminationChronology(
                null,
                appointmentData.PatientId,
                formatDate(appointmentData.Date, 'yyyy-MM-dd', 'sr-Latn'),
                appointmentId,
                null,
                null,
              ),
            )
            .subscribe(
              (response: boolean) => {
                this.progressBarService.stop();

                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Hronologija pregleda za izabranog pacijenta je uspešno dodata.',
                  );

                  // If patient is new, redirect to Patient page. Otherwise, refresh Appointments table data.
                  if (
                    appointmentData.hasOwnProperty('NewPatient') &&
                    appointmentData.NewPatient === true
                  ) {
                    this.dashboardService.setPatientId(
                      appointmentData.PatientId,
                    );
                    this.dashboardService.setIsNewPatientState(true);
                    this.router.navigate(['/dashboard/patient']);
                  } else this.onChangeDateOrDoctor(this.forDate, this.doctor);
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

        this.subscriptions.add(createPatientExaminationChronologySubscription);
      } else {
        this.snackbarService.showInfo(
          'Ne postoji dostupan datum zakazanog termina pregleda.',
        );
      }
    } else {
      this.snackbarService.showInfo(
        'Ne postoji dostupan pacijent za zakazani termin pregleda.',
      );
    }
  }

  onOpenPatientPage(patientId: number) {
    if (typeof patientId === 'number' && isNaN(patientId) === false) {
      this.dashboardService.setPatientId(patientId);
      //this.router.navigate(['/dashboard/patient']);
      this.router.navigate(['/dashboard/scheduling']).then((result) => {
        window.open('dashboard/patient', '_blank');
      });
    }
  }

  readAppointments(readAppointmentsRequest: ReadAppointmentsRequest) {
    this.progressBarService.start();

    const readAppointmentsSubscription = this.dashboardService
      .readAppointments(readAppointmentsRequest)
      .subscribe(
        (response: ReadAppointments) => {
          this.progressBarService.stop();

          if (response) {
            this.appoitmentCollections = response;
            this.appoitmentCollections.sort((a: any, b: any) => {
              if (a.UserId === readAppointmentsRequest.UserId) {
                return -1;
              } else if (b.UserId === readAppointmentsRequest.UserId) {
                return 1;
              } else {
                return 0;
              }
            });
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readAppointmentsSubscription);
  }

  readSurgeons(Datum: Date) {
    // this.dataSource = new MatTableDataSource<ReadAppointmentsResponse>([]);

    const readSurgeonsSubscription = this.dashboardService
      .getallsurgeonsfordate(formatDate(Datum, 'yyyy-MM-dd', 'sr-Latn'))
      .subscribe(
        (response: Surgeon) => {
          if (response) {
            this.allSurgeons = response;
            document
              .getElementById('appointmentTable')
              .scroll({ top: 0, behavior: 'smooth' });
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readSurgeonsSubscription);
  }

  readAppointmentDateParameter(): string | null {
    return this.forDate
      ? formatDate(this.forDate, 'yyyy-MM-dd', 'sr-Latn')
      : null;
  }

  onCreateAppointment(doctorId: number, doctorName: string) {
    this.progressBarService.start();

    const readAppointmentTypesSubscription = this.dashboardService
      .readAppointmentTypes()
      .subscribe((response: AppointmentType[]) => {
        this.progressBarService.stop();

        if (response.length === 0) {
          this.snackbarService.showInfo(
            'Ne postoje dostupne vrste pregleda za zakazivanje termina.',
          );
        } else if (response.length > 0) {
          const dialogRef = this.dialog.open(CreateAppointmentDialogComponent, {
            minWidth: '44vw',
            data: {
              date: this.readAppointmentDateParameter(),
              doctorId: doctorId,
              appointment_types: response,
              appointment_times: [],
              selected_appointment_time: null,
            },
          });

          const createAppointmentDialogSubscription = dialogRef
            .afterClosed()
            .subscribe(
              (result) => {
                if (result === true) {
                  const param = {
                    Id: doctorId,
                    UserName: doctorName,
                  };
                  this.onChangeDateOrDoctor(this.forDate, param);
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(createAppointmentDialogSubscription);
        }
      });

    this.subscriptions.add(readAppointmentTypesSubscription);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
