import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from 'src/app/shared/constants.service';

@Injectable({
  providedIn: 'root',
})
export class InterventionTypeService {
  constructor(private http: HttpClient) {}

  getAllInterventions() {
    return this.http.get<any>(`${apiUrl}/adminPanel/getAllInterventions`);
  }

  getInterventionById(id: any) {
    return this.http.get<any>(`${apiUrl}/adminPanel/getInterventionById/${id}`);
  }

  addNewIntervention(data: any) {
    return this.http.post<any>(`${apiUrl}/adminPanel/addNewIntervention`, data);
  }

  updateIntervention(data: any) {
    return this.http.put<any>(`${apiUrl}/adminPanel/updateIntervention`, data);
  }

  deleteIntervention(id: any) {
    return this.http.delete<boolean>(
      `${apiUrl}/adminPanel/deleteIntervention/${id}`,
    );
  }
}
