import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiUrl } from 'src/app/shared/constants.service';
import { AuthenticationService } from 'src/app/core/authentication.service';

@Injectable({ providedIn: 'root' })
export class LockService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {}

  getLock(resourceId: number): Observable<any> {
    return this.http.get<any>(`${apiUrl}/access/getLock/${resourceId}`);
  }

  acquireLock(resourceId: number): Observable<any> {
    return this.http.post(apiUrl + '/Access/AcquireLock/', resourceId, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }

  releaseLock(resourceId: number): Observable<any> {
    return this.http.delete(apiUrl + '/Access/ReleaseLock/' + resourceId, {
      headers: new HttpHeaders({
        token: this.authenticationService.getToken(),
      }),
    });
  }
}
