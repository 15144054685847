import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterventionType } from '../../../operation-administration/operation-administration.component';
import { InterventionTypeService } from 'src/app/core/services/intervention-type.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-new-operation',
  templateUrl: './create-new-operation.component.html',
  styleUrls: ['./create-new-operation.component.scss'],
})
export class CreateNewOperationComponent implements OnInit {
  nameInput: string = '';
  levelInput: number = null;
  parentIdInput: number = null;
  allInterventionTypers: InterventionType[] = [];
  availableTypes: InterventionType[] = [];
  isAvailablyTypesDisabled: boolean = true;
  listOfParentIds: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateNewOperationComponent>,
    private interventionTypeService: InterventionTypeService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.allInterventionTypers = this.data;
  }

  changeLevelInput() {
    if (this.levelInput == 1) {
      this.isAvailablyTypesDisabled = true;
      this.availableTypes = [];
      return;
    }
    this.isAvailablyTypesDisabled = false;
    this.availableTypes = this.allInterventionTypers.filter(
      (type) => type.Level == +this.levelInput - 1,
    );
  }

  onSubmit() {
    this.progressBarService.start();
    let successfullyAdded = 0;
    const observables = this.listOfParentIds.map((parentId) =>
      this.interventionTypeService
        .addNewIntervention({
          Name: this.nameInput,
          Level: this.levelInput,
          ParentId: parentId,
        })
        .pipe(tap(() => successfullyAdded++)),
    );

    forkJoin(observables).subscribe(
      () => {
        this.progressBarService.stop();
        this.dialogRef.close(successfullyAdded);
      },
      (error) => {
        console.log(error);
      },
    );
  }

  selectChangeParentId(event: any) {
    this.listOfParentIds = event.data.map((item: any) => item.Id);
    console.log(this.listOfParentIds);
  }

  isFormValid() {
    if (!this.nameInput) return true;
    if (!this.levelInput) return true;
  }
}
