export class DischargeNote {
    Id: number | null;
    LaserExclaimerId: number | null;
    Description: string;
    DoctorId: number | null;
    LoggedInDoctorId: number | null;
    Date: string | null;
    SurgeryDate: string | null;
    LeftEye: boolean | null;
    TypeOfSurgery: string;
    Therapy: string;
    Recheck: string;
    SurgeonId: number | null;
    DiagnosisIds: number[] | null;
    DiagnosisOnArrival: string;
    DiagnosisOnDischarge: string;
    InterventionTypeLevel1IdOD: number;
    InterventionTypeLevel1IdOS: number;
    InterventionTypeLevel2IdOD: number;
    InterventionTypeLevel2ODText: string;
    InterventionTypeLevel2IdOS: number;
    InterventionTypeLevel2OSText: string;
    InterventionTypeLevel3ODId: number;
    InterventionTypeLevel3ODText: string;
    InterventionTypeLevel3OSId: number;
    InterventionTypeLevel3OSText: string;
    InterventionTypeLevel3OUId: number;
    InterventionTypeLevel4IdOD: number;
    InterventionTypeLevel4ODText: string;
    InterventionTypeLevel4IdOS: string;
    InterventionTypeLevel4OSText: string;
    ArrivalTOD: string;
    ArrivalTOS: string;
    ArrivalVOD: string;
    ArrivalVOS: string;
    DischargeTOD: string;
    DischargeTOS: string;
    DischargeVOD: string;
    DischargeVOS: string;
    TreatedFromDate: string;
    TreatedToDate: string;
    ODOU: boolean;
    OSOU: boolean; 

    constructor(
        Id: number | null,
        LaserExclaimerId: number | null,
        Description: string,
        DoctorId: number | null,
        LoggedInDoctorId: number | null,
        Date: string | null,
        SurgeryDate: string | null,
        LeftEye: boolean | null,
        TypeOfSurgery: string,
        Therapy: string,
        Recheck: string,
        SurgeonId: number | null,
        DiagnosisIds: number[] | null,
        DiagnosisOnArrival: string,
        DiagnosisOnDischarge: string,
        InterventionTypeLevel1IdOD: number,
        InterventionTypeLevel1IdOS: number,
        InterventionTypeLevel2IdOD: number,
        InterventionTypeLevel2ODText: string,
        InterventionTypeLevel2IdOS: number,
        InterventionTypeLevel2OSText: string,
        InterventionTypeLevel3ODId: number,
        InterventionTypeLevel3ODText: string,
        InterventionTypeLevel3OSId: number,
        InterventionTypeLevel3OSText: string,
        InterventionTypeLevel3OUId: number,
        InterventionTypeLevel4IdOD: number,
        InterventionTypeLevel4ODText: string,
        InterventionTypeLevel4IdOS: string,
        InterventionTypeLevel4OSText: string,
        ArrivalTOD: string,
        ArrivalTOS: string,
        ArrivalVOD: string,
        ArrivalVOS: string,
        DischargeTOD: string,
        DischargeTOS: string,
        DischargeVOD: string,
        DischargeVOS: string,
        TreatedFromDate: string,
        TreatedToDate: string,
        ODOU: boolean,
        OSOU: boolean) {
            this.Id = Id;
            this.LaserExclaimerId = LaserExclaimerId;
            this.Description = Description;
            this.DoctorId = DoctorId;
            this.LoggedInDoctorId = LoggedInDoctorId,
            this.Date = Date;
            this.SurgeryDate = SurgeryDate;
            this.LeftEye = LeftEye;
            this.TypeOfSurgery = TypeOfSurgery;
            this.Therapy = Therapy;
            this.Recheck = Recheck;
            this.SurgeonId = SurgeonId;
            this.DiagnosisIds = DiagnosisIds;
            this.DiagnosisOnArrival = DiagnosisOnArrival;
            this.DiagnosisOnDischarge = DiagnosisOnDischarge;
            this.InterventionTypeLevel1IdOD = InterventionTypeLevel1IdOD;
            this.InterventionTypeLevel1IdOS = InterventionTypeLevel1IdOS;
            this.InterventionTypeLevel2IdOD = InterventionTypeLevel2IdOD;
            this.InterventionTypeLevel2ODText = InterventionTypeLevel2ODText;
            this.InterventionTypeLevel2IdOS = InterventionTypeLevel2IdOS;
            this.InterventionTypeLevel2OSText = InterventionTypeLevel2OSText;
            this.InterventionTypeLevel3ODId = InterventionTypeLevel3ODId;
            this.InterventionTypeLevel3ODText = InterventionTypeLevel3ODText;
            this.InterventionTypeLevel3OSId = InterventionTypeLevel3OSId;
            this.InterventionTypeLevel3OSText = InterventionTypeLevel3OSText;
            this.InterventionTypeLevel3OUId = InterventionTypeLevel3OUId;
            this.InterventionTypeLevel4IdOD = InterventionTypeLevel4IdOD;
            this.InterventionTypeLevel4ODText = InterventionTypeLevel4ODText;
            this.InterventionTypeLevel4IdOS = InterventionTypeLevel4IdOS;
            this.InterventionTypeLevel4OSText = InterventionTypeLevel4OSText;
            this.ArrivalTOD = ArrivalTOD;
            this.ArrivalTOS = ArrivalTOS;
            this.ArrivalVOD = ArrivalVOD;
            this.ArrivalVOS = ArrivalVOS;
            this.DischargeTOD = DischargeTOD;
            this.DischargeTOS = DischargeTOS;
            this.DischargeVOD = DischargeVOD;
            this.DischargeVOS = DischargeVOS;
            this.TreatedFromDate = TreatedFromDate;
            this.TreatedToDate = TreatedToDate;
            this.ODOU = ODOU;   
            this.OSOU = OSOU;
    }
}
