import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-appointment-dialog',
  templateUrl: './delete-appointment-dialog.component.html',
  styleUrls: ['./delete-appointment-dialog.component.css']
})
export class DeleteAppointmentDialogComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}