import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
    private newDate$: BehaviorSubject<number> = new BehaviorSubject(null);
    
    changedDate$ = this.newDate$.asObservable();
    

    constructor() { }

    emitNewDate(monthIndex: number) {
        this.newDate$.next(monthIndex);
    }
    
}
