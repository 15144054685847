import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ProgressBarService } from './progress-bar.service';
import { SnackbarService } from './services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private router: Router,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  handleError(error: HttpErrorResponse) {
    this.progressBarService.stop();

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error && typeof error === 'object') {
        if (
          error.hasOwnProperty('error') &&
          typeof error.error === 'string' &&
          error.error.length > 0
        ) {
          const errorMessage = this.extractErrorMessage(error.error);
          this.snackbarService.showError(errorMessage);
        }

        if (
          error.hasOwnProperty('status') &&
          typeof error.status === 'number' &&
          isNaN(error.status) === false &&
          error.status === 401 &&
          error.hasOwnProperty('statusText') &&
          typeof error.statusText === 'string' &&
          error.statusText.length > 0 &&
          error.statusText.toLowerCase() === 'unauthorized'
        ) {
          if (typeof Storage !== 'undefined') sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private extractErrorMessage(fullErrorMessage: string): string {
    const regex = /(?:System\.Exception:)(.*?)(?=\s+at)/;
    const match = fullErrorMessage.match(regex);
    return match ? match[1].trim() : fullErrorMessage ?? 'Došlo je do greške.';
  }
}
