export class Hospitalization {
    Id: number | null;
    LaserExclaimerId: number | null;
    IdentificationNumber: string;
    AcceptanceDate: string | null;
    GenderId: number | null;
    DateOfBirth: string | null;
    Address: string;
    CompanyAndJobAddress: string;
    HealthInsuranceCategory: string;
    Occupation: string;
    ExternalCauseOfInjury: string;
    CauseOfDeath: string;
    OtherDiseaseOrComplications: string;
    DateOfDischarge: string | null;
    TypeOfSurgery: string;
    DateOfSurgery: string | null;
    SurgeryResult: string;
    DoctorId: number | null;
    InjuryAtWork: boolean | null;
    DateAndTimeOfInjury: string | null;
    Rehabilitation: string;

    constructor(Id: number | null, LaserExclaimerId: number | null, IdentificationNumber: string, AcceptanceDate: string | null, GenderId: number | null, DateOfBirth: string | null, Address: string, CompanyAndJobAddress: string, HealthInsuranceCategory: string, Occupation: string, ExternalCauseOfInjury: string, CauseOfDeath: string, OtherDiseaseOrComplications: string, DateOfDischarge: string | null, TypeOfSurgery: string, DateOfSurgery: string | null, SurgeryResult: string, DoctorId: number | null, InjuryAtWork: boolean | null, DateAndTimeOfInjury: string | null, Rehabilitation: string) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.IdentificationNumber = IdentificationNumber;
        this.AcceptanceDate = AcceptanceDate;
        this.GenderId = GenderId;
        this.DateOfBirth = DateOfBirth;
        this.Address = Address;
        this.CompanyAndJobAddress = CompanyAndJobAddress;
        this.HealthInsuranceCategory = HealthInsuranceCategory;
        this.Occupation = Occupation;
        this.ExternalCauseOfInjury = ExternalCauseOfInjury;
        this.CauseOfDeath = CauseOfDeath;
        this.OtherDiseaseOrComplications = OtherDiseaseOrComplications;
        this.DateOfDischarge = DateOfDischarge;
        this.TypeOfSurgery = TypeOfSurgery;
        this.DateOfSurgery = DateOfSurgery;
        this.SurgeryResult = SurgeryResult;
        this.DoctorId = DoctorId;
        this.InjuryAtWork = InjuryAtWork;
        this.DateAndTimeOfInjury = DateAndTimeOfInjury;
        this.Rehabilitation = Rehabilitation;
    }
}