import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Import the Angular Material modules
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { SearchComponent } from './search/search.component';
import { PatientComponent } from './patient/patient.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { RecipesComponent } from './recipes/recipes.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StaffAdministrationComponent } from './staff-administration/staff-administration.component';
import { BusinessHoursComponent } from './business-hours/business-hours.component';
import { HomeComponent } from './home/home.component';
import { CreateAppointmentDialogComponent } from './scheduling/create-appointment-dialog/create-appointment-dialog.component';
import { UpdateAppointmentDialogComponent } from './scheduling/update-appointment-dialog/update-appointment-dialog.component';
import { DeleteAppointmentDialogComponent } from './scheduling/delete-appointment-dialog/delete-appointment-dialog.component';
import { UpdateEmployeeDialogComponent } from './staff-administration/update-employee-dialog/update-employee-dialog.component';
import { AppointmentTemplateDialogComponent } from './business-hours/appointment-template-dialog/appointment-template-dialog.component';
import { UpdateDayBusinessHoursDialogComponent } from './business-hours/update-day-business-hours-dialog/update-day-business-hours-dialog.component';
import { CompareDiagnosticImagesDialogComponent } from './patient/general-examination/compare-diagnostic-images-dialog/compare-diagnostic-images-dialog.component';
import { UpdateAgreementDialogComponent } from './patient/shared/update-agreement-dialog/update-agreement-dialog.component';
import { UpdateAnesthesiologicalExaminationDialogComponent } from './patient/shared/update-anesthesiological-examination-dialog/update-anesthesiological-examination-dialog.component';
import { UpdateHospitalizationDialogComponent } from './patient/shared/update-hospitalization-dialog/update-hospitalization-dialog.component';
import { UpdateOperationProtocolDialogComponent } from './patient/shared/update-operation-protocol-dialog/update-operation-protocol-dialog.component';
import { UpdateInternalHistoryDialogComponent } from './patient/shared/update-internal-history-dialog/update-internal-history-dialog.component';
import { UpdateSpecialistFindingsDialogComponent } from './patient/shared/update-specialist-findings-dialog/update-specialist-findings-dialog.component';
import { UpdateLaboratoryDialogComponent } from './patient/shared/update-laboratory-dialog/update-laboratory-dialog.component';
import { UpdateTemperatureNoteDialogComponent } from './patient/shared/update-temperature-note-dialog/update-temperature-note-dialog.component';
import { CreateTemperatureNoteItemDialogComponent } from './patient/shared/update-temperature-note-dialog/create-temperature-note-item-dialog/create-temperature-note-item-dialog.component';
import { UpdateDiabeticNoteDialogComponent } from './patient/shared/update-diabetic-note-dialog/update-diabetic-note-dialog.component';
import { CreateDiabeticNoteItemDialogComponent } from './patient/shared/update-diabetic-note-dialog/create-diabetic-note-item-dialog/create-diabetic-note-item-dialog.component';
import { UpdateDischargeNoteDialogComponent } from './patient/shared/update-discharge-note-dialog/update-discharge-note-dialog.component';
import { FilePreviewDialogComponent } from './patient/shared/file-preview-dialog/file-preview-dialog.component';
import { RecipePreviewDialogComponent } from './recipes/recipe-preview-dialog/recipe-preview-dialog.component';

import { DashboardService } from './shared/dashboard.service';
import { DatepickerDateAdapterService } from '../core/datepicker-date-adapter.service';
import { PaginatorInternationalizationService } from '../core/paginator-internationalization.service';

import { NamesOfAppointmentTypesPipe } from './shared/names-of-appointment-types.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateMedicalHistoryDialogComponent } from './patient/shared/update-medical-history-dialog/update-medical-history-dialog.component';
import { FullSizeComparisonComponent } from './patient/general-examination/full-size-comparison/full-size-comparison.component';
import { DocumentListDialogComponent } from './patient/shared/document-list-dialog/document-list-dialog.component';
import { SpecialistFindingsFromArhiveDialogComponent } from './patient/shared/specialist-findings-from-arhive-dialog/specialist-findings-from-arhive-dialog.component';
import { UpdateDecursusMorbiDialogComponent } from './patient/shared/update-decursus-morbi-dialog/update-decursus-morbi-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { SurgeonWorkPlanComponent } from './surgeon-work-plan/surgeon-work-plan.component';
import { SurgeonCalendarComponent } from './surgeon-work-plan/surgeon-calendar/surgeon-calendar.component';

import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  MOMENT,
} from 'angular-calendar';
import { DateAdapter as Adapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { SurgeonListComponent } from './surgeon-work-plan/surgeon-list/surgeon-list.component';
import { SrugeonDialogComponent } from './surgeon-work-plan/srugeon-dialog/srugeon-dialog.component';
import { MessagesComponent } from './messages/messages.component';
import { MessageItemComponent } from './messages/message-item/message-item.component';
import { ProtocolsComponent } from './protocols/protocols.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ApartmentsComponent } from './apartments/apartments.component';
import { ApartmentDialogComponent } from './apartments/apartment-dialog/apartment-dialog.component';
import { ApartmentCalendarComponent } from './apartments/apartment-calendar/apartment-calendar.component';
import { ApartmentListComponent } from './apartments/apartment-list/apartment-list.component';
import { ApartmentsOccupancyComponent } from './apartments/apartments-occupancy/apartments-occupancy.component';
import { OperationAdministrationComponent } from './operation-administration/operation-administration/operation-administration.component';
import { CreateNewOperationComponent } from './operation-administration/dialogs/create-new-operation/create-new-operation/create-new-operation.component';
import { EditOperationComponent } from './operation-administration/dialogs/edit-operation/edit-operation.component';
import { CreateEmployeeDialogComponent } from './staff-administration/create-employee-dialog/create-employee-dialog/create-employee-dialog.component';
import { PricelistAdministrationComponent } from './pricelist-administration/pricelist-administration.component';
import { CreatePricelistComponent } from './pricelist-administration/dialogs/create-pricelist/create-pricelist.component';
import { UpdatePricelistComponent } from './pricelist-administration/dialogs/update-pricelist/update-pricelist.component';
import { FindInterventrionNamePipe } from '../core/pipes/find-intervention-name.pipe';
import { DeleteDialogComponent } from './operation-administration/dialogs/delete-dialog/delete-dialog.component';
import { PermissionAdministrationComponent } from './permission-administration/permission-administration/permission-administration.component';
import { UpdatePermissionComponent } from './permission-administration/dialogs/update-permission/update-permission.component';
import { MultiselectAutocompleteComponent } from './shared/multiselect-autocomplete/multiselect-autocomplete.component';
import { EventCalendarComponent } from './shared/event-calendar/event-calendar.component';
import { DayPilotModule } from '@daypilot/daypilot-lite-angular';
import { DataService } from './shared/event-calendar/data.service';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  imports: [
    DayPilotModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatSliderModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatBadgeModule,
    DashboardRoutingModule,
    MatExpansionModule,
    MatRadioModule,
    CalendarModule.forRoot(
      {
        provide: Adapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      },
    ),
  ],
  declarations: [
    FindInterventrionNamePipe,
    DashboardComponent,
    NewPatientComponent,
    SearchComponent,
    SchedulingComponent,
    RecipesComponent,
    StatisticsComponent,
    StaffAdministrationComponent,
    BusinessHoursComponent,
    HomeComponent,
    PatientComponent,
    CreateAppointmentDialogComponent,
    UpdateAppointmentDialogComponent,
    DeleteAppointmentDialogComponent,
    UpdateEmployeeDialogComponent,
    AppointmentTemplateDialogComponent,
    UpdateDayBusinessHoursDialogComponent,
    CompareDiagnosticImagesDialogComponent,
    UpdateAgreementDialogComponent,
    UpdateAnesthesiologicalExaminationDialogComponent,
    UpdateHospitalizationDialogComponent,
    UpdateOperationProtocolDialogComponent,
    UpdateInternalHistoryDialogComponent,
    UpdateSpecialistFindingsDialogComponent,
    UpdateLaboratoryDialogComponent,
    UpdateTemperatureNoteDialogComponent,
    CreateTemperatureNoteItemDialogComponent,
    UpdateDiabeticNoteDialogComponent,
    CreateDiabeticNoteItemDialogComponent,
    UpdateDischargeNoteDialogComponent,
    FilePreviewDialogComponent,
    RecipePreviewDialogComponent,
    NamesOfAppointmentTypesPipe,
    UpdateMedicalHistoryDialogComponent,
    FullSizeComparisonComponent,
    DocumentListDialogComponent,
    SpecialistFindingsFromArhiveDialogComponent,
    UpdateDecursusMorbiDialogComponent,
    SurgeonWorkPlanComponent,
    SurgeonCalendarComponent,
    SurgeonListComponent,
    SrugeonDialogComponent,
    MessagesComponent,
    MessageItemComponent,
    ProtocolsComponent,
    ApartmentsComponent,
    ApartmentDialogComponent,
    ApartmentCalendarComponent,
    ApartmentListComponent,
    ApartmentsOccupancyComponent,
    OperationAdministrationComponent,
    CreateNewOperationComponent,
    EditOperationComponent,
    CreateEmployeeDialogComponent,
    PricelistAdministrationComponent,
    CreatePricelistComponent,
    UpdatePricelistComponent,
    DeleteDialogComponent,
    PermissionAdministrationComponent,
    UpdatePermissionComponent,
    MultiselectAutocompleteComponent,
    EventCalendarComponent,
  ],
  providers: [
    DataService,
    DashboardService,
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorInternationalizationService,
    },
    {
      provide: DateAdapter,
      useClass: DatepickerDateAdapterService,
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
  ],
  exports: [MultiselectAutocompleteComponent],
})
export class DashboardModule {}
