import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/core/authorization.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import {
  AssignPermissionDTO,
  PermissionService,
} from 'src/app/core/services/permission.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { UpdatePermissionComponent } from '../dialogs/update-permission/update-permission.component';
import { DeleteDialogComponent } from '../../operation-administration/dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-permission-administration',
  templateUrl: './permission-administration.component.html',
  styleUrls: ['./permission-administration.component.scss'],
})
export class PermissionAdministrationComponent implements OnInit {
  permissions: any = [];
  helperList: any = [];
  nameInput: string;
  roleInput: number;

  displayedColumns: string[] = [
    'Name',
    // 'SuperUser',
    'Secretary',
    'Nurse',
    'Ophthalmologist',
    'Assistant',
    'Anesthesiologist',
    'Surgeon',
    'NewUser',
    'Delete',
  ];
  dataSource = new MatTableDataSource<any>(this.permissions);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public authorizationService: AuthorizationService,
    private progressBarService: ProgressBarService,
    private permissionService: PermissionService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllPermissions();
  }

  getAllPermissions() {
    this.progressBarService.start();
    this.permissionService.getAllPermissionsWithJobs().subscribe(
      (data) => {
        this.permissions = data;
        this.progressBarService.stop();
        this.helperList = [...this.permissions];
        this.initDataSource();
      },
      (error) => {
        console.log(error);
      },
    );
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<any>(this.permissions);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  updatePermission(element: any) {
    const dialogRef = this.dialog.open(UpdatePermissionComponent, {
      width: '900px',
      data: {
        permission: element,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelSearch();
        this.getAllPermissions();
      }
    });
  }

  deletePermission(element: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '300px', // You can adjust the width as needed
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Only proceed with the deletion if the result is true (i.e., the user clicked "Yes")
        this.progressBarService.start();
        this.permissionService.deletePermission(element.Id).subscribe(
          () => {
            this.progressBarService.stop();
            this.snackBarService.showSuccess('Uspesno obrisana dozvola');
            this.getAllPermissions();
          },
          (error) => {
            console.log(error);
          },
        );
      }
    });
  }

  assignPermission(element: any, jobId: number) {
    this.progressBarService.start();
    let dto: AssignPermissionDTO = {
      permissionId: element.Id,
      jobId: jobId,
    };
    this.permissionService.assignPermission(dto).subscribe(
      () => {
        this.progressBarService.stop();
        this.snackBarService.showSuccess('Uspesno dodeljena dozvola');
        this.getAllPermissions();
      },
      (error) => {
        console.log(error);
      },
    );
  }

  removePermission(element: any, jobId: number) {
    this.progressBarService.start();
    let dto: AssignPermissionDTO = {
      permissionId: element.Id,
      jobId: jobId,
    };
    this.permissionService.removePermission(dto).subscribe(
      () => {
        this.progressBarService.stop();
        this.snackBarService.showSuccess('Uspešno uklonjena dozvola');
        this.getAllPermissions();
      },
      (error) => {
        this.snackBarService.showError(
          'Morate ukloniti sve dozvole sa korisnika',
        );
        console.log(error);
      },
    );
  }

  searchPermissions() {
    this.permissions = [...this.helperList];
    if (this.nameInput) {
      this.permissions = this.permissions.filter((x: any) =>
        x.Description.toLowerCase().includes(this.nameInput.toLowerCase()),
      );
      this.initDataSource();
    }
    if (this.roleInput) {
      this.permissions = this.permissions.filter((x: any) =>
        x.Roles.some((role: any) => role.JobId === this.roleInput),
      );
      this.initDataSource();
    }
  }

  cancelSearch() {
    this.nameInput = '';
    this.roleInput = null;
    this.permissions = this.helperList;
    this.initDataSource();
  }

  initialCheckboxValues(element: any, jobId: number) {
    return element.Roles.some((role: any) => role.JobId === jobId);
  }

  handleCheckboxChange(permission: any, jobId: any): void {
    const isChecked = this.initialCheckboxValues(permission, jobId);

    if (!isChecked) {
      this.assignPermission(permission, jobId);
    } else {
      this.removePermission(permission, jobId);
    }
  }
}
