export class ExaminationChronology {
    Id: number | null;
    PatientId: number;
    Date: string;
    AppointmentId: number | null;
    BluePaper: string | null;
    ChildrenList: ExaminationChronology[] | null

    constructor(Id: number | null, PatientId: number, Date: string, AppointmentId: number | null, BluePaper: string | null, ChildrenList: [] | null) {
        this.Id = Id;
        this.PatientId = PatientId;
        this.Date = Date;
        this.AppointmentId = AppointmentId;
        this.BluePaper = BluePaper;
        this.ChildrenList = ChildrenList;
    }
}
