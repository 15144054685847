import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snack: MatSnackBar) {}

  showSuccess(message: string) {
    this.snack.open(message, '', {
      duration: 1500,
      panelClass: ['mat-toolbar', 'mat-primary'],
    });
  }

  showError(message: string) {
    this.snack.open(message, '', {
      duration: 3500,
      panelClass: ['mat-toolbar', 'mat-warn'],
    });
  }

  showInfo(message: string) {
    this.snack.open(message, '', {
      duration: 1500,
    });
  }
}
