import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DashboardService } from '../../../shared/dashboard.service';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { IssueDocument } from '../../../shared/issue-document-model';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import { DeleteDialogComponent } from 'src/app/dashboard/operation-administration/dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-document-list-dialog',
  templateUrl: './document-list-dialog.component.html',
  styleUrls: ['./document-list-dialog.component.scss'],
})
export class DocumentListDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  displayedColumns: string[] = ['Name', 'Date', 'Actions', 'Delete'];
  dataSource = new MatTableDataSource<IssueDocument>([]);

  constructor(
    private dialogRef: MatDialogRef<DocumentListDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  ngOnInit() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('patient_id') &&
      typeof this.data.patient_id === 'number' &&
      isNaN(this.data.patient_id) === false
    ) {
      const getDocumentListSubscribe = this.dashboardService
        .getIssuedDocumentsForPatient(this.data.patient_id)
        .subscribe(
          (response: IssueDocument[]) => {
            this.dataSource = new MatTableDataSource<IssueDocument>(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );
      this.subscriptions.add(getDocumentListSubscribe);
    }
  }

  onDownload(doc: IssueDocument) {
    const onDownloadSubscription = this.dashboardService
      .getIssuedDocumentById(doc.Id)
      .subscribe(
        (response: Blob) => {
          if (response && response instanceof Blob && response.size > 0) {
            let hyperlinkElement: HTMLElement = document.createElement('A');
            hyperlinkElement.setAttribute(
              'href',
              URL.createObjectURL(response),
            );
            hyperlinkElement.setAttribute(
              'download',
              doc.Name +
                formatDate(new Date(), 'dd-MM-yyyy HH-mm-ss', 'sr-Latn'),
            );
            hyperlinkElement.click();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );
    this.subscriptions.add(onDownloadSubscription);
  }

  deleteDocument(element: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const deleteDocumentSubscription = this.dashboardService
          .deleteIssuedDocument(element.Id)
          .subscribe(
            (response: any) => {
              if (response) {
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.Id !== element.Id,
                );
              }
            },
            (error) => this.errorHandlerService.handleError(error),
          );
        this.subscriptions.add(deleteDocumentSubscription);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
