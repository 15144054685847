import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DashboardService } from '../../shared/dashboard.service';
import { Observable, Subscription } from 'rxjs';
import { Surgeon } from '../../shared/surgeon.model';
import { SurgeonSchedule } from '../../shared/surgeon-schedule-model';
import { MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-srugeon-dialog',
  templateUrl: './srugeon-dialog.component.html',
  styleUrls: ['./srugeon-dialog.component.scss'],
})
export class SrugeonDialogComponent implements OnInit, OnDestroy {
  surgeonsList$: Observable<Array<Surgeon>>;
  subscriptions: Subscription = new Subscription();
  surgeonDialogForm = new UntypedFormGroup({
    FromDate: new UntypedFormControl({
      value: new Date(),
    }),
    ToDate: new UntypedFormControl({
      value: new Date(),
    }),
    Surgeon: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    private readonly dashboardService: DashboardService,
    public dialogRef: MatDialogRef<SrugeonDialogComponent>,
    private readonly progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    this.surgeonsList$ = this.dashboardService.getAllSurgeons();
    this.surgeonDialogForm.setValue({
      FromDate: new Date(),
      ToDate: new Date(),
      Surgeon: null,
    });
  }

  addAppointment() {
    this.progressBarService.start();

    if (
      this.surgeonDialogForm.get('FromDate').value >
      this.surgeonDialogForm.get('ToDate').value
    ) {
      this.snackbarService.showError(
        'Datum početka mora biti pre datuma kraja.',
      );
      this.progressBarService.stop();
      return;
    }

    const params = new SurgeonSchedule(
      null,
      this.surgeonDialogForm.get('Surgeon').value.Id,
      this.surgeonDialogForm.get('Surgeon').value.Name,
      formatDate(
        this.surgeonDialogForm.get('FromDate').value,
        'yyyy-MM-ddThh:mm:ss',
        'sr-Latn',
      ),
      formatDate(
        this.surgeonDialogForm.get('ToDate').value,
        'yyyy-MM-ddThh:mm:ss',
        'sr-Latn',
      ),
      null,
    );

    const saveSurgeonWorkingDaySubscription = this.dashboardService
      .saveSurgeonWorkingDay(params)
      .subscribe((response) => {
        this.progressBarService.stop();

        if (response) {
          this.snackbarService.showSuccess('Novi termin je uspešno kreiran.');
          this.dialogRef.close(response);
        }
      });
    this.subscriptions.add(saveSurgeonWorkingDaySubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
