import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';
import {
  standardYearDateFormatRegex,
  leapYearDateFormatRegex,
} from '../../shared/constants.service';

import { Gender } from '../shared/gender.model';
import { Employee } from '../shared/employee.model';
import { Patient } from '../shared/patient.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.css'],
})
export class NewPatientComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  gendersCollection: Gender[] = [];
  doctorsCollection: Employee[] = [];

  maxPatientDateOfBirth = new Date();

  createPatientForm = new UntypedFormGroup({
    Name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    MiddleName: new UntypedFormControl('', Validators.maxLength(64)),
    IdentificationNumber: new UntypedFormControl('', Validators.maxLength(64)),
    PersonalInsuranceNumber: new UntypedFormControl(
      '',
      Validators.pattern('[0-9]*'),
    ),
    PassportNumber: new UntypedFormControl(''),
    DateOfBirth: new UntypedFormControl(null),
    Occupation: new UntypedFormControl('', Validators.maxLength(64)),
    Address: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    City: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    State: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    GenderId: new UntypedFormControl(null),
    PhoneNumber: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    PhoneNumber1: new UntypedFormControl('', [
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    PhoneNumber2: new UntypedFormControl('', [
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    Email: new UntypedFormControl('', [Validators.required, Validators.email]),
    ChoosenDoctorId: new UntypedFormControl(null),
    Medicine: new UntypedFormControl(false),
    MedicineDescription: new UntypedFormControl(''),
    Allergies: new UntypedFormControl(false),
    AllergiesDescription: new UntypedFormControl(''),
    Hormones: new UntypedFormControl(false),
    HormonesDescription: new UntypedFormControl(''),
    Pregnant: new UntypedFormControl(false),
    PregnantMonth: new UntypedFormControl(null),
    Diabetes: new UntypedFormControl(false),
    PupilDialation: new UntypedFormControl(false),
    PupilDialationDescription: new UntypedFormControl(''),
    ChronicDisease: new UntypedFormControl(false),
    ChronicDiseaseDescription: new UntypedFormControl(''),
    EyeSurgery: new UntypedFormControl(false),
    EyeSurgeryDescription: new UntypedFormControl(''),
    OtherSurgery: new UntypedFormControl(false),
    OtherSurgeryDescription: new UntypedFormControl(''),
  });

  constructor(
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private readonly snackbarService: SnackbarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.progressBarService.start();

    const readGendersSubscription = this.dashboardService
      .readGenders()
      .subscribe(
        (response: Gender[]) => {
          this.progressBarService.stop();

          if (response.length > 0) this.gendersCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readGendersSubscription);

    const readDoctorsSubscription = this.dashboardService
      .readDoctors()
      .subscribe(
        (response: Employee[]) => {
          this.progressBarService.stop();

          if (response.length > 0) this.doctorsCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readDoctorsSubscription);

    this.createPatientForm.get('DateOfBirth').valueChanges.subscribe(date => {
      if (date) {
        const year = new Date(date).getFullYear();
        this.createPatientForm.get('PassportNumber').setValue(year.toString());
      }
    })

    // Clear validators, values and disable questionnaire inputs on initialization
    this.onChangeQuestionState('Medicine');
    this.onChangeQuestionState('Allergies');
    this.onChangeQuestionState('Hormones');
    this.onChangeQuestionState('Pregnant');
    this.onChangeQuestionState('PupilDialation');
    this.onChangeQuestionState('ChronicDisease');
    this.onChangeQuestionState('EyeSurgery');
    this.onChangeQuestionState('OtherSurgery');
  }

  onReadPhoneNumberErrorMessage(): string {
    return this.createPatientForm.get('PhoneNumber').hasError('required')
      ? 'Unesite broj telefona'
      : this.createPatientForm.get('PhoneNumber').hasError('pattern')
      ? 'Broj telefona mora da sadrži samo cifre'
      : '';
  }

  onReadEmailErrorMessage(): string {
    return this.createPatientForm.get('Email').hasError('required')
      ? 'Unesite e-mail'
      : this.createPatientForm.get('Email').hasError('email')
      ? 'Niste uneli validan e-mail'
      : '';
  }

  onReadPregnancyErrorMessage(): string {
    return this.createPatientForm.get('PregnantMonth').hasError('required')
      ? 'Unesite mesec trudnoće'
      : this.createPatientForm.get('PregnantMonth').hasError('min')
      ? 'Mesec trudnoće mora biti od 1 do 9'
      : this.createPatientForm.get('PregnantMonth').hasError('max')
      ? 'Mesec trudnoće mora biti od 1 do 9'
      : '';
  }

  onChangeQuestionState(questionType: string) {
    if (typeof questionType === 'string' && questionType.length > 0) {
      switch (questionType) {
        case 'Medicine':
          {
            if (
              typeof this.createPatientForm.get('Medicine').value === 'boolean'
            ) {
              if (this.createPatientForm.get('Medicine').value === true) {
                this.createPatientForm
                  .get('MedicineDescription')
                  .setValidators(Validators.required);
                this.createPatientForm.get('MedicineDescription').enable();
              } else {
                this.createPatientForm
                  .get('MedicineDescription')
                  .clearValidators();
                this.createPatientForm.get('MedicineDescription').setValue('');
                this.createPatientForm.get('MedicineDescription').disable();
              }
            }
          }
          break;
        case 'Allergies':
          {
            if (
              typeof this.createPatientForm.get('Allergies').value === 'boolean'
            ) {
              if (this.createPatientForm.get('Allergies').value === true) {
                this.createPatientForm
                  .get('AllergiesDescription')
                  .setValidators(Validators.required);
                this.createPatientForm.get('AllergiesDescription').enable();
              } else {
                this.createPatientForm
                  .get('AllergiesDescription')
                  .clearValidators();
                this.createPatientForm.get('AllergiesDescription').setValue('');
                this.createPatientForm.get('AllergiesDescription').disable();
              }
            }
          }
          break;
        case 'Hormones':
          {
            if (
              typeof this.createPatientForm.get('Hormones').value === 'boolean'
            ) {
              if (this.createPatientForm.get('Hormones').value === true) {
                this.createPatientForm
                  .get('HormonesDescription')
                  .setValidators(Validators.required);
                this.createPatientForm.get('HormonesDescription').enable();
              } else {
                this.createPatientForm
                  .get('HormonesDescription')
                  .clearValidators();
                this.createPatientForm.get('HormonesDescription').setValue('');
                this.createPatientForm.get('HormonesDescription').disable();
              }
            }
          }
          break;
        case 'Pregnant':
          {
            if (
              typeof this.createPatientForm.get('Pregnant').value === 'boolean'
            ) {
              if (this.createPatientForm.get('Pregnant').value === true) {
                this.createPatientForm
                  .get('PregnantMonth')
                  .setValidators([
                    Validators.required,
                    Validators.min(1),
                    Validators.max(9),
                  ]);
                this.createPatientForm.get('PregnantMonth').enable();
              } else {
                this.createPatientForm.get('PregnantMonth').clearValidators();
                this.createPatientForm.get('PregnantMonth').setValue(null);
                this.createPatientForm.get('PregnantMonth').disable();
              }
            }
          }
          break;
        case 'PupilDialation':
          {
            if (
              typeof this.createPatientForm.get('PupilDialation').value ===
              'boolean'
            ) {
              if (this.createPatientForm.get('PupilDialation').value === true) {
                this.createPatientForm
                  .get('PupilDialationDescription')
                  .setValidators(Validators.required);
                this.createPatientForm
                  .get('PupilDialationDescription')
                  .enable();
              } else {
                this.createPatientForm
                  .get('PupilDialationDescription')
                  .clearValidators();
                this.createPatientForm
                  .get('PupilDialationDescription')
                  .setValue('');
                this.createPatientForm
                  .get('PupilDialationDescription')
                  .disable();
              }
            }
          }
          break;
        case 'ChronicDisease':
          {
            if (
              typeof this.createPatientForm.get('ChronicDisease').value ===
              'boolean'
            ) {
              if (this.createPatientForm.get('ChronicDisease').value === true) {
                this.createPatientForm
                  .get('ChronicDiseaseDescription')
                  .setValidators(Validators.required);
                this.createPatientForm
                  .get('ChronicDiseaseDescription')
                  .enable();
              } else {
                this.createPatientForm
                  .get('ChronicDiseaseDescription')
                  .clearValidators();
                this.createPatientForm
                  .get('ChronicDiseaseDescription')
                  .setValue('');
                this.createPatientForm
                  .get('ChronicDiseaseDescription')
                  .disable();
              }
            }
          }
          break;
        case 'EyeSurgery':
          {
            if (
              typeof this.createPatientForm.get('EyeSurgery').value ===
              'boolean'
            ) {
              if (this.createPatientForm.get('EyeSurgery').value === true) {
                this.createPatientForm
                  .get('EyeSurgeryDescription')
                  .setValidators(Validators.required);
                this.createPatientForm.get('EyeSurgeryDescription').enable();
              } else {
                this.createPatientForm
                  .get('EyeSurgeryDescription')
                  .clearValidators();
                this.createPatientForm
                  .get('EyeSurgeryDescription')
                  .setValue('');
                this.createPatientForm.get('EyeSurgeryDescription').disable();
              }
            }
          }
          break;
        case 'OtherSurgery': {
          if (
            typeof this.createPatientForm.get('OtherSurgery').value ===
            'boolean'
          ) {
            if (this.createPatientForm.get('OtherSurgery').value === true) {
              this.createPatientForm
                .get('OtherSurgeryDescription')
                .setValidators(Validators.required);
              this.createPatientForm.get('OtherSurgeryDescription').enable();
            } else {
              this.createPatientForm
                .get('OtherSurgeryDescription')
                .clearValidators();
              this.createPatientForm
                .get('OtherSurgeryDescription')
                .setValue('');
              this.createPatientForm.get('OtherSurgeryDescription').disable();
            }
          }
        }
      }
    }
  }

  onCreatePatient() {
    this.progressBarService.start();
    let patient = new Patient(
      null,
      this.createPatientForm.value.Name,
      this.createPatientForm.value.MiddleName,
      this.createPatientForm.value.GenderId,
      this.createPatientForm.value.IdentificationNumber,
      this.createPatientForm.value.PassportNumber,
      this.createPatientForm.value.PersonalInsuranceNumber,
      this.createPatientForm.value.DateOfBirth,
      this.createPatientForm.value.Occupation,
      this.createPatientForm.value.Address,
      this.createPatientForm.value.PhoneNumber,
      this.createPatientForm.value.Email,
      this.createPatientForm.value.ChoosenDoctorId,
      this.createPatientForm.value.Medicine,
      this.createPatientForm.value.MedicineDescription,
      this.createPatientForm.value.Allergies,
      this.createPatientForm.value.AllergiesDescription,
      this.createPatientForm.value.Hormones,
      this.createPatientForm.value.HormonesDescription,
      this.createPatientForm.value.Pregnant,
      this.createPatientForm.value.PregnantMonth,
      this.createPatientForm.value.Diabetes,
      this.createPatientForm.value.PupilDialation,
      this.createPatientForm.value.PupilDialationDescription,
      this.createPatientForm.value.ChronicDisease,
      this.createPatientForm.value.ChronicDiseaseDescription,
      this.createPatientForm.value.EyeSurgery,
      this.createPatientForm.value.EyeSurgeryDescription,
      this.createPatientForm.value.OtherSurgery,
      this.createPatientForm.value.OtherSurgeryDescription,
      null,
      '',
      '',
      this.createPatientForm.value.City,
      this.createPatientForm.value.State,
      true,
      this.createPatientForm.value.PhoneNumber1,
      this.createPatientForm.value.PhoneNumber2,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );

    const createPatientSubscription = this.dashboardService
      .createPatient(patient)
      .subscribe(
        (patientId) => {
          this.progressBarService.stop();

          if (patientId) {
            this.createPatientForm.reset();

            this.snackbarService.showSuccess('Novi pacijent je uspešno dodat.');
          }
          this.dashboardService.setPatientId(patientId);
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(createPatientSubscription);
  }

  isCurrentYearLeap(): boolean {
    return new Date(new Date().getFullYear(), 1, 29).getDate() === 29;
  }

  isValidDateOfBirth(): boolean {
    let dateOfBirth: string = this.createPatientForm.get('DateOfBirth').value;
    return (
      dateOfBirth &&
      typeof dateOfBirth === 'string' &&
      dateOfBirth.length === 10 &&
      ((this.isCurrentYearLeap() === false &&
        RegExp(standardYearDateFormatRegex).test(dateOfBirth)) ||
        (this.isCurrentYearLeap() === true &&
          RegExp(leapYearDateFormatRegex).test(dateOfBirth))) &&
      new Date(
        parseInt(dateOfBirth.split('.')[2]),
        parseInt(dateOfBirth.split('.')[1]) - 1,
        parseInt(dateOfBirth.split('.')[0]),
        0,
        0,
        0,
        0,
      ).getTime() <= new Date().getTime()
    );
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
