import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

// Import the Angular Material components
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';

import { UpdateEmployeeDialogComponent } from './update-employee-dialog/update-employee-dialog.component';

import { Job } from '../shared/job.model';
import { Employee } from '../shared/employee.model';
import { CreateEmployeeDialogComponent } from './create-employee-dialog/create-employee-dialog/create-employee-dialog.component';
import { Titles } from 'src/app/shared/enum/titles-enum';
import { StaffService } from 'src/app/core/services/staff.service';
import { DeleteDialogComponent } from '../operation-administration/dialogs/delete-dialog/delete-dialog.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-staff-administration',
  templateUrl: './staff-administration.component.html',
  styleUrls: ['./staff-administration.component.css'],
})
export class StaffAdministrationComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  jobsCollection: Job[] = [];
  titleCollection: Titles[] = Object.values(Titles);
  nameInput: string = '';
  jobInput: Job = null;
  titleInput: string = '';

  permissions: string[] = [];

  displayedColumns: string[] = [
    'JobName',
    'Title',
    'Name',
    'Email',
    'Active',
    'Actions',
  ];
  allEmployees: Employee[] = [];
  helperList: Employee[] = [];
  dataSource = new MatTableDataSource<Employee>([]);

  enterRole: boolean;
  editRole: boolean;
  changePasswordRole: boolean;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public authorizationService: AuthorizationService,
    private dashboardService: DashboardService,
    private staffService: StaffService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.progressBarService.start();

    const readJobsSubscription = this.dashboardService.readJobs().subscribe(
      (response: Job[]) => {
        this.progressBarService.stop();

        if (response.length > 0) this.jobsCollection = response;
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(readJobsSubscription);

    this.getAllStaff();

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.enterRole = permissions.includes('ADMUNOS');
        this.editRole = permissions.includes('ADMIZMENA');
        this.changePasswordRole = permissions.includes('ADMRESET');
      },
      (error) => {
        console.error('Error fetching permissions', error);
      },
    );
  }

  onCreateEmployee() {
    const dialogRef = this.dialog.open(CreateEmployeeDialogComponent, {
      width: '900px',
      data: {
        jobsCollection: this.jobsCollection,
        titleCollection: this.titleCollection,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelSearch();
        this.getAllStaff();
        this.snackbarService.showSuccess('Novo osoblje je uspešno dodato.');
      }
    });
  }

  onOpenUpdateEmployeeDialog(employee: Employee) {
    const dialogRef = this.dialog.open(UpdateEmployeeDialogComponent, {
      width: '900px',
      data: employee,
    });

    const updateEmployeeDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.cancelSearch();
          this.getAllStaff();
        }
      });

    this.subscriptions.add(updateEmployeeDialogSubscription);
  }

  onResetPassword(employeeId: number) {
    if (typeof employeeId === 'number' && isNaN(employeeId) === false) {
      this.progressBarService.start();

      const resetPasswordSubscription = this.staffService
        .resetPassword(employeeId)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true)
              this.snackbarService.showSuccess(
                'Lozinka je uspešno resetovana.',
              );
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(resetPasswordSubscription);
    }
  }

  getAllStaff() {
    this.dataSource = new MatTableDataSource<Employee>([]);
    this.progressBarService.start();

    const readEmployeesSubscription = this.staffService.getAllStaff().subscribe(
      (response: Employee[]) => {
        this.progressBarService.stop();

        if (response.length > 0) {
          this.allEmployees = response;
          this.helperList = response;
          this.dataSource = new MatTableDataSource(response);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(readEmployeesSubscription);
  }

  deleteStaff(employeeId: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressBarService.start();
        this.staffService.deleteStaff(employeeId).subscribe(
          (response: boolean) => {
            if (response) {
              this.progressBarService.stop();
              this.cancelSearch();
              this.getAllStaff();
              this.snackbarService.showSuccess('Osoblje je uspešno obrisano.');
            }
          },
          (error) => {
            this.errorHandlerService.handleError(error),
              this.snackbarService.showInfo(
                'Došlo je do greške prilikom brisanja osoblja.',
              );
          },
        );
      }
    });
  }

  searchEmployees() {
    this.allEmployees = [...this.helperList];
    if (this.nameInput) {
      this.allEmployees = this.allEmployees.filter((x) =>
        x.Name.toLowerCase().includes(this.nameInput.toLowerCase()),
      );
      this.initDataSource();
    }
    if (this.jobInput) {
      this.allEmployees = this.allEmployees.filter(
        (x) => x.JobId == this.jobInput.Id,
      );
      this.initDataSource();
    }
    if (this.titleInput) {
      this.allEmployees = this.allEmployees.filter(
        (x) => x.Title == this.titleInput,
      );
      this.initDataSource();
    }
    if (!this.nameInput && !this.jobInput && !this.titleInput) {
      this.allEmployees = this.helperList;
      this.initDataSource();
    }
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<Employee>(this.allEmployees);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  cancelSearch() {
    this.nameInput = '';
    this.jobInput = null;
    this.titleInput = '';
    this.allEmployees = [...this.helperList];
    this.initDataSource();
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
