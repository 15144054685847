import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

// Import the Angular Material component
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { DiagnosticImage } from "../../../shared/diagnostic-image.model";
import { FullSizeComparisonComponent } from '../full-size-comparison/full-size-comparison.component';

@Component({
    selector: 'app-compare-diagnostic-images-dialog',
    templateUrl: './compare-diagnostic-images-dialog.component.html',
    styleUrls: ['./compare-diagnostic-images-dialog.component.css']
})
export class CompareDiagnosticImagesDialogComponent implements OnInit {
    imageSourcePrefix: string = "data:image/JPEG;base64,";

    baseImagesCollection: DiagnosticImage[] = [];
    selectedBaseImageId: number | null = null;
    selectedBaseImageSource: string = "";

    comparisonImagesCollection: DiagnosticImage[] = [];
    selectedComparisonImageId: number | null = null;
    selectedComparisonImageSource: string = "";

    readComparisonImageForm = new UntypedFormGroup({
        SideBySideComparison: new UntypedFormControl(false)
    });

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialog: MatDialog) { }

    ngOnInit() {
        if (this.data && typeof this.data === "object") {
            if (this.data.hasOwnProperty("base_images") && typeof this.data.base_images === "object" && this.data.base_images.length > 0) {
                this.baseImagesCollection = this.data.base_images;
                this.onChangeSelectedImage("base", 0);
            }

            if (this.data.hasOwnProperty("comparison_images") && typeof this.data.comparison_images === "object" && this.data.comparison_images.length > 0) {
                this.comparisonImagesCollection = this.data.comparison_images;
                this.onChangeSelectedImage("comparison", 0);
            }
        }
    }

    onChangeComparisonTypeState() {
        if (typeof this.readComparisonImageForm.get('SideBySideComparison').value === "boolean") this.readComparisonImageForm.get('SideBySideComparison').setValue(!this.readComparisonImageForm.get('SideBySideComparison').value);
    }

    onChangeSelectedImage(imageType: string, index: number) {
        if ((typeof imageType === "string" && imageType.length > 0 && (imageType.toLowerCase() === "base" || imageType.toLowerCase() === "comparison")) && typeof index === "number" && isNaN(index) === false) {
            switch (imageType.toLowerCase()) {
                case "base":
                    {
                        if (this.baseImagesCollection[index] && typeof this.baseImagesCollection[index] === "object") {
                            if (this.baseImagesCollection[index].hasOwnProperty("Id") && typeof this.baseImagesCollection[index].Id === "number" && isNaN(this.baseImagesCollection[index].Id) === false) this.selectedBaseImageId = this.baseImagesCollection[index].Id;
                            else this.selectedBaseImageId = null;

                            if (this.baseImagesCollection[index].hasOwnProperty("FileStream") && typeof this.baseImagesCollection[index].FileStream === "string" && this.baseImagesCollection[index].FileStream.length > 0) this.selectedBaseImageSource = this.imageSourcePrefix + this.baseImagesCollection[index].FileStream;
                            else this.selectedBaseImageSource = "";
                        }
                    }
                    break;
                case "comparison":
                    {
                        if (this.comparisonImagesCollection[index] && typeof this.comparisonImagesCollection[index] === "object") {
                            if (this.comparisonImagesCollection[index].hasOwnProperty("Id") && typeof this.comparisonImagesCollection[index].Id === "number" && isNaN(this.comparisonImagesCollection[index].Id) === false) this.selectedComparisonImageId = this.comparisonImagesCollection[index].Id;
                            else this.selectedComparisonImageId = null;

                            if (this.comparisonImagesCollection[index].hasOwnProperty("FileStream") && typeof this.comparisonImagesCollection[index].FileStream === "string" && this.comparisonImagesCollection[index].FileStream.length > 0) this.selectedComparisonImageSource = this.imageSourcePrefix + this.comparisonImagesCollection[index].FileStream;
                            else this.selectedComparisonImageSource = "";
                        }
                    }
            }
        }
    }

    onOpenFullSizeComperisonDialog() {
        if (this.selectedComparisonImageSource.length > 0 && this.selectedBaseImageSource.length > 0) {
            const diagnosticImagesData = {
                "comparison_image": this.selectedComparisonImageSource,
                "base_image": this.selectedBaseImageSource
            };

            this.dialog.open(FullSizeComparisonComponent, {
                minWidth: "60vw",
                minHeight: "90vh",
                data: diagnosticImagesData
            });
        }
    }
}
