import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body instanceof FormData) {
      // For FormData, do not set the 'Content-Type' header
      // This allows the browser to set it with the correct boundary
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.authenticationService.getToken(),
        },
      });
    } else {
      // For JSON data, set the 'Content-Type' to 'application/json'
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.authenticationService.getToken(),
        },
      });
    }

    return next.handle(request);
  }
}
