import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import {
  PermissionService,
  Permissions,
} from 'src/app/core/services/permission.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-permission',
  templateUrl: './update-permission.component.html',
  styleUrls: ['./update-permission.component.scss'],
})
export class UpdatePermissionComponent implements OnInit {
  nameInput: string;
  selectedPermission: any;

  constructor(
    public dialogRef: MatDialogRef<UpdatePermissionComponent>,
    private permissionService: PermissionService,
    private snackbarService: SnackbarService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.selectedPermission = this.data.permission;
    this.nameInput = this.selectedPermission.Description;
  }

  onSubmit() {
    this.progressBarService.start();
    let dto: Permissions = {
      id: this.selectedPermission.Id,
      key: this.selectedPermission.Key,
      description: this.nameInput,
      roles: this.selectedPermission.Roles,
    };

    this.permissionService.updatePermission(dto).subscribe({
      next: () => {
        this.progressBarService.stop();
        this.snackbarService.showSuccess('Uspesno izmenjena dozvola.');
        this.dialogRef.close(true);
      },
      error: (error: any) => {
        this.progressBarService.stop();
        console.log(error);
      },
    });
  }

  isFormValid() {
    if (!this.nameInput) return true;
    return false;
  }
}
