export class SurgeonSchedule {
    constructor(
        public Id: number,
        public SurgeonId: number,
        public Name: string,
        public Start: string,
        public End: string,
        public Schedule: string
    ) { }
}
