import { Injectable } from '@angular/core';

// Import the Angular Material service
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable({
  providedIn: 'root'
})
export class PaginatorInternationalizationService extends MatPaginatorIntl {
  itemsPerPageLabel: string = "Stavki po stranici";
  firstPageLabel: string = "Prva stranica";
  previousPageLabel: string = "Prethodna stranica";
  nextPageLabel: string = "Sledeća stranica";
  lastPageLabel: string = "Poslednja stranica";

  constructor() {
      super();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) return `0 od ${length}`;
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} od ${length}`;
  }
}