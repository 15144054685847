//export const apiUrl = 'https://localhost:44303/api'; // Development
//export const apiUrl = 'http://localhost:8080/api'; // Production
export const apiUrl = 'https://reservisi.me/api' // Linode ????
export const timeFormat1stRegex = '^([0-1][0-9]|2[0-3]):([0-5][0-9])$'; // Time in format XX:XX (from 00:00 to 23:59)
export const timeFormat2ndRegex =
  '^(00:0[1-9])|(00:[1-5][0-9])|((0[1-9]|1[0-9]|2[0-3]):[0-5][0-9])$'; // Time in format XX:XX (from 00:01 to 23:59)
export const standardYearDateFormatRegex =
  '^(((0[1-9]|[1-2][0-9]|3[0-1])\\.(0(1|3|5|7|8)|1(0|2)))|((0[1-9]|[1-2][0-9]|30)\\.(0(4|6|9)|11))|((0[1-9]|1[0-9]|2[0-8])\\.02))\\.(19[0-9][0-9]|20[0-9][0-9])$'; // Date in format dd.MM.yyyy for standard year (not leap year). Years are in range [1900-2099].
export const leapYearDateFormatRegex =
  '^(((0[1-9]|[1-2][0-9]|3[0-1])\\.(0(1|3|5|7|8)|1(0|2)))|((0[1-9]|[1-2][0-9]|30)\\.(0(4|6|9)|11))|((0[1-9]|[1-2][0-9])\\.02))\\.(19[0-9][0-9]|20[0-9][0-9])$'; // Date in format dd.MM.yyyy for leap year. Years are in range [1900-2099].
