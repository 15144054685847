export class OperationProtocol {
    Id: number | null;
    LaserExclaimerId: number | null;
    SurnameMiddleNameAndName: string;
    HistoryIdentificationNumber: string;
    Date: string | null;
    PersonalIdentificationNumber: string;
    ClinicalDiagnosis: string;
    Surgery: string;
    SurgeryFlow: string;
    PreOperationDiagnostics: string;
    PreMedication: string;
    SurgeryConclusion: string;
    Anestezia: string;
    Duration: string;
    Surgeon: string;
    Assistent: string;
    Anesteziologist: string;
    Note: string;
    Anesthetist: string;
    OrdinalNumber: number;

    constructor(Id: number | null, LaserExclaimerId: number | null, SurnameMiddleNameAndName: string, HistoryIdentificationNumber: string, Date: string | null, PersonalIdentificationNumber: string, ClinicalDiagnosis: string, Surgery: string, SurgeryFlow: string, PreOperationDiagnostics: string, PreMedication: string, SurgeryConclusion: string, Anestezia: string, Duration: string, Surgeon: string, Assistent: string, Anesteziologist: string, Note: string, Anesthetist: string, OrdinalNumber: number) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.SurnameMiddleNameAndName = SurnameMiddleNameAndName;
        this.HistoryIdentificationNumber = HistoryIdentificationNumber;
        this.Date = Date;
        this.PersonalIdentificationNumber = PersonalIdentificationNumber;
        this.ClinicalDiagnosis = ClinicalDiagnosis;
        this.Surgery = Surgery;
        this.SurgeryFlow = SurgeryFlow;
        this.PreOperationDiagnostics = PreOperationDiagnostics;
        this.PreMedication = PreMedication;
        this.SurgeryConclusion = SurgeryConclusion;
        this.Anestezia = Anestezia;
        this.Duration = Duration;
        this.Surgeon = Surgeon;
        this.Assistent = Assistent;
        this.Anesteziologist = Anesteziologist;
        this.Note = Note;
        this.Anesthetist = Anesthetist;
        this.OrdinalNumber = OrdinalNumber;
    }
}
