import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterventionType } from '../../operation-administration/operation-administration.component';
import { InterventionTypeService } from 'src/app/core/services/intervention-type.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';

@Component({
  selector: 'app-edit-operation',
  templateUrl: './edit-operation.component.html',
  styleUrls: ['./edit-operation.component.scss'],
})
export class EditOperationComponent implements OnInit {
  idForEdit: number = 0;
  nameInput: string = '';
  levelInput: number = null;
  parentIdInput: number = null;
  allInterventionTypers: InterventionType[] = [];
  availableTypes: InterventionType[] = [];
  isAvailablyTypesDisabled: boolean = true;
  interventionType: InterventionType = null;

  constructor(
    public dialogRef: MatDialogRef<EditOperationComponent>,
    private interventionTypeService: InterventionTypeService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.allInterventionTypers = this.data.helperList;
    this.interventionType = this.data.interventionType;
    this.idForEdit = this.interventionType.Id;
    this.nameInput = this.interventionType.Name;
    this.levelInput = this.interventionType.Level;
    this.parentIdInput = this.interventionType.ParentId;
    this.changeLevelInput();
  }

  changeLevelInput() {
    if (this.levelInput == 1) {
      this.isAvailablyTypesDisabled = true;
      this.availableTypes = [];
      return;
    }
    this.isAvailablyTypesDisabled = false;
    this.availableTypes = this.allInterventionTypers.filter(
      (type) => type.Level == +this.levelInput - 1,
    );
  }

  onSubmit() {
    this.progressBarService.start();

    this.interventionTypeService
      .updateIntervention({
        Id: this.idForEdit,
        Name: this.nameInput,
        Level: this.levelInput,
        ParentId: this.levelInput !== 1 ? this.parentIdInput : null,
      })
      .subscribe(
        () => {
          this.progressBarService.stop();
          this.dialogRef.close(true);
        },
        (error) => {
          this.progressBarService.stop();
          console.log(error);
        },
      );
  }

  isFormValid() {
    if (!this.nameInput) return true;
    if (!this.levelInput) return true;
  }
}
