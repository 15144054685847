export class Recipe {
    Id: number | null;
    ExamChartId: number | null;
    DoctorId: number | null;
    DistanceOD: string;
    DistanceOS: string;
    NearOD: string;
    NearOS: string;
    DistancePD: string;
    NearPD: string;
    Date: string;

    constructor(Id: number | null, ExamChartId: number | null, DoctorId: number | null, DistanceOD: string, DistanceOS: string, NearOD: string, NearOS: string, DistancePD: string, NearPD: string, Date: string) {
        this.Id = Id;
        this.ExamChartId = ExamChartId;
        this.DoctorId = DoctorId;
        this.DistanceOD = DistanceOD;
        this.DistanceOS = DistanceOS;
        this.NearOD = NearOD;
        this.NearOS = NearOS;
        this.DistancePD = DistancePD;
        this.NearPD = NearPD;
        this.Date = Date;
    }
}