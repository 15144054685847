import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationService } from './authentication.service';
import { AuthorizationService } from "./authorization.service";
import { ErrorHandlerService } from "./error-handler.service";
import { DatepickerDateAdapterService } from "./datepicker-date-adapter.service";
import { PaginatorInternationalizationService } from "./paginator-internationalization.service";
import { ProgressBarService } from "./progress-bar.service";

@NgModule({
  imports: [
      CommonModule
  ],
  declarations: [],
  providers: [AuthenticationService, AuthorizationService, ErrorHandlerService, DatepickerDateAdapterService, PaginatorInternationalizationService, ProgressBarService]
})
export class CoreModule {}