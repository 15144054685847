import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ProgressBarService } from '../../../core/progress-bar.service';
import { timeFormat1stRegex } from '../../../shared/constants.service';

import { Appointment } from '../../shared/appointment.model';
import { AppointmentType } from '../../shared/appointment-type.model';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-appointment-dialog',
  templateUrl: './update-appointment-dialog.component.html',
  styleUrls: ['./update-appointment-dialog.component.css'],
})
export class UpdateAppointmentDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  appointment: Appointment;
  patient: string = '';
  appointmentTypesCollection: AppointmentType[] = [];
  appointmentTimesCollection: string[] = [];

  updateAppointmentForm = new UntypedFormGroup({
    AppointmentTypes: new UntypedFormControl(null, Validators.required),
    Time: new UntypedFormControl('', Validators.required),
    CenaPregleda: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
    ]),
    Anamnesis: new UntypedFormControl('', Validators.required),
    Note: new UntypedFormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data && typeof this.data === 'object') {
      if (this.data.hasOwnProperty('appointment')) {
        this.appointment = this.data.appointment;

        // Parsing Patient data before displaying
        if (
          this.appointment.hasOwnProperty('PatientName') &&
          typeof this.appointment.PatientName === 'string' &&
          this.appointment.PatientName.length > 0
        )
          this.patient = this.appointment.PatientName;

        if (
          this.appointment.hasOwnProperty('PatientDateOfBirth') &&
          typeof this.appointment.PatientDateOfBirth === 'string' &&
          this.appointment.PatientDateOfBirth.length > 0
        ) {
          if (this.patient.length > 0)
            this.patient +=
              ', ' +
              formatDate(
                this.appointment.PatientDateOfBirth,
                'dd.MM.yyyy',
                'sr-Latn',
              );
          else
            this.patient = formatDate(
              this.appointment.PatientDateOfBirth,
              'dd.MM.yyyy',
              'sr-Latn',
            );
        }

        if (
          this.appointment.hasOwnProperty('PhoneNumber') &&
          typeof this.appointment.PhoneNumber === 'string' &&
          this.appointment.PhoneNumber.length > 0
        ) {
          if (this.patient.length > 0)
            this.patient += ', ' + this.appointment.PhoneNumber;
          else this.patient = this.appointment.PhoneNumber;
        }

        // Assign values to Price, Anamnesis and Note
        if (
          this.appointment.hasOwnProperty('CenaPregleda') &&
          typeof this.appointment.CenaPregleda === 'number' &&
          isNaN(this.appointment.CenaPregleda) === false &&
          this.appointment.CenaPregleda >= 0
        )
          this.updateAppointmentForm
            .get('CenaPregleda')
            .setValue(this.appointment.CenaPregleda);
        if (
          this.appointment.hasOwnProperty('Anamnesis') &&
          typeof this.appointment.Anamnesis === 'string' &&
          this.appointment.Anamnesis.length > 0
        )
          this.updateAppointmentForm
            .get('Anamnesis')
            .setValue(this.appointment.Anamnesis);
        if (
          this.appointment.hasOwnProperty('Note') &&
          typeof this.appointment.Note === 'string' &&
          this.appointment.Note.length > 0
        )
          this.updateAppointmentForm
            .get('Note')
            .setValue(this.appointment.Note);
      }

      if (this.data.hasOwnProperty('appointment_types')) {
        this.appointmentTypesCollection = this.data.appointment_types;

        // Selecting corresponding Appointment types
        if (
          this.appointment &&
          typeof this.appointment === 'object' &&
          this.appointment.hasOwnProperty('AppointmentTypes') &&
          typeof this.appointment.AppointmentTypes === 'object' &&
          this.appointment.AppointmentTypes.length > 0
        ) {
          let selectedAppointmentTypesCollection: AppointmentType[] = [];

          for (let appointmentType of this.appointment.AppointmentTypes) {
            if (
              typeof appointmentType === 'object' &&
              appointmentType.hasOwnProperty('Id')
            ) {
              let filteredAppointmentTypes: AppointmentType[] =
                this.appointmentTypesCollection.filter(
                  (currentElement) =>
                    typeof currentElement === 'object' &&
                    currentElement.hasOwnProperty('Id') &&
                    currentElement.Id === appointmentType.Id,
                );
              if (
                filteredAppointmentTypes &&
                typeof filteredAppointmentTypes === 'object' &&
                filteredAppointmentTypes.length > 0
              )
                selectedAppointmentTypesCollection.push(
                  filteredAppointmentTypes[0],
                );
            }
          }

          this.updateAppointmentForm
            .get('AppointmentTypes')
            .setValue(selectedAppointmentTypesCollection);
        }
      }

      if (this.data.hasOwnProperty('appointment_times')) {
        this.appointmentTimesCollection = this.data.appointment_times;

        if (
          this.appointment &&
          typeof this.appointment === 'object' &&
          this.appointment.hasOwnProperty('Time') &&
          typeof this.appointment.Time === 'string' &&
          this.appointment.Time.length > 0 &&
          RegExp(timeFormat1stRegex).test(this.appointment.Time)
        ) {
          // Selecting corresponding Appointment time
          if (
            this.appointmentTimesCollection.filter(
              (currentElement) =>
                typeof currentElement === 'string' &&
                currentElement.length > 0 &&
                currentElement === this.appointment.Time,
            ).length > 0
          )
            this.updateAppointmentForm
              .get('Time')
              .setValue(this.appointment.Time);
        }
      }
    }

    this.subscriptions.add(
      this.updateAppointmentForm.get('AppointmentTypes').valueChanges.subscribe(selectedTypes => {
        this.calculateAndUpdatePrice(selectedTypes);
      })
    )
  }

  onReadPriceErrorMessage(): string {
    return this.updateAppointmentForm.get('CenaPregleda').hasError('required')
      ? 'Unesite cenu'
      : this.updateAppointmentForm.get('CenaPregleda').hasError('min')
      ? 'Cena mora biti veća ili jednaka 0'
      : '';
  }

  calculateAndUpdatePrice(selectedTypes: AppointmentType[]){
    if (!selectedTypes || selectedTypes.length === 0){
      this.updateAppointmentForm.get('CenaPregleda').setValue(0);
      return;
    }

    let totalPrice = selectedTypes.reduce((sum, currentType) => {
      return sum + (currentType.Price || 0);
    }, 0);

    this.updateAppointmentForm.get('CenaPregleda').setValue(totalPrice);
  }

  onUpdateAppointment() {
    if (
      this.appointment &&
      typeof this.appointment === 'object' &&
      this.appointment.hasOwnProperty('AppointmentTypes') &&
      this.appointment.hasOwnProperty('Time') &&
      this.appointment.hasOwnProperty('CenaPregleda') &&
      this.appointment.hasOwnProperty('Anamnesis')
    ) {
      if (
        typeof this.updateAppointmentForm.get('AppointmentTypes').value ===
          'object' &&
        this.updateAppointmentForm.get('AppointmentTypes').value.length > 0
      )
        this.appointment.AppointmentTypes =
          this.updateAppointmentForm.get('AppointmentTypes').value;

      if (
        typeof this.updateAppointmentForm.get('Time').value === 'string' &&
        this.updateAppointmentForm.get('Time').value.length > 0 &&
        RegExp(timeFormat1stRegex).test(
          this.updateAppointmentForm.get('Time').value,
        )
      )
        this.appointment.Time = this.updateAppointmentForm.get('Time').value;

      if (
        typeof this.updateAppointmentForm.get('CenaPregleda').value ===
          'number' &&
        isNaN(this.updateAppointmentForm.get('CenaPregleda').value) === false &&
        this.updateAppointmentForm.get('CenaPregleda').value >= 0
      )
        this.appointment.CenaPregleda =
          this.updateAppointmentForm.get('CenaPregleda').value;

      if (
        typeof this.updateAppointmentForm.get('Anamnesis').value === 'string' &&
        this.updateAppointmentForm.get('Anamnesis').value.length > 0
      )
        this.appointment.Anamnesis =
          this.updateAppointmentForm.get('Anamnesis').value;

      if (
        typeof this.updateAppointmentForm.get('Note').value === 'string' &&
        this.updateAppointmentForm.get('Note').value.length > 0
      )
        this.appointment.Note = this.updateAppointmentForm.get('Note').value;

      this.progressBarService.start();

      const updateAppointmentSubscription = this.dashboardService
        .updateAppointment(this.appointment)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();
            if (response === true) {
              this.snackbarService.showSuccess(
                'Zakazani termin pregleda je uspešno izmenjen.',
              );
              this.dialogRef.close(response);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updateAppointmentSubscription);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
