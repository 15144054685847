import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Subscription, Observable, forkJoin } from 'rxjs';

// Import the Angular Material components
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';

import { Diagnosis } from '../../../shared/diagnosis.model';
import { Employee } from '../../../shared/employee.model';
import { DischargeNote } from '../../../shared/discharge-note.model';
import { InterventionType } from '../../../shared/intervention-type.model';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-discharge-note-dialog',
  templateUrl: './update-discharge-note-dialog.component.html',
  styleUrls: ['./update-discharge-note-dialog.component.css'],
})
export class UpdateDischargeNoteDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  dischargeNoteId: number | null = null;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  diagnosisSearchFormControl = new UntypedFormControl();
  matchedDiagnosisCollection: Observable<Diagnosis[]>;
  selectedDiagnosisCollection: Diagnosis[] = [];
  diagnosisCollection: Diagnosis[] = [];
  doctorsCollection: Employee[] = [];
  intreventionTypesCollection: InterventionType[] = [];
  intreventionTypes2Collection: InterventionType[] = [];
  intreventionTypes3Collection: InterventionType[] = [];
  intreventionTypes4Collection: InterventionType[] = [];
  intreventionTypesCollectionOS: InterventionType[] = [];
  intreventionTypes2CollectionOS: InterventionType[] = [];
  intreventionTypes3CollectionOS: InterventionType[] = [];
  intreventionTypes4CollectionOS: InterventionType[] = [];

  updateDischargeNoteForm = new UntypedFormGroup({
    Date: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    TreatedFromDate: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    TreatedToDate: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    SurgeryDate: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    LeftEye: new UntypedFormControl(false),
    TypeOfSurgery: new UntypedFormControl(''),
    SurgeonId: new UntypedFormControl(null),
    Description: new UntypedFormControl(''),
    Therapy: new UntypedFormControl(''),
    Recheck: new UntypedFormControl(''),
    DoctorId: new UntypedFormControl(null),
    LoggedInDoctorId: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    DiagnosisOnArrival: new UntypedFormControl(null),
    DiagnosisOnDischarge: new UntypedFormControl(null),
    InterventionTypeLevel1IdOD: new UntypedFormControl(null),
    InterventionTypeLevel1IdOS: new UntypedFormControl(null),
    InterventionTypeLevel2IdOD: new UntypedFormControl(null),
    InterventionTypeLevel2ODText: new UntypedFormControl(null),
    InterventionTypeLevel2IdOS: new UntypedFormControl(null),
    InterventionTypeLevel2OSText: new UntypedFormControl(null),
    InterventionTypeLevel3ODId: new UntypedFormControl(null),
    InterventionTypeLevel3ODText: new UntypedFormControl(null),
    InterventionTypeLevel3OSId: new UntypedFormControl(null),
    InterventionTypeLevel3OSText: new UntypedFormControl(null),
    InterventionTypeLevel3OUId: new UntypedFormControl(null),
    InterventionTypeLevel4IdOD: new UntypedFormControl(null),
    InterventionTypeLevel4ODText: new UntypedFormControl(null),
    InterventionTypeLevel4IdOS: new UntypedFormControl(null),
    InterventionTypeLevel4OSText: new UntypedFormControl(null),
    ArrivalTOD: new UntypedFormControl(null),
    ArrivalTOS: new UntypedFormControl(null),
    ArrivalVOD: new UntypedFormControl(null),
    ArrivalVOS: new UntypedFormControl(null),
    DischargeTOD: new UntypedFormControl(null),
    DischargeTOS: new UntypedFormControl(null),
    DischargeVOD: new UntypedFormControl(null),
    DischargeVOS: new UntypedFormControl(null),
    ODOU: new UntypedFormControl(false),
    OSOU: new UntypedFormControl(false),
  });

  dischargeNoteEditRole: boolean;
  dischargeNotePrintRole: boolean;

  @ViewChild('dgSearchInput', { static: false })
  dgSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('dgAutocomplete', { static: false })
  matAutocomplete: MatAutocomplete;

  constructor(
    private dialogRef: MatDialogRef<UpdateDischargeNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.dischargeNoteEditRole = permissions.includes('OTPLIZMENA');
        this.dischargeNotePrintRole = permissions.includes('OTPLSTAMPA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  
    this.resetForm();
    this.loadFormData();
  }

  resetForm() { 
    this.updateDischargeNoteForm.reset({
      Date: null,
      TreatedFromDate: null,
      TreatedToDate: null,
      SurgeryDate: null,
      LeftEye: false,
      TypeOfSurgery: '',
      SurgeonId: null,
      Description: '',
      Therapy: '',
      Recheck: '',
      DoctorId: null,
      LoggedInDoctorId: null,
      DiagnosisOnArrival: null,
      DiagnosisOnDischarge: null,
      InterventionTypeLevel1IdOD: null,
      InterventionTypeLevel1IdOS: null,
      InterventionTypeLevel2IdOD: null,
      InterventionTypeLevel2ODText: null,
      InterventionTypeLevel2IdOS: null,
      InterventionTypeLevel2OSText: null,
      InterventionTypeLevel3ODId: null,
      InterventionTypeLevel3ODText: null,
      InterventionTypeLevel3OSId: null,
      InterventionTypeLevel3OSText: null,
      InterventionTypeLevel3OUId: null,
      InterventionTypeLevel4IdOD: null,
      InterventionTypeLevel4ODText: null,
      InterventionTypeLevel4IdOS: null,
      InterventionTypeLevel4OSText: null,
      ArrivalTOD: null,
      ArrivalTOS: null,
      ArrivalVOD: null,
      ArrivalVOS: null,
      DischargeTOD: null,
      DischargeTOS: null,
      DischargeVOD: null,
      DischargeVOS: null,
      ODOU: false,
      OSOU: false,
    });
  }

  loadFormData() {
    const userId$ = this.authorizationService.getUserId();

  const combinedObservables$ = forkJoin({
    surgeonsWithDoctors: this.dashboardService.getAllSurgeonsWithDoctors(),
    readInterventionTypes: this.dashboardService.readInterventionTypesForFirstLevel(),
    userId: userId$,
  });

  const subscription = combinedObservables$.subscribe(
    ({ surgeonsWithDoctors, readInterventionTypes, userId }) => {
      if (surgeonsWithDoctors && surgeonsWithDoctors.length > 0) {
        this.doctorsCollection = surgeonsWithDoctors;
      }

      if (readInterventionTypes && readInterventionTypes.length > 0) {
        this.intreventionTypesCollection = readInterventionTypes;
        this.intreventionTypesCollectionOS = readInterventionTypes;
      }

      if (
        this.data &&
        this.data.source_type &&
        (this.data.source_type.toLowerCase() === 'excimer_laser' ||
          this.data.source_type.toLowerCase() === 'operations') &&
        !isNaN(this.data.source_id) &&
        this.data.has_discharge_note
      ) {
        this.readDischargeNoteByExcimerLaser(userId);
      } else {
        this.updateDischargeNoteForm.get('LoggedInDoctorId').setValue(userId);
      }
    },
    (error) => this.errorHandlerService.handleError(error),
  );

  this.subscriptions.add(subscription);
  }

  onDeleteDiagnosisChip(diagnosis: Diagnosis) {
    if (
      diagnosis &&
      typeof diagnosis === 'object' &&
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0
    ) {
      if (this.selectedDiagnosisCollection.includes(diagnosis))
        this.selectedDiagnosisCollection.splice(
          this.selectedDiagnosisCollection.indexOf(diagnosis),
          1,
        );
    }
  }

  onSelectDiagnosisOption(event: MatAutocompleteSelectedEvent) {
    if (
      event &&
      typeof event === 'object' &&
      event.hasOwnProperty('option') &&
      event.option &&
      typeof event.option === 'object' &&
      event.option.hasOwnProperty('value') &&
      event.option.value &&
      typeof event.option.value === 'object'
    ) {
      if (
        this.selectedDiagnosisCollection &&
        typeof this.selectedDiagnosisCollection === 'object'
      ) {
        if (this.selectedDiagnosisCollection.length > 0) {
          if (this.selectedDiagnosisCollection.includes(event.option.value)) {
            this.snackbarService.showInfo('Dijagnoza je već izabrana.');
          } else this.selectedDiagnosisCollection.push(event.option.value);
        } else this.selectedDiagnosisCollection.push(event.option.value);
      }

      this.dgSearchInput.nativeElement.value = '';
      this.diagnosisSearchFormControl.setValue(null);
    }
  }

  private filterDiagnosisByName(searchTerm: string): Diagnosis[] {
    return typeof searchTerm === 'string' && searchTerm.length > 0
      ? this.diagnosisCollection.filter(
          (currentElement) =>
            currentElement &&
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Name') &&
            typeof currentElement.Name === 'string' &&
            currentElement.Name.length > 0 &&
            currentElement.Name.toLowerCase().includes(
              searchTerm.toLowerCase(),
            ),
        )
      : [];
  }

  filterSelectedDiagnosis(diagnosisIds: number[]) {
    let collection: Diagnosis[] = [];

    if (
      diagnosisIds &&
      typeof diagnosisIds === 'object' &&
      diagnosisIds.length > 0 &&
      this.diagnosisCollection &&
      typeof this.diagnosisCollection === 'object' &&
      this.diagnosisCollection.length > 0
    ) {
      for (let diagnosis of this.diagnosisCollection) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false &&
          diagnosisIds.includes(diagnosis.Id) &&
          collection.includes(diagnosis) === false
        )
          collection.push(diagnosis);
      }
    }

    this.selectedDiagnosisCollection = collection;
  }

  readDiagnosisIdsParameter(): number[] {
    let diagnosisIds: number[] = [];

    if (
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0
    ) {
      for (let diagnosis of this.selectedDiagnosisCollection) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false &&
          diagnosisIds.includes(diagnosis.Id) === false
        )
          diagnosisIds.push(diagnosis.Id);
      }
    }

    return diagnosisIds;
  }

  onChangeDominantEyeState() {
    if (typeof this.updateDischargeNoteForm.get('LeftEye').value === 'boolean')
      this.updateDischargeNoteForm
        .get('LeftEye')
        .setValue(!this.updateDischargeNoteForm.get('LeftEye').value);
  }

  onUpdateDischargeNote() {
    return new Promise((resolve, reject) => {
      const loggedInDoctorId = this.updateDischargeNoteForm.get('LoggedInDoctorId').value;
      if (loggedInDoctorId instanceof Observable) {
        loggedInDoctorId.subscribe((id) => {
          this.updateDischargeNoteForm.get('LoggedInDoctorId').setValue(id);
          this.saveDischargeNote().then(resolve).catch(reject);
        });
      } else {
        this.saveDischargeNote().then(resolve).catch(reject);
      }
    });
  }

  saveDischargeNote() {
    return new Promise((resolve, reject) => {
      if (this.dischargeNoteId === null || (typeof this.dischargeNoteId === 'number' && isNaN(this.dischargeNoteId) === false)) {
        if (this.data && typeof this.data === 'object' && this.data.hasOwnProperty('source_type') && typeof this.data.source_type === 'string' && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === 'excimer_laser' || this.data.source_type.toLowerCase() === 'operations') && this.data.hasOwnProperty('source_id') && typeof this.data.source_id === 'number' && isNaN(this.data.source_id) === false) {
          this.progressBarService.start();
  
          const dischargeNote = new DischargeNote(
            this.dischargeNoteId,
            this.data.source_id,
            this.updateDischargeNoteForm.get('Description').value,
            this.updateDischargeNoteForm.get('DoctorId').value,
            this.updateDischargeNoteForm.get('LoggedInDoctorId').value,
            this.readDateParameter('none'),
            this.readInterventionDateParameter(),
            this.updateDischargeNoteForm.get('LeftEye').value,
            this.updateDischargeNoteForm.get('TypeOfSurgery').value,
            this.updateDischargeNoteForm.get('Therapy').value,
            this.updateDischargeNoteForm.get('Recheck').value,
            this.updateDischargeNoteForm.get('SurgeonId').value,
            this.readDiagnosisIdsParameter(),
            this.updateDischargeNoteForm.get('DiagnosisOnArrival').value,
            this.updateDischargeNoteForm.get('DiagnosisOnDischarge').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOD').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOS').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOD').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel2ODText').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOS').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel2OSText').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel3ODId').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel3ODText').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel3OSId').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel3OSText').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel3OUId').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel4IdOD').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel4ODText').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel4IdOS').value,
            this.updateDischargeNoteForm.get('InterventionTypeLevel4OSText').value,
            this.updateDischargeNoteForm.get('ArrivalTOD').value,
            this.updateDischargeNoteForm.get('ArrivalTOS').value,
            this.updateDischargeNoteForm.get('ArrivalVOD').value,
            this.updateDischargeNoteForm.get('ArrivalVOS').value,
            this.updateDischargeNoteForm.get('DischargeTOD').value,
            this.updateDischargeNoteForm.get('DischargeTOS').value,
            this.updateDischargeNoteForm.get('DischargeVOD').value,
            this.updateDischargeNoteForm.get('DischargeVOS').value,
            this.readDateParameter('from'),
            this.readDateParameter('to'),
            this.updateDischargeNoteForm.get('ODOU').value,
            this.updateDischargeNoteForm.get('OSOU').value,
          );
  
          // Log the dischargeNote object to see if it's properly populated
          console.log('DischargeNote:', dischargeNote);
  
          const updateDischargeNoteByExcimerLaserSubscription = this.dashboardService.updateDischargeNoteByExcimerLaser(dischargeNote).subscribe(
            (response: boolean) => {
              this.progressBarService.stop();
              if (response === true) {
                this.snackbarService.showSuccess('Otpusna lista je uspešno izmenjena.');
                resolve(response);
                this.readDischargeNoteByExcimerLaser(this.authorizationService.getUserId());
              } else {
                reject('Greška prilikom izmene otpusne liste');
              }
            },
            (error) => {
              console.error('Error updating discharge note:', error);
              this.errorHandlerService.handleError(error);
              reject(error);
            }
          );
  
          this.subscriptions.add(updateDischargeNoteByExcimerLaserSubscription);
        }
      } else {
        reject('Note Id otpusne liste nije validan');
      }
    });
  }

  onReadDischargeNoteReport() {
    // First, attempt to save the changes
    this.onUpdateDischargeNote()
      .then(() => {
        if (
          typeof this.dischargeNoteId === 'number' &&
          isNaN(this.dischargeNoteId) === false
        ) {
          this.progressBarService.start();

          const readDischargeNoteReportSubscription = this.dashboardService
            .readDischargeNoteReport(this.dischargeNoteId)
            .subscribe(
              (response: Blob) => {
                this.progressBarService.stop();

                if (response && response instanceof Blob && response.size > 0)
                  this.dashboardService.printFile(response);
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(readDischargeNoteReportSubscription);
        }
      })
      .catch((error) => {
        console.error(
          'Greška prilikom čuvanja pre štampanja otpusne liste: ',
          error,
        );
      });
  }

  readDateParameter(period?: string): string | null {
    switch (period) {
      case 'none':
        {
          return this.updateDischargeNoteForm.get('Date').value instanceof Date
            ? formatDate(
                this.updateDischargeNoteForm.get('Date').value,
                'yyyy-MM-dd',
                'sr-Latn',
              )
            : null;
        }
        break;
      case 'from':
        {
          return this.updateDischargeNoteForm.get('TreatedFromDate')
            .value instanceof Date
            ? formatDate(
                this.updateDischargeNoteForm.get('TreatedFromDate').value,
                'yyyy-MM-dd',
                'sr-Latn',
              )
            : null;
        }
        break;
      case 'to':
        {
          return this.updateDischargeNoteForm.get('TreatedToDate')
            .value instanceof Date
            ? formatDate(
                this.updateDischargeNoteForm.get('TreatedToDate').value,
                'yyyy-MM-dd',
                'sr-Latn',
              )
            : null;
        }
        break;
    }
  }

  readInterventionDateParameter(): string | null {
    return this.updateDischargeNoteForm.get('SurgeryDate').value instanceof Date
      ? formatDate(
          this.updateDischargeNoteForm.get('SurgeryDate').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  interventionTypeLevelHandler(
    level: number,
    id: number,
    onInit: boolean,
    eye: string,
  ) {
    if (id) {
      this.progressBarService.start();
      const readInterventionTypesByLevelAndParentSubscription =
        this.dashboardService
          .readInterventionTypesByLevelAndParent(level, id)
          .subscribe((response: InterventionType[]) => {
            this.progressBarService.stop();

            switch (level) {
              case 2:
                {
                  if (eye === 'OD') {
                    this.intreventionTypes2Collection = response;

                    if (!onInit) {
                      this.intreventionTypes3Collection = [];
                      this.intreventionTypes4Collection = [];

                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel2IdOD')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel2ODText')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3ODId')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3ODText')
                        .setValue(null);
                      this.updateDischargeNoteForm.get('ODOU').setValue(false);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOD')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4ODText')
                        .setValue(null);
                    }
                  } else if (eye === 'OS') {
                    this.intreventionTypes2CollectionOS = response;

                    if (!onInit) {
                      this.intreventionTypes3CollectionOS = [];
                      this.intreventionTypes4CollectionOS = [];

                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel2IdOS')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel2OSText')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3OSId')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3OSText')
                        .setValue(null);
                      this.updateDischargeNoteForm.get('OSOU').setValue(false);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOS')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4OSText')
                        .setValue(null);
                    }
                  }
                }
                break;
              case 3:
                {
                  if (eye === 'OD') {
                    this.intreventionTypes3Collection = response;

                    if (!onInit) {
                      this.intreventionTypes4Collection = [];

                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3ODId')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3ODText')
                        .setValue(null);
                      this.updateDischargeNoteForm.get('ODOU').setValue(false);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOD')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4ODText')
                        .setValue(null);
                    }
                  } else if (eye === 'OS') {
                    this.intreventionTypes3CollectionOS = response;

                    if (!onInit) {
                      this.intreventionTypes4CollectionOS = [];

                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3OSId')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel3OSText')
                        .setValue(null);
                      this.updateDischargeNoteForm.get('OSOU').setValue(false);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOS')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4OSText')
                        .setValue(null);
                    }
                  }
                }
                break;
              case 4:
                {
                  if (eye === 'OD') {
                    this.intreventionTypes4Collection = response;

                    if (!onInit) {
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOD')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4ODText')
                        .setValue(null);
                    }
                  } else if (eye === 'OS') {
                    this.intreventionTypes4CollectionOS = response;

                    if (!onInit) {
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4IdOS')
                        .setValue(null);
                      this.updateDischargeNoteForm
                        .get('InterventionTypeLevel4OSText')
                        .setValue(null);
                    }
                  }
                }
                break;
            }
          });

      this.subscriptions.add(readInterventionTypesByLevelAndParentSubscription);
    } else {
      switch (level) {
        case 2:
          {
            if (eye === 'OD') {
              this.intreventionTypes3Collection = [];
              this.intreventionTypes4Collection = [];

              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2IdOD')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2ODText')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3ODId')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3ODText')
                .setValue(null);
              this.updateDischargeNoteForm.get('ODOU').setValue(false);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOD')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4ODText')
                .setValue(null);
            } else if (eye === 'OS') {
              this.intreventionTypes3CollectionOS = [];
              this.intreventionTypes4CollectionOS = [];

              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2IdOS')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2OSText')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3OSId')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3OSText')
                .setValue(null);
              this.updateDischargeNoteForm.get('OSOU').setValue(false);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOS')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4OSText')
                .setValue(null);
            }
          }
          break;
        case 3:
          {
            if (eye === 'OD') {
              this.intreventionTypes4Collection = [];

              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2ODText')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3ODId')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3ODText')
                .setValue(null);
              this.updateDischargeNoteForm.get('ODOU').setValue(false);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOD')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4ODText')
                .setValue(null);
            } else if (eye === 'OS') {
              this.intreventionTypes4CollectionOS = [];

              this.updateDischargeNoteForm
                .get('InterventionTypeLevel2OSText')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3OSId')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel3OSText')
                .setValue(null);
              this.updateDischargeNoteForm.get('OSOU').setValue(false);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOS')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4OSText')
                .setValue(null);
            }
          }
          break;
        case 4:
          {
            if (eye === 'OD') {
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOD')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4ODText')
                .setValue(null);
            } else if (eye === 'OS') {
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4IdOS')
                .setValue(null);
              this.updateDischargeNoteForm
                .get('InterventionTypeLevel4OSText')
                .setValue(null);
            }
          }
          break;
      }
    }
  }

  readDischargeNoteByExcimerLaser(userId: any) {
    const readDischargeNoteByExcimerLaserSubscription = this.dashboardService
      .readDischargeNoteByExcimerLaser(this.data.source_id)
      .subscribe((response: DischargeNote) => {
        if (response && typeof response === 'object') {
          if (
            response.hasOwnProperty('Id') &&
            typeof response.Id === 'number' &&
            isNaN(response.Id) === false
          )
            this.dischargeNoteId = response.Id;
          //if (response.hasOwnProperty("Date") && typeof response.Date === "string" && response.Date.length > 0) this.updateDischargeNoteForm.get('Date').setValue(new Date(response.Date));
          if (
            response.hasOwnProperty('TreatedFromDate') &&
            typeof response.TreatedFromDate === 'string' &&
            response.TreatedFromDate.length > 0
          )
            this.updateDischargeNoteForm
              .get('TreatedFromDate')
              .setValue(new Date(response.TreatedFromDate));
          if (
            response.hasOwnProperty('TreatedToDate') &&
            typeof response.TreatedToDate === 'string' &&
            response.TreatedToDate.length > 0
          )
            this.updateDischargeNoteForm
              .get('TreatedToDate')
              .setValue(new Date(response.TreatedToDate));
          //if (response.hasOwnProperty("DiagnosisIds") && typeof response.DiagnosisIds === "object" && response.DiagnosisIds.length > 0) this.filterSelectedDiagnosis(response.DiagnosisIds);
          if (
            response.hasOwnProperty('SurgeryDate') &&
            typeof response.SurgeryDate === 'string' &&
            response.SurgeryDate.length > 0
          )
            this.updateDischargeNoteForm
              .get('SurgeryDate')
              .setValue(new Date(response.SurgeryDate));
          if (
            response.hasOwnProperty('LeftEye') &&
            typeof response.LeftEye === 'boolean'
          )
            this.updateDischargeNoteForm
              .get('LeftEye')
              .setValue(response.LeftEye);
          if (
            response.hasOwnProperty('TypeOfSurgery') &&
            typeof response.TypeOfSurgery === 'string' &&
            response.TypeOfSurgery.length > 0
          )
            this.updateDischargeNoteForm
              .get('TypeOfSurgery')
              .setValue(response.TypeOfSurgery);
          if (
            response.hasOwnProperty('SurgeonId') &&
            typeof response.SurgeonId === 'number' &&
            isNaN(response.SurgeonId) === false
          )
            this.updateDischargeNoteForm
              .get('SurgeonId')
              .setValue(response.SurgeonId);
          if (
            response.hasOwnProperty('Description') &&
            typeof response.Description === 'string' &&
            response.Description.length > 0
          )
            this.updateDischargeNoteForm
              .get('Description')
              .setValue(response.Description);
          //if (response.hasOwnProperty("Therapy") && typeof response.Therapy === "string" && response.Therapy.length > 0) this.updateDischargeNoteForm.get('Therapy').setValue(response.Therapy);
          //if (response.hasOwnProperty("Recheck") && typeof response.Recheck === "string" && response.Recheck.length > 0) this.updateDischargeNoteForm.get('Recheck').setValue(response.Recheck);
          if (
            response.hasOwnProperty('DoctorId') &&
            typeof response.DoctorId === 'number' &&
            isNaN(response.DoctorId) === false
          )
            this.updateDischargeNoteForm
              .get('DoctorId')
              .setValue(response.DoctorId);
          if (
            response.hasOwnProperty('LoggedInDoctorId') &&
            typeof response.LoggedInDoctorId === 'number' &&
            isNaN(response.LoggedInDoctorId) === false
          )
            this.updateDischargeNoteForm
              .get('LoggedInDoctorId')
              .setValue(response.LoggedInDoctorId);
          if (
            response.hasOwnProperty('DiagnosisOnArrival') &&
            typeof response.DiagnosisOnArrival === 'string' &&
            response.DiagnosisOnArrival.length > 0
          )
            this.updateDischargeNoteForm
              .get('DiagnosisOnArrival')
              .setValue(response.DiagnosisOnArrival);
          if (
            response.hasOwnProperty('DiagnosisOnDischarge') &&
            typeof response.DiagnosisOnDischarge === 'string' &&
            response.DiagnosisOnDischarge.length > 0
          )
            this.updateDischargeNoteForm
              .get('DiagnosisOnDischarge')
              .setValue(response.DiagnosisOnDischarge);
          if (
            response.hasOwnProperty('InterventionTypeLevel1IdOD') &&
            typeof response.InterventionTypeLevel1IdOD === 'number' &&
            isNaN(response.InterventionTypeLevel1IdOD) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel1IdOD')
              .setValue(response.InterventionTypeLevel1IdOD);
          if (
            response.hasOwnProperty('InterventionTypeLevel1IdOS') &&
            typeof response.InterventionTypeLevel1IdOS === 'number' &&
            isNaN(response.InterventionTypeLevel1IdOS) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel1IdOS')
              .setValue(response.InterventionTypeLevel1IdOS);
          if (
            response.hasOwnProperty('InterventionTypeLevel2IdOD') &&
            typeof response.InterventionTypeLevel2IdOD === 'number' &&
            isNaN(response.InterventionTypeLevel2IdOD) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel2IdOD')
              .setValue(response.InterventionTypeLevel2IdOD);
          if (
            response.hasOwnProperty('InterventionTypeLevel2ODText') &&
            typeof response.InterventionTypeLevel2ODText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel2ODText')
              .setValue(response.InterventionTypeLevel2ODText);
          if (
            response.hasOwnProperty('InterventionTypeLevel2IdOS') &&
            typeof response.InterventionTypeLevel2IdOS === 'number' &&
            isNaN(response.InterventionTypeLevel2IdOS) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel2IdOS')
              .setValue(response.InterventionTypeLevel2IdOS);
          if (
            response.hasOwnProperty('InterventionTypeLevel2OSText') &&
            typeof response.InterventionTypeLevel2OSText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel2OSText')
              .setValue(response.InterventionTypeLevel2OSText);
          if (
            response.hasOwnProperty('InterventionTypeLevel3ODId') &&
            typeof response.InterventionTypeLevel3ODId === 'number' &&
            isNaN(response.InterventionTypeLevel3ODId) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel3ODId')
              .setValue(response.InterventionTypeLevel3ODId);
          if (
            response.hasOwnProperty('InterventionTypeLevel3ODText') &&
            typeof response.InterventionTypeLevel3ODText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel3ODText')
              .setValue(response.InterventionTypeLevel3ODText);
          if (
            response.hasOwnProperty('InterventionTypeLevel3OSId') &&
            typeof response.InterventionTypeLevel3OSId === 'number' &&
            isNaN(response.InterventionTypeLevel3OSId) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel3OSId')
              .setValue(response.InterventionTypeLevel3OSId);
          if (
            response.hasOwnProperty('InterventionTypeLevel3OSText') &&
            typeof response.InterventionTypeLevel3OSText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel3OSText')
              .setValue(response.InterventionTypeLevel3OSText);
          if (
            response.hasOwnProperty('InterventionTypeLevel3OUId') &&
            typeof response.InterventionTypeLevel3OUId === 'number' &&
            isNaN(response.InterventionTypeLevel3OUId) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel3OUId')
              .setValue(response.InterventionTypeLevel3OUId);
          if (
            response.hasOwnProperty('InterventionTypeLevel4IdOD') &&
            typeof response.InterventionTypeLevel4IdOD === 'number' &&
            isNaN(response.InterventionTypeLevel4IdOD) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel4IdOD')
              .setValue(response.InterventionTypeLevel4IdOD);
          if (
            response.hasOwnProperty('InterventionTypeLevel4ODText') &&
            typeof response.InterventionTypeLevel4ODText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel4ODText')
              .setValue(response.InterventionTypeLevel4ODText);
          if (
            response.hasOwnProperty('InterventionTypeLevel4IdOS') &&
            typeof response.InterventionTypeLevel4IdOS === 'number' &&
            isNaN(response.InterventionTypeLevel4IdOS) === false
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel4IdOS')
              .setValue(response.InterventionTypeLevel4IdOS);
          if (
            response.hasOwnProperty('InterventionTypeLevel4OSText') &&
            typeof response.InterventionTypeLevel4OSText === 'string'
          )
            this.updateDischargeNoteForm
              .get('InterventionTypeLevel4OSText')
              .setValue(response.InterventionTypeLevel4OSText);
          if (
            response.hasOwnProperty('ArrivalTOD') &&
            typeof response.ArrivalTOD === 'string' &&
            response.ArrivalTOD.length > 0
          )
            this.updateDischargeNoteForm
              .get('ArrivalTOD')
              .setValue(response.ArrivalTOD);
          if (
            response.hasOwnProperty('ArrivalTOS') &&
            typeof response.ArrivalTOS === 'string' &&
            response.ArrivalTOS.length > 0
          )
            this.updateDischargeNoteForm
              .get('ArrivalTOS')
              .setValue(response.ArrivalTOS);
          if (
            response.hasOwnProperty('ArrivalVOD') &&
            typeof response.ArrivalVOD === 'string' &&
            response.ArrivalVOD.length > 0
          )
            this.updateDischargeNoteForm
              .get('ArrivalVOD')
              .setValue(response.ArrivalVOD);
          if (
            response.hasOwnProperty('ArrivalVOS') &&
            typeof response.ArrivalVOS === 'string' &&
            response.ArrivalVOS.length > 0
          )
            this.updateDischargeNoteForm
              .get('ArrivalVOS')
              .setValue(response.ArrivalVOS);
          if (
            response.hasOwnProperty('DischargeTOD') &&
            typeof response.DischargeTOD === 'string' &&
            response.DischargeTOD.length > 0
          )
            this.updateDischargeNoteForm
              .get('DischargeTOD')
              .setValue(response.DischargeTOD);
          if (
            response.hasOwnProperty('DischargeTOS') &&
            typeof response.DischargeTOS === 'string' &&
            response.DischargeTOS.length > 0
          )
            this.updateDischargeNoteForm
              .get('DischargeTOS')
              .setValue(response.DischargeTOS);
          if (
            response.hasOwnProperty('DischargeVOD') &&
            typeof response.DischargeVOD === 'string' &&
            response.DischargeVOD.length > 0
          )
            this.updateDischargeNoteForm
              .get('DischargeVOD')
              .setValue(response.DischargeVOD);
          if (
            response.hasOwnProperty('DischargeVOS') &&
            typeof response.DischargeVOS === 'string' &&
            response.DischargeVOS.length > 0
          )
            this.updateDischargeNoteForm
              .get('DischargeVOS')
              .setValue(response.DischargeVOS);
          if (
            response.hasOwnProperty('ODOU') &&
            typeof response.ODOU === 'boolean'
          )
            this.updateDischargeNoteForm.get('ODOU').setValue(response.ODOU);
          if (
            response.hasOwnProperty('OSOU') &&
            typeof response.ODOU === 'boolean'
          )
            this.updateDischargeNoteForm.get('OSOU').setValue(response.OSOU);

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOD').value
          ) {
            this.interventionTypeLevelHandler(
              2,
              this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOD')
                .value,
              true,
              'OD',
            );
          }

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOS').value
          ) {
            this.interventionTypeLevelHandler(
              2,
              this.updateDischargeNoteForm.get('InterventionTypeLevel1IdOS')
                .value,
              true,
              'OS',
            );
          }

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOD').value
          ) {
            this.interventionTypeLevelHandler(
              3,
              this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOD')
                .value,
              true,
              'OD',
            );
          }

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOS').value
          ) {
            this.interventionTypeLevelHandler(
              3,
              this.updateDischargeNoteForm.get('InterventionTypeLevel2IdOS')
                .value,
              true,
              'OS',
            );
          }

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel3ODId').value
          ) {
            this.interventionTypeLevelHandler(
              4,
              this.updateDischargeNoteForm.get('InterventionTypeLevel3ODId')
                .value,
              true,
              'OD',
            );
          }

          if (
            this.updateDischargeNoteForm.get('InterventionTypeLevel3OSId').value
          ) {
            this.interventionTypeLevelHandler(
              4,
              this.updateDischargeNoteForm.get('InterventionTypeLevel3OSId')
                .value,
              true,
              'OS',
            );
          }

          if (!this.updateDischargeNoteForm.get('LoggedInDoctorId').value) {
            this.updateDischargeNoteForm
              .get('LoggedInDoctorId')
              .setValue(userId);
          }
        }
      });
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
