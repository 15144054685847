export class OperationFlow {
    Id: number | null;
    LaserExclaimerId: number | null;
    UserId: number | null;
    Description: string;

    constructor(Id: number | null, LaserExclaimerId: number | null, UserId: number | null, Description: string) {
        this.Id = Id;
        this.LaserExclaimerId = LaserExclaimerId;
        this.UserId = UserId;
        this.Description = Description;
    }
}