export class ReadDiagnosticImagesRequest {
    MedicalExaminationId: number;
    PatientId: number;
    DiagnosticsTypeId: number;

    constructor(MedicalExaminationId: number, PatientId: number, DiagnosticsTypeId: number) {
        this.MedicalExaminationId = MedicalExaminationId;
        this.PatientId = PatientId;
        this.DiagnosticsTypeId = DiagnosticsTypeId;
    }
}