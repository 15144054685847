export class Payment {
    Id: number;
    ExamChartId: number | null;
    PriceListId: number | null;
    ServiceName: string;
    ServiceDescription: string;
    Price: number;
    Discount: number;
    PriceWithDiscount: number;

    constructor(Id: number, ExamChartId: number | null, PriceListId: number | null, ServiceName: string, ServiceDescription: string, Price: number, Discount: number, PriceWithDiscount: number) {
        this.Id = Id;
        this.ExamChartId = ExamChartId;
        this.PriceListId = PriceListId;
        this.ServiceName = ServiceName;
        this.ServiceDescription = ServiceDescription;
        this.Price = Price;
        this.Discount = Discount;
        this.PriceWithDiscount = PriceWithDiscount;
    }
}