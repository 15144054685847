import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ResolveEnd } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { filter, take, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../core/authentication.service';
import { AuthorizationService } from '../core/authorization.service';
import { ErrorHandlerService } from '../core/error-handler.service';
import { ProgressBarService } from '../core/progress-bar.service';

import PerfectScrollbar from 'perfect-scrollbar';
import { DashboardService } from './shared/dashboard.service';
import { MessagesService } from './shared/messages.service';
import { Employee } from '../dashboard/shared/employee.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  currentYear: number = new Date().getFullYear();
  subscriptions: Subscription = new Subscription();
  numberOfMessages$: Observable<number>;
  navigationEnd: Observable<NavigationEnd>;
  userName: string;
  userRole: string;
  permissions: string[] = [];

  constructor(
    public authorizationService: AuthorizationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private readonly dashboardService: DashboardService,
    private readonly messagesService: MessagesService,
  ) {
    // Create a new Observable that publishes only the NavigationEnd event (triggered when navigation ends successfully)
    this.navigationEnd = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    // Material Dashboard Perfect Scrollbar (plug-in) classes and scroll resetting
    let bodyElement = document.getElementsByTagName('body');
    this.getRoleFromToken();
    this.numberOfMessages$ = this.messagesService.numberOfMessages;
    this.getNumberOfUnreadMessagesForUser();
    this.getuser();

    if (
      bodyElement &&
      typeof bodyElement === 'object' &&
      bodyElement.length > 0 &&
      bodyElement[0] &&
      bodyElement[0].classList &&
      typeof bodyElement[0].classList === 'object'
    ) {
      if (
        this.readBrowserPlatform() &&
        typeof this.readBrowserPlatform() === 'string' &&
        this.readBrowserPlatform().length > 0 &&
        this.readBrowserPlatform().includes('Win') === true &&
        bodyElement[0].classList.contains('sidebar-mini') === false
      )
        bodyElement[0].classList.add('perfect-scrollbar-on');
      else bodyElement[0].classList.remove('perfect-scrollbar-off');
    }

    const navigationSubscription = this.navigationEnd.subscribe((event) => {
      if (document.querySelector('.main-panel'))
        document.querySelector('.main-panel').scrollTop = 0;
      if (document.querySelector('.sidebar .sidebar-wrapper'))
        document.querySelector('.sidebar .sidebar-wrapper').scrollTop = 0;
      this.getNumberOfUnreadMessagesForUser();
    });

    this.subscriptions.add(navigationSubscription);
  }

  ngAfterViewInit() {
    // Material Dashboard Perfect Scrollbar (plug-in) updating
    if (
      window.matchMedia('(min-width: 960px)').matches &&
      this.readBrowserPlatform() &&
      typeof this.readBrowserPlatform() === 'string' &&
      this.readBrowserPlatform().length > 0 &&
      this.readBrowserPlatform().includes('Mac') === false &&
      this.readBrowserPlatform().includes('iPad') === false
    ) {
      if (document.querySelector('.main-panel')) {
        const mainPanelPerfectScrollbar = new PerfectScrollbar('.main-panel');
        mainPanelPerfectScrollbar.update();
      }

      if (document.querySelector('.sidebar .sidebar-wrapper')) {
        const sidebarPerfectScrollbar = new PerfectScrollbar(
          '.sidebar .sidebar-wrapper',
        );
        sidebarPerfectScrollbar.update();
      }
    }

    // Reset scrollbar after browser window is resized
    window.addEventListener('resize', function () {
      if (document.querySelector('.main-panel'))
        document.querySelector('.main-panel').scrollTop = 0;
      if (document.querySelector('.sidebar .sidebar-wrapper'))
        document.querySelector('.sidebar .sidebar-wrapper').scrollTop = 0;
    });
  }

  readBrowserPlatform(): string {
    return navigator.platform;
  }

  onSignOut() {
    // Get token from a sessionStorage object for the current origin
    let currentToken = this.authenticationService.getToken();

    if (typeof currentToken === 'string' && currentToken.length > 0) {
      this.progressBarService.start();
      const signOutSubscription = this.authenticationService.signOut();
      this.subscriptions.add(signOutSubscription);
      this.progressBarService.stop();
    }
  }

  getRoleFromToken() {
    this.userRole = this.authenticationService.getRoleFromToken();
  }

  getNumberOfUnreadMessagesForUser() {
    this.authorizationService
      .getUserId()
      .pipe(
        switchMap((userId: number) => {
          return this.dashboardService.getNumberOfUnreadMessagesForUser(userId);
        }),
        take(1),
      )
      .subscribe((response: number) =>
        this.messagesService.emitNewnumberOfMessagesValue(response),
      );
  }

  getuser() {
    const readUserSubscription = this.authorizationService.readUser().subscribe(
      (response: Employee) => {
        this.progressBarService.stop();
        if (response && typeof response === 'object') {
          let userData = response;

          // If User have generated password, do the redirection to password change page. Otherwise, continue towards the Dashboard.
          if (
            userData.hasOwnProperty('GeneratedPassword') &&
            userData.GeneratedPassword === true
          )
            this.router.navigate(['/change-password']);
          else if (
            userData.hasOwnProperty('UserName') &&
            typeof userData.UserName === 'string' &&
            userData.UserName.length > 0
          ) {
            // Get list of permissions (by UserName)
            this.progressBarService.start();

            const readPermissionsSubscription = this.authorizationService
              .readPermissions()
              .subscribe(
                (response: string[]) => {
                  this.permissions = response;
                  this.progressBarService.stop();
                  this.userName = userData.Name;
                },
                (error) => this.errorHandlerService.handleError(error),
              );

            this.subscriptions.add(readPermissionsSubscription);
          }
        }
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(readUserSubscription);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
