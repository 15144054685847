import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';

import { AuthenticationService } from "./authentication.service";
import { AuthorizationService } from "./authorization.service";

@Injectable({
  providedIn: 'root',
})
export class RouterGuard implements CanActivate, CanActivateChild {
  constructor(private authenticationService: AuthenticationService, private authorizationService: AuthorizationService, private router: Router) {}

  canActivate(): boolean {
    return this.isUserSignedIn();
  }

  canActivateChild(): boolean {
    return this.isUserSignedIn();
  }

  isUserSignedIn(): boolean {
/*     // Currently, we assume that User is signed in if he has: token, ID and permission(s)
    if ((typeof this.authenticationService.getToken() === "string" && this.authenticationService.getToken().length > 0) && (typeof this.authorizationService.getUserId() === "number" && isNaN(this.authorizationService.getUserId()) === false) && (this.authorizationService.getPermissions() && typeof this.authorizationService.getPermissions() === "object" && this.authorizationService.getPermissions().length > 0)) return true;
    
    if (typeof Storage !== "undefined") sessionStorage.clear();
    this.router.navigate(['/login']); */
    
    return true;
  }
}