import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DayPilot,
  DayPilotCalendarComponent,
  DayPilotMonthComponent,
  DayPilotNavigatorComponent,
} from '@daypilot/daypilot-lite-angular';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DeleteDialogComponent } from '../../operation-administration/dialogs/delete-dialog/delete-dialog.component';
import { ApartmentBooking } from '../apartment-booking.model';
import { ApartmentBookingDto } from '../apartment-bookingDto.model';
import { DashboardService } from '../dashboard.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
})
export class EventCalendarComponent implements AfterViewInit {
  @ViewChild('day') day!: DayPilotCalendarComponent;
  @ViewChild('week') week!: DayPilotCalendarComponent;
  @ViewChild('month') month!: DayPilotMonthComponent;
  @ViewChild('navigator') nav!: DayPilotNavigatorComponent;

  @Input() apartmentList: any;

  events: DayPilot.EventData[] = [];
  apartmentBookingsForMonth: any = [];
  date = DayPilot.Date.today();
  currentMonth: number = DayPilot.Date.today().getMonth() + 1;

  contextMenu = new DayPilot.Menu({
    items: [
      {
        text: 'Delete',
        onClick: (args) => {
          const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: '400px',
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              const event = args.source;
              const dp = event.calendar;
              let booking = this.findBookingForDelete(
                event.data.start.value,
                event.data.end.value,
                event.data.apartmentName,
              )[0];
              this.deleteApartmentBooking(booking.Id);

              // dp.events.remove(event); //INSTANT BRISANJE EVENT-a
              setTimeout(() => this.loadEvents(event.data.start), 300);
            }
          });
        },
      },
      // EDIT TEKSTA ONEMOGUCEN

      // {
      //   text: 'Edit...',
      //   onClick: async (args) => {
      //     const event = args.source;
      //     const dp = event.calendar;

      //     const modal = await DayPilot.Modal.prompt(
      //       'Edit event text:',
      //       event.data.text,
      //     );
      //     dp.clearSelection();
      //     if (!modal.result) {
      //       return;
      //     }
      //     event.data.text = modal.result;
      //     dp.events.update(event);
      //   },
      // },
      {
        text: '-',
      },
      {
        text: 'Crvena',
        onClick: (args) => {
          const event = args.source;
          const dp = event.calendar;
          event.data.backColor = DataService.colors.red;
          dp.events.update(event);
        },
      },
      {
        text: 'Zelena',
        onClick: (args) => {
          const event = args.source;
          const dp = event.calendar;
          event.data.backColor = DataService.colors.green;

          dp.events.update(event);
        },
      },
      {
        text: 'Plava',
        onClick: (args) => {
          const event = args.source;
          const dp = event.calendar;
          event.data.backColor = DataService.colors.blue;

          dp.events.update(event);
        },
      },
      {
        text: 'Žuta',
        onClick: (args) => {
          const event = args.source;
          const dp = event.calendar;
          event.data.backColor = DataService.colors.yellow;

          dp.events.update(event);
        },
      },

      {
        text: 'Siva',
        onClick: (args) => {
          const event = args.source;
          const dp = event.calendar;
          event.deata.backColor = DataService.colors.gray;

          dp.evnts.update(event);
        },
      },
    ],
  });

  configNavigator: DayPilot.NavigatorConfig = {
    showMonths: 3,
    cellWidth: 25,
    cellHeight: 25,
    // metoda se poziva kada se klikne na kalendar sa strane, ukoliko je razliciti mesec od trenutnog, poziva se endpoint za bookinge
    onTimeRangeSelected: (args) => {
      if (args.mode === 'Month') {
        let day = args.day.getMonth() + 1;
        if (day !== this.currentMonth) {
          this.currentMonth = day;
          this.loadEvents(args.day);
        }
        console.log('day', day);
      }
      console.log('onTimeRangeSelected', args);
    },
    onVisibleRangeChanged: (args) => {
      // this.loadEvents(args.start);
    },
  };

  selectTomorrow() {
    this.date = DayPilot.Date.today().addDays(1);
  }

  changeDate(date: DayPilot.Date): void {
    this.configDay.startDate = date;
    this.configWeek.startDate = date;
    this.configMonth.startDate = date;
  }

  configDay: DayPilot.CalendarConfig = {
    locale: 'fr-ch',
    durationBarVisible: false,
    contextMenu: this.contextMenu,
    onTimeRangeSelected: this.onTimeRangeSelected.bind(this),
    onBeforeEventRender: this.onBeforeEventRender.bind(this),
    onEventClick: this.onEventClick.bind(this),
  };

  configWeek: DayPilot.CalendarConfig = {
    locale: 'fr-ch',
    viewType: 'Week',
    durationBarVisible: false,
    contextMenu: this.contextMenu,
    onTimeRangeSelected: this.onTimeRangeSelected.bind(this),
    onBeforeEventRender: this.onBeforeEventRender.bind(this),
    onEventClick: this.onEventClick.bind(this),
  };

  configMonth: DayPilot.MonthConfig = {
    locale: 'en-us',
    contextMenu: this.contextMenu,
    eventBarVisible: false,
    onTimeRangeSelected: this.onTimeRangeSelected.bind(this),
    onEventClick: this.onEventClick.bind(this),
  };

  constructor(
    private dataService: DataService,
    private dashboardService: DashboardService,
    private snackbarService: SnackbarService,
    private errorHandlerService: ErrorHandlerService,
    public dialog: MatDialog,
  ) {
    this.viewMonth();
  }

  ngAfterViewInit(): void {
    this.loadEvents(this.date);
  }

  loadEvents(selectedDate: any): void {
    this.dataService.getApartmentBookings(selectedDate).subscribe(
      (data: any) => {
        this.apartmentBookingsForMonth = data;
        this.events = this.formatApartmentBookingEvents(data);
      },
      (error: any) => {
        console.error('Error fetching apartment bookings:', error);
      },
    );

    // TODO: zakomentarisati ovo i otkomentarisati gornji kod. Ovo je samo za testiranje - vadi podatke iz data.service
    // const from = this.nav.control.visibleStart();
    // const to = this.nav.control.visibleEnd();
    // this.dataService.getEvents(from, to).subscribe((result) => {
    // this.events = result;
    //});
  }

  viewDay(): void {
    this.configNavigator.selectMode = 'Day';
    this.configDay.visible = true;
    this.configWeek.visible = false;
    this.configMonth.visible = false;
    this.addPrintableClass('day');
  }

  viewWeek(): void {
    this.configNavigator.selectMode = 'Week';
    this.configDay.visible = false;
    this.configWeek.visible = true;
    this.configMonth.visible = false;
    this.addPrintableClass('week');
  }

  viewMonth(): void {
    this.configNavigator.selectMode = 'Month';
    this.configDay.visible = false;
    this.configWeek.visible = false;
    this.configMonth.visible = true;
    this.addPrintableClass('month');
  }

  addPrintableClass(view: string): void {
    const dayElement = document.querySelector('.day-container');
    const weekElement = document.querySelector('.week-container');
    const monthElement = document.querySelector('.month-container');

    dayElement?.classList.remove('printable');
    weekElement?.classList.remove('printable');
    monthElement?.classList.remove('printable');

    switch (view) {
      case 'day':
        dayElement?.classList.add('printable');
        break;
      case 'week':
        weekElement?.classList.add('printable');
        break;
      case 'month':
        monthElement?.classList.add('printable');
        break;
    }
  }

  onBeforeEventRender(args: any) {
    const dp = args.control;
    args.data.areas = [
      {
        top: 3,
        right: 3,
        width: 20,
        height: 20,
        symbol: 'assets/icons/daypilot.svg#minichevron-down-2',
        fontColor: '#fff',
        toolTip: 'Show context menu',
        action: 'ContextMenu',
      },
      {
        top: 3,
        right: 25,
        width: 20,
        height: 20,
        symbol: 'assets/icons/daypilot.svg#x-circle',
        fontColor: '#fff',
        action: 'None',
        toolTip: 'Delete event',
        onClick: async (args: any) => {
          dp.events.remove(args.source);
        },
      },
    ];

    args.data.areas.push({
      bottom: 5,
      left: 5,
      width: 36,
      height: 36,
      action: 'None',
      image: `https://picsum.photos/36/36?random=${args.data.id}`,
      style: 'border-radius: 50%; border: 2px solid #fff; overflow: hidden;',
    });
  }

  // kad se klikne na prazno polje da se kreira event
  async onTimeRangeSelected(args: any) {
    const form = [
      {
        name: 'Apartman',
        id: 'apartmentName',
        type: 'select',
        options: this.formatAparmtentList(this.apartmentList),
      },
      { name: 'Tekst', id: 'text' },
      {
        name: 'Početak',
        id: 'start',
        dateFormat: 'dd.MM.yyyy.',
        type: 'date',
      },
      { name: 'Kraj', id: 'end', dateFormat: 'dd.MM.yyyy.', type: 'date' },
    ];

    const data = { start: args.start, end: args.end };
    const modal = await DayPilot.Modal.form(form, data);
    const dp = args.control;
    dp.clearSelection();
    if (!modal.result) {
      return;
    }

    this.addApartmentBooking(args, modal);
    setTimeout(() => this.loadEvents(args.start), 300);

    // INSTANT DODAVANJE EVENT-a - sluzi za testiranje ukoliko se zakomentarise dodavanje bookinga preko endpointa
    // dp.events.add(
    //   new DayPilot.Event({
    //     start: args.start,
    //     end: args.end,
    //     id: DayPilot.guid(),
    //     text: modal.result.apartment + ' - ' + modal.result.text,
    //   }),
    // );
  }

  // kad se klikne na event
  async onEventClick(args: any) {
    const form = [
      { name: 'Tekst', id: 'text' },
      {
        name: 'Početak',
        id: 'start',
        dateFormat: 'dd.MM.yyyy.',
        type: 'date',
      },
      {
        name: 'Kraj',
        id: 'end',
        dateFormat: 'dd.MM.yyyy.',
        type: 'date',
      },
      {
        name: 'Boja',
        id: 'backColor',
        type: 'select',
        options: this.dataService.getColors(),
        disabled: true,
      },
    ];

    const data = args.e.data;
    let booking = this.findBookingForDelete(
      data.start,
      data.end,
      data.apartmentName,
    )[0];
    const modal = await DayPilot.Modal.form(form, data);

    if (modal.canceled) {
      return;
    }
    this.updateApartmentBooking(booking.Id, args, modal);
    setTimeout(() => this.loadEvents(args.start), 300);

    // INSTANT UPDATE EVENT-a
    const dp = args.control;
    dp.events.update(modal.result.bookingId);
    this.loadEvents(this.date);
  }

  //metoda za prikaz eventova u kalendaru
  formatApartmentBookingEvents(listOfApartmentBookings: any) {
    return listOfApartmentBookings.map((item: any) => {
      const start = new DayPilot.Date(item.Start).getDatePart();
      const end = new DayPilot.Date(item.End).getDatePart(); //nema vreme u bazi, a na 00:00 doda 1h kad se prebaci sa beka na front

      // // zbog vremenske zone, ovo je za zimsko gledanje [NIJE POTREBNO VIŠE, KORISTI SE DAYPILOT.DATE]
      // if (start.getHours() != 0) {
      //   start.setHours(start.getHours() + 1);
      //   end.setHours(end.getHours() + 24); // 00:00 sa beka -1h zbog prenosa + 24h = 23:00, 45 je zbog prikaza na kalendaru
      //   end.setMinutes(45);
      // } else {
      //   start.setHours(start.getHours() + 2);
      //   end.setHours(end.getHours() + 25);
      //   end.setMinutes(45);
      // }

      return {
        id: item.ApartmentId,
        bookingId: item.Id,
        text: item.Text,
        start: start,
        end: end,
        backColor: this.getColorForApartment(item.Color),
        apartmentName: item.ApartmentName,
      };
    });
  }

  getColorForApartment(apartmentColor: string) {
    switch (apartmentColor) {
      case 'Green':
        return DataService.colors.green;
      case 'Yellow':
        return DataService.colors.yellow;
      case 'Red':
        return DataService.colors.red;
      case 'Gray':
        return DataService.colors.gray;
      case 'Blue':
        return DataService.colors.blue;
      default:
        return DataService.colors.green;
    }
  }

  formatAparmtentList(apartmentList: any) {
    return apartmentList.map((item: any) => {
      return {
        name: item.Name,
        id: item.Name,
      };
    });
  }

  findApartmentByName(name: string) {
    return this.apartmentList.find((item: any) => item.Name === name).Id;
  }

  addApartmentBooking(args: any, modal: any) {
    const params = this.formatApartmentBookingDto(null, args, modal);
    this.dashboardService.savebookingforapartment(params).subscribe(
      (response) => {
        if (response) {
          this.snackbarService.showSuccess('Apartman je uspešno zakazan.');
          return true;
        }
        return false;
      },
      (error) => {
        this.errorHandlerService.handleError(error);
      },
    );
  }

  formatApartmentBookingDto(bookingId: number, args: any, modal: any) {
    let start = new DayPilot.Date(modal.result.start).getDatePart();
    let end = new DayPilot.Date(modal.result.end).getDatePart();

    // // zbog vremenske zone, ovo je za zimsko gledanje
    // if (start.getHours() != 0 || end.getHours() != 0) {
    //   start.setHours(start.getHours() + 10); // zbog vremenske zone, moze +1 za srbiju samo
    //   end.setHours(end.getHours() + 10); // zbog vremenske zone, moze +1 za srbiju samo
    //   end.setDate(end.getDate() - 1); //jer doda 1 dan
    // } else {
    //   end.setDate(end.getDate() - 1); //jer doda 1 dan
    // }

    const params = new ApartmentBookingDto(
      bookingId,
      this.findApartmentByName(modal.result.apartmentName),
      start.toString(),
      end.toString(),
      modal.result.text,
      modal.result.apartmentName,
      modal.result.backColor,
    );
    return params;
  }

  findBookingForDelete(startDate: any, endDate: any, apartmentName: string) {
    const starttargetDate = new DayPilot.Date(startDate)
      .getDatePart()
      .toString();
    const endTargetDate = new DayPilot.Date(endDate).getDatePart().toString();
    this.loadEvents(this.date);

    return this.apartmentBookingsForMonth.filter((item: any) => {
      const startDate = new DayPilot.Date(item.Start).getDatePart().toString();
      const endDate = new DayPilot.Date(item.End).getDatePart().toString();
      return (
        startDate === starttargetDate &&
        endDate === endTargetDate &&
        item.ApartmentName === apartmentName
      );
    });
  }

  deleteApartmentBooking(id: any) {
    this.dashboardService
      .deleteapartmentbookingforid(id)
      .subscribe((response) => {
        if (response) {
          this.loadEvents(this.date);
          this.snackbarService.showSuccess('Apartman je uspešno obrisan.');
        }
      });
  }

  updateApartmentBooking(bookingId: number, args: any, modal: any) {
    const params = this.formatApartmentBookingDto(bookingId, args, modal);
    this.dashboardService.updateBookingForApartment(params).subscribe(
      (response) => {
        if (response) {
          this.loadEvents(this.date);
          this.snackbarService.showSuccess('Apartman je uspešno zakazan.');
          return true;
        }
        return false;
      },
      (error) => {
        this.loadEvents(this.date);
        this.errorHandlerService.handleError(error);
      },
    );
  }
}
