import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root',
})
export class NewPatientGuard implements CanActivate, CanActivateChild {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this.hasPermission();
  }

  canActivateChild(): Observable<boolean> {
    return this.hasPermission();
  }

  hasPermission(): Observable<boolean> {
    return this.authorizationService.readPermissions().pipe(
      map((permissions) => {
        if (permissions.includes('PACNOVI')) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return false;
        }
      }),
    );
  }
}
