import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { ProgressBarService } from 'src/app/core/progress-bar.service';
import { StaffService } from 'src/app/core/services/staff.service';
import { Job } from 'src/app/dashboard/shared/job.model';
import { Titles } from 'src/app/shared/enum/titles-enum';

@Component({
  selector: 'app-create-employee-dialog',
  templateUrl: './create-employee-dialog.component.html',
  styleUrls: ['./create-employee-dialog.component.scss'],
})
export class CreateEmployeeDialogComponent implements OnInit {
  nameInput: string = '';
  usernameInput: string = '';
  emailInput: string = '';
  jobInput: Job = null;
  titleInput: string = '';
  jobsCollection: Job[] = [];
  titleCollection: Titles[] = [];
  isNewUser: boolean = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    private staffService: StaffService,
    public dialogRef: MatDialogRef<CreateEmployeeDialogComponent>,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.jobsCollection = this.data.jobsCollection;
    this.titleCollection = this.data.titleCollection;
  }

  changeJob(event: any) {
    this.jobInput = event;
    if (this.jobInput.Name == 'Novi user') {
      this.titleInput = '';
      this.isNewUser = true;
    } else {
      this.isNewUser = false;
    }
  }

  onSubmit() {
    if (!this.isFormValid()) return;
    this.progressBarService.start();
    let dto: any = {
      Name: this.nameInput,
      Username: this.usernameInput,
      Active: true,
      Email: this.emailInput,
      JobId: this.jobInput.Id,
      Title: this.titleInput,
      Color: null,
    };
    const createEmployeeSubscription = this.staffService
      .addStaff(dto)
      .subscribe(
        (response: boolean) => {
          if (response) {
            this.progressBarService.stop();
            this.dialogRef.close(true);
          }
        },
        (error) => {
          this.progressBarService.stop();
          this.errorHandlerService.handleError(error);
        },
      );

    this.subscriptions.add(createEmployeeSubscription);
  }

  isFormValid() {
    if (!this.nameInput) return false;
    if (!this.usernameInput) return false;
    if (!this.emailInput) return false;
    if (!this.jobInput) return false;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegex.test(this.emailInput)) return false;
    return true;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
