import { Injectable } from '@angular/core';

// Import the Angular Material adapter
import { NativeDateAdapter } from "@angular/material/core";

@Injectable({
  providedIn: 'root'
})
export class DatepickerDateAdapterService extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
      // Setting Monday as the first day of the week
      return 1;
  }
}