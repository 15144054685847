import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';

import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';

import { SpecialistFindingType } from '../../../shared/specialist-finding-type.model';
import { SpecialistFinding } from '../../../shared/specialist-finding.model';
import { SpecialistFindingsFromArhiveDialogComponent } from '../specialist-findings-from-arhive-dialog/specialist-findings-from-arhive-dialog.component';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-specialist-findings-dialog',
  templateUrl: './update-specialist-findings-dialog.component.html',
  styleUrls: ['./update-specialist-findings-dialog.component.css'],
})
export class UpdateSpecialistFindingsDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();

  specialistFindingTypesCollection: SpecialistFindingType[] = [];

  createSpecialistFindingForm = new UntypedFormGroup({
    SpecialistExamTypeId: new UntypedFormControl(null, Validators.required),
  });

  displayedColumns: string[] = ['SpecialistExamType', 'Date', 'Actions'];
  dataSource = new MatTableDataSource<SpecialistFinding>([]);

  specialistFindingsEditRole: boolean;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    const readSpecialistFindingTypesSubscription = this.dashboardService
      .readSpecialistFindingTypes()
      .subscribe(
        (response: SpecialistFindingType[]) => {
          if (response.length > 0)
            this.specialistFindingTypesCollection = response;
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(readSpecialistFindingTypesSubscription);

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.specialistFindingsEditRole = permissions.includes('SPECNALIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );

    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('has_specialist_findings') &&
      this.data.has_specialist_findings === true
    )
      this.readSpecialistFindings();
  }

  onReadNameOfSpecialistFindingType(typeId: number): string {
    let name = '';

    if (
      this.specialistFindingTypesCollection &&
      typeof this.specialistFindingTypesCollection === 'object' &&
      this.specialistFindingTypesCollection.length > 0 &&
      typeof typeId === 'number' &&
      isNaN(typeId) === false
    ) {
      let filteredSpecialistFindingTypes: SpecialistFindingType[] =
        this.specialistFindingTypesCollection.filter(
          (currentElement) =>
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Id') &&
            currentElement.Id === typeId,
        );
      if (
        filteredSpecialistFindingTypes &&
        typeof filteredSpecialistFindingTypes === 'object' &&
        filteredSpecialistFindingTypes.length > 0 &&
        filteredSpecialistFindingTypes[0] &&
        typeof filteredSpecialistFindingTypes[0] === 'object' &&
        filteredSpecialistFindingTypes[0].hasOwnProperty('Name') &&
        typeof filteredSpecialistFindingTypes[0].Name === 'string' &&
        filteredSpecialistFindingTypes[0].Name.length > 0
      )
        name = filteredSpecialistFindingTypes[0].Name;
    }

    return name;
  }

  onChangeSpecialistFindingFileSelection(filesCollection: FileList) {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0 &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false &&
        this.createSpecialistFindingForm.get('SpecialistExamTypeId').value &&
        typeof this.createSpecialistFindingForm.get('SpecialistExamTypeId')
          .value === 'number' &&
        isNaN(
          this.createSpecialistFindingForm.get('SpecialistExamTypeId').value,
        ) === false
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf' ||
          filesCollection[0].type.toLowerCase().includes('document') === true
        ) {
          let requestData = new FormData();

          requestData.append(
            'LaserExclaimerId',
            this.data.source_id.toString(),
          );
          requestData.append(
            'SpecialistExamTypeId',
            this.createSpecialistFindingForm
              .get('SpecialistExamTypeId')
              .value.toString(),
          );
          requestData.append(
            'Date',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );
          requestData.append('Description', '');
          requestData.append('_file', filesCollection[0]);
          requestData.append(
            'UploadedFileType',
            filesCollection[0].type.toLowerCase(),
          );
          requestData.append('OriginallyUploaded', 'true');

          this.progressBarService.start();

          const uploadSpecialistFindingFileSubscription = this.dashboardService
            .uploadSpecialistFindingFile(requestData)
            .subscribe(
              (response: boolean) => {
                this.progressBarService.stop();

                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Specijalistički nalaz je uspešno dodat.',
                  );

                  this.readSpecialistFindings();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(uploadSpecialistFindingFileSubscription);
        } else {
          this.snackbarService.showInfo(
            'Izabrani fajl mora biti slika ili PDF dokument.',
          );
        }
      }
    }
  }

  onOpenSpecialistFindingPreviewDialog(specialistFinding: SpecialistFinding) {
    if (
      specialistFinding &&
      typeof specialistFinding === 'object' &&
      specialistFinding.hasOwnProperty('Id') &&
      typeof specialistFinding.Id === 'number' &&
      isNaN(specialistFinding.Id) === false
    ) {
      this.progressBarService.start();

      const readSpecialistFindingSubscription = this.dashboardService
        .readSpecialistFinding(specialistFinding.Id)
        .subscribe(
          (response: SpecialistFinding) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('UploadedFileType') &&
                typeof response.UploadedFileType === 'string' &&
                response.UploadedFileType.length > 0 &&
                (response.UploadedFileType.toLowerCase().includes('image/') ===
                  true ||
                  response.UploadedFileType.toLowerCase() === 'application/pdf')
              ) {
                if (
                  response.hasOwnProperty('FileStream') &&
                  typeof response.FileStream === 'string' &&
                  response.FileStream.length > 0
                ) {
                  this.dialog.open(FilePreviewDialogComponent, {
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    data: {
                      file_type: response.UploadedFileType.toLowerCase(),
                      file_content: response.FileStream,
                    },
                  });
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoji dostupan sadržaj izabranog specijalističkog nalaza.',
                  );
                }
              } else if (
                response.UploadedFileType.toLowerCase().includes('document') ===
                true
              ) {
                function base64ToArrayBuffer(base64) {
                  var binaryString = window.atob(base64);
                  var binaryLen = binaryString.length;
                  var bytes = new Uint8Array(binaryLen);
                  for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                  }
                  return bytes;
                }
                const data = new Blob(
                  [base64ToArrayBuffer(response.FileStream)],
                  { type: response.UploadedFileType },
                );
                let hyperlinkElement: HTMLElement = document.createElement('A');
                hyperlinkElement.setAttribute(
                  'href',
                  URL.createObjectURL(data),
                );
                hyperlinkElement.setAttribute('download', '');
                hyperlinkElement.click();
              } else {
                this.snackbarService.showInfo(
                  'Izabrani specijalistički nalaz mora biti slika ili PDF dokument.',
                );
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(readSpecialistFindingSubscription);
    }
  }

  readSpecialistFindings() {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_type') &&
      typeof this.data.source_type === 'string' &&
      this.data.source_type.length > 0 &&
      (this.data.source_type.toLowerCase() === 'excimer_laser' ||
        this.data.source_type.toLowerCase() === 'operations') &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      const readSpecialistFindingsByExcimerLaserSubscription =
        this.dashboardService
          .readSpecialistFindingsByExcimerLaser(this.data.source_id)
          .subscribe(
            (response: SpecialistFinding[]) => {
              if (
                response &&
                typeof response === 'object' &&
                response.length > 0
              )
                this.dataSource = new MatTableDataSource<SpecialistFinding>(
                  response,
                );
            },
            (error) => this.errorHandlerService.handleError(error),
          );

      this.subscriptions.add(readSpecialistFindingsByExcimerLaserSubscription);
    }
  }

  onReadFromArchive() {
    if (
      this.createSpecialistFindingForm.get('SpecialistExamTypeId').value &&
      typeof this.createSpecialistFindingForm.get('SpecialistExamTypeId')
        .value === 'number' &&
      isNaN(
        this.createSpecialistFindingForm.get('SpecialistExamTypeId').value,
      ) === false &&
      this.data.hasOwnProperty('patient_id') &&
      typeof this.data.patient_id === 'number' &&
      isNaN(this.data.patient_id) === false
    ) {
      const dialogRef = this.dialog.open(
        SpecialistFindingsFromArhiveDialogComponent,
        {
          minWidth: '44vw',
          data: {
            specialistExamTypeId: this.createSpecialistFindingForm.get(
              'SpecialistExamTypeId',
            ).value,
            patientId: this.data.patient_id,
          },
        },
      );

      dialogRef.afterClosed().subscribe(() => {
        if (
          this.data &&
          typeof this.data === 'object' &&
          this.data.hasOwnProperty('has_specialist_findings') &&
          this.data.has_specialist_findings === true
        )
          this.readSpecialistFindings();
      });
    }
  }

  onDeleteSpecialistFinding(id: number) {
    this.progressBarService.start();

    const deleteSpecialistFindings = this.dashboardService
      .deleteSpecialistFindings(id)
      .subscribe(
        (response) => {
          if (response) {
            this.progressBarService.stop();

            this.snackbarService.showSuccess(
              'Specijalistički nalaz je uspešno obrisan.',
            );

            this.readSpecialistFindings();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(deleteSpecialistFindings);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
