import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

import { DashboardService } from '../../../shared/dashboard.service';
import { ErrorHandlerService } from '../../../../core/error-handler.service';
import { ProgressBarService } from '../../../../core/progress-bar.service';
import { Consent } from '../../../shared/consent.model';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { AuthorizationService } from '../../../../core/authorization.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-anesthesiological-examination-dialog',
  templateUrl: './update-anesthesiological-examination-dialog.component.html',
  styleUrls: ['./update-anesthesiological-examination-dialog.component.css'],
})
export class UpdateAnesthesiologicalExaminationDialogComponent
  implements OnInit, OnDestroy
{
  subscriptions: Subscription = new Subscription();
  displayedColumns: string[] = ['DateCreated', 'Actions'];
  dataSource = new MatTableDataSource<Consent>([]);

  // anesthesiologicalExaminationId: number | null = null;
  // gendersCollection: Gender[] = [];

  updateAnesthesiologicalExaminationForm = new UntypedFormGroup({
    GenderId: new UntypedFormControl(null, Validators.required),
    MainProblems: new UntypedFormControl(''),
    AnamnesisBySystems: new UntypedFormControl(''),
    PersonalAnamnesis: new UntypedFormControl(''),
    FamilyAnamnesis: new UntypedFormControl(''),
    SociologicalAnamnesys: new UntypedFormControl(''),
    TH: new UntypedFormControl(''),
    GeneralStatus: new UntypedFormControl(''),
    HeadAndNeck: new UntypedFormControl(''),
    Thorax: new UntypedFormControl(''),
    Abdomen: new UntypedFormControl(''),
    Extremities: new UntypedFormControl(''),
    TA: new UntypedFormControl(''),
    EKG: new UntypedFormControl(''),
    DG: new UntypedFormControl(''),
    Advice: new UntypedFormControl(''),
  });

  anesthesiologicalExaminationEditRole: boolean;

  constructor(
    private dialogRef: MatDialogRef<UpdateAnesthesiologicalExaminationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    //const readGendersSubscription = this.dashboardService.readGenders()
    //    .subscribe(
    //        (response: Gender[]) => {
    //            if (response.length > 0) this.gendersCollection = response;
    //        },
    //        error => this.errorHandlerService.handleError(error)
    //    );

    //this.subscriptions.add(readGendersSubscription);

    //if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false) && (this.data.hasOwnProperty("has_anesthesiological_examination") && this.data.has_anesthesiological_examination === true)) {
    //    const readAnesthesiologicalExaminationByExcimerLaserSubscription = this.dashboardService.readAnesthesiologicalExaminationByExcimerLaser(this.data.source_id)
    //        .subscribe(
    //            (response: AnesthesiologicalExamination) => {
    //                if (response && typeof response === "object") {
    //                    if (response.hasOwnProperty("Id") && typeof response.Id === "number" && isNaN(response.Id) === false) this.anesthesiologicalExaminationId = response.Id;
    //                    if (response.hasOwnProperty("GenderId") && typeof response.GenderId === "number" && isNaN(response.GenderId) === false) this.updateAnesthesiologicalExaminationForm.get('GenderId').setValue(response.GenderId);
    //                    if (response.hasOwnProperty("MainProblems") && typeof response.MainProblems === "string" && response.MainProblems.length > 0) this.updateAnesthesiologicalExaminationForm.get('MainProblems').setValue(response.MainProblems);
    //                    if (response.hasOwnProperty("AnamnesisBySystems") && typeof response.AnamnesisBySystems === "string" && response.AnamnesisBySystems.length > 0) this.updateAnesthesiologicalExaminationForm.get('AnamnesisBySystems').setValue(response.AnamnesisBySystems);
    //                    if (response.hasOwnProperty("PersonalAnamnesis") && typeof response.PersonalAnamnesis === "string" && response.PersonalAnamnesis.length > 0) this.updateAnesthesiologicalExaminationForm.get('PersonalAnamnesis').setValue(response.PersonalAnamnesis);
    //                    if (response.hasOwnProperty("FamilyAnamnesis") && typeof response.FamilyAnamnesis === "string" && response.FamilyAnamnesis.length > 0) this.updateAnesthesiologicalExaminationForm.get('FamilyAnamnesis').setValue(response.FamilyAnamnesis);
    //                    if (response.hasOwnProperty("SociologicalAnamnesys") && typeof response.SociologicalAnamnesys === "string" && response.SociologicalAnamnesys.length > 0) this.updateAnesthesiologicalExaminationForm.get('SociologicalAnamnesys').setValue(response.SociologicalAnamnesys);
    //                    if (response.hasOwnProperty("TH") && typeof response.TH === "string" && response.TH.length > 0) this.updateAnesthesiologicalExaminationForm.get('TH').setValue(response.TH);
    //                    if (response.hasOwnProperty("GeneralStatus") && typeof response.GeneralStatus === "string" && response.GeneralStatus.length > 0) this.updateAnesthesiologicalExaminationForm.get('GeneralStatus').setValue(response.GeneralStatus);
    //                    if (response.hasOwnProperty("HeadAndNeck") && typeof response.HeadAndNeck === "string" && response.HeadAndNeck.length > 0) this.updateAnesthesiologicalExaminationForm.get('HeadAndNeck').setValue(response.HeadAndNeck);
    //                    if (response.hasOwnProperty("Thorax") && typeof response.Thorax === "string" && response.Thorax.length > 0) this.updateAnesthesiologicalExaminationForm.get('Thorax').setValue(response.Thorax);
    //                    if (response.hasOwnProperty("Abdomen") && typeof response.Abdomen === "string" && response.Abdomen.length > 0) this.updateAnesthesiologicalExaminationForm.get('Abdomen').setValue(response.Abdomen);
    //                    if (response.hasOwnProperty("Extremities") && typeof response.Extremities === "string" && response.Extremities.length > 0) this.updateAnesthesiologicalExaminationForm.get('Extremities').setValue(response.Extremities);
    //                    if (response.hasOwnProperty("TA") && typeof response.TA === "string" && response.TA.length > 0) this.updateAnesthesiologicalExaminationForm.get('TA').setValue(response.TA);
    //                    if (response.hasOwnProperty("EKG") && typeof response.EKG === "string" && response.EKG.length > 0) this.updateAnesthesiologicalExaminationForm.get('EKG').setValue(response.EKG);
    //                    if (response.hasOwnProperty("DG") && typeof response.DG === "string" && response.DG.length > 0) this.updateAnesthesiologicalExaminationForm.get('DG').setValue(response.DG);
    //                    if (response.hasOwnProperty("Advice") && typeof response.Advice === "string" && response.Advice.length > 0) this.updateAnesthesiologicalExaminationForm.get('Advice').setValue(response.Advice);
    //                }
    //            },
    //            error => this.errorHandlerService.handleError(error)
    //        );

    //    this.subscriptions.add(readAnesthesiologicalExaminationByExcimerLaserSubscription);
    //}
    this.readAllConsents();

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.anesthesiologicalExaminationEditRole =
          permissions.includes('ANEIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  readAllConsents(): void {
    if (
      this.data &&
      typeof this.data === 'object' &&
      this.data.hasOwnProperty('source_id') &&
      typeof this.data.source_id === 'number' &&
      isNaN(this.data.source_id) === false
    ) {
      const readAllConsentsSubscription = this.dashboardService
        .getAllAnesthesiologyExamForLaserExclaimerId(this.data.source_id)
        .subscribe(
          (response: Consent[]) => {
            this.dataSource = new MatTableDataSource(response);
          },
          (error) => this.errorHandlerService.handleError(error),
        );
      this.subscriptions.add(readAllConsentsSubscription);
    }
  }

  //onUpdateAnesthesiologicalExamination() {
  //    // Make sure that anesthesiological examination ID is valid (null for new and number for existing anesthesiological examination)
  //    if (this.anesthesiologicalExaminationId === null || typeof this.anesthesiologicalExaminationId === "number" && isNaN(this.anesthesiologicalExaminationId) === false) {

  //        // Make sure that source type (currently Excimer laser or Operations) and source ID are valid
  //        if (this.data && typeof this.data === "object" && (this.data.hasOwnProperty("source_type") && typeof this.data.source_type === "string" && this.data.source_type.length > 0 && (this.data.source_type.toLowerCase() === "excimer_laser" || this.data.source_type.toLowerCase() === "operations")) && (this.data.hasOwnProperty("source_id") && typeof this.data.source_id === "number" && isNaN(this.data.source_id) === false)) {

  //            // Make sure that gender is selected
  //            if (typeof this.updateAnesthesiologicalExaminationForm.get('GenderId').value === "number" && isNaN(this.updateAnesthesiologicalExaminationForm.get('GenderId').value) === false) {
  //                let requestParameters: AnesthesiologicalExamination;

  //                switch (this.updateAnesthesiologicalExaminationForm.get('GenderId').value) {
  //                    case 1:
  //                    case 2:
  //                        requestParameters = new AnesthesiologicalExamination(this.anesthesiologicalExaminationId, this.data.source_id, this.updateAnesthesiologicalExaminationForm.get('GenderId').value, this.updateAnesthesiologicalExaminationForm.get('MainProblems').value, this.updateAnesthesiologicalExaminationForm.get('AnamnesisBySystems').value, this.updateAnesthesiologicalExaminationForm.get('PersonalAnamnesis').value, this.updateAnesthesiologicalExaminationForm.get('FamilyAnamnesis').value, this.updateAnesthesiologicalExaminationForm.get('SociologicalAnamnesys').value, this.updateAnesthesiologicalExaminationForm.get('TH').value, this.updateAnesthesiologicalExaminationForm.get('GeneralStatus').value, this.updateAnesthesiologicalExaminationForm.get('HeadAndNeck').value, this.updateAnesthesiologicalExaminationForm.get('Thorax').value, this.updateAnesthesiologicalExaminationForm.get('Abdomen').value, this.updateAnesthesiologicalExaminationForm.get('Extremities').value, this.updateAnesthesiologicalExaminationForm.get('TA').value, this.updateAnesthesiologicalExaminationForm.get('EKG').value, this.updateAnesthesiologicalExaminationForm.get('DG').value, this.updateAnesthesiologicalExaminationForm.get('Advice').value);
  //                        break;
  //                    case 3:
  //                        requestParameters = new AnesthesiologicalExamination(this.anesthesiologicalExaminationId, this.data.source_id, this.updateAnesthesiologicalExaminationForm.get('GenderId').value, this.updateAnesthesiologicalExaminationForm.get('MainProblems').value, this.updateAnesthesiologicalExaminationForm.get('AnamnesisBySystems').value, this.updateAnesthesiologicalExaminationForm.get('PersonalAnamnesis').value, this.updateAnesthesiologicalExaminationForm.get('FamilyAnamnesis').value, this.updateAnesthesiologicalExaminationForm.get('SociologicalAnamnesys').value, this.updateAnesthesiologicalExaminationForm.get('TH').value, this.updateAnesthesiologicalExaminationForm.get('GeneralStatus').value, this.updateAnesthesiologicalExaminationForm.get('HeadAndNeck').value, this.updateAnesthesiologicalExaminationForm.get('Thorax').value, this.updateAnesthesiologicalExaminationForm.get('Abdomen').value, this.updateAnesthesiologicalExaminationForm.get('Extremities').value, "", "", "", this.updateAnesthesiologicalExaminationForm.get('Advice').value);
  //                        break;
  //                    default:
  //                        requestParameters = new AnesthesiologicalExamination(this.anesthesiologicalExaminationId, this.data.source_id, this.updateAnesthesiologicalExaminationForm.get('GenderId').value, this.updateAnesthesiologicalExaminationForm.get('MainProblems').value, "", "", "", "", "", this.updateAnesthesiologicalExaminationForm.get('GeneralStatus').value, "", "", "", "", "", "", "", this.updateAnesthesiologicalExaminationForm.get('Advice').value);
  //                }

  //                this.progressBarService.start();

  //                const updateAnesthesiologicalExaminationByExcimerLaserSubscription = this.dashboardService.updateAnesthesiologicalExaminationByExcimerLaser(requestParameters)
  //                    .subscribe(
  //                        (response: boolean) => {
  //                            this.progressBarService.stop();

  //                            if (response === true) {
  //                                this.snackBar.open("Anesteziološki pregled je uspešno izmenjen.", "", {
  //                                    "duration": 3000,
  //                                    "verticalPosition": "top"
  //                                });

  //                                this.dialogRef.close(response);
  //                            }
  //                        },
  //                        error => this.errorHandlerService.handleError(error)
  //                    );

  //                this.subscriptions.add(updateAnesthesiologicalExaminationByExcimerLaserSubscription);
  //            }
  //        }
  //    }
  //}

  onConsentUpload(filesCollection: FileList): void {
    if (
      filesCollection &&
      filesCollection instanceof FileList &&
      filesCollection.length > 0
    ) {
      if (
        filesCollection[0] &&
        filesCollection[0] instanceof File &&
        typeof filesCollection[0].type === 'string' &&
        filesCollection[0].type.length > 0 &&
        this.data.hasOwnProperty('source_id') &&
        typeof this.data.source_id === 'number' &&
        isNaN(this.data.source_id) === false
      ) {
        if (
          filesCollection[0].type.toLowerCase().includes('image/') === true ||
          filesCollection[0].type.toLowerCase() === 'application/pdf' ||
          filesCollection[0].type.toLowerCase().includes('document') === true
        ) {
          let requestData = new FormData();
          requestData.append(
            'LaserExclaimerId',
            this.data.source_id.toString(),
          );
          requestData.append(
            'DateCreated',
            formatDate(new Date(), 'yyyy-MM-dd', 'sr-Latn'),
          );
          requestData.append('_file', filesCollection[0]);
          requestData.append(
            'UploadedFileType',
            filesCollection[0].type.toLowerCase(),
          );

          this.progressBarService.start();

          const onConsentUploadSubscription = this.dashboardService
            .updateAnesthesiologicalExaminationByExcimerLaser(requestData)
            .subscribe(
              (response) => {
                this.progressBarService.stop();
                if (response === true) {
                  this.snackbarService.showSuccess(
                    'Anesteziološki pregled je uspešno dodat.',
                  );
                  this.readAllConsents();
                }
              },
              (error) => this.errorHandlerService.handleError(error),
            );

          this.subscriptions.add(onConsentUploadSubscription);
        }
      } else {
        this.snackbarService.showInfo(
          'Izabrani fajl mora biti slika ili PDF dokument.',
        );
      }
    }
  }

  onOpenConsent(consent: Consent): void {
    if (
      consent &&
      consent.hasOwnProperty('Id') &&
      typeof consent.Id === 'number' &&
      isNaN(consent.Id) === false
    ) {
      this.progressBarService.start();

      const onOpenConsentSubscription = this.dashboardService
        .readAnesthesiologicalExaminationByExcimerLaser(consent.Id)
        .subscribe(
          (response: Consent) => {
            this.progressBarService.stop();

            if (response && typeof response === 'object') {
              if (
                response.hasOwnProperty('UploadedFileType') &&
                typeof response.UploadedFileType === 'string' &&
                response.UploadedFileType.length > 0 &&
                (response.UploadedFileType.toLowerCase().includes('image/') ===
                  true ||
                  response.UploadedFileType.toLowerCase() === 'application/pdf')
              ) {
                if (
                  response.hasOwnProperty('FileStream') &&
                  typeof response.FileStream === 'string' &&
                  response.FileStream.length > 0
                ) {
                  this.dialog.open(FilePreviewDialogComponent, {
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    data: {
                      file_type: response.UploadedFileType.toLowerCase(),
                      file_content: response.FileStream,
                    },
                  });
                } else {
                  this.snackbarService.showInfo(
                    'Ne postoji dostupan sadržaj izabranog anesteziološkog pregleda.',
                  );
                }
              } else if (
                response.UploadedFileType.toLowerCase().includes('document') ===
                true
              ) {
                function base64ToArrayBuffer(base64) {
                  var binaryString = window.atob(base64);
                  var binaryLen = binaryString.length;
                  var bytes = new Uint8Array(binaryLen);
                  for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                  }
                  return bytes;
                }
                const data = new Blob(
                  [base64ToArrayBuffer(response.FileStream)],
                  { type: response.UploadedFileType },
                );
                let hyperlinkElement: HTMLElement = document.createElement('A');
                hyperlinkElement.setAttribute(
                  'href',
                  URL.createObjectURL(data),
                );
                hyperlinkElement.setAttribute('download', '');
                hyperlinkElement.click();
              } else {
                this.snackbarService.showInfo(
                  'Izabran anesteziološki pregled mora biti slika ili PDF dokument.',
                );
              }
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(onOpenConsentSubscription);
    }
  }

  onDeleteConsent(id: number) {
    this.progressBarService.start();

    const deleteAnesthesiologyExam = this.dashboardService
      .deleteAnesthesiologyExam(id)
      .subscribe(
        (response) => {
          if (response) {
            this.progressBarService.stop();

            this.snackbarService.showSuccess(
              'Anesteziološki pregled je uspešno obrisan.',
            );
            this.readAllConsents();
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(deleteAnesthesiologyExam);
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
