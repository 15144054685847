import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { apiUrl } from 'src/app/shared/constants.service';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {}

  getAllStaff() {
    return this.http.get<any>(`${apiUrl}/adminPanel/getAllStaff`);
  }

  getStaffById(id: any) {
    return this.http.get<any>(`${apiUrl}/adminPanel/getStaffById/${id}`);
  }

  addStaff(data: any) {
    return this.http.post<any>(`${apiUrl}/adminPanel/addStaff`, data);
  }

  updateStaff(data: any) {
    return this.http.put<any>(`${apiUrl}/adminPanel/updateStaff`, data);
  }

  deleteStaff(id: any) {
    return this.http.delete<boolean>(`${apiUrl}/adminPanel/deleteStaff/${id}`);
  }

  resetPassword(id: any) {
    return this.http.get<boolean>(`${apiUrl}/adminPanel/resetPassword/${id}`);
  }

  changePassword(password: string, token: string) {
    return this.http.post(`${apiUrl}/adminPanel/ChangePassword`, { password, token});
  }
}

export interface UserDto {
  Id: number;
  Username: string;
  Name: string;
  Active: boolean;
  Title: string;
  JobId: number;
  Email: string;
  Color?: string;
}
