import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { apiUrl } from '../shared/constants.service'
import { ChangePasswordRequest } from '../change-password/shared/change-password-request.model'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    changePassword(arg0: ChangePasswordRequest) : Observable<boolean> {
        // TODO: Implement change and reset password
        throw new Error('Method not implemented.')
    }
    private userPayload:any;
    constructor(private http: HttpClient, private router: Router){
    }

    signUp(userObj:any){
        return this.http.post<any>(`${apiUrl}/access/admin`, userObj);
    }

    signIn(userObj:any){
        return this.http.post<any>(`${apiUrl}/access/login`, userObj)
    }

    signOut(){
        localStorage.clear();
        this.userPayload = null;
        this.router.navigate(['/login'])
    }

    storeToken(response:any): void{
        const token = response.JWT;
        localStorage.setItem('token', token);
    }

    getToken(){
        return localStorage.getItem('token');
    }

    isLoggedIn(): boolean {
        return !!localStorage.getItem('token');
    }

    decodedToken(){
        const jwtHelper = new JwtHelperService();
        const token = this.getToken();
        if (token) {
            console.log(jwtHelper.decodeToken(token))
            return jwtHelper.decodeToken(token);
        }
        return null;
    }

    getFullNameFromToken(){
        const payload = this.decodedToken();
        return payload ? payload.name : null;
    }

    getRoleFromToken(){
        const payload = this.decodedToken();
        return payload ? payload.role : null;
    }

    renewToken(){
        return this.http.get<any>(`${apiUrl}/access/RefreshJWT`);
    }
}