import { AppointmentType } from "./appointment-type.model";
import { Appointment } from "./appointment.model";
import { Patient } from "./patient.model";

export class CreateAppointmentRequest extends Appointment {
  Patient: Patient;

  constructor(Id: number | null, Date: string, Time: string, LengthMinutes: number | null, PatientId: number | null, DoctorId: number | null, PatientName: string, PhoneNumber: string, PatientDateOfBirth: string | null, NewPatient: boolean | null, Anamnesis: string, CenaPregleda: number | null, AppointmentTypes: AppointmentType[], Arrival: string | null, AmountPayed: number, Note: string, DatePayed: string | null, TimePayed: string | null, PassportNumber: string | null, Patient: Patient) {
    super(Id, Date, Time, LengthMinutes, PatientId, DoctorId, PatientName, PhoneNumber, PatientDateOfBirth, NewPatient, Anamnesis, CenaPregleda, AppointmentTypes, Arrival, AmountPayed, Note, DatePayed, TimePayed, PassportNumber);
        this.Patient = Patient;
    }
}
