export class Patient {
    Id: number | null;
    Name: string;
    MiddleName: string;
    GenderId: number | null;
    IdentificationNumber: string;
    PassportNumber: string;
    PersonalInsuranceNumber: string;
    DateOfBirth: string | null;
    Occupation: string;
    Address: string;
    PhoneNumber: string;
    Email: string;
    ChoosenDoctorId: number | null;
    Medicine: boolean | null;
    MedicineDescription: string;
    Allergies: boolean | null;
    AllergiesDescription: string;
    Hormones: boolean | null;
    HormonesDescription: string;
    Pregnant: boolean | null;
    PregnantMonth: number | null;
    Diabetes: boolean | null;
    PupilDialation: boolean | null;
    PupilDialationDescription: string;
    ChronicDisease: boolean | null;
    ChronicDiseaseDescription: string;
    EyeSurgery: boolean | null;
    EyeSurgeryDescription: string;
    OtherSurgery: boolean | null;
    OtherSurgeryDescription: string;
    DiagnosysId: number | null;
    DiagnosysText1: string;
    DiagnosysText2: string;
    City: string;
    State: string;
    NewPatient: boolean;
    PhoneNumber1: string;
    PhoneNumber2: string;
    AppointmentDate: string | null;
    AppointmentDateTo: string | null;
    DiagnosysIds: number[] | null;
    InterventionTypeLevel1Id: number | null;
    InterventionTypeLevel2Id: number | null;
    InterventionTypeLevel3Text: string | null;
    InterventionTypeLevel4Text: string | null;
    Intervention: string | null;
    Administrative: string | null;
  MedicalNote: string | null;
  ChartProtocol: string | null;
  NewUser: boolean;
    
    constructor(
        Id: number | null,
        Name: string,
        MiddleName: string,
        GenderId: number | null,
        IdentificationNumber: string,
        PassportNumber: string,
        PersonalInsuranceNumber: string,
        DateOfBirth: string | null,
        Occupation: string,
        Address: string,
        PhoneNumber: string,
        Email: string,
        ChoosenDoctorId: number | null,
        Medicine: boolean | null,
        MedicineDescription: string,
        Allergies: boolean | null,
        AllergiesDescription: string,
        Hormones: boolean | null,
        HormonesDescription: string,
        Pregnant: boolean | null,
        PregnantMonth: number | null,
        Diabetes: boolean | null,
        PupilDialation: boolean | null,
        PupilDialationDescription: string,
        ChronicDisease: boolean | null,
        ChronicDiseaseDescription: string,
        EyeSurgery: boolean | null,
        EyeSurgeryDescription: string,
        OtherSurgery: boolean | null,
        OtherSurgeryDescription: string,
        DiagnosysId: number | null,
        DiagnosysText1: string,
        DiagnosysText2: string,
        City: string,
        State: string,
        NewPatient: boolean,
        PhoneNumber1: string,
        PhoneNumber2: string,
        AppointmentDate: string | null,
        AppointmentDateTo: string | null,
        DiagnosysIds: number[] | null,
        InterventionTypeLevel1Id: number | null,
        InterventionTypeLevel2Id: number | null,
        InterventionTypeLevel3Text: string | null,
        InterventionTypeLevel4Text: string | null,
        Intervention: string | null,
        Administrative: string | null,
        MedicalNote: string | null,
      ChartProtocol: string | null,
      NewUser: boolean | null
    ) {
            this.Id = Id;
            this.Name = Name;
            this.MiddleName = MiddleName;
            this.GenderId = GenderId;
            this.IdentificationNumber = IdentificationNumber;
            this.PassportNumber = PassportNumber;
            this.PersonalInsuranceNumber = PersonalInsuranceNumber;
            this.DateOfBirth = DateOfBirth;
            this.Occupation = Occupation;
            this.Address = Address;
            this.PhoneNumber = PhoneNumber;
            this.Email = Email;
            this.ChoosenDoctorId = ChoosenDoctorId;
            this.Medicine = Medicine;
            this.MedicineDescription = MedicineDescription;
            this.Allergies = Allergies;
            this.AllergiesDescription = AllergiesDescription;
            this.Hormones = Hormones;
            this.HormonesDescription = HormonesDescription;
            this.Pregnant = Pregnant;
            this.PregnantMonth = PregnantMonth;
            this.Diabetes = Diabetes;
            this.PupilDialation = PupilDialation;
            this.PupilDialationDescription = PupilDialationDescription;
            this.ChronicDisease = ChronicDisease;
            this.ChronicDiseaseDescription = ChronicDiseaseDescription;
            this.EyeSurgery = EyeSurgery;
            this.EyeSurgeryDescription = EyeSurgeryDescription;
            this.OtherSurgery = OtherSurgery;
            this.OtherSurgeryDescription = OtherSurgeryDescription;
            this.DiagnosysId = DiagnosysId;
            this.DiagnosysText1 = DiagnosysText1;
            this.DiagnosysText2 = DiagnosysText2;
            this.City = City;
            this.State = State;
            this.NewPatient = NewPatient;
            this.PhoneNumber1 = PhoneNumber1;
            this.PhoneNumber2 = PhoneNumber2;
            this.AppointmentDate = AppointmentDate;
            this.AppointmentDateTo = AppointmentDateTo;
            this.DiagnosysIds = DiagnosysIds;
            this.InterventionTypeLevel1Id = InterventionTypeLevel1Id;
            this.InterventionTypeLevel2Id = InterventionTypeLevel2Id;
            this.InterventionTypeLevel3Text = InterventionTypeLevel3Text;
            this.InterventionTypeLevel4Text = InterventionTypeLevel4Text;
            this.Intervention = Intervention;
            this.Administrative = Administrative;
      this.MedicalNote = MedicalNote;
      this.ChartProtocol = ChartProtocol;
      this.NewUser = NewUser;
    }
}
