import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Import the Angular Material components
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorHandlerService } from '../../../core/error-handler.service';
import { ProgressBarService } from '../../../core/progress-bar.service';

import { Employee } from '../../shared/employee.model';
import { Titles } from 'src/app/shared/enum/titles-enum';
import { StaffService, UserDto } from 'src/app/core/services/staff.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-update-employee-dialog',
  templateUrl: './update-employee-dialog.component.html',
  styleUrls: ['./update-employee-dialog.component.css'],
})
export class UpdateEmployeeDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  employee: Employee = null;
  titles: string[] = Object.values(Titles);

  editEmployeeForm = new UntypedFormGroup({
    Name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(64),
    ]),
    Active: new UntypedFormControl(false, Validators.required),
    Title: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private dialogRef: MatDialogRef<UpdateEmployeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Employee,
    private staffService: StaffService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit() {
    if (this.data && typeof this.data === 'object') {
      if (
        this.data.hasOwnProperty('Name') &&
        typeof this.data.Name === 'string' &&
        this.data.Name.length > 0
      )
        this.editEmployeeForm.get('Name').setValue(this.data.Name);
      if (
        this.data.hasOwnProperty('Active') &&
        typeof this.data.Active === 'boolean'
      )
        this.editEmployeeForm.get('Active').setValue(this.data.Active);
      if (
        this.data.hasOwnProperty('Title') &&
        typeof this.data.Title === 'string' &&
        this.data.Title.length > 0
      )
        this.editEmployeeForm.get('Title').setValue(this.data.Title);

      this.employee = this.data;
    }
  }

  onChangeEmployeeActiveState() {
    if (typeof this.editEmployeeForm.get('Active').value === 'boolean')
      this.editEmployeeForm
        .get('Active')
        .setValue(!this.editEmployeeForm.get('Active').value);
  }

  updateEmployee() {
    if (this.employee && typeof this.employee === 'object') {
      if (
        this.employee.hasOwnProperty('Name') &&
        typeof this.editEmployeeForm.get('Name').value === 'string' &&
        this.editEmployeeForm.get('Name').value.length > 0
      )
        this.employee.Name = this.editEmployeeForm.get('Name').value;
      if (
        this.employee.hasOwnProperty('Active') &&
        typeof this.editEmployeeForm.get('Active').value === 'boolean'
      )
        this.employee.Active = this.editEmployeeForm.get('Active').value;
      if (
        this.employee.hasOwnProperty('Title') &&
        typeof this.editEmployeeForm.get('Title').value === 'string' &&
        this.editEmployeeForm.get('Title').value.length > 0
      )
        this.employee.Title = this.editEmployeeForm.get('Title').value;

      this.progressBarService.start();

      let userDto: UserDto = {
        Id: this.employee.Id,
        Username: this.employee.UserName,
        Name: this.employee.Name,
        Active: this.employee.Active,
        Title: this.employee.Title,
        JobId: this.employee.JobId,
        Email: this.employee.Email,
        Color: this.employee.Color,
      };

      const updateEmployeeSubscription = this.staffService
        .updateStaff(userDto)
        .subscribe(
          (response: boolean) => {
            this.progressBarService.stop();

            if (response === true) {
              this.snackbarService.showSuccess('Osoblje je uspešno izmenjeno.');

              this.dialogRef.close(response);
            }
          },
          (error) => this.errorHandlerService.handleError(error),
        );

      this.subscriptions.add(updateEmployeeSubscription);
    }
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
