import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpClientModule,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { apiUrl } from '../shared/constants.service';
import { AuthenticationService } from './authentication.service';

import { Employee } from '../dashboard/shared/employee.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {}

  readUser(): Observable<Employee> {
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.authenticationService.getToken(),
    );
    return this.http.get<Employee>(apiUrl + '/access/getuser', {
      headers: header,
    });
  }

  // sv_ui is the abbreviation of sveti_vid_user_id
  getUserId(): Observable<number> {
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.authenticationService.getToken(),
    );
    return this.http.get<number>(`${apiUrl}/access/getuserid`, {
      headers: header,
    });
  }

  readPermissions(): Observable<string[]> {
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.authenticationService.getToken(),
    );
    return this.http.get<string[]>(apiUrl + '/access/getuserpermissions', {
      headers: header,
    });
  }

  getCurrentUserJobId(): Observable<Number> {
    return this.readUser().pipe(
      map((user: Employee) => user.JobId)
    );
  }
}
