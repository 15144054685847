export class ReadAppointmentsRequest {
    UserName: string;
    UserId: number | null;
    ForDate: string;

    constructor(UserName: string, UserId: number | null, ForDate: string) {
        this.UserName = UserName;
        this.UserId = UserId;
        this.ForDate = ForDate;
    }
}