import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ApartmentDialogComponent } from './apartment-dialog/apartment-dialog.component';
import { Observable } from 'rxjs';
import { ApartmentBooking } from '../shared/apartment-booking.model';
import { DataService } from '../shared/event-calendar/data.service';
import { EventCalendarComponent } from '../shared/event-calendar/event-calendar.component';

@Component({
  selector: 'app-apartments',
  templateUrl: './apartments.component.html',
  styleUrls: ['./apartments.component.scss'],
})
export class ApartmentsComponent implements OnInit {
  @ViewChild(EventCalendarComponent) eventCalendar!: EventCalendarComponent;
  apartmentList: any = [];
  editRole: boolean;

  permissions: string[] = [];

  constructor(
    public authorizationService: AuthorizationService,
    private readonly dashboardService: DashboardService,
    private readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.cd.detectChanges();
    this.getallapartments();
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('APARTIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  openDialog() {
    this.dialog.open(ApartmentDialogComponent, {
      width: '40vw',
      data: this.apartmentList,
    });
  }

  getallapartments() {
    this.apartmentList = this.dashboardService.getallapartments().subscribe(
      (data: any) => {
        this.apartmentList = data;
      },
      (error: any) => {
        console.error('Error fetching apartment list:', error);
      },
    );
  }

  getColorForApartment(apartmentColor: string) {
    switch (apartmentColor) {
      case 'Green':
        return DataService.colors.green;
      case 'Yellow':
        return DataService.colors.yellow;
      case 'Red':
        return DataService.colors.red;
      case 'Gray':
        return DataService.colors.gray;
      case 'Blue':
        return DataService.colors.blue;
      default:
        return DataService.colors.green;
    }
  }

  ngAfterViewInit() {
    // Ensure calendar is fully rendered before allowing print
    setTimeout(() => {
      this.ensureCalendarRender();
    }, 1000);
  }

  ensureCalendarRender() {
    const calendar: any = document.querySelector('.printable');
    if (calendar) {
      calendar.style.visibility = 'visible';
    }
  }

  printCalendar() {
    setTimeout(() => {
      window.print();
    }, 500); // Adjust delay if necessary
  }
}
