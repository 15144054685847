import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subscription, forkJoin, Observable } from 'rxjs';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import { ErrorHandlerService } from '../../core/error-handler.service';
import { ProgressBarService } from '../../core/progress-bar.service';

import { Gender } from '../shared/gender.model';
import { Employee } from '../shared/employee.model';
import { Diagnosis } from '../shared/diagnosis.model';
import { Patient } from '../shared/patient.model';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { InterventionType } from '../shared/intervention-type.model';
import { DeleteDialogComponent } from '../operation-administration/dialogs/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  permissions: string[] = [];
  maxPatientDateOfBirth = new Date();
  gendersCollection: Gender[] = [];
  doctorsCollection: Employee[] = [];
  diagnosisCollection: Diagnosis[] = [];
  iterventionTypeLevel1Collection: InterventionType[] = [];
  iterventionTypeLevel2Collection: InterventionType[] = [];

  displayedColumns: string[] = [
    'Name',
    'DateOfBirth',
    'IdentificationNumber',
    'PersonalInsuranceNumber',
    'PhoneNumber',
    'Email',
    'Actions',
    'Delete',
  ];
  allPatients: any = [];
  dataSource = new MatTableDataSource<Patient>([]);

  editRole: boolean;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('diagnosisSearchInput', { static: false })
  diagnosisSearchInput: ElementRef<HTMLInputElement>;

  searchPatientsForm = new UntypedFormGroup({
    Name: new UntypedFormControl('', Validators.maxLength(64)),
    MiddleName: new UntypedFormControl('', Validators.maxLength(64)),
    GenderId: new UntypedFormControl(null),
    IdentificationNumber: new UntypedFormControl(''),
    PassportNumber: new UntypedFormControl(''),
    PersonalInsuranceNumber: new UntypedFormControl(
      '',
      Validators.pattern('[0-9]*'),
    ),
    DateOfBirth: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    Occupation: new UntypedFormControl('', Validators.maxLength(64)),
    Address: new UntypedFormControl('', Validators.maxLength(64)),
    PhoneNumber: new UntypedFormControl('', [
      Validators.maxLength(32),
      Validators.pattern('[0-9]+'),
    ]),
    Email: new UntypedFormControl('', Validators.email),
    ChoosenDoctorId: new UntypedFormControl(null),
    DiagnosysId: new UntypedFormControl(null),
    DiagnosysText1: new UntypedFormControl(''),
    DiagnosysText2: new UntypedFormControl(''),
    AppointmentDate: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    AppointmentDateTo: new UntypedFormControl({
      value: null,
      disabled: true,
    }),
    DiagnosysIds: new UntypedFormControl(null),
    InterventionTypeLevel1Id: new UntypedFormControl(null),
    InterventionTypeLevel2Id: new UntypedFormControl(null),
    InterventionTypeLevel3Text: new UntypedFormControl(null),
    InterventionTypeLevel4Text: new UntypedFormControl(null),
    Intervention: new UntypedFormControl(null),
  });

  diagnosisSearchFormControl = new UntypedFormControl();
  selectedDiagnosisCollection: Diagnosis[] = [];
  matchedDiagnosisCollection: Observable<Diagnosis[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public authorizationService: AuthorizationService,
    private router: Router,
    private dashboardService: DashboardService,
    private errorHandlerService: ErrorHandlerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
  ) {
    this.matchedDiagnosisCollection =
      this.diagnosisSearchFormControl.valueChanges.pipe(
        startWith(null),
        map((searchTerm: string | null) =>
          typeof searchTerm === 'string' && searchTerm.length > 0
            ? this.filterDiagnosisByName(searchTerm)
            : this.diagnosisCollection.slice(),
        ),
      );
  }

  ngOnInit() {
    this.progressBarService.start();

    const forkJoinSubscription = forkJoin([
      this.dashboardService.readGenders(),
      this.dashboardService.readDoctors(),
      this.dashboardService.readDiagnosis(),
      this.dashboardService.getInterventionTypesByLevel(1),
      this.dashboardService.getInterventionTypesByLevel(2),
    ]).subscribe(
      ([response1st, response2nd, response3rd, response4th, response5th]) => {
        this.progressBarService.stop();

        if (
          response1st &&
          typeof response1st === 'object' &&
          response1st.length > 0
        )
          this.gendersCollection = response1st;
        if (
          response2nd &&
          typeof response2nd === 'object' &&
          response2nd.length > 0
        )
          this.doctorsCollection = response2nd;
        if (
          response3rd &&
          typeof response3rd === 'object' &&
          response3rd.length > 0
        )
          this.diagnosisCollection = response3rd;
        if (
          response4th &&
          typeof response4th === 'object' &&
          response4th.length > 0
        )
          this.iterventionTypeLevel1Collection = response4th;
        if (
          response5th &&
          typeof response5th === 'object' &&
          response5th.length > 0
        )
          this.iterventionTypeLevel2Collection = response5th;
      },
      (error) => this.errorHandlerService.handleError(error),
    );

    this.subscriptions.add(forkJoinSubscription);

    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('PACIZMENA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  initDataSource(list: any) {
    this.dataSource = new MatTableDataSource<Patient>(list);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onDeleteDatePickerValue(pickerType: string, dateRange?: string) {
    if (
      typeof pickerType === 'string' &&
      pickerType.length > 0 &&
      (pickerType.toLowerCase() === 'dateofbirth' ||
        pickerType.toLowerCase() === 'arrival')
    ) {
      switch (pickerType.toLowerCase()) {
        case 'dateofbirth':
          this.searchPatientsForm.get('DateOfBirth').reset();
          break;
        case 'arrival': {
          if (dateRange === 'from') {
            this.searchPatientsForm.get('AppointmentDate').reset();
          } else if (dateRange === 'to') {
            this.searchPatientsForm.get('AppointmentDateTo').reset();
          }
        }
      }
    }
  }

  onSearchPatients() {
    this.dataSource = new MatTableDataSource<Patient>([]);

    this.progressBarService.start();

    const searchPatientsSubscription = this.dashboardService
      .searchPatients(
        new Patient(
          null,
          this.searchPatientsForm.value.Name,
          this.searchPatientsForm.value.MiddleName,
          this.searchPatientsForm.value.GenderId,
          this.searchPatientsForm.value.IdentificationNumber,
          this.searchPatientsForm.value.PassportNumber,
          this.searchPatientsForm.value.PersonalInsuranceNumber,
          this.readDateOfBirthParameter(),
          this.searchPatientsForm.value.Occupation,
          this.searchPatientsForm.value.Address,
          this.searchPatientsForm.value.PhoneNumber,
          this.searchPatientsForm.value.Email,
          this.searchPatientsForm.value.ChoosenDoctorId,
          null,
          '',
          null,
          '',
          null,
          '',
          null,
          null,
          null,
          null,
          '',
          null,
          '',
          null,
          '',
          null,
          '',
          this.searchPatientsForm.value.DiagnosysId,
          this.searchPatientsForm.value.DiagnosysText1,
          this.searchPatientsForm.value.DiagnosysText2,
          '',
          '',
          false,
          '',
          '',
          this.readArrivalDateParameter(),
          this.readArrivalDateToParameter(),
          this.readDiagnosesParameter(),
          this.searchPatientsForm.value.InterventionTypeLevel1Id,
          this.searchPatientsForm.value.InterventionTypeLevel2Id,
          this.searchPatientsForm.value.InterventionTypeLevel3Text,
          this.searchPatientsForm.value.InterventionTypeLevel4Text,
          this.searchPatientsForm.value.Intervention,
          null,
          null,
          null,
          null,
        ),
      )
      .subscribe(
        (response: Patient[]) => {
          this.progressBarService.stop();

          if (response.length === 0) {
            this.snackbarService.showInfo(
              'Ne postoje dostupni pacijenti za izabrane parametre pretrage.',
            );
          } else if (response.length > 0) {
            this.allPatients = response;
            this.initDataSource(response);
          }
        },
        (error) => this.errorHandlerService.handleError(error),
      );

    this.subscriptions.add(searchPatientsSubscription);
  }

  onOpenPatientPage(patient: Patient) {
    if (
      patient &&
      typeof patient === 'object' &&
      patient.hasOwnProperty('Id') &&
      typeof patient.Id === 'number' &&
      isNaN(patient.Id) === false
    ) {
      this.dashboardService.setPatientId(patient.Id);
      this.router.navigate(['/dashboard/patient']);
    }
  }

  deletePatient(patientId: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progressBarService.start();
        this.dashboardService.deletePatient(patientId);
        const deletePatientSubscription = this.dashboardService
          .deletePatient(patientId)
          .subscribe(
            (response: boolean) => {
              this.progressBarService.stop();
              this.allPatients = this.allPatients.filter(
                (patient: Patient) => patient.Id !== patientId,
              );
              this.initDataSource(this.allPatients);
              this.snackbarService.showSuccess('Uspešno obrisan pacijent.');
              this.onSearchPatients();
            },
            (error) => this.errorHandlerService.handleError(error),
          );

        this.subscriptions.add(deletePatientSubscription);
      }
    });
  }

  readDateOfBirthParameter(): string | null {
    return this.searchPatientsForm.get('DateOfBirth').value instanceof Date
      ? formatDate(
          this.searchPatientsForm.get('DateOfBirth').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readArrivalDateParameter(): string | null {
    return this.searchPatientsForm.get('AppointmentDate').value instanceof Date
      ? formatDate(
          this.searchPatientsForm.get('AppointmentDate').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  readArrivalDateToParameter(): string | null {
    return this.searchPatientsForm.get('AppointmentDateTo').value instanceof
      Date
      ? formatDate(
          this.searchPatientsForm.get('AppointmentDateTo').value,
          'yyyy-MM-dd',
          'sr-Latn',
        )
      : null;
  }

  private filterDiagnosisByName(searchTerm: string): Diagnosis[] {
    return typeof searchTerm === 'string' && searchTerm.length > 0
      ? this.diagnosisCollection.filter(
          (currentElement) =>
            currentElement &&
            typeof currentElement === 'object' &&
            currentElement.hasOwnProperty('Name') &&
            typeof currentElement.Name === 'string' &&
            currentElement.Name.length > 0 &&
            currentElement.Name.toLowerCase().includes(
              searchTerm.toLowerCase(),
            ),
        )
      : [];
  }

  onSelectDiagnosisOption(event: MatAutocompleteSelectedEvent) {
    if (
      event &&
      typeof event === 'object' &&
      event.hasOwnProperty('option') &&
      event.option &&
      typeof event.option === 'object' &&
      event.option.hasOwnProperty('value') &&
      event.option.value &&
      typeof event.option.value === 'object'
    ) {
      if (
        this.selectedDiagnosisCollection &&
        typeof this.selectedDiagnosisCollection === 'object'
      ) {
        if (this.selectedDiagnosisCollection.length > 0) {
          if (this.selectedDiagnosisCollection.includes(event.option.value)) {
            this.snackbarService.showInfo('Dijagnoza je već izabrana.');
          } else this.selectedDiagnosisCollection.push(event.option.value);
        } else this.selectedDiagnosisCollection.push(event.option.value);
      }

      this.diagnosisSearchInput.nativeElement.value = '';
      this.diagnosisSearchFormControl.setValue(null);
    }
  }

  onDeleteDiagnosisChip(diagnosis: Diagnosis) {
    if (
      diagnosis &&
      typeof diagnosis === 'object' &&
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0
    ) {
      if (this.selectedDiagnosisCollection.includes(diagnosis))
        this.selectedDiagnosisCollection.splice(
          this.selectedDiagnosisCollection.indexOf(diagnosis),
          1,
        );
    }
  }

  readDiagnosesParameter(): number[] {
    let diagnosisCollection: number[] = [];

    if (
      this.selectedDiagnosisCollection &&
      typeof this.selectedDiagnosisCollection === 'object' &&
      this.selectedDiagnosisCollection.length > 0
    ) {
      for (let diagnosis of this.selectedDiagnosisCollection) {
        if (
          diagnosis &&
          typeof diagnosis === 'object' &&
          diagnosis.hasOwnProperty('Id') &&
          typeof diagnosis.Id === 'number' &&
          isNaN(diagnosis.Id) === false
        ) {
          diagnosisCollection.push(diagnosis.Id);
        }
      }
    }

    return diagnosisCollection;
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed (link https://rxjs-dev.firebaseapp.com/guide/subscription )
    this.subscriptions.unsubscribe();
  }
}
