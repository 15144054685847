import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';
import { DashboardService } from '../shared/dashboard.service';
import * as moment from 'moment';
import { SurgeonWorkPlan } from '../shared/surgeon-work-plan.model';
import { Subscription, Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SrugeonDialogComponent } from './srugeon-dialog/srugeon-dialog.component';
import { formatDate } from '@angular/common';
import { ProgressBarService } from '../../core/progress-bar.service';
import { SurgeonListComponent } from './surgeon-list/surgeon-list.component';

@Component({
  selector: 'app-surgeon-work-plan',
  templateUrl: './surgeon-work-plan.component.html',
  styleUrls: ['./surgeon-work-plan.component.scss'],
})
export class SurgeonWorkPlanComponent implements OnInit, OnDestroy {
  @ViewChildren(SurgeonListComponent)
  surgeonLists: QueryList<SurgeonListComponent>;
  refreshIds: any = [];

  subscriptions: Subscription = new Subscription();
  surgeonList$: Observable<any>;
  viewDate: Date = new Date();
  selectedDateFromCalendar: Date;
  months: Array<any>;
  editRole: boolean;

  permissions: string[] = [];

  constructor(
    public authorizationService: AuthorizationService,
    private readonly dashboardService: DashboardService,
    private readonly dialog: MatDialog,
    private readonly progressBarService: ProgressBarService,
  ) {}

  ngOnInit() {
    this.renderCalendar();
    this.authorizationService.readPermissions().subscribe(
      (permissions) => {
        this.permissions = permissions;
        this.editRole = permissions.includes('HIRPLRADIZMENAPLANA');
      },
      (error) => {
        console.error('Error fetching permissions:', error);
      },
    );
  }

  onPanelOpened(workPlanId: any) {
    this.surgeonLists.toArray().forEach((surgeonList) => {
      if (surgeonList.surgeon.Id === workPlanId) {
        surgeonList.getAllLocks();
      }
    });
  }

  renderCalendar() {
    this.progressBarService.start();

    const getAllSurgeonWorkingDaysSubscription = this.dashboardService
      .getAllSurgeonWorkingDays(moment(this.viewDate).year())
      .subscribe((response: SurgeonWorkPlan[]) => {
        this.progressBarService.stop();
        this.months = this.generateCalendarInput(response);
      });

    this.subscriptions.add(getAllSurgeonWorkingDaysSubscription);
  }

  reloadCalendarAfterDeleteWorkingPlan() {
    this.renderCalendar();
  }

  subtractYear(): void {
    this.viewDate = moment(this.viewDate).subtract(1, 'years').toDate();
    this.surgeonList$ = of([]);
    this.renderCalendar();
  }

  addYear(): void {
    this.viewDate = moment(this.viewDate).add(1, 'years').toDate();
    this.surgeonList$ = of([]);
    this.renderCalendar();
  }

  onSelectedDay(date: Date = this.selectedDateFromCalendar) {
    this.selectedDateFromCalendar = date;
    const param = formatDate(date, 'yyyy-MM-ddThh:mm:ss', 'sr-Latn');
    if (param) {
      this.surgeonList$ =
        this.dashboardService.getAllSurgeonWorkingDaysForDate(param);
    }
  }

  addWorkPlanDialog() {
    const dialogRef = this.dialog.open(SrugeonDialogComponent, {
      width: '40vw',
      data: '',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.surgeonList$ = of([]);
        this.selectedDateFromCalendar = null;
        this.renderCalendar();
      }
    });
  }

  private generateCalendarInput(data) {
    const calendar = [
      { month: 'Januar', events: [] },
      { month: 'Februar', events: [] },
      { month: 'Mart', events: [] },
      { month: 'April', events: [] },
      { month: 'Maj', events: [] },
      { month: 'Jun', events: [] },
      { month: 'Jul', events: [] },
      { month: 'Avgust', events: [] },
      { month: 'Septembar', events: [] },
      { month: 'Oktobar', events: [] },
      { month: 'Novembar', events: [] },
      { month: 'Decembar', events: [] },
    ];

    data.forEach((item: any) => {
      let startMonth = new Date(item.Start).getMonth();
      let endMonth = new Date(item.End).getMonth();
      if (startMonth !== endMonth) {
        for (let i = startMonth; i <= endMonth; i++) {
          calendar[i].events.push(this.transformWorkDay(item));
        }
      } else {
        calendar[startMonth].events.push(this.transformWorkDay(item));
      }
    });

    return calendar;
  }

  transformWorkDay(day: any) {
    const param = {
      start: moment(day.Start).toDate(),
      end: moment(day.End).toDate(),
      title: day.Title,
      color: { primary: day.Color, secondary: 'blue' },
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    };

    return param;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.surgeonLists.forEach((surgeonList) => {
      surgeonList.cancelRefresh();
    });
  }
}
