export class StatisticsFilter {
    DateFrom: string;
    DateTo: string;
    DoctorList: number[];

    constructor(dateFrom: string | null, dateTo: string | null, doctorList: number[] | []) {
        this.DateFrom = dateFrom;
        this.DateTo = dateTo;
        this.DoctorList = doctorList;
    }
}
