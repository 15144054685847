import { Pipe, PipeTransform } from '@angular/core';

import { AppointmentType } from "./appointment-type.model";

@Pipe({ name: 'namesOfAppointmentTypes' })
export class NamesOfAppointmentTypesPipe implements PipeTransform {

  transform(collection: AppointmentType[]): string {
      let names = "";

      if (collection && typeof collection === "object" && collection.length > 0) {
          for (let appointmentType of collection) {
              if (typeof appointmentType === "object" && appointmentType.hasOwnProperty("Name") && typeof appointmentType.Name === "string" && appointmentType.Name.length > 0) {
                  if (names.length > 0) names += ", " + appointmentType.Name;
                  else names = appointmentType.Name;
              }
          }
      }

      return names;
  }
}